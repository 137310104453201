const image_load_async = (src) => {
	return new Promise((res,rej) => {
		var img = new window.Image()
		img.crossOrigin = "anonymous"
		img.src = src
		img.onload = function(){
			res(img)
		}	
	})
}

export const drawCanvasPattern = (context,pattern_name,length) => {
	if(!length){
		length = 100
	}

	if(pattern_name == 'diagonal_lines'){ // brick
		context.beginPath();
		context.moveTo(0.5*length,0);
		context.lineTo(0,0.5*length);
		context.moveTo(length,0.5*length);
		context.lineTo(0.5*length,length);
		context.stroke();
	}else if(pattern_name == 'double_diagonal_lines'){ // steel
		context.beginPath();
		context.moveTo(0.4*length,0);
		context.lineTo(0,0.4*length);
		context.moveTo(length,0.4*length);
		context.lineTo(0.4*length,length);
		context.moveTo(0.6*length,0);
		context.lineTo(0,0.6*length);
		context.moveTo(length,0.6*length);
		context.lineTo(0.6*length,length);
		context.stroke();
	}else if(pattern_name == 'triple_diagonal_lines'){ // aluminium
		context.beginPath();
		context.moveTo(0.5*length,0);
		context.lineTo(0,0.5*length);
		context.moveTo(length,0.5*length);
		context.lineTo(0.5*length,length);

		context.moveTo(0.7*length,0);
		context.lineTo(0,0.7*length);
		context.moveTo(length,0.7*length);
		context.lineTo(0.7*length,length);

		context.moveTo(0.3*length,0);
		context.lineTo(0,0.3*length);
		context.moveTo(length,0.3*length);
		context.lineTo(0.3*length,length);

		context.stroke();
	}else if(pattern_name == 'diagonal_cross'){ // concrete block
		context.beginPath();
		context.moveTo(0.5*length,0);
		context.lineTo(0,0.5*length);
		context.moveTo(0.5*length,0);
		context.lineTo(length,0.5*length);
		context.moveTo(length,0.5*length);
		context.lineTo(0.5*length,length);
		context.moveTo(0.5*length,length);
		context.lineTo(0,0.5*length);
		context.stroke();
	}else if(pattern_name == 'straight_cross'){ // concrete block
		context.beginPath();
		context.moveTo(0.5*length,0);
		context.lineTo(0.5*length,length);
		context.moveTo(0,0.5*length);
		context.lineTo(length,0.5*length);
		context.stroke();
	}else if(pattern_name == 'full_bubbles'){ //concrete wall
		context.beginPath();
		context.arc(0.25*length, 0.25*length, 0.25*length, 0, 2 * Math.PI, false);
		context.stroke();
		context.beginPath();
		context.arc(0.75*length, 0.25*length, 0.25*length, 0, 2 * Math.PI, false);
		context.stroke();
		context.beginPath();
		context.arc(0.25*length, 0.75*length, 0.25*length, 0, 2 * Math.PI, false);
		context.stroke();
		context.beginPath();
		context.arc(0.75*length, 0.75*length, 0.25*length, 0, 2 * Math.PI, false);
		context.stroke();
	}else if(pattern_name == 'bubbles_with_gaps'){ //concrete wall
		var radius = 0.15*length
		var gap = (length - (4*radius))/4
		context.beginPath();
		context.arc(gap+radius, gap+radius, radius, 0, 2 * Math.PI, false);
		context.fill();
		context.beginPath();
		context.arc(3*gap+3*radius, gap+radius, radius, 0, 2 * Math.PI, false);
		context.fill();
		context.beginPath();
		context.arc(gap+radius, 3*gap+3*radius, radius, 0, 2 * Math.PI, false);
		context.fill();
		context.beginPath();
		context.arc(3*gap+3*radius, 3*gap+3*radius, radius, 0, 2 * Math.PI, false);
		context.fill();
		
	}else if(pattern_name == 'glass'){ //concrete wall
		context.beginPath();
		context.moveTo(0.3*length,0.4*length);
		context.lineTo(0.5*length,0);
		context.moveTo(0.3*length,0.6*length);
		context.lineTo(0.5*length,0.2*length);
		context.moveTo(0.3*length,0.8*length);
		context.lineTo(0.5*length,0.4*length);
		context.moveTo(0.6*length,length);
		context.lineTo(0.85*length,0.5*length);
		context.moveTo(0.7*length,0.4*length);
		context.lineTo(0.9*length,0);

		context.stroke();
	}else if(pattern_name == 'dots_and_triangles'){ //concrete wall
		var radius = 0.03*length
		var gap = (length - (4*radius))/4
		window.drawSimpleBubble(0.1*length, 0.1*length, radius,context)
		window.drawSimpleBubble(0.5*length, 0.3*length, radius,context)
		window.drawSimpleBubble(0.45*length, 0.6*length, radius,context)
		window.drawSimpleBubble(0.15*length, 0.8*length, radius,context)
		window.drawSimpleBubble(0.55*length, 0.68*length, radius,context)
		window.drawSimpleBubble(0.31*length, 0.31*length, radius,context)
		window.drawSimpleBubble(0.8*length, 0.7*length, radius,context)

		var p = {x:0.3*length,y:0.5*length}
		window.drawSimpleTriangle(p,{x:p.x+ 0.1*length,y:p.y+0.1*length},{x:p.x - 0.1*length,y:p.y+0.1*length},context)
		var p = {x:0.5*length,y:0.8*length}
		window.drawSimpleTriangle(p,{x:p.x - 0.1*length,y:p.y+0.1*length},{x:p.x,y:p.y+0.2*length},context)
		var p = {x:0.8*length,y:0.2*length}
		window.drawSimpleTriangle(p,{x:p.x+ 0.1*length,y:p.y+0.1*length},{x:p.x ,y:p.y+0.2*length},context)
	}
}


export const updateWallHatchPatterns = (return_map = false) => {
	var data = []
    var data_map = {}

	var temp_canvas = document.createElement('canvas');
	var temp_canvas2 = document.createElement('canvas');

	temp_canvas.width = 100;
	temp_canvas.height = 100;
	temp_canvas2.width = 200;
	temp_canvas2.height = 200;

	var ctx2 = temp_canvas2.getContext('2d')
	ctx2.clearRect(0, 0, 200, 200);
	
	var ctx = temp_canvas.getContext("2d");
	ctx.lineCap = "square";
	ctx.strokeStyle = '#000000'
	ctx.fillStyle = '#ffffff'
	ctx.fillRect(0, 0, 100, 100);
	ctx.fillStyle = '#000000'

	var patterns = ['diagonal_lines', 'vegetation', 'bricks','double_diagonal_lines','triple_diagonal_lines','diagonal_cross','straight_cross','dots_and_triangles','glass','full_bubbles','bubbles_with_gaps', 'solid']
	// var patterns = ['','diagonal_lines','double_diagonal_lines','triple_diagonal_lines','diagonal_cross','straight_cross','dots_and_triangles','glass','full_bubbles','bubbles_with_gaps', 'solid']

	for (var i = 0; i < patterns.length; i++) {
		if(patterns[i]!= 'dots_and_triangles'){
			ctx.lineWidth = 7.5
		}else{
			ctx.lineWidth = 2
		}
		drawCanvasPattern(ctx,patterns[i]);
		ctx2.drawImage(temp_canvas,0,0,100,100);
		ctx2.drawImage(temp_canvas,100,0,100,100);
		ctx2.drawImage(temp_canvas,0,100,100,100);
		ctx2.drawImage(temp_canvas,100,100,100,100);
		
		//New hatch patterns to to sent only when map is requested
		if( !(patterns[i] == 'vegetation' ||  patterns[i] == 'bricks') ){
			data.push({
				src: patterns[i] == 'vegetation' ? '/resources/hatch_patterns/hatch_txt_file2_image.svg' : patterns[i] == 'bricks' ? '/resources/hatch_patterns/hatch_txt_file_image.svg' : temp_canvas2.toDataURL(),
				name: patterns[i]
			});
		}

        data_map[patterns[i]] = patterns[i] == 'vegetation' ? '/resources/hatch_patterns/hatch_txt_file2_image.svg' : patterns[i] == 'bricks' ? '/resources/hatch_patterns/hatch_txt_file_image.svg' : temp_canvas2.toDataURL();

		ctx.fillStyle = '#ffffff'
		ctx.fillRect(0, 0, 100, 100);
		ctx.fillStyle = '#000000'
		ctx2.clearRect(0, 0, 400, 400);
	}
    
    if(return_map){
        return data_map
    }

	return data
}

export const generate_2d_snapshot_of_composite = (composite) => {
    var dummy_canvas = document.createElement('canvas')
    dummy_canvas.width = 200;
    dummy_canvas.height = 200;

    var context = dummy_canvas.getContext('2d');
    context.clearRect(0, 0, dummy_canvas.width, dummy_canvas.height);
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, dummy_canvas.width, dummy_canvas.height);

    var total_thickness = composite.layers.reduce(function(acc,el){return acc + el.thickness},0);
    var current_thickness = 0;
    for (var i = 0; i < composite.layers.length; i++) {
        context.lineWidth = 1
        context.strokeStyle = composite.layers[i].lineColor;
        context.fillStyle = composite.layers[i].hatchingFillColor
        var y0 = (current_thickness)/total_thickness
        y0 *= dummy_canvas.height;

        var y1 = (current_thickness + composite.layers[i].thickness)/total_thickness
        y1 *= dummy_canvas.height;

        var x0 = 0; var x1 = dummy_canvas.width;

        context.beginPath();
        context.moveTo(x0,y0)
        context.lineTo(x1,y0)
        context.lineTo(x1,y1)
        context.lineTo(x0,y1);
        context.stroke();
        context.fill()

        if(composite.layers[i].hatchingPattern){
            var temp_canvas = document.createElement('canvas');
            temp_canvas.width = dummy_canvas.width/2
            temp_canvas.height = dummy_canvas.height/2
            var ctx = temp_canvas.getContext('2d')
            if(composite.layers[i].hatchingPattern != 'dots_and_triangles'){
                ctx.lineWidth = 7.5
            }else{
                ctx.lineWidth = 2
            }

            ctx.lineCap = "square";
            ctx.strokeStyle = composite.layers[i].hatchingStrokeColor;
            ctx.fillStyle = composite.layers[i].hatchingStrokeColor;
            ctx.clearRect(0, 0, temp_canvas.width, temp_canvas.height);
            drawCanvasPattern(ctx,composite.layers[i].hatchingPattern,temp_canvas.width)

            context.drawImage(temp_canvas,0,y0,dummy_canvas.width/2,dummy_canvas.height/2);
            context.drawImage(temp_canvas,dummy_canvas.width/2,y0,dummy_canvas.width/2,dummy_canvas.height/2);
            context.drawImage(temp_canvas,0,y0+dummy_canvas.height/2,dummy_canvas.width/2,dummy_canvas.height/2);
            context.drawImage(temp_canvas,dummy_canvas.width/2,y0+dummy_canvas.height/2,dummy_canvas.width/2,dummy_canvas.height/2);
        }

        current_thickness += composite.layers[i].thickness
    }

    return dummy_canvas.toDataURL();
}

export const generate_3d_snapshot_of_composite = async (composite) => {
    var dummy_canvas = document.createElement('canvas')
    dummy_canvas.width = 100;
    dummy_canvas.height = 100;

    var context = dummy_canvas.getContext('2d');
    context.clearRect(0, 0, dummy_canvas.width, dummy_canvas.height);
    context.fillStyle = '#ffffff'
    context.fillRect(0, 0, dummy_canvas.width, dummy_canvas.height);

    context.strokeStyle = '#000000';
    var total_thickness = composite.layers.reduce(function(acc,el){return acc + el.thickness},0);
    var current_thickness = 0;

    var prom_array = []

    for(var i=0;i<composite.layers.length;i++){
        var layer = composite.layers[i];

        var y0 = (current_thickness)/total_thickness
        y0 *= dummy_canvas.height;

        var y1 = (current_thickness + layer.thickness)/total_thickness
        y1 *= dummy_canvas.height;

        var x0 = 0; var x1 = dummy_canvas.width;

        current_thickness += layer.thickness

        if(layer.material_image){
            var img = await image_load_async(layer.material_image)
            var box_aspect = dummy_canvas.width/(y1-y0);
            var image_aspect = img.width/img.height
            if(box_aspect < image_aspect){
                context.drawImage(img,x0,y0,image_aspect*(y1-y0),(y1-y0));
            }else{
                context.drawImage(img,x0,y0,dummy_canvas.width,dummy_canvas.width/image_aspect);
            }
        }else if(layer.material_color){
            context.beginPath();
            context.moveTo(x0,y0)
            context.lineTo(x1,y0)
            context.lineTo(x1,y1)
            context.lineTo(x0,y1);
            context.fillStyle = layer.material_color
            context.fill()
        }
    }

    return dummy_canvas.toDataURL();
}

export const generate_2d_snapshot_of_layer = (layer) => {
    var dummy_canvas = document.createElement('canvas')
    dummy_canvas.width = 200;
    dummy_canvas.height = 200;
    
    var context = dummy_canvas.getContext('2d');
    context.clearRect(0, 0, dummy_canvas.width, dummy_canvas.height);
    context.fillStyle = layer.hatchingFillColor;
    context.fillRect(0, 0, dummy_canvas.width, dummy_canvas.height);

    if(layer.hatchingPattern){
        var temp_canvas = document.createElement('canvas');
        temp_canvas.width = dummy_canvas.width/2
        temp_canvas.height = dummy_canvas.height/2
        var ctx = temp_canvas.getContext('2d')
        if(layer.hatchingPattern != 'dots_and_triangles'){
            ctx.lineWidth = 7.5
        }else{
            ctx.lineWidth = 2
        }

        ctx.strokeStyle = layer.hatchingStrokeColor;
        ctx.fillStyle = layer.hatchingStrokeColor;
        ctx.lineCap = "square";
        ctx.clearRect(0, 0, temp_canvas.width, temp_canvas.height);
        drawCanvasPattern(ctx,layer.hatchingPattern,temp_canvas.width)

        context.drawImage(temp_canvas,0,0,dummy_canvas.width/2,dummy_canvas.height/2);
        context.drawImage(temp_canvas,dummy_canvas.width/2,0,dummy_canvas.width/2,dummy_canvas.height/2);
        context.drawImage(temp_canvas,0,dummy_canvas.height/2,dummy_canvas.width/2,dummy_canvas.height/2);
        context.drawImage(temp_canvas,dummy_canvas.width/2,dummy_canvas.height/2,dummy_canvas.width/2,dummy_canvas.height/2);
    }

    return dummy_canvas.toDataURL();
}

export const generate_3d_snapshot_of_layer = async (layer) => {
    var dummy_canvas = document.createElement('canvas')
    dummy_canvas.width = 100;
    dummy_canvas.height = 100;
    var context = dummy_canvas.getContext('2d');
    context.clearRect(0, 0, dummy_canvas.width, dummy_canvas.height);
                
    if(layer.material_image){
        var img = await image_load_async(layer.material_image)
        context.drawImage(img,0,0,dummy_canvas.width,dummy_canvas.height);
    }else if(layer.material_color) {
        context.fillStyle = layer.material_color
        context.fillRect(0, 0, dummy_canvas.width, dummy_canvas.height);
    }

    return dummy_canvas.toDataURL();
}