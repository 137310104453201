import React, { useState,useEffect } from 'react';
import { Modal, Button, Form, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import Multiselect from 'multiselect-react-dropdown';
import { COLORS } from '../../utils/color';


const Design_Revert_Modal = ({ open, handle_close }) => {
    useEffect(() => {
        if(open){
            
        }
    },[open])


    const onclick_apply = () => {
        
    }

    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={handle_close}>
            <Card className="bg-secondary shadow border-0">
                <CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color, height:'63px'}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Action Needed</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
                <CardBody style = {{backgroundColor: "#fefefe" }} className="px-lg-5 py-lg-5">
                        <Row>
                            There seems to be an error loading this design. This usually doesn't happen and our team will look into it. In the meanwhile, you can choose to revert your design to the previous stable state.
                        </Row>
                        <br/>

                        <div className="text-center mt-5">
                            <Button type="button" onClick={onclick_apply}>Revert design to the last action</Button>
                        </div>
                    {/* </Form> */}
                </CardBody>
            </Card>
        </Modal>
    )    
}

export default Design_Revert_Modal;