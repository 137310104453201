import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import is_valid_add_key from '../../utils/is_valid_add_key';
import { useAlert } from 'react-alert'

const KeyValuePair = ({item_json, set_item_json, mode, all_allowed_key}) => {

    // const [is_ap_open, set_is_ap_open] = useState(false);
    const alert = useAlert();

    const add_new_item_json = () => {
        if(is_valid_add_key(item_json)){
            let a = [...item_json];
            a.push({key:'', value:''})
            set_item_json(a);
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }
	}

    useEffect(() =>{
		if(item_json && !item_json.length){
			add_new_item_json()
		}
		// console.log('item_json=======>',item_json)
	},[item_json])

	const delete_item_json = (index) => {
		let a = [...item_json];
		// console.log('item_json=======>',item_json,a)
		a.splice(index,1)
		// console.log('item_json=======>',item_json, a)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
	}

    return(
        <CardBody style={{padding:'10px 15px', fontSize:'14px', backgroundColor:'white', marginTop:'15px'}}>
            <div>
                <div style={{marginBottom:'10px', fontWeight:600}}>{mode==='default'?'Default Properties':'Custom Properties'}</div>
                {item_json && item_json.length ? item_json.map((x,idx) => (
                    <Row key={idx} style={{margin:0,marginTop:'10px'}}>
                        <Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            Key
                        </Col>
                        <Col xs={4} style={{paddingLeft:0}}>
                            {    
                                mode==='default'?
                                <Input id={'box1'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select" 
                                    value={item_json[idx].key}
                                    onChange={(e) =>update_item_json(idx,'key', e.target.value) }
                                >
                                    <option value='none'>Select a key</option>
                                    {
                                        all_allowed_key && Object.keys(all_allowed_key).length?Object.keys(all_allowed_key).map((item, idx) => 
                                            <option value={item}>{all_allowed_key[item].name}</option>
                                        ):''
                                    }
                                </Input>
                                :
                                <Input id={'box1'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="text" 
                                    value={item_json[idx].key}
                                    onChange={(e) =>update_item_json(idx,'key', e.target.value) }
                                />
                            }

                        </Col>
                        <Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            Value
                        </Col>
                        <Col xs={4} style={{padding:0}}>
                        {
                            mode==='default'?
                                <div>
                                    {
                                        item_json && item_json[idx] && item_json[idx].key && all_allowed_key[item_json[idx].key]?
                                        <div>
                                            {   
                                                all_allowed_key[item_json[idx].key].type==='text' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="text"
                                                    value={item_json[idx].value}
                                                    onChange={(e) =>update_item_json(idx,'value', e.target.value) }
                                                />
                                                :''
                                            }
                                            {
                                                all_allowed_key[item_json[idx].key].type==='number' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="number"
                                                    value={item_json[idx].value}
                                                    onChange={(e) =>update_item_json(idx,'value', e.target.value) }
                                                />
                                                :''
                                            }
                                            {    
                                                all_allowed_key[item_json[idx].key].option_type==='prelam' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value}
                                                    onChange={(e) =>update_item_json(idx,'value', e.target.value) }
                                                >
                                                    <option value='none'>select a value</option>
                                                    <option value='prelam_internal'>Prelam Internal</option>
                                                    <option value='prelam_both'>Prelam Both</option>
                                                    <option value='postlam'>Postlam</option>
                                                </Input>
                                                :''
                                            }
                                        </div>
                                        :
                                        <Input disabled id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="text"/>
                                    }
                                </div>
                                :
                                <Input 
                                    id={'box2'+idx} type="text"
                                    style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} 
                                    value={item_json[idx].value}
                                    onChange={(e) =>update_item_json(idx,'value', e.target.value) }
                                />
                            }
                        </Col>
                        <Col xs={2} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            <span style={{cursor:'pointer',display:'flex', alignItems:'center'}} onClick={() =>delete_item_json(idx)}><i className='fa fa-trash'/></span>
                        </Col>
                    </Row>
                )):''}
                <Row style={{margin:0}}>
                    <Col style={{textAlign:'left', padding:'10px', paddingLeft:0, marginTop:'5px'}}>
                        <span onClick={add_new_item_json}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
                    </Col>
                </Row>
            </div>
        </CardBody>
    )
}

export default KeyValuePair;

{/* <CardBody style={{padding:0, fontSize:'14px'}}>
            
            <div>
                {item_json && item_json.length ? item_json.map((x,idx) => (
                    <Row key={idx} style={{margin:0,marginTop:'10px'}}>
                        <Col xs={1} style={{display:'flex', alignItems:'center', padding:0}}>
                           {idx+1}
                        </Col>
                        <Col xs={5} >
                            <Input id={'box1'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="text" 
                                value={item_json[idx].key}
                                onChange={(e) =>update_item_json(idx,'key', e.target.value) }
                            />
                        </Col>
                        
                        <Col xs={5} >
                            <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="text"
                                value={item_json[idx].value}
                                onChange={(e) =>update_item_json(idx,'value', e.target.value) }
                            />
                        </Col>
                        <Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            <span style={{cursor:'pointer',display:'flex', alignItems:'center'}} onClick={() =>delete_item_json(idx)}><i className='fa fa-trash'/></span>
                        </Col>
                    </Row>
                )):''}
                <Row style={{margin:0}}>
                    <Col style={{textAlign:'left', padding:'10px', paddingLeft:0, marginTop:'5px'}}>
                        <span onClick={add_new_item_json}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
                    </Col>
                </Row>
            </div>
        </CardBody>
 */}
