const get_scroll_position = () => {
    if(document.getElementById('panel_scroll_container')){
        return document.getElementById('panel_scroll_container').scrollTop
    }else{
        return 0;
    }
}

const set_scroll_position = (val) => {
    if(document.getElementById('panel_scroll_container')){
        // document.getElementById('panel_scroll_container').scrollTop = val;
        document.getElementById('panel_scroll_container').scroll(0,val);
    }
}

const repopulate_panel = () => {
    // var scroll_pos = get_scroll_position();
    // console.log("scroll_pos", scroll_pos)
    // console.log("scroll_height_before", document.getElementById('panel_scroll_container').scrollHeight);
    if(window['Module']){
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.repopulate_panel();
        active_design.delete();
        active_view.delete();
    }
    // console.log("scroll_height_before", document.getElementById('panel_scroll_container').scrollHeight);
    // set_scroll_position(scroll_pos);
}

export default repopulate_panel;