import React , { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavItem } from 'reactstrap';
import { COLORS } from '../../utils/color';
// import Tooltip_compo from '../Tooltip/'
import './unit.css';
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

const styles = {
	// owned_style: {
    //     display: "unset",
    //     position: "absolute", 
    //     backgroundColor:"#000033", 
    //     color:'white',
    //     borderRadius:"3px",
    //     zIndex:250,
    //     top:10, 
    //     right:'90px',
    //     fontSize:'12px'
    // },
	text_elipsis : {
		whiteSpace: "nowrap", 
		overflow: "hidden",
		textOverflow: "ellipsis",
		paddingTop: 5
	},
    nav_bar_icons: {
        color:COLORS.black,
    },
    vertical_nav_bar_icons: {
        lineHeight: '20px',
        marginBottom: '0px'
    }
};


const Text_Icon = ({ unit_type, unit_id, unit_name, unit_content, unit_root, unit_show, unit_disabled, unit_active,onClick}) => {

    useEffect(() => {
        // console.log(unit_type, unit_id, unit_name, unit_content, unit_root, unit_show, unit_disabled, unit_active)
    },[unit_type])

	return (
        <Tooltip title={unit_name?unit_name:''} placement="bottom" mouseEnterDelay={0.5}>
            <div id={unit_id} onClick={onClick} className='hover_toolbar' style={{height:'40px', width:'40px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                {
                    unit_show ?
                    <NavItem title={unit_name?unit_name:''} style={{ listStyleType:'none'}}><div style={styles.nav_bar_icons}>{unit_content}</div></NavItem>
                    :''
                }
            </div>      
        </Tooltip>
    );
};

const Fa_Icon = ({ unit_type, unit_id, unit_name, unit_content, unit_root, unit_show, unit_disabled, unit_active,onClick }) => {

    useEffect(() => {
        // console.log(unit_type, unit_id, unit_name, unit_content, unit_root, unit_show, unit_disabled, unit_active)
    },[unit_type])

    return (
        <Tooltip title={unit_name?unit_name:''} placement="bottom" mouseEnterDelay={0.5}>
            <div id={unit_id} onClick={onClick} className='hover_toolbar' style={{height:'40px', width:'40px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                {
                    unit_show ?
                    <NavItem style={{ listStyleType:'none'}}><i style={{...styles.nav_bar_icons, fontSize:'27px'}} className={unit_content}></i></NavItem>
                    :''
                } 
            </div>  
        </Tooltip>
    );
};

const Image_Icon = ({ unit_type, unit_id, unit_name, unit_content, unit_root, unit_show, unit_show_name, unit_disabled, unit_active,onClick, unit_disabled_message, icon_placement}) => {

    const [ new_src, set_new_src ] = useState("")
    const {t} = useTranslation();
    
    useEffect(() => {
         // console.log(unit_type, unit_id, unit_name, unit_content, unit_root,"unit_show ======>", unit_show, "====>unit_disabled", unit_disabled, unit_active)

        if(unit_content){
            set_new_src(unit_content)
        }
    },[unit_content])

	const RenderTooltip = ({ data }) => {
		return (
		  <div style={{padding: '4px'}}>
			{data.heading.exists && <div style={{fontSize:'15px', marginBottom:'4px'}}>{t(data.heading.content)}</div>}
	  
			{data.description.map((item, index) => (
			  <div style={{color: data.heading.exists ? '#A7A8B2' : ''}} key={index}>
				{t(item.text)}
				{item.link && <a target='_blank' href={item.link.url}>{item.link.text}</a>}
				{t(item.textAfterLink)}
			  </div>
			))}
		  </div>
		);
	};
	  

    return (
        <Tooltip title={(unit_name && unit_name.substr(0, 6) === "parse:") ? <RenderTooltip data={JSON.parse(unit_name.substr(6))} /> : t(unit_name)} placement="bottom" mouseEnterDelay={0.5}>
            <Tooltip title={unit_disabled ? ((unit_disabled_message && unit_disabled_message.substr(0, 6) === "parse:") ? <RenderTooltip data={JSON.parse(unit_disabled_message.substr(6))} /> : t(unit_disabled_message)) : ''} placement="right" mouseEnterDelay={0.5}>

                <div id={unit_id} onClick={onClick} className='hover_toolbar' style={{height:'40px', width:'40px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                    {
                        unit_show ?
                        <NavItem style={{listStyleType:'none', height:icon_placement != 'panel_footer' && icon_placement!='toolbar_button' ? '40px' : 'auto'}}>
                            <img style={{color:COLORS.white}} height='24px' width='24px' src={new_src?new_src:''} />
                            {
                                (icon_placement != 'panel_footer' && icon_placement!='toolbar_button')?<div style={{fontSize:'8.5px', marginTop:'6px', textAlign:'center', color:COLORS.gray8}}>{t(unit_show_name)}</div>:''
                            }
                        </NavItem>
                        :''
                    }
                </div>
            </Tooltip>
        </Tooltip>  
    );
};

const Left_menu_Image_Icon = ({ idx, unit, unit_type, unit_id, unit_name, unit_content, unit_show, unit_disabled, unit_disabled_message, onClick ,update_view, active_left_menu_item}) => {

    const [ left_menu_new_src, set_left_menu_new_src ] = useState("")
    const[ tooltip_open, set_tooltip_open] = useState(true)

    useEffect(() => {
        if(unit_content){
            set_left_menu_new_src(unit_content) 
        }
    },[unit_content])

    useEffect(() => {
        // console.log('unit_disabled1==========>',unit_disabled)
        // console.log('unit_disabled_message1==========>',unit_disabled_message)
    },[unit_disabled])

    return (
        <div>
            <NavItem id={unit_id} onClick={!unit_disabled?onClick:null} /*className={unit_id===active_left_menu_item?'hover_leftmenu_green_border':''}*/ style={{cursor:unit_disabled?'not-allowed':'pointer',opacity:unit_disabled?'0.3':'1',listStyleType:'none', minHeight:'60px', display:'flex', alignItems:"center", justifyContent:'center'}}>
                <div style={{color:COLORS.white, textAlign:'center', margin:'5px 0px',display:'flex',flexFlow:'column',alignItems:'center'}}>
                    <div className="hover_left_menu" style={{width:40,height:40, display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img style={styles.vertical_nav_bar_icons} height='24px' width='24px' src={left_menu_new_src ? left_menu_new_src :''} />
                    </div>
                    <p style={{fontSize:'10px', margin:'0px', fontWeight:'400',color:COLORS.black_left_menu}}>{unit_name}</p>
                    <span className='tooltip3' >{unit_disabled_message}</span>
                </div>
            </NavItem>
            <div className={unit_disabled?'tooltip3':''}>{unit_disabled_message?unit_disabled_message:''}</div>
        </div>
    );
};

export { Image_Icon, Fa_Icon, Text_Icon, Left_menu_Image_Icon };



