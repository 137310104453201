import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import './style.css'
import includes_search_string from '../../utils/includes_search_string';
import { COLORS } from '../../utils/color';

const ISingleSelect = ({ options, value, onOptionClick, placeholder, displayValue = 'name', optionIdentifier = 'id', styles = { dropdown: {}, dropdownToggle: {}, dropdownMenu: {}, dropdownItem: {} }, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchString, setSearchString] = useState('');

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const toggle_dropdown = () => {
        setIsOpen(!isOpen)
        setSearchString('')
    }

    const option_item_onclick = (item) => {
        onOptionClick(item)
        toggle_dropdown()
    }

    useEffect(() => {
        // Function to close dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            if (inputRef && inputRef.current) {
                inputRef.current.focus();
                const inputLength = inputRef.current.value.length;
                inputRef.current.setSelectionRange(0, inputLength);
            }
        }

    }, [isOpen]);

    return (
        <div style={{ position: 'relative', ...(styles && styles.dropdown ? styles.dropdown : {}) }} {...props} ref={dropdownRef}>
            <div className='inf_single_select_toggle' style={styles.dropdownToggle}>
                {
                    isOpen ? <input ref={inputRef} className='search_component' value={searchString} onChange={(e) => setSearchString(e.target.value)} placeholder="Type to Search" type='select'></input>
                        :
                        (value !== undefined || value !== null) ? <span onClick={toggle_dropdown} className='single_select_selected_item'>{options.find(item => item[optionIdentifier] === value) ? options.find(item => item[optionIdentifier] === value)[displayValue] : "Select an Option"}</span>
                            :
                            <span onClick={toggle_dropdown} style={{ flex: 1, minWidth: '20px', padding: '5px', margin: '5px', fontSize: '12px', color: '#676878' }} > {placeholder ? placeholder : "Select an Option"} </span>

                }
                <i className={`${isOpen ? 'fa fa-angle-up' : 'fa fa-angle-down'} ml-3`} onClick={toggle_dropdown}></i>
            </div>
            {
                isOpen ?
                    <div className='inf_single_select_menu' style={styles.dropdownMenu}>
                        {
                            options && options.length ? options.map(item => (
                                <div className='inf_single_select_item' onClick={() => { option_item_onclick(item) }} style={{ display: "flex", backgroundColor: item[optionIdentifier] === value ? "#e2effd" : "", ...(styles && styles.dropdownItem ? styles.dropdownItem : {}) }}>
                                    <span className='inf_single_select_item_content' style={{ backgroundColor: item[optionIdentifier] === value ? "#e2effd" : "" }}>{item ? item[displayValue] : ''}</span>
                                    <i className='fa fa-check' style={{ color: "#23C16B", visibility: item[optionIdentifier] === value ? "visible" : "hidden", width: '16px', fontSize: '10px', alignSelf: 'center' }}></i>
                                </div>

                            ))
                                :
                                <div className='inf_single_select_item' style={styles.dropdownItem}>No Items Found</div>
                        }
                    </div>
                    : ''
            }
        </div>
    );
}

/*
 * IMPORTANT NOTE - options with unique optionIdentifier is necessary to use this component
 */
export const ISingleSelectRSD = ({ options, value, onOptionClick, placeholder, customToggle, displayValue = 'name', optionIdentifier = 'id', container="body", styles = { dropdown: {}, dropdownToggle: {}, dropdownMenu: {}, dropdownItem: {} }, customProps = { dropdown: {}, dropdownToggle: {}, dropdownMenu: {}, dropdownItem: {} }, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [focusedItemIndex, setFocusedItemIndex] = useState({index: 0});
    const [display_options, set_display_options] = useState([])

    const inputRef = useRef(null);

    const toggle_dropdown = () => {
        setIsOpen(!isOpen)
        setSearchString('')
        focusedItemIndex.index = 0;
    }

    const option_item_onclick = (item) => {
        onOptionClick(item)
        // toggle_dropdown()
    }

    const handleKeyDown = (e) => {
        if(isOpen){
            if(e.key === 'Escape'){
                setIsOpen(false);
            } else if (e.key === 'ArrowDown') {
                focusedItemIndex.index = (focusedItemIndex.index + 1) % display_options.length;
                setFocusedItemIndex(JSON.parse(JSON.stringify(focusedItemIndex)))
                // console.log("kpp curr len", searchString, display_options, display_options.length, focusedItemIndex.index, "ref ", isOpen)
            } else if (e.key === 'ArrowUp') {
                focusedItemIndex.index = (focusedItemIndex.index - 1 + display_options.length) % display_options.length;
                setFocusedItemIndex(JSON.parse(JSON.stringify(focusedItemIndex)))
            } else if(e.key == 'Enter') {
                let item_id = display_options && (display_options.length > focusedItemIndex.index) && display_options[focusedItemIndex.index][optionIdentifier];
                let dd_item = document.getElementById('inf_single_select_dropdown_item' + item_id);
                if(dd_item){
                    // console.log("kpp dd_item is ", focusedItemIndex.index, dd_item)
                    dd_item.click();
                    // setIsOpen(false)
                }
            }
        }
    };

    useEffect(() => {
       set_display_options(options && Array.isArray(options) ? options.filter(item => includes_search_string(item ? item[displayValue] : '' , searchString)) : [])
       focusedItemIndex.index = 0;
    }, [searchString, options])

    useEffect(() => {
        // console.log("kpp display display_options", display_options)
        focusedItemIndex.index = 0;
        JSON.parse(JSON.stringify(focusedItemIndex))
    }, [display_options])

    useEffect(() => {
        if (isOpen) {
            if (inputRef && inputRef.current) {
                inputRef.current.focus();
                const inputLength = inputRef.current.value.length;
                inputRef.current.setSelectionRange(0, inputLength);
            }
        }

    }, [isOpen]);

    return (
        <Dropdown isOpen={isOpen} className='DMSans' toggle={toggle_dropdown} style={{ ...(styles && styles.dropdown ? styles.dropdown : {}) }} {...props}>
            <DropdownToggle toggle={toggle_dropdown} className='inf_single_select_toggle' style={{border: 'none', boxShadow: 'none', backgroundColor: 'white', color: COLORS.gray7, ...(styles.dropdownToggle ? styles.dropdownToggle : {})}}>
                {
                    customToggle ? customToggle
                        :
                        <React.Fragment>
                            {
                                (value !== undefined || value !== null) ? <span onClick={toggle_dropdown} className='single_select_selected_item'>{display_options && Array.isArray(display_options) && display_options.find(item => item[optionIdentifier] === value) ? display_options.find(item => item[optionIdentifier] === value)[displayValue] : "Select an Option"}</span>
                                    :
                                    <span onClick={toggle_dropdown} style={{ flex: 1, minWidth: '20px', padding: '5px', margin: '5px', fontSize: '12px', color: '#676878' }} > {placeholder ? placeholder : "Select an Option"} </span>

                            }
                            <i className={`${isOpen ? 'fa fa-angle-up' : 'fa fa-angle-down'} ml-3`} onClick={toggle_dropdown}></i>
                        </React.Fragment>
                }
            </DropdownToggle>
            <DropdownMenu onKeyDown={handleKeyDown} {...(customProps.dropdownMenu ? customProps.dropdownMenu : {})} className='inf_single_select_menu' style={styles && styles.dropdownMenu ? styles.dropdownMenu : {}} container={container}>
               <div className='inf_single_select_search_component'>
                    <div className={'infurnia_search_component_container_ideal'} style={{margin: '0px'}}>
                        <span><i style={{ paddingLeft: '12px', paddingBottom: '3px', fontSize: '12px', color: '#9aa5b5' }} className='fa fa-search' /></span>
                        <input ref={inputRef} className='infurnia_search_component_inner_container' value={searchString} onChange={(e) => { setSearchString(e.target.value)}} placeholder={placeholder ? placeholder : "Search Category Name"} type='select'></input>
                    </div>
                    <DropdownItem divider />
                </div> 
                {
                    display_options && display_options.length ? display_options.map((item, idx) => (
                        <div id={'inf_single_select_dropdown_item' + item[optionIdentifier]} className='inf_single_select_item' onClick={() => { option_item_onclick(item); toggle_dropdown(); }} style={{outline:focusedItemIndex.index===idx?'2px solid #0078FF':'', outlineOffset: '-2px', cursor: 'pointer', display: includes_search_string(item[displayValue], searchString) ? "flex" : "none", backgroundColor: item[optionIdentifier] === value ? "#e2effd" : "", ...(styles && styles.dropdownItem ? styles.dropdownItem : {}) }}>
                            <span className='inf_single_select_item_content' style={{ backgroundColor: item[optionIdentifier] === value ? "#e2effd" : "" }}>{item ? item[displayValue] : ''}</span>
                            <i className='fa fa-check' style={{ color: "#23C16B", visibility: item[optionIdentifier] === value ? "visible" : "hidden", width: '16px', fontSize: '10px', alignSelf: 'center' }}></i>
                        </div>

                    ))
                        :
                        <DropdownItem className='inf_single_select_item' style={styles && styles.dropdownItem ? styles.dropdownItem : {}}>No Items Found</DropdownItem>
                }
            </DropdownMenu>
        </Dropdown>
    );
}

export default ISingleSelect;