const is_duplicate_key = (idx, item_json) => {
    var x_value = item_json.filter(x=>x.key===item_json[idx].key).length>1?true:false
    // var x_value = keys.includes(item_json[idx].key)?true:false
    return x_value;
}

const is_valid_add_key = (item_json) => {
    if(item_json && item_json.length>1){
        for(var i=0;i<item_json.length;i++){
            if(item_json[i].key==='' || is_duplicate_key(i, item_json)){
                return false;
            }
        }
        return true;
    }else{
        return true;
    }
}

export default is_valid_add_key;