import React, { useEffect, useState, useCallback, useRef } from 'react';
import { ChromePicker } from 'react-color';
import './style.css';

const MCD_ColorPicker = ({ color, handleChange, toggle_dropdown, colors, customize_json}) => {
	const [open, set_open] = useState(customize_json && customize_json.default_state ? true : false);
	const toggle = () => set_open(!open)
	
	return (
		<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '6px 12px', fontSize: '12px'}} className='panel-mcd-color-picker'>
			{customize_json && customize_json.hide_presets ? '' :
            <>
                <div>
                    <div>Presets</div>
                    <div>
                        <div className="inf-presets-color-picker">
                            <div className="inf-presets-color-grid">
                                {colors && colors.map(col => (
                                    <div
                                        key={col}
                                        className={`inf-presets-color-option ${color === col ? 'selected' : ''}`}
                                        style={{ backgroundColor: col }}
                                        onClick={() => {handleChange({hex: col}); toggle_dropdown()}}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
            </>}
			<div style={{width: '100%'}}>
				<div onClick={customize_json.always_open ? null : toggle} style={{fontSize: '14px', paddingBottom: '8px', color: open ? '' : '#0078ff', display: 'flex', alignItems: 'center', cursor: customize_json.always_open ? '' : 'pointer'}}>
					{customize_json && customize_json.toggle_text ? customize_json.toggle_text : 'Custom color'}
					{customize_json.always_open ? '' : <i className={`ml-2 ${open ? 'fa fa-caret-up' : 'fa fa-caret-down'}`}/>}
				</div>
			</div>
			{
				open ?
                    (customize_json.trigger_onChangeComplete ?
                    <ChromePicker className='mcd_color_picker' color={color} onChangeComplete={(c, e) => {handleChange(c, e);}} disableAlpha/> :
					<ChromePicker className='mcd_color_picker' color={color} onChange={handleChange} disableAlpha/>)
					: ''
			}
		</div>
	)
}

export default MCD_ColorPicker;