import React from 'react';
import './style.css';

export const IModalHeader = ({ children, toggle, custom_toggle_icon, style, className, style_children }) => {
  return (
    <div className={(className ? className : '') + " infurnia_modal_header"} style={{...style}}>
      <div style={{...style_children}}>{children}</div>
      <img onClick={toggle} style={{cursor:'pointer'}} src={custom_toggle_icon ? custom_toggle_icon : '/resources/icons/modal_cross.svg'}></img>
    </div>
  );
};

export const IModalBody = ({ children, style, className }) => {
  return (
    <div className={(className ? className : '') + " infurnia_modal_body"} style={{...style}}>
      {children}
    </div>
  );
};

export const IModalFooter = ({ children, style, className }) => {
  return (
    <div className={(className ? className : '') + " infurnia_modal_footer"} style={{...style}}>
      {children}
    </div>
  );
};
