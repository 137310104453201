// Function to group elements by a specified key without using reduce
const group_by_json = (arr, key) => {
    const groupedData = {};

    for (const item of arr) {
        const groupKey = item[key];

        // Create a new group if it doesn't exist
        if (!groupedData[groupKey]) {
            groupedData[groupKey] = [];
        }

        // Push the item to the group
        groupedData[groupKey].push(item);
    }

    return groupedData;
};

export default group_by_json;