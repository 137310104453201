import { all_legal_routes_initial } from "../models/constants";

const get_org_key = () => {
    if (!window.location.origin.includes("infurnia.com")) {
        return window.location.origin.split('.')[1]
    } else {
        var initial_key = window.location.pathname.split('/')[1]
        if (all_legal_routes_initial.includes(initial_key)) {
            return "";
        } else {
            return initial_key;
        }
    }
}

export default get_org_key