import React, { useState } from 'react';
import { Button, Input, Card, Col, Row } from "reactstrap";
import reactCSS from 'reactcss';
import {COLORS} from '../../utils//color.js'
import { useAlert } from 'react-alert'
import general_fetch from '../../utils/fetch';
import {useHistory } from 'react-router-dom';

const styles =  reactCSS({
    'default': {
        main_div:{
            background: 'url("/resources/images/site_image.jpeg") 50% fixed',
            height: '100vh',
            width : '100%',
            backgroundSize: 'cover',
            overflow: 'hidden'

        },
        layer: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: '0',
            top: '0',
            background: COLORS.layer_color,
        },
        nav_bar: {
            background: COLORS.primary,
            color: COLORS.white,
            height:'55px',
            width:'100%',
        },
        infurnia_logo:{
            maxWidth: '120px',
            maxHeight: '30px',
            width: '100%',
            objectFit: 'contain'
        },
        nav_bar_icons: {
            lineHeight: '20px',
            marginBottom: '0px',
            padding:'8px',
            marginRight:'10px'
        },
        main_page_button: {
            color: COLORS.white,
            border: '2px solid #1ab',
            borderRadius: '25px',
            background: COLORS.main_page_button,
            fontSize: '14px',
            padding: '10px',
            width: '100%',
            textAlign: 'center',
            fontWeight: 'bold'
        },
        recent_designs_title: {
            color: COLORS.white,
            fontSize:'16px', 
            paddingLeft:'10px',
            width:'100%',
            textAlign:'left',
            borderLeft:'2px solid #1ABB9C',
            height:'20px',
            marginLeft:'15px'
        },
    },
});

const ResetPassword = ({org_key, reset_password_token, set_page_loader, org_key_logo, org_key_id, org_key_name, logout}) => {

    const alert = useAlert()
    const history = useHistory();
    var SHA512 = require('js-sha512')

    const [ new_password, set_new_password ] = useState("");
    const [ confirm_password, set_confirm_password] = useState("");

    const onclick_reset_password = () =>{
        if(!new_password){
            alert.error('Please enter password')
        }else if (!confirm_password){
            alert.error('Please confirm password')
        }else if (confirm_password!=new_password){
            alert.error('Password mismatch')
        }else{
            reset_password();
        }

    }
    // {
    //     "new_password": "hellopass",
    //     "new_password_confirm": "hellopass"
    // }

    const reset_password = async () => {
        try{
            var body = { new_password:SHA512(new_password), new_password_confirm:SHA512(confirm_password) }
            var resp = await general_fetch({url:'/user/reset_password/'+reset_password_token, body, return_response_code:true})
            if(resp && resp.response_code == 1){ 
                alert.success('Password successfully updated')
                setTimeout(() =>{
                    // window.close();
                    // var url = '/login';
                    // (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
                    // history.push(url)
                    
                    set_new_password('')
                    set_confirm_password('')
                    logout();

                },3000);
            }else if(resp && resp.code){
                if(resp.code == "RESET_PASSWORD_TOKEN_INVALID"){
                    alert.success('Invalid reset password token')
                }
            }
        }catch(err){
            alert.error('Token expired')
            console.error(err)
            // var url = '/login';
            // (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
            // history.push(url)

            logout();
        }
    }

    return(
        <div style={styles.main_div}>
            <div style={{width:'350px', position:"absolute", left:'calc((100% - 350px)/2)',top:'5%', height:'auto', overflow:'auto'}}>
                
                <div style={{backgroundColor:'inherit', textAlign:'center', padding:'20px', marginBottom:'10px'}}><img  height='100%' width='200px' src={org_key_logo} /></div>    
                <Card style={{boxShadow: '0 2px 5px 0 rgb(0 0 0), 0 2px 5px 0 rgb(0 0 0)'}}>
                        
                    <div style = {{ padding:'40px 25px 30px'}}>
                        
                        <Row style={{paddingBottom:'20px'}}>
                            <Col xs={5} className="text-muted text-right">
                                <span className='sign_up_text_color'>Password</span>
                            </Col>
                            <Col xs={7}>
                                <Input value={new_password} onChange={(e) => set_new_password(e.target.value)} style={{fontSize:'12px'}} placeholder='Password' fullWidth type='password'/>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'20px'}}>
                            <Col xs={5} className="text-muted text-right">
                                <span className='sign_up_text_color'>Confirm Password</span>
                            </Col>
                            <Col xs={7}>
                                <Input value={confirm_password} onChange={(e) => set_confirm_password(e.target.value)} style={{fontSize:'12px'}} placeholder='Confirm Password' fullWidth type='password'/>
                            </Col>
                        </Row>
                        
                        <Row>    
                            <Col style={{padding:'20px 0px 20px', textAlign:'center'}}>
                                <Button onClick={onclick_reset_password} className='blue_button' >Reset Password</Button>
                            </Col>
                        </Row>
                    </div>
                </Card>
            </div>
        </div>
    );

}


export default ResetPassword;