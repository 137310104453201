import React, { useEffect, useState } from "react";

const IInput = ({children, type, className, ...props}) => {
    return(
        <>
            {
                type === 'select' ? <select className={`${className ? className : ''} infurnia_input`} {...props}>
                    {children}
                </select>
                :
                <input type={type} className={`${className ? className : ''} infurnia_input`} {...props} />
            }
        </>
    )
}

export default IInput