function multiFieldSort(data, fields, order) {
    return data.sort((a, b) => {
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            const aValue = a[field];
            const bValue = b[field];

            if (aValue < bValue) {
                return order === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return order === 'asc' ? 1 : -1;
            }
        }

        return 0;
    });
}

export default multiFieldSort