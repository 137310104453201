export const is_valid_file_type = (file, valid_types) => {
    try{
        if(file && file.name){
            let extension = file.name.split('.')
            extension = extension[extension.length - 1]
            if(extension){
                extension = extension.toLowerCase()
            }
            if(valid_types && valid_types.includes(extension)){
                return true
            }
        }
        return false
    }catch(err){
        console.error(`Error in is_valid_file_name `, err)
        throw (err);
    }
}