import React , { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col } from 'reactstrap';
import { COLORS } from '../../utils/color';

const styles = {
	owned_style: {
		// display: "unset",
		position: "absolute", 
		backgroundColor:COLORS.white, 
		color: COLORS.black,
		borderRadius:'50%',
		zIndex:500,
		top:'5%', 
		right:'4%',
		fontSize:'12px'
	},
	text_elipsis : {
		whiteSpace: "nowrap", 
		overflow: "hidden",
		textOverflow: "ellipsis",
		paddingTop: 5
	},
	design_mask : {
		position: 'absolute',
		zIndex: 500,
		fontSize: '14px',
		color: COLORS.black,
		float: 'right',
		width: '100%',
		background: COLORS.white,
		opacity: '.7',
		bottom: '0px',
		height: '20%',
		letterSpacing: '1px',
		display:'flex',
		alignItems:'center',
		paddingLeft:'5%',
		zIndex: 'unset',
	}
};
const Card_Component = ({ list_item, item_id, item_name, item_img_src, item_project_name, item_updated_at_locale, page_name, is_template, Header_Element, image_onclick_fn, active_project_id, set_page_loader,custom_style={} }) => {
	try {
		const [ dropdown_open, set_dropdown_open ] = useState(false);
		const [ redirect_url, set_redirect_url ] = useState('');
		const history = useHistory();

		// const img_click = () => {
		// 	set_page_loader({
		// 		show: true,
		// 		text: 'Creating New Design...'
		// 	},() => {
		// 		let url = null;
		// 		if(is_template && active_project_id){
		// 			var list_item_stringify=JSON.stringify(list_item)
		// 			var response1 = window.Module.add_design_from_template(list_item_stringify, active_project_id)
		// 			var response= JSON.parse(response1)
		// 			url='/design/'+response;
		// 		}
		// 		if(list_item && list_item.current_design_branch_id){
		// 			url = url ? url : '/design/'+list_item.current_design_branch_id;
		// 		}
		// 		history.push(url)
		// 		set_page_loader({
		// 			show: false,
		// 			text: 'Creating New Design...'
		// 		})
		// 	})
		// }

		const pre_image_onclick_fn = () => {
			if(is_template && active_project_id){
				image_onclick_fn(list_item, active_project_id)
			}else if(list_item && list_item.current_design_branch_id){
				image_onclick_fn(list_item.current_design_branch_id)
			}else{
				image_onclick_fn(list_item)
			}
		}

		useEffect(() => {
			//console.log('1111 =>', item_project_name ,item_name , item_img_src)
			//var x = document.getElementById('card_image').offsetWidth
		},[])

		const get_image_src = () => {
			return item_img_src?(item_img_src.includes('resources/')?(item_img_src):(window.Module.API.server_path+item_img_src)) : '/resources/images/site_image.jpeg';
		}

		return (
			<div style={{ width:'100%'}}>
				{
					page_name === "main"?(
						
							<div style={{ position:'relative', paddingBottom:'calc(56.25% + 20px)', margin:'15px', marginTop:'20px'}}  >  
								<div style={{ position:'absolute', top:'0', left:'0', bottom:'0', right:'0'}}  >  
									<div style={{ width:'100%', height:'100%', cursor:'pointer', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.1)'}} onClick={pre_image_onclick_fn} >
										<div style={{ textAlign:"center", maxHeight:"calc(100% - 45px)", width:'100%'}} >  
											<img id='card_image' style={{width:'100%' , height:'100%', borderTopRightRadius:'4px', borderTopLeftRadius:'4px'}}  src={get_image_src()} />
										</div>
										<div style={{height:"auto", width:'100%',paddingTop:'10px'}}>
											{/* <Row style={{margin:0, textAlign:'center', lineHeight:"13px", width:'100%'}}>
												<Col style={{padding:0, textAlign:'center', lineHeight:"100%", width:'100%'}}>
													
												</Col>
											</Row> */}
											<Row style={{margin:0, textAlign:'center', lineHeight:"16px", width:'100%', backgroundColor:'rgba(255, 255, 255, 0.8)', padding:'8px 0px', borderBottomRightRadius:'4px', borderBottomLeftRadius:'4px'}}>
												<Col style={{padding:0, textAlign:'center', lineHeight:"100%", width:'100%'}}>
													<div style={{color:COLORS.black}} >{item_project_name?item_project_name:''} | {item_name?item_name:''}</div>
												</Col>
											</Row>
											{/* <Row style={{margin:0,textAlign:'center', lineHeight:"16px", width:'100%', backgroundColor:'rgba(255, 255, 255, 0.8)'}}>
												<Col style={{padding:0, textAlign:'center', lineHeight:"100%", width:'100%'}} >
													<div style={{color:COLORS.black, whiteSpace:'nowrap', paddingBottom:'12px'}} >{item_updated_at_locale}</div>
												</Col>
											</Row> */}
										</div>
									</div>
								</div>
							</div>
							
					):''
				}
				{
					page_name === "designs"?(
						<div style={{ position:'relative', paddingBottom:'56.25%', margin:'15px', marginTop:'20px'}}  >  
							<div style={{ position:'absolute', top:'0', left:'0', bottom:'0', right:'0'}}  >  
								<div style={{ width:'100%', height:'100%', cursor:'pointer'}}  className='show_on_hover'>  
									<img id='card_image' onClick={pre_image_onclick_fn} style={{width:'100%' , height:'100%', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.1)', borderRadius:'4px'}}  src={get_image_src()} />
									<div style={styles.design_mask}>
										<strong>{item_name?item_name:''}</strong>
									</div>
								
									<span style={{...styles.owned_style}} className='hide'>
										<Header_Element
											active_item={list_item}
											dropdown_open={dropdown_open}
											set_dropdown_open={set_dropdown_open}
										/>
									</span>
								</div>
							</div>
						</div>
					):''
				}
				{
					is_template?(
						<div style={{ position:'relative', paddingBottom:'56.25%', margin:'15px', marginTop:'20px'}}  >  
							<div style={{ position:'absolute', top:'0', left:'0', bottom:'0', right:'0'}}  >  
								<div style={{ width:'100%', height:'100%', cursor:'pointer'}}  >  
									<img onClick={pre_image_onclick_fn} id='card_image' style={{width:'100%' , height:'100%',...custom_style, boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.1)', borderRadius:'4px'}}  src={get_image_src()} />
									<div style={styles.design_mask}>
										<strong>{item_name?item_name:''}</strong>
									</div>	
								</div>
							</div>
						</div>
					):''
				}
			</div>
		);
	} catch(err) {
		//console.log('error in Card Component >>>>>>>>>', err);
		return '';
	}
};

export default Card_Component;



