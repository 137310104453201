const desc_custom_sort = (a,b) => {
    if (!a.custom_field) {
        return 1;
    }
    if (!b.custom_field) {
        return -1;
    }
    if(a.custom_field.toLowerCase() < b.custom_field.toLowerCase()) { 
        return 1;
    }
    if(a.custom_field.toLowerCase() > b.custom_field.toLowerCase()) { 
        return -1;
    }
    return 0;
}

export default desc_custom_sort;