import { Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';

const TriggerTooltip = ({trigger, title, ...props}) => {
    return ( 
        <Tooltip title={title} {...props}>
            {
                trigger ? trigger
                :
                <div className='inf-px-3'><img src='/resources/icons/default_tooltip_trigger.svg' width={'16px'} height={'16px'} /></div>
            }
        </Tooltip>
     );
}
 
export default TriggerTooltip;