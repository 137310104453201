import React, { Component, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import './style.css';

const base_button_style = {
	cursor: 'pointer',
	position: 'absolute',
	fontSize:'30px',
	boxShadow: '1px 1px 5px 0 rgba(0,0,0,0.15)',
	padding:'0px 5px',
	borderRadius: '11px',
	top: 'calc( var(--inf-bottom-carousel-height) / 2 )',
	transform: 'translateY(-50%)'
}

const PenguinSlider = ({ children, id ,infinite_scroll, fetchMoreData}) => {
	try {
		// const [ show_back, set_show_back ] = useState(false);
		// const [ show_forward, set_show_forward ] = useState(true);

		// useEffect(() => {
		// 	var element = window.document.getElementById(id);
		// 	if(element){
		// 		element.scrollLeft = 0
		// 	}
		// 	setTimeout(() => {
		// 		set_initial_scroll_params();
		// 	}, 100)
		// },[id])

		// const scroll_forward = () => {
		// 	try {
		// 		sideScroll({ direction: 'right', speed: 10, distance: 453, step: 5 });
		// 	} catch(err) {
		// 		// console.log('error in scroll_forward --> ', err);
		// 	}
		// };


		// const scroll_back = () => {
		// 	try {
		// 		sideScroll({ direction: 'left', speed: 10, distance: 453, step: 5});
		// 	} catch(err) {
		// 		// console.log('error in scroll_back --> ', err);
		// 	}
		// };

		// const sideScroll = ({ direction, speed, distance, step, hide_front }) => {
		// 	var element = window.document.getElementById(id);
		// 	if(element){
		// 		var scrollAmount = 0;
		// 		var slideTimer = setInterval(() =>{
		// 			if((scrollAmount + step) >= distance){
		// 				step = distance - scrollAmount			
		// 			}
		// 			if (direction == 'left') {
		// 					element.scrollLeft -= step;
		// 			} else {
		// 					element.scrollLeft += step;
		// 			}

		// 			check_scroll_position();

		// 			scrollAmount += step;
		// 			if (scrollAmount >= distance) {
		// 				window.clearInterval(slideTimer);
		// 			}        
		// 		}, speed);
		// 	}
		// }

		// const check_scroll_position = (e) => {
		// 	try {
		// 		var element = window.document.getElementById(id);
		// 		if(element){
		// 			if (element.scrollLeft > 0 && !show_back) {
		// 				set_show_back(true);
		// 			}
		// 			if (element.scrollLeft < (element.scrollWidth - element.clientWidth) && !show_forward) {
		// 				set_show_forward(true);
		// 			}

		// 			if ((Math.abs(element.scrollLeft - (element.scrollWidth - element.clientWidth))<2 && show_forward)) {
		// 				set_show_forward(false);
		// 				if(infinite_scroll){
		// 					var new_items = fetchMoreData();
		// 					if(new_items > 0){
		// 						set_show_forward(true);
		// 					}
		// 				}
		// 			} else if (element.scrollLeft == 0 && show_back) {
		// 				set_show_back(false)
		// 			}
		// 		}
		// 	} catch(err) {
		// 		// console.log('error in on scroll ---> ', err)
		// 	}
		// }

		// const set_initial_scroll_params = () => {
		// 	try {
		// 		var element = window.document.getElementById(id);
		// 		// console.log('initind ---> ', element.clientWidth, element.scrollWidth)
		// 		if(element){
		// 			if (element.scrollWidth > element.clientWidth) {
		// 				set_show_forward(true);
		// 			}

		// 			// element.addEventListener("wheel", (evt) => {
		// 			// 	evt.preventDefault();
		// 			// 	element.scrollLeft += evt.deltaY;
		// 			// 	check_scroll_position();
		// 			// });
		// 		}
		// 	} catch (err) {
		// 		// console.log("error setting init params ---> ", err);
		// 	}
		// }

		// const wheel_handler = (e) => {
		// 	// e.preventDefault();
		// 	e.stopPropagation();
		// 	if(e.deltaY != 0 || e.deltaX != 0){
		// 		// var scroll_factor = Math.abs(e.deltaY)/360;
		// 		// var number_of_cards_to_scroll_per_wheel_rotation = 4;
		// 		// var distance_of_one_card = 226;
		// 		// var total_distance = scroll_factor*number_of_cards_to_scroll_per_wheel_rotation*distance_of_one_card
		// 		// var speed = 10;
		// 		// var step = 5;

		// 		var element = window.document.getElementById(id);
		// 		// if(element){
		// 		// 	if (e.deltaY<0) {
		// 		// 		element.scrollLeft -= total_distance;
		// 		// 	} else {
		// 		// 		element.scrollLeft += total_distance;
		// 		// 	}
		// 		// }

		// 		if(element){
		// 			if(e.deltaX != 0){
		// 				element.scrollLeft += e.deltaX;
		// 			}else {
		// 				element.scrollLeft += e.deltaY;
		// 			}
		// 		}
		// 	}
		// 	check_scroll_position();
		// }

		const onVerticalScroll = () => {
			var element = window.document.getElementById("vertical_"+id);
			if (Math.abs(element.scrollTop + element.clientHeight - element.scrollHeight) < 32) {
				fetchMoreData()
			  }
		}

		const dummy_carousel_grid = () => {
			let dummy_divs = []
			var available_carousel_width;
			let carousel_container = document.getElementById('infurnia-carousel-container')
			if(carousel_container && carousel_container.clientWidth){
			available_carousel_width = carousel_container.clientWidth
			}else{
				available_carousel_width =  document.body.clientWidth
			}
			var width_of_one_card = 220;
			var initial_number_of_cards_needed_to_fill_screen = Math.floor(available_carousel_width/width_of_one_card)*3;

			for(let i=0; i<initial_number_of_cards_needed_to_fill_screen; i++){
				dummy_divs.push(<div className='inf_dummy_carousel_card'></div>)
			}

			return dummy_divs
		}

		// useEffect(() => {
		// 	setTimeout(() => {
		// 		set_initial_scroll_params();
		// 	}, 100)
		// }, [])

		// useEffect(() => {
		// 	check_scroll_position()
		// 	// console.log('id      =========>',id)
		// }, [id])

		return (
			<div style={{position:'relative', overflow: 'hidden', height: '100%'}}>
				<div style={{ whiteSpace: 'nowrap',display:'grid', gap: '6px', margin:'0px', position:"absolute", textAlign:"left", gridTemplateColumns: `repeat(auto-fill, minmax(250px, 1fr))`, padding: '8px', width: '100%'}} >
					{dummy_carousel_grid()}
				</div>
				<div onScroll={onVerticalScroll} style={{height: '100%', position: 'relative', overflow: 'auto', zIndex: 1}} id={"vertical_"+id}>
					<div id={id} className="penguin_slider" style={{ whiteSpace: 'nowrap',display:'grid', gap: '6px', margin:'0px', position:"relative", textAlign:"left", gridTemplateColumns: `repeat(auto-fill, minmax(250px, 1fr))`, padding: '8px'}} >
					{/* <div onWheel={e => wheel_handler(e)} id={id} className="penguin_slider" style={{ whiteSpace: 'nowrap',display:'flex', gap: '1px', margin:'0px', overflowX:'hidden', overflowY: 'hidden', position:"relative", textAlign:"left", height:'100%'}} > */}
						{children}
					</div>
					{/* <div onClick={scroll_back} style={{ ...base_button_style, display:show_back ? 'block' : 'none'  , left: '12px' }} className="penguin_slider_arrow_button"><i className="fa fa-chevron-circle-left penguin_slider_arrow_icon" /></div>
					<div onClick={scroll_forward} style={{ ...base_button_style, display:show_forward ? 'block' : 'none', right: '12px'}} className="penguin_slider_arrow_button"><i className="fa fa-chevron-circle-right penguin_slider_arrow_icon" /> </div> */}
				</div>
			</div>
		)

	} catch(err) {
		// console.log('penguin slider error --> ', err);
		return '';
	}
}

export default PenguinSlider;