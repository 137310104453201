const set_panel_pop_up_search_show = (state) => {
    var active_design = window.Module.get_active_design()
	var active_view = active_design.active_view;

    active_view.set_panel_pop_up_search_show(state);

    active_design.delete();
	active_view.delete();
}

export default set_panel_pop_up_search_show;