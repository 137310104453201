import debug_log from "./debug_log"
import general_fetch from "./fetch"

const validate_model_filename = (filename) => {
    var re = /^[._a-zA-Z0-9 ]+$/
    return re.test(filename)
}

const upload_asset = async ({alert, set_page_loader, primary_file, primary_file_type, mtl_file, additional_files, expected_texture_files, callbackFnOn3DModelUpload}) => {
    const poll_for_output_completion = async ids => {
        try {
            var body = { ids };
            var resp = await general_fetch({ url: '/skp/get_status', body });
            if (resp[ids[0]].status == 'completed' || resp[ids[0]].status == 'partially_completed') {
                return resp[ids[0]].model_3d;
            } else if (resp[ids[0]].status == 'failed') {
                if (resp[ids[0]].error) {
                    alert.error(resp[ids[0]].error)
                } else {
                    alert.error("Download Failed!")
                }
                return -1;
            } else if (resp[ids[0]].status == 'initialized') {
                return 0;
            } else if (resp[ids[0]].status == 'ongoing') {
                return 0;
            } else {
                return 0;
            }
        } catch (err) {
            console.error(err);
        }
    }
    try {
        if ((primary_file_type != 'obj' && primary_file) || (primary_file_type === 'obj' && primary_file && mtl_file)) {
            
            if (!validate_model_filename(primary_file.name)) {
                alert.error("Invalid Filename")
                return
            }

            if (mtl_file && !validate_model_filename(mtl_file.name)) {
                alert.error("Invalid Filename")
                return
            }

            let temp_file_name = primary_file.name.replaceAll(' ', '_')
            let temp_file = new File([primary_file], temp_file_name)

            var resp
            
            if (primary_file_type === 'skp') {
                let data = new FormData();
                data.append('skp_file', temp_file);
                data.append('output_type', 'glb');
                set_page_loader({
                    show: true,
                    text: 'Please wait.. Uploading SKU model'
                })
                let resp_skp_fire = await general_fetch({ url: '/skp/fire', body: data, is_form_data: true, return_raw_response: true })
                debug_log(` resp from asset upload --> `, resp_skp_fire);
                let request_id = resp_skp_fire.data;
                resp = await (async () => {
                    return new Promise((resolve, reject) => {
                        let poll_interval = setInterval(async () => {
                            let model_3d = await poll_for_output_completion([request_id]);
                            if (model_3d == -1) {
                                // alert.error("Download Failed!")
                                clearInterval(poll_interval);
                                reject('skp_upload_failed');
                            } else if (model_3d) {
                                clearInterval(poll_interval);
                                resolve({ ...model_3d  })
                            }
                        }, 5000);
                    })
                })()


                set_page_loader({
                    show: false,
                    text: ''
                })
            } else {
                let data = new FormData();
                data.append('file', temp_file);
                if (mtl_file) {
                    let temp_mtl_file_name = mtl_file.name.replaceAll(' ', '_')
                    let temp_mtl_file = new File([mtl_file], temp_mtl_file_name)
                    data.append('mtl_file', temp_mtl_file)
                }
                if (additional_files && Object.keys(additional_files).length) {
                    let temp = 0;
                    Object.keys(additional_files).map((json_key, idx) => {
                        
                        if ((primary_file_type == 'obj' && additional_files[json_key] && additional_files[json_key].name != expected_texture_files[idx])) {
                            // alert.error("File name not matched")
                            throw("Name of the uploaded file does not match the required filename")
                            // temp = 1
                        }

                        if ((primary_file_type == 'obj' && !additional_files[json_key])) {
                            throw("Required Textures not Uploaded")
                            // temp = 2
                        }
                        data.append('additional_file_' + (Number(json_key) + 1), additional_files[json_key])
                    })
                    // if (temp) {
                    //     if (temp == 2) alert.error("Upload all required textures")
                    // }
                }
                data.append('high', false);
                data.append('format', primary_file_type)
                
                set_page_loader({
                    show: true,
                    text: 'Please wait.. Uploading SKU model'
                })

                resp = await general_fetch({ url: '/model/upload_asset', body: data, is_form_data: true })

                set_page_loader({
                    show: false,
                    text: ''
                })
            }

            // alert.success("Model uploaded successfully.")

            if(callbackFnOn3DModelUpload){
                callbackFnOn3DModelUpload(resp)
            }

            
            debug_log("upload 3D model response", resp)
            
            // onclick_close_upload_3d_modal()
            
        }
    } catch (err) {
        set_page_loader({
            show: false,
            text: 'Error in uploading ,please refresh'
        })
        console.error(`error in uploading asset ---> ${err}`);
        if (err !== "skp_upload_failed") {
            // alert.error("Error in uploading asset")
            return Promise.reject(err)
        }else{
            return Promise.reject("")
        }

    }
}

export default upload_asset