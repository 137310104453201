import React from 'react';
import { Button } from 'reactstrap';
import Icons from '../Icons';

const Infurnia_Tooltip = ({
    continuous,
    index,
    size,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
  }) => {

    const {
        content,
        hideBackButton,
        hideCloseButton,
        hideFooter,
        locale,
        showProgress,
        showSkipButton,
        styles,
        title,
      } = step;
    
  return (
    <div style={{fontSize: '14px', padding: '16px', minWidth: '240px', maxWidth: '280px', backgroundColor: 'white', boxShadow: '0px 4px 8px 0px #00000033', borderRadius: '8px'}} {...tooltipProps}>
      <div style={{display: 'flex', justifyContent: step.title ? 'space-between' : 'flex-end', alignItems: 'center', marginBottom: '16px'}}>
        {step.title && <div style={{color: '#323338', fontWeight: 700}}>{step.title}</div>}
        <Icons name='cross' style={{color: '#676878', cursor: 'pointer'}} {...closeProps} />
      </div>

      <div style={{marginBottom: '24px', paddingRight: '16px'}}>
        {step.content}
      </div>

      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        {index > 0 && (
          <Button style={{all: 'unset', backgroundColor: 'white', color: '#676878', cursor: 'pointer'}} {...backProps}> Back </Button>
        )}
        {showProgress && (
            <span style={{color: '#A7A8B2'}}>{(index+1) + '/' + size}</span>
        )}
        {continuous && (
          <Button style={{all: 'unset', backgroundColor: 'white', color: '#0078FF', cursor: 'pointer'}} {...primaryProps}> {index == size - 1 ? 'Finish' : 'Next'} </Button>
        )}
        {!continuous && (
          <Button style={{all: 'unset', backgroundColor: 'white', color: '#0078FF', cursor: 'pointer'}} {...closeProps}>
            Finish 🎉
          </Button>
        )}
      </div>
    </div>
  )
}
export default Infurnia_Tooltip;
