const formatDateTime = (dateTimeString, format = 1) => {
    const date = new Date(dateTimeString);

    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    const formattedDateTime = date.toLocaleString('en-GB', options);
    const time = formattedDateTime.substring(formattedDateTime.indexOf(',') + 1).trim();
    const datePart = formattedDateTime.substring(0, formattedDateTime.indexOf(',')).trim();

    return `${time} ${datePart}`;
}

export default formatDateTime