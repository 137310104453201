import React, { useState, useEffect, useRef } from 'react';
import Joyride from 'react-joyride'
import Infurnia_Tooltip from './infurnia_tooltip';
import general_fetch from '../../utils/fetch';
import { get_image_src } from '../../utils/get_image_url_from_FS';

const Feature_Guide = ({joyrideConfigs, onFinishSkipCallback, initCallback}) => {
  
    const [joyrideStates, setJoyrideStates] = useState([]);

    // useEffect(() => {
    //   console.log("Kpp joyridestates are", joyrideStates)
    // }, [joyrideStates])

    // useEffect(() => {
    //   if(initCallback)
    //       setJoyrideStates(
    //         joyrideConfigs && joyrideConfigs.length && joyrideConfigs.map(config => ({...config, run: false, steps: config.feature_guide_steps && config.feature_guide_steps.length && config.feature_guide_steps.map(item => {return {...item, content: item.tooltip_text, target: item.element_id, title: config.guide_name}})}))
    //       )
    //   else
    //     setJoyrideStates(joyrideConfigs && joyrideConfigs.length && joyrideConfigs.map(item => ({...item, run: false})));
    // }, [joyrideConfigs])
  
    window.trigger_feature_guide = async() => {
      if(initCallback){
        let resp = await initCallback()
        // setTimeout(() => {
          setJoyrideStates(
            resp && resp.length && resp.map(config => ({...config, run: true, steps: config.feature_guide_steps && config.feature_guide_steps.length && config.feature_guide_steps.map(item => {return {...item, content: <div>
              <div style={{marginBottom: '12px', fontSize: '13px', lineHeight: 1.4}}>{item.tooltip_text}</div>
             {item.gif_image && item.gif_image.image && <img style={{borderRadius: '4px', width: '100%', aspectRatio: '1/1', objectFit: 'contain'}} src={get_image_src(item.gif_image.image)} />}
          </div>,
          disableBeacon: config.type === "Forced",
          spotlightPadding: 4,
          target: `#${CSS.escape(item.element_id)}`, title: config.guide_name}})}))
          )
        // }, 2000)
      }
      else
      // setTimeout(() => {
        // setJoyrideStates(prevStates =>
        // prevStates && prevStates.length ? prevStates.map(config => ({...config, run: true})) : []
        // );
        setJoyrideStates(joyrideConfigs && joyrideConfigs.length && joyrideConfigs.map(item => ({...item, run: true})))
      // }, 2000)
    };
  
    const handleJoyrideCallback = (data, index) => {
      if (data.status === 'finished' || data.status === 'skipped') {
        if(onFinishSkipCallback)
          onFinishSkipCallback(joyrideConfigs && (joyrideConfigs.length > index) && joyrideConfigs[index].id, data.status == 'finished' ? 'Completed' : 'Skipped')
        else
          setJoyrideStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = {...newStates[index], run: false};
            return newStates;
          });
      }
    };
  
    return (
      <>
        {!(sessionStorage.getItem("disable_feature_guide") == 'true') && joyrideStates && joyrideStates.length ? joyrideStates.map((config, index) => (
          <Joyride
            styles={{
                options: {
                    primaryColor: '#0078FF',
                    beaconSize: 12,
                    overlayHeight: '100vh',
                }
            }}
            key={index}
            steps={config.steps}
            continuous={true}
            showProgress={true}
            run={config.run}
            callback={(data) => handleJoyrideCallback(data, index)}
            disableScrolling={true}
            tooltipComponent={Infurnia_Tooltip}
          />
        )) : ''}
      </>
    );
  };

  export default Feature_Guide;