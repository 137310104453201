/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Modal, FormGroup, Button, Form, Input, Card, CardBody, CardHeader, CardFooter, Col, Row } from "reactstrap";
import reactCSS from 'reactcss';
import { useAlert } from 'react-alert'
import './login.css';
// import {COLORS} from '../../utils//color.js'
import general_fetch from '../../utils/fetch';
import {useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next'


const styles =  reactCSS({
    'default': {
        main_div:{
            background: 'url("/resources/images/site_image.jpeg") 50% fixed',
            height: '100vh',
            width : '100%',
            position: 'relative',
            backgroundSize: 'cover',
            overflow: 'hidden'

        },
        layer: {
            width: '100%',
            height: '100vh',
            position: 'absolute',
            left: '0',
            top: '0',
            background: 'rgba(4, 40, 68, 0.7)'
        },
        modal_size_sm:{
            maxWidth:'350px !important'
        }
    },
});

const Store_options_modal = ({open, handle_close, all_store_options, onclick_store}) => {
    const {t} = useTranslation()

    const onclick_handleclose = () => {
        handle_close()
    }

    const onclick_single_store = (selected_store) => {
        onclick_store(selected_store)
        onclick_handleclose()
    }

    return(
        <Modal className="modal-dialog-centered" size="sm" isOpen={open} toggle={onclick_handleclose}>
            <Card className='store_options_card'>
                <CardHeader style={{display:'flex', alignItems:'center', justifyContent:'space-between', fontSize:'16px', fontWeight:600}}>
                    <div>{t(`Sign-in with Infurnia`)}</div>
                    <i style={{cursor:'pointer'}} onClick={onclick_handleclose} className='fa fa-times'/>
                </CardHeader> 
                <CardBody style={{padding:0}}> 
                    <div style={{padding:'20px', fontSize:'16px', fontWeight:600, textAlign:'center'}}>
                        <div>{t(`Choose Store`)}</div>
                    </div>
                    <div className='all_store_options_div' id="all_store_options_div">
                        {
                            all_store_options && all_store_options.length?all_store_options.map((item, idx)=>
                                <div id={item.id} title={item.name} className='single_store' onClick={()=>onclick_single_store(item)} key={idx} >
                                    <i className='fa fa-user-circle-o single_store_icon'/>
                                    <div className='single_store_text'>{item.name}</div>
                                </div>
                            )
                            :<div style={{padding:'20px'}}>{t(`No Store to list`)}</div>
                        }
                    </div>
                </CardBody>
            </Card>
        </Modal>
    )
}


const Forgot_password_modal = ({ open, handleclose, set_page_loader}) => {

    const alert = useAlert()
    const [email_id_for_link, set_email_id_for_link] = useState('')
    const [error_text, set_error_text] = useState('')

    const onclick_handleclose = () => {
        set_email_id_for_link('');
        handleclose();
    }

    const validate_email = () => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email_id_for_link);
    }

    const clean_Fp_data = () =>{
        set_email_id_for_link('')
    }

    const onclick_send_reset_link = async() => {
        if(!validate_email()){
            set_error_text('Incorrect Email Id');
            alert.error('Please enter correct Email Id.');
        }else{
            try{
                var body={email_or_username:email_id_for_link}
                set_page_loader({
                    show: true,
                    text: 'Sending password reset link...'
                });
                var resp = await general_fetch({url:'/user/init_forgot_password', body, return_response_code:true});
                set_page_loader({
                    show: false,
                    text: ''
                });
                if(resp && resp.response_code===1){
                    alert.success('Reset password link sent successfully.')
                    onclick_handleclose()
                }else if(resp && resp.code){
                    if(resp.code == "USER_NOT_SIGNED_UP"){
                        alert.error('Email not registered. Please Sign up.')
                        onclick_handleclose()
                    }
                }
                clean_Fp_data();
        }catch(err){
            console.error(err)
            alert.error('Request Failed!')
            clean_Fp_data()
            set_page_loader({
                show: false,
                text: ''
            });
        }
        }

    }
    
    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_handleclose}>
            <Card style={{boxShadow: '0 2px 5px 0 rgb(0 0 0), 0 2px 5px 0 rgb(0 0 0)'}}>
                <CardHeader>
                    <Row>
                        <Col>
                            Find your Account
                        </Col>
                        <Col style={{textAlign:'right'}}>
                            <span style={{cursor:'pointer'}} onClick={onclick_handleclose}>×</span>
                        </Col>
                    </Row>
                </CardHeader> 
                <CardBody>           
                    <Row style={{paddingBottom:'5px'}}>
                        <Col className="text-muted">
                            <span className='sign_up_text_color'>Please enter your Email Id</span>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom:'30px'}}>
                        <Col>
                            <Input value={email_id_for_link} onChange={(e) => set_email_id_for_link(e.target.value)} style={{fontSize:'12px'}} placeholder='Email'  type='text'/>
                        </Col>
                    </Row>
                    {/* <Row style={{display:error_text?'block':'none',paddingBottom:'30px'}}>
                        <Col>
                            <span>{error_text}</span>
                        </Col>
                    </Row> */}
                </CardBody> 
                <CardFooter>   
                    <Row>    
                        <Col style={{ textAlign:'right'}}>
                            <Button onClick={onclick_send_reset_link} className='blue_button' style={{ whiteSpace:'nowrap'}}>Send Reset Link</Button>
                        </Col>
                    </Row>
                </CardFooter>
                
            </Card>
        </Modal>
    )    
}


const Sign_up = ({org_key, init, init_needed, set_init_needed, target, set_page_loader, org_key_logo, org_key_id, org_key_name, logout}) => {

    const alert = useAlert()
    const history = useHistory();
    var SHA512 = require('js-sha512')
    
    const [login_state, set_login_state] = useState('signin')
    //const [target, set_target] = useState('')
    const [target_type, set_target_type] = useState('')
    const [target_path, set_target_path] = useState('')

    const [ email, set_email ] = useState("");
    const [ password, set_password] = useState("");

    const [ IP_city, set_IP_city ] = useState("");
    const [ IP_country, set_IP_country] = useState("");
    const [ market, set_market] = useState("");

    const [ login_modal_type, set_login_modal_type ] = useState('normal')

    const [ sign_up_email, set_sign_up_email ] = useState("");
    const [ account_type, set_account_type] = useState("professional");
    const [ store_name, set_store_name] = useState('');

    const [ create_password, set_create_password ] = useState("");
    const [ confirm_password, set_confirm_password] = useState("");
    
    const [ confirm_otp, set_confirm_otp] = useState("");
    const [ terms_and_conditions, set_terms_and_conditions] = useState(false);

    const [show_forgot_password_modal, set_show_forgot_password_modal] = useState(false)

    const [show_store_options_modal, set_show_store_options_modal] = useState(false)
    const [all_store_options, set_all_store_options] = useState([])

    
    useEffect(() => {
        login_init();

        if(target === "social_signup"){
            set_login_modal_type("already_signed_user")
        }else if(target === "admin"){
            set_target_type('admin')
            set_target_path(window.Module.API.admin_path)
        }else if(target === "affiliate"){
            set_target_type('affiliate')
            set_target_path(window.Module.API.affiliate_path)
        }else{
            set_target_type('designer');
            set_target_path('/');
        }

        if(window.location.href.includes('e=multilogin')){
            alert.error("The same account was signed in on another device.")
        }
    },[target]);

    const route_finder = async() => {
        console.log('workspaces----------------------')
        try{
            var checking_response = await window.Promisify(window.Module.get_user_details());
            console.log("checking_response", checking_response)
            if(checking_response && checking_response != "error" && checking_response != "unauthorized"){
                if(org_key && org_key_id != ""){
                    var resp_store_details = await general_fetch({url:'/user/get_attached_stores'});
                    if(resp_store_details && resp_store_details.length>0){
                        var store_find = resp_store_details.find(o => o.id == org_key_id)
                        if(store_find){
                            var resp_onclick_store = await general_fetch({url:'/user/set_active_store', body:{selected_store_id:org_key_id}})
                            alert.success('Successfully signed into ' + org_key_name);
                            redirect_to_target_final();
                        }else{
                            alert.error("You don't have an account in " + org_key_name)
                            logout();
                        }
                    }
                }else{
                    var resp_store_details = await general_fetch({url:'/user/get_attached_stores'})
                    if(resp_store_details && resp_store_details.length>1){
                        set_all_store_options(resp_store_details)
                    }else{
                        redirect_to_target_final();   
                    }
                }
            }else{
                redirect_to_target_final();
            }
        }catch(err){
            alert.error('Error in fetching details for Stores')
            console.error(err)
        }
    } 

    const login_init = async () => {
        try{
            var response = await window.Promisify(window.Module.get_user_details());
            if(response == "success"){
                await redirect_to_target_final();
            }

            return response;
        }catch(err){
            console.error(err);
            return "error";
            // return Promise.reject(err);
        }
    }

    const redirect_to_target_final = async () => {
        if(target_type==='admin'|| target_type==='affiliate'){
            window.location.href=target_path
        }
        else{
            var resp = await trigger_init();
            if(!resp){
                var actual_org_key = org_key;
                if(window['Module'] && window.Module['get_active_user']){
                    var active_user = window.Module.get_active_user();
                    if(active_user && active_user.white_labelling_url && active_user.white_labelling_url != actual_org_key){
                        actual_org_key = active_user.white_labelling_url;
                    }
                    //active_user.delete()
                }

                var url = "/";
                (actual_org_key?(url = "/"+actual_org_key + url):null);
                history.push(url)
            }else{
                console.log(resp)
            }
        }
    }

    useEffect(() => {
        if(all_store_options && all_store_options.length){
            set_show_store_options_modal(true)
        }
    },[all_store_options])

    const onclick_store = async(selected_store) => {
        try{
            var resp_onclick_store = await general_fetch({url:'/user/set_active_store', body:{selected_store_id:selected_store.id}})
            alert.success('Successfully signed into ' + selected_store.name)
            if(target_type==='admin'|| target_type==='affiliate'){
                window.location.href=target_path
            }
            else{
                var resp = await trigger_init();
                if(!resp){
                    var actual_org_key = org_key;
                    if(window['Module'] && window.Module['get_active_user']){
                        var active_user = window.Module.get_active_user();
                        if(active_user && active_user.white_labelling_url && active_user.white_labelling_url != actual_org_key){
                            actual_org_key = active_user.white_labelling_url;
                        }
                        //active_user.delete()
                    }

                    var url = "/";
                    (actual_org_key?(url = "/"+actual_org_key + url):null);
                    history.push(url)
                }else{
                    console.log(resp)
                }
            }
        }catch(err){
            alert.error('Error in Logging into selected store')
            console.error(err)
        }
    }

    const close_store_options_modal = () => {
        set_show_store_options_modal(false)
        set_all_store_options([])
    }

    useEffect(()=> {
        // if (getUrlVars()['target'] == 'admin') {
        //     set_target_type('admin');
        //     set_target_path(global.config.admin_path);
        // }
        // else if (getUrlVars()['target'] == 'affiliate') {
        //     set_target_type('affiliate');
        //     set_target_path(global.config.affiliate_path);
        //     // $('#terms_and_conditions_link').attr('href', 'https://www.infurnia.com/affiliate-terms-and-conditions/');
        //     // $('#account_type_div').hide()
        // }
        // else {
        //     set_target_type('designer');
        //     set_target_path('/');

        //     if (getUrlVars()['account_type'] == 'professional') {
        //         set_account_type('professional')
        //         go_to_signup_choice()
        //     }
        //     else if (getUrlVars()['account_type'] == 'business') {
        //         set_account_type('business')
        //         go_to_signup_choice()
        //     }
        // } 

        initialize_get_ip_details() 


    },[])

   

    // {
    //     "ip": "49.207.198.175",
    //     "hostname": "broadband.actcorp.in",
    //     "city": "Bengaluru",
    //     "region": "Karnataka",
    //     "country": "IN",
    //     "loc": "12.9719,77.5937",
    //     "org": "AS24309 Atria Convergence Technologies Pvt. Ltd. Broadband Internet Service Provider INDIA",
    //     "postal": "560002",
    //     "timezone": "Asia/Kolkata",
    //     "readme": "https://ipinfo.io/missingauth"
    //   }%  

    const initialize_get_ip_details = async () =>{
        try{
            var response = await general_fetch({full_url:"https://ipapi.co/json/", return_raw_response:true, method:'get',credentials:'omit'})
            // console.log(response.city, response.country);
            response && set_IP_country(response.country);
            response && set_IP_city(response.city);
            if (response.country === "IN") {
                set_market('india')
            }
            else {
                set_market('international')
            }
        }catch(err){
            // console.error(err)
            set_IP_city('')
            set_IP_country('IN')
            set_market('india')
        }
    }

    var getUrlVars = function() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
        });
        return vars;
    }

    const go_to_signup_choice = () => {
        set_login_state('signup');
    
        // $("#login_section").slideUp('slow',function(){
        //     $("#signup_section").slideDown('slow');        
        //   });
        // send_analytics_event('click', 'signup', 'signup_entry', 0)
    }

    const trigger_init = async () => {
        set_page_loader({
            show: true,
            text: 'Loading...'
        });
        set_init_needed(false);
        var resp = await init();
        set_page_loader({
            show: false,
            text: ''
        });

        return resp;
    }


    const enter_handle_login = (e) => {
        if(e.key==='Enter'){
            login_call_fn()
        } 
    }

    const login_call_fn = () => {
        if(email && password){
            set_page_loader({
                show: true,
                text: 'Loading...'
            });

            setTimeout(() => {
                window.Promisify(window.Module.login(email,password))
                .then(response => {
                    if(response != "error"){
                        // init().then(() => {
                            setTimeout(() => {
                                set_page_loader({
                                    show: false,
                                    text: ''
                                });
                                route_finder()
                            }, 1000);
                        // });    
                    }else{
                        return Promise.reject();
                    }
                })
                .catch(err=>{
                    alert.error('Incorrect Email or Password')
                    set_page_loader({
                        show: false,
                        text: ''
                    });
                });
            }, 0);
        }else{
            alert.error('Please enter correct Email Id and Password.')
        }
    }

    const login_pop_up = async (social_site) => {
        var w = 550;
        var h = 600;
        var url = window.Module.API.server_path+'/'+social_site;
        var title = 'Google Login';
     
        // Fixes dual-screen position
        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;
     
        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
     
        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;
        var win = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
        // Puts focus on the win
        if (window.focus) {
            win.focus();
        }

        var timer = setInterval(async() => {
            if(win && win.closed) {
                win = undefined;
               // clearInterval(timer);
               try{
                    route_finder()
               }catch(err){
                   console.error(err)
                   alert.error("Request Failed. Try Again.")
                   set_page_loader({
                    show: false,
                    text: ''
                });
               }                
            }
        }, 100);
     
        return false;               
    }

    const delete_all_data_taken_for_cerate_store = () =>{
        set_IP_city("");
        set_IP_country("");
        set_market("");
        set_login_modal_type('')
        set_sign_up_email("");
        set_account_type("professional");
        set_store_name('');
        set_target_type('');
        set_target_type('');

        set_create_password("");
        set_confirm_password("");
        set_confirm_otp("");
        set_terms_and_conditions(false);

    }

    const create_store = async () => {
        try{
            var sha_password = SHA512(create_password);
            var store_data = { username:'', contact:'', website:'',otp:confirm_otp, name: store_name, store_address:IP_city + "," + IP_country, email:sign_up_email, password : sha_password, market:market, type:account_type}
            set_page_loader({
                show: true,
                text: 'Creating New Store...'
            });
            var resp = await general_fetch({url:'/store/add', body:store_data, return_response_code:true})
            set_page_loader({
                show: false,
                text: ''
            });
            if(resp && resp.response_code === 1) {
                alert.success(account_type==='business'?'Business':'Professional' + " account successfully created")
                delete_all_data_taken_for_cerate_store() ;
                await trigger_init();
                var url = "/";
                (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
                history.push(url)
            }
            // else if (resp && resp.response_code === 0) {
            //     //loader
            //     alert.error("Signup Failed. Try Again.")
            //     delete_all_data_taken_for_cerate_store() ;
            //     var url = "/login";
            //     (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
            //     history.push(url)
            // }
            // else if (resp &&resp.response_code === -2) {
            //     //loader
            //     alert.error("OTP : " + sign_up_email)
            //     delete_all_data_taken_for_cerate_store() ;
            //     var url = "/login";
            //     (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
            //     history.push(url)
            // }
            else if (resp &&resp.code) {
                //loader
                if(resp.code == "OTP_INVALID"){
                    alert.error("Incorrect OTP")
                }
            }
            else {
                //loader
                alert.error("Signup Failed. Try Again.");
                delete_all_data_taken_for_cerate_store() ;
                set_init_needed(true);
                var url = "/login";
                (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
                history.push(url)
            }
        }catch(err){
            console.error(err)
            alert.error("Signup Failed. Try Again.");
            delete_all_data_taken_for_cerate_store();
            set_page_loader({
                show: false,
                text: ''
            });
            var url = "/login";
            set_init_needed(true);
            (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
            history.push(url)
        }   
    }

    const create_affiliate = async() => {
        try{
            var affiliate_data = { email:sign_up_email, password : SHA512(confirm_password), market:market}
            set_page_loader({
                show: true,
                text: 'Creating New Store...'
            });
            var resp = await general_fetch({url:'/referral/add_affiliate', body:affiliate_data});
            set_page_loader({
                show: false,
                text: ''
            });
            if(resp.response_code === 1) {
                //loader
                alert.success("Affiliate account successfully created")
                //window.location = 'https://www.infurnia.com/affiliate-terms-and-conditions/';
                delete_all_data_taken_for_cerate_store() ;
                route_finder();
            }
            else {
                //loader
                alert.error('Signup Failed. Try Again.');
            }
        }catch(err){
            console.error(err)
            alert.error('Signup Failed. Try Again.');
            set_page_loader({
                show: false,
                text: ''
            });
        }  
        
    }

    const validate_email = (email_temp) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email_temp);
    }

    const validate_password = () => {
        if(create_password) {
            return true
        }
        return false
    }
    
    const validate_repassword=() => {
        if(create_password===confirm_password) {
            return true
        }
        return false
    }


    const send_otp_to_email = async () => {
        try{
            var body={email:sign_up_email}
            set_page_loader({
                show: true,
                text: 'Sending OTP...'
            });
            var resp = await general_fetch({url:'/store/generate_signup_otp', body, return_response_code:true});
            set_page_loader({
                show: false,
                text: 'Sent'
            });
            if(resp && resp.response_code === 1) {
                set_login_modal_type('password')
                alert.success("Please check otp sent on your Email")
            }
            else if (resp && resp.code) {
                if(resp.code == "USER_ALREADY_SIGNED_UP"){
                    alert.error("User with this Email id already exists")
                }
            }
        }catch(err){
            console.error(err)
            alert.error("Request Failed!")
            set_page_loader({
                show: false,
                text: ''
            });
        }  
    }

    const onclick_proceed_for_otp = () =>{
        
        var valid_email = validate_email(sign_up_email);
    
        if (!valid_email) {
            alert.error("Please provide a valid Email id")
        }else{     
            send_otp_to_email();   
        }
    }

    const user_validation_submit = () => {
        
        if (!validate_password()) {
            alert.error("Please provide a valid password");    
        }
        else if (!validate_repassword()) {
            alert.error("Password mismatch");
        }
        else if (!terms_and_conditions) {
            alert.error("Please agree to terms and conditions");
        }
        else if(!confirm_otp){
            alert.error('Please Enter OTP')
        }else {
            if (target_type==='affiliate') {
                create_affiliate()
            }
            else {
                create_store();
            }
        }        
    }

    const user_validation_submit_no_store = () => {
        
        if (!terms_and_conditions) {
            alert.error("Please agree to terms and conditions");
        }
        else {
            if (target_type==='affiliate') {
                create_affiliate()
            }
            else {
                create_store();
            }
        }        
    }

    const close_forgot_password_modal = () => {
        set_show_forgot_password_modal(false);
    }
    const open_forgot_password_modal = () => {
        set_show_forgot_password_modal(true);
    }

    const go_back_to_normal = () => {
        set_login_modal_type('normal')
        set_login_state('signin')
    }

    return (
        <div style={styles.main_div}>
            <div style={styles.layer}>
            </div>
            
            <Forgot_password_modal open={show_forgot_password_modal} handleclose={close_forgot_password_modal} set_page_loader={set_page_loader}/>
            <Store_options_modal open={show_store_options_modal} handle_close={close_store_options_modal} all_store_options={all_store_options} set_page_loader={set_page_loader} onclick_store={onclick_store}/>
            <div style={{width:'350px', position:"absolute", left:'calc((100% - 350px)/2)',top:'5%', height:'auto', overflow:'auto'}}>
                
                <div style={{backgroundColor:'inherit', textAlign:'center', padding:'20px', marginBottom:'10px'}}><img  height='100%' width='200px' src={org_key_logo} /></div>    
                <Card style={{boxShadow: '0 2px 5px 0 rgb(0 0 0), 0 2px 5px 0 rgb(0 0 0)'}}>
                    
                    <div style = {{ display: login_modal_type==='normal'? 'block':'none', padding:'30px', fontSize:'14px'}}>
                            
                        <Row>
                            <Col>
                                <Button className='btn_login btn_google' style={{margin:'15px 0px', backgroundColor:'#dd4b39', border:0, fontWeight:'bold'}} type="button" onClick={() =>login_pop_up('google')} >Sign in with Google</Button>
                            </Col>
                        </Row>
                        {!org_key?<Row>
                            <Col>    
                                <Button className='btn_login btn_linkedin' style={{margin:'15px 0px', backgroundColor:'#007bb6', border:0 , fontWeight:'bold'}} type="button" onClick={() =>login_pop_up('linkedin')} >Sign in with Linkedin</Button>
                            </Col>
                        </Row>:''}
                        <Row>
                            <Col style={{padding: '10px'}}>
                                <span style={{fontSize:'18px'}}>or</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{paddingBottom: '10px'}} >
                                <span style={{fontSize:'18px'}}>Login with credentials</span>
                            </Col>
                        </Row>    
                            
                        <Form role="form">
                            <Row>
                                <Col>
                                    <FormGroup style={{marginBottom:"16px"}}>
                                        <Input id="login_username" className='input_login' placeholder="Email or Username" type="text" value={email?email:''} onChange={(e) => set_email(e.target.value)} />
                                    </FormGroup>
                                </Col>  
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup style={{marginBottom:"16px"}}>
                                        <Input id="login_password" className='input_login' placeholder="Password" type="Password" value={password?password:''}  onChange={(e) => set_password(e.target.value)} onKeyUp={(e)=>enter_handle_login(e)} />
                                    </FormGroup>
                                </Col>  
                            </Row>
                            <Row>
                                <Col>
                                    <Button  id="login_submit" type="button" className='btn_login btn_success' style={{ backgroundColor:'#5cb85c', border:0}}  onClick={login_call_fn} >Log in</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign:'right', fontSize:'10px', padding:'5px 15px'}}>
                                    <span onClick={open_forgot_password_modal} style={{cursor:"pointer"}} className='forgot_password_hover'>Forgot Password?</span>
                                </Col>
                            </Row>
                        </Form>

                        {!org_key?(<Row>
                            <Col>
                                <Button className='btn_login btn_default' onClick={()=>{set_login_modal_type('new_to_inf');set_login_state('signup')}} style={{ margin:'25px 0px', width:'100%', backgroundColor:'white', color:'#333', border:"1px solid #ccc"}}  type="button">New to Infurnia? Sign Up</Button>
                            </Col>
                        </Row>):''}
                        
                    </div>
                    <div style = {{ display: login_modal_type==='new_to_inf'? 'block':'none', padding:'30px'}}>
                        
                        <Row>
                            <Col>
                                <Button className='btn_login btn_google' style={{margin:'15px 0px', backgroundColor:'#dd4b39', border:0, fontWeight:'bold'}} onClick={() =>login_pop_up('google')} type="button" >Sign up with Google </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>    
                                <Button className='btn_login btn_linkedin' style={{margin:'15px 0px', backgroundColor:'#007bb6', border:0, fontWeight:'bold'}} type="button" >Sign up with Linkedin</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className='btn_login' onClick={() =>set_login_modal_type('with_email')} style={{ margin:'15px 0px',padding:'5px', width:'100%', backgroundColor:'#008B8B', border:0, fontWeight:'bold'}}  type="button">Sign up with Email</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span onClick={go_back_to_normal} style={{ padding:'5px', color:'#5092BD', fontSize:'14px',fontWeight:'bold'}}  type="button">Go back to Login</span>
                            </Col>
                        </Row>
                        
                    </div>
                    <div style = {{ display: login_modal_type==='with_email'? 'block':'none', padding:'40px 25px 30px'}}>
                        
                        <Row style={{paddingBottom:'20px'}}>
                            <Col xs={5} className="text-muted text-right" >
                                <span className='sign_up_text_color'>Account Type</span>
                            </Col>
                            <Col xs={7}>
                                <Input style={{fontSize:'12px'}} value={account_type} onChange={(e) => set_account_type(e.target.value)} type='select'>
                                    <option value='professional'>Professional</option>
                                    <option value='business'>Business</option>
                                </Input>
                            </Col>
                        </Row>
                        {
                            account_type==='business'?(
                                <Row style={{paddingBottom:'20px'}}>
                                    <Col xs={5} className="text-muted text-right" >
                                        <span className='sign_up_text_color'>Store Name</span>
                                    </Col>
                                    <Col xs={7}>
                                        <Input value={store_name} onChange={(e) => set_store_name(e.target.value)} style={{fontSize:'12px'}} placeholder='Name'  type='text'/>
                                    </Col>
                                </Row>
                            ):''
                        }
                        <Row style={{paddingBottom:'30px'}}>
                            <Col xs={5} className="text-muted text-right">
                                <span className='sign_up_text_color'>Email</span>
                            </Col>
                            <Col xs={7}>
                                <Input value={sign_up_email} onChange={(e) => set_sign_up_email(e.target.value)} style={{fontSize:'12px'}} placeholder='example@gmail.com'  type='text'/>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'20px'}}>
                            <Col style={{display:'flex', justifyContent:'flex-end', paddingRight:'0px'}}>
                                <Button className='white_button' onClick={()=>set_login_modal_type('new_to_inf')} style={{width:'100px', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'white'}}>Back</Button>
                            </Col>
                            <Col style={{display:'flex', justifyContent:'flex-start'}}>
                                <Button className='blue_button' onClick={onclick_proceed_for_otp} style={{width:'100px', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'white'}}>Proceed</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span onClick={go_back_to_normal} style={{ marginBottom:'5px',padding:'5px',color:'#5092BD', fontSize:'14px',fontWeight:'bold'}}  type="button">Go back to Login</span>
                            </Col>
                        </Row>
                    </div>

                    <div style = {{ display: login_modal_type==='already_signed_user'? 'block':'none', padding:'40px 25px 30px'}}>
                        
                        <Row style={{paddingBottom:'20px'}}>
                            <Col xs={5} className="text-muted text-right" >
                                <span className='sign_up_text_color'>Account Type</span>
                            </Col>
                            <Col xs={7}>
                                <Input style={{fontSize:'12px'}} value={account_type} onChange={(e) => set_account_type(e.target.value)}   type='select'>
                                    <option value='professional'>Professional</option>
                                    <option value='business'>Business</option>
                                </Input>
                            </Col>
                        </Row>
                        {
                            account_type==='business'?(
                                <Row style={{paddingBottom:'20px'}}>
                                    <Col xs={5} className="text-muted text-right" >
                                        <span className='sign_up_text_color'>Store Name</span>
                                    </Col>
                                    <Col xs={7}>
                                        <Input value={store_name} onChange={(e) => set_store_name(e.target.value)} style={{fontSize:'12px'}} placeholder='Name'  type='text'/>
                                    </Col>
                                </Row>
                            ):''
                        }
                        <Row style={{padding:'10px 0px'}}>
                            <Col className="text-center" style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'5px'}}>
                                <span style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'5px'}}><Input value={terms_and_conditions} onChange={() => set_terms_and_conditions(!terms_and_conditions)} type='checkbox'/></span>
                                <span style={{fontSize:'10px'}}>I agree to the <a target="blank" href="https://www.infurnia.com/terms-and-conditions/">terms and conditions</a> </span>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'20px'}}>
                            
                            <Col style={{display:'flex', justifyContent:'center'}}>
                                <Button onClick={user_validation_submit_no_store} className='blue_button' style={{ display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'white'}}>Create Account</Button>
                            </Col>
                        </Row>
                        
                    </div>

                    <div style = {{ display: login_modal_type==='password'? 'block':'none', padding:'40px 25px 30px'}}>
                        
                        <Row style={{paddingBottom:'20px'}}>
                            <Col xs={5} className="text-muted text-right">
                                <span className='sign_up_text_color'>Password</span>
                            </Col>
                            <Col xs={7}>
                                <Input value={create_password} onChange={(e) => set_create_password(e.target.value)} style={{fontSize:'12px'}} placeholder='Password'  type='password'/>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'20px'}}>
                            <Col xs={5} className="text-muted text-right">
                                <span className='sign_up_text_color'>Re Password</span>
                            </Col>
                            <Col xs={7}>
                                <Input value={confirm_password} onChange={(e) => set_confirm_password(e.target.value)} style={{fontSize:'12px'}} placeholder='Confirm Password'  type='password'/>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'30px'}}>
                            <Col xs={5} className="text-muted text-right">
                                <span className='sign_up_text_color'>OTP</span>
                            </Col>
                            <Col xs={7}>
                                <Input value={confirm_otp} onChange={(e) => set_confirm_otp(e.target.value)} style={{fontSize:'12px'}} placeholder='Password sent on Email'  type='text'/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center" style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'5px'}}>
                                <span style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'5px'}}><Input value={terms_and_conditions} onChange={() => set_terms_and_conditions(!terms_and_conditions)} type='checkbox'/></span>
                                <span style={{fontSize:'10px'}}>I agree to the <a target="blank" href="https://www.infurnia.com/terms-and-conditions/">terms and conditions</a></span>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'20px'}}>
                            <Col style={{display:'flex', justifyContent:'flex-end', paddingRight:'0px'}}>
                                <Button className='white_button' onClick={()=>set_login_modal_type('with_email')} style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'white'}}>Back</Button>
                            </Col>
                            <Col style={{display:'flex', justifyContent:'flex-start'}}>
                                <Button onClick={user_validation_submit} className='blue_button' style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'white'}}>Create Account</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span onClick={go_back_to_normal} style={{ marginBottom:'5px',padding:'5px',color:'#5092BD', fontSize:'14px', fontWeight:'bold'}}  type="button">Go back to Login</span>
                            </Col>
                        </Row>
                        
                    </div>
                </Card>
            </div>
        </div>    
    );
}

export default Sign_up;

