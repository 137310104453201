const generic_sort = ( a,b,field,order=1,type) => {
	if(a[field]==''|| a[field]===null||a[field]){
		// console.log([field]')
		if (!a[field]) {
			return -1*order;
		}
		if (!b[field]) {
			return 1*order;
		}
        if(type != 'date'){
            if (a[field].toLowerCase() < b[field].toLowerCase()) {
                return -1 * order;
            }
            if (a[field].toLowerCase() > b[field].toLowerCase()) {
                return 1 * order;
            }
        }else{
            return (new Date(b[field]) - new Date(a[field]))*order;
        }
		return 0;
	}
    
    return -1*order;
}

export default generic_sort;