import React, { useEffect, useState } from 'react';
import './style.css'; // Add your styling if needed
import { COLORS } from '../../utils/color';
import {useTranslation} from 'react-i18next'
import IImg from '../IImg';

const ImageCarousel = ({ images, initalIndex=0, onIndexChangeCallback, show_on_hover=true, icon_styles, carousel_navigation_button_styles, ...props }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(initalIndex);
  const {t} = useTranslation()

  useEffect(() => {
    if(onIndexChangeCallback)
      onIndexChangeCallback(currentImageIndex)
  }, [currentImageIndex])

  const handlePrevClick = (event) => {
    event.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNextClick = (event) => {
    event.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };


  return (
    <div className="infurnia-image-carousel" {...props}>
      {images && images.length > 0 ? (
        <div className={"infurnia-image-carousel-card" + (show_on_hover?"":" fixed-image-carousel-card")}>
          <IImg style={{ width: '100%', aspectRatio: '16/9' }} src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} />
          <div className="infurnia-carousel-navigation-buttons" style={{...carousel_navigation_button_styles}} onClick={(e) => e.stopPropagation()}>
            <i className="fa fa-caret-left ml-1" style={{fontSize: '24px', cursor: images.length === 1 || currentImageIndex === 0 ? 'not-allowed' : 'pointer', color: '#A7A8B2', opacity: images.length === 1 || currentImageIndex === 0 ? 0.5 : 1, ...icon_styles}} onClick={(images.length === 1 || currentImageIndex === 0) ? null : handlePrevClick} disabled={images.length === 1 || currentImageIndex === 0} />
            <i className="fa fa-caret-right mr-1" style={{fontSize: '24px', cursor: images.length === 1 || currentImageIndex === images.length - 1  ? 'not-allowed' : 'pointer', color: '#A7A8B2', opacity: images.length === 1 || currentImageIndex === images.length - 1 ? 0.5 : 1, ...icon_styles}} onClick={(images.length === 1 || currentImageIndex === images.length - 1) ? null : handleNextClick} disabled={images.length === 1 || currentImageIndex === images.length - 1} />
          </div>
        </div>
      ) : (
        <div className='flex_center inf-flex-col' style={{width: '100%', aspectRatio: '16/9', background: COLORS.gray3, color: COLORS.gray5}}>
            <img className='inf-mb-2' src="/resources/icons/no_designs.svg" width={'36px'} />
            <div className='inf-text-base'>{t(`No Designs`)}</div>
        </div>
      )}
    </div>
  );
};

export default ImageCarousel;
