const changeProject = (id, history) => {
    const searchParams = new URLSearchParams();
    // Update the 'view' query parameter
    searchParams.set('project', id);

    // Replace the current URL with the updated query parameter
    history.push({
        pathname: window.location.pathname,
        search: searchParams.toString(),
        state: {from: 'infurnia'}
    });
};

export default changeProject;