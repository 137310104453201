import { send_analytics } from "./send_analytics";
import sleep from "./sleep";

export const fetch_store_materials_onclick = (json_item) => {

    var analytics_data = {};
    analytics_data.category = "Material fetch from store clicked";
    analytics_data.element_type = window.Module.get_selection_element_type();
    send_analytics(analytics_data,true);
    window.trigger_page_loader(true, 'Please wait', async() => { 
        await sleep(50)
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.active_material_select_item_id = json_item.id;
        if(window.debug_mode){
            window.add_debug_log("fetch_store_materials_onclick(\"" + json_item.id + "\", \"" + json_item.route + "\", \"" + json_item.material_type.replace(/"/g, '\\"') + "\", \"" + json_item.active_category_id + "\", " + json_item.pass_params_to_carousel_onclick + ", \"" + json_item.parameter_expression_self_element_id + "\", \"" + json_item.parameter_expression_map_key + "\");");
        }
        window.Module.fetch_store_materials_onclick(json_item.id, json_item.route, json_item.material_type, json_item.active_category_id, json_item.pass_params_to_carousel_onclick, json_item.parameter_expression_self_element_id, json_item.parameter_expression_map_key);
        
        if(window.update_view){
            window.update_view();
        }

        window.trigger_page_loader(false, 'Please wait')
        active_design.delete();
        active_view.delete();
    });
};

export const on_click_picker = async(json_item, is_panel_pop_up) => {
    if(json_item && json_item.type == "material_select"){
        try{
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;
            active_view.active_material_select_item_id = json_item.id;
            
            var to_pass = {};
            to_pass.is_panel_pop_up = is_panel_pop_up ? true : false;
            to_pass.material_type = json_item.material_type;

            to_pass.id = json_item.id;
            to_pass.route = json_item.route;
            to_pass.pass_params_to_carousel_onclick = json_item.pass_params_to_carousel_onclick;


            if(window.debug_mode){
                window.add_debug_log("trigger_material_picker_apply" + "(\"" + (JSON.stringify(to_pass).replace(/"/g, '\\"')) + "\");");
            }

            window.Module['trigger_material_picker_apply'](JSON.stringify(to_pass))
            if(window.update_view){
                window.update_view();
            }
            active_design.delete();
            active_view.delete();
        } catch(err){
            if(err.constructor.name.includes("Error")){
                err = new Error(err)
            }
            err.name = "Captured error in Panel menu (material picker) onclick error at " + "trigger_material_picker_apply" + " : " + err.name // change reset_function to actual reset function above
            console.error(err);
            window.sentry_capture_exception("",err);
        }
    }
}

export const open_material_settings = async(json_item) => {
    if(json_item && json_item.type == "material_select" && json_item.active_material){
        try{
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;
            active_view.active_material_select_item_id = json_item.id;
            if(window.debug_mode){
                window.add_debug_log("trigger_material_edit_modal" + "(\"" + json_item.active_material.id + "\");");
            }
            window.Module['trigger_material_edit_modal'](json_item.active_material.id)
            if(window.update_view){
                window.update_view();
            }
            active_design.delete();
            active_view.delete();
        } catch(err){
            if(err.constructor.name.includes("Error")){
                err = new Error(err)
            }
            err.name = "Captured error in Panel menu (reset to default for material) onclick error at " + "reset_function" + " : " + err.name // change reset_function to actual reset function above
            console.error(err);
            window.sentry_capture_exception("",err);
        }
    }
}

export const reset_material = async(json_item) => {
    if(json_item && json_item.type == "material_select"){
        try{
            if(window.debug_mode){
                window.add_debug_log("reset_original_material_general" + "(\"" + JSON.stringify(json_item).replace(/"/g, '\\"') + "\");");
            }
            window.Module['reset_original_material_general'](JSON.stringify(json_item))
            if(window.update_view){
                window.update_view();
            }
        } catch(err){
            if(err.constructor.name.includes("Error")){
                err = new Error(err)
            }
            err.name = "Captured error in Panel menu (reset to default for material) onclick error at " + "reset_function" + " : " + err.name // change reset_function to actual reset function above
            console.error(err);
            window.sentry_capture_exception("",err);
        }
    }
}

export const clone_active_modal_onclick = (json_item, is_panel_pop_up) => {
    if(json_item && json_item.type == "material_select" && json_item.active_material){
        
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.active_material_select_item_id = json_item.id;

        var apply_data = {};
        apply_data.id = json_item.id;
        apply_data.pass_params_to_carousel_onclick = json_item.pass_params_to_carousel_onclick;
        apply_data.route = json_item.route;
        apply_data.is_panel_pop_up = is_panel_pop_up;

        if(window.debug_mode){
            window.add_debug_log("clone_active_modal_onclick(\"" + json_item.active_material.id + "\", \"" + json_item.id + "\", \"" + json_item.route + "\", \"" + JSON.stringify(apply_data).replace(/"/g, '\\"') + "\");");
        }

        window.Module.clone_active_modal_onclick(json_item.active_material.id, json_item.id, json_item.route, JSON.stringify(apply_data));
        
        if(window.update_view){
            window.update_view();
        }

        active_design.delete();
        active_view.delete();

        var analytics_data = {};
        analytics_data.category = "Material clone clicked";
        analytics_data.element_type = window.Module.get_selection_element_type();
        send_analytics(analytics_data,true);
    }
};