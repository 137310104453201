import React, { useState, useEffect, isValidElement } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DnD = ({Single_Component_arr, Single_Component, Single_Component_props, onDragEnd, direction='vertical'}) => {
    return (<DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-context" direction={direction}>
        {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={{}}>
                {Single_Component_arr.map((sub_item,idx) => (
                    <Draggable styles={{height:'100%'}} key={sub_item.id} draggableId={sub_item.id} index={idx}>
                        {(provided) => (
                        <div style={{height:'100%'}} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Single_Component {...Single_Component_props} index={idx} json_item={sub_item} key={Single_Component_props.parent_id+idx} />
                        </div>
                        )}
                    </Draggable>
                ))}
                {provided.placeholder}
            </div>
        )}
        </Droppable>
    </DragDropContext>)
}

export default DnD;