import React, { useEffect, useState } from "react";
import './style.css';

export const Ping = ({color = "#0078ff", size = 12}) => {
    return(
        <>
            <span style={{position: "relative", display: "flex", height: `${size}px`, width: `${size}px`}}>
                <span className="inf_animate_ping" style={{ position: "absolute", display: "inline-flex", borderRadius: "50%", background: color, height: `100%`, width: `100%`, opacity: '0.75' }}></span>
                <span style={{ position: "relative", display: "inline-flex", borderRadius: "50%", background: color, height: `${size}px`, width: `${size}px` }}></span>
            </span>
        </>
    )
}
