import React, { useState } from 'react';
import {Dropdown, DropdownItem, DropdownToggle, DropdownMenu, } from "reactstrap";

import './style.css';


const Generic_Dropdown = ({ className, display_jsx, options, caret}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown style={{padding:'8px',listStyle: 'none'}} nav isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle nav className={className} style={{fontSize:'12px', padding:'0px'}}>
                {display_jsx}
            </DropdownToggle>
            <DropdownMenu>
                {options && options.map((o,idx) => (
                    <DropdownItem style={{listStyle: 'none', fontSize:'12px', height:'36px'}} className='flex_center' key={idx} onClick={()=>{o.onClick(o.id);}}>{o.jsx?o.jsx:o.name}</DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}



export default Generic_Dropdown;