import React, { useEffect, useState } from 'react';
import './style.css';

const ISideBar = ({tabs, style_tabs, style_sidebar, handleTabClick, activeTab, tab_identifier = 'id', display_identifier='name'}) => {

    return (
        // <div style={{ display: 'flex', ...style_sidebar }}>
            <div style={{width: '200px', overflow: 'auto', ...style_sidebar }}>
                {
                    tabs && tabs.length ? tabs.map((tab) => (
                        <div key={tab[tab_identifier]} onClick={() => handleTabClick(tab[tab_identifier])} className={activeTab == tab[tab_identifier] ? 'inf_nav_sidebar_transparent active' : 'inf_nav_sidebar_transparent'} style={{...style_tabs}}>
                            {
                                tab[display_identifier]
                            }
                        </div>
                    ))
                        : ''
                }
            </div>
        // </div>
    )
}

export const UncontrolledISidebar = ({tabs, style_tabs, style_sidebar, display_identifier, set_active_tab}) => {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        set_active_tab(activeTab)
    }, [activeTab]);

    return (
        // <div style={{ display: 'flex', ...style_container }} className='mb-3' >
            <div style={{width: '200px', ...style_sidebar }}>
                {
                    tabs && tabs.length ? tabs.map((tab, idx) => (
                        <div key={idx} onClick={() => setActiveTab(idx)} className={activeTab == idx ? 'inf_nav_sidebar_transparent active' : 'inf_nav_sidebar_transparent'} style={{...style_tabs}}>
                            {
                                display_identifier ? tab[display_identifier] : tab
                            }
                        </div>
                    ))
                        : ''
                }
            </div>
        // </div>
    )
}

export const SidebarLayout = ({tabs, style_tabs, style_sidebar, style_content, handleTabClick, activeTab, tab_identifier = 'id', display_identifier='name', children}) => {
    return (
        <div style={{display: 'flex', flexFlow: 'row', height: '100%'}}>
            <ISideBar tabs={tabs} style_tabs={style_tabs} style_sidebar={style_sidebar} handleTabClick={handleTabClick} activeTab={activeTab} tab_identifier={tab_identifier} display_identifier={display_identifier}></ISideBar>
            <div style={{flex: 1, height: '100%', overflow: 'auto', ...style_content}}>
                {children}
            </div>
        </div>
    )
}

export default ISideBar