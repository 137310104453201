const to_fixed = (number, level=2) => {
    if(number == undefined){
        return "";
    }
    
	// Convert the number to a string with fixed decimal places
    let fixedString = number.toFixed(level);
    
    // Remove trailing zeroes and the decimal point if not necessary
    fixedString = fixedString.replace(/\.?0+$/, '');
    
    return fixedString;
}

export default to_fixed;