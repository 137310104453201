import React, { useEffect, useState } from 'react';
import './style.css';

export const INavBar = ({tabs, style_tabs, style_container, handleTabClick, activeTab, tab_identifier = 'id', display_identifier='name', isTabVisible}) => {
    const styleDisplay = (tab) => {
        if(isTabVisible && !isTabVisible(tab)){
            return {display: 'none'}
        }

        return {}
    }

    return (
        <div style={{ display: 'flex', ...style_container }} className='mb-3' >
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexWrap: 'wrap', ...style_tabs }}>
                {
                    tabs && tabs.length ? tabs.map((tab) => (
                        <div key={tab[tab_identifier]} onClick={() => handleTabClick(tab[tab_identifier], tab)} className={activeTab == tab[tab_identifier] ? 'inf_nav_tabs_transparent active' : 'inf_nav_tabs_transparent'} style={{...styleDisplay(tab)}}>
                            {
                                tab[display_identifier]
                            }
                        </div>
                    ))
                        : ''
                }
            </div>
        </div>
    )
}
export const UncontrolledINavbar = ({tabs, style_tabs, style_container, display_identifier, set_active_tab, active_border_bottom, active_tab_border, active_color, custom_classname}) => {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        set_active_tab(activeTab)
    }, [activeTab]);

    return (
        <div style={{ display: 'flex', ...style_container }} className='mb-3' >
            <div className='flex_property' style={{ width: '100%', flexWrap: 'wrap', ...style_tabs }}>
                {
                    tabs && tabs.length ? tabs.map((tab, idx) => (
                        <div key={idx} onClick={() => setActiveTab(idx)} className={activeTab == idx ? (custom_classname ? custom_classname + ' active' : 'inf_nav_tabs_transparent active') : (custom_classname ? custom_classname : 'inf_nav_tabs_transparent')} style={{color: activeTab == idx ? active_color : '', borderBottom: active_border_bottom  && activeTab == idx ? (active_tab_border ? active_tab_border : '4px solid ' + active_color) : ''}}>
                            {
                                display_identifier ? tab[display_identifier] : tab
                            }
                        </div>
                    ))
                        : ''
                }
            </div>
        </div>
    )
}

export default INavBar