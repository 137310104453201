//General purpose fetch  
//window.Module.API.server_path'https://componentdevbackend.infurnia.com'
const general_fetch = async ({ url, full_url, method, headers, body, is_form_data, credentials, data_field, return_response_code, return_raw_response }) => {
	try {
		if (!data_field) {
			data_field = 'data';
		}
		if (full_url) {
			url = full_url;
		} else {
			url = window.Module.API.server_path + url;
		}
		if (!url) {
			throw 'invalid url provided';
		}
		if(!method) {
			method = 'POST';
		}
		if (!is_form_data) {
			if (!headers) {
				headers = {
					'Content-Type': 'application/json'
				};
				headers['x-request-Id'] = window.Module.generateRandomHex32String()
			}
			if (body) {
				body = JSON.stringify(body);
			}
		}
		if (!credentials) {
			credentials = 'include';
		}
		
		
        var options = { method, headers, credentials, body };
        //console.log("options======>" , options)
        var resp = await fetch(url, options);
        resp = await resp.json();
		if(return_raw_response){
			return resp;
		}
		if(return_response_code){
			return {response_code:resp.response_code, [data_field]:resp[data_field], code:resp.code}
		}
        if (resp.response_code == 1 || resp.response_code == 0) {
        	return resp[data_field];
        } else {
        	throw new Error('Server call failed with error --' + resp.error); 
        }
	} catch(err) {
		//console.error(err)
		return Promise.reject({ err, info: 'Error in general_fetch' })
	}
} 

export default general_fetch ;
