import React, { useRef, useEffect, useState } from 'react';

var rotatable_slider_needs_redraw = false;
var rotatable_slider_killswitch = false;


const RotatableSlider = ({ radius, minYPosition, maxYPosition, canvasWidth, canvasHeight, InitialValue, InitialAngle, update_function, camera_max, camera_min }) => {
    const canvasRef = useRef(null);
    const [isCenterDragging, setIsCenterDragging] = useState(false);
    const [isPointDragging, setIsPointDragging] = useState(false);
    const [angle, setAngle] = useState(0);
    const [centerY, setCenterY] = useState((maxYPosition - minYPosition) / 2);
    const [pointX, setPointX] = useState(radius);
    const [pointY, setPointY] = useState(0);
    const [extra, setExtra] = useState(0);
    const [control_point_image, set_control_point_image] = useState();
    const [camera_image, set_camera_image] = useState();



    const drawCircle = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const centerX = canvasWidth / 2;
        const centerYOffset = centerY - minYPosition; // Initialize centerYOffset here

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // ctx.beginPath();
        // ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        // ctx.stroke();

        ctx.lineWidth = 2

        // Draw track along which the center moves
        ctx.beginPath();
        ctx.moveTo(centerX, minYPosition + extra / 2);
        ctx.lineTo(centerX, maxYPosition + extra / 2);
        ctx.strokeStyle = '#ccc';
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(centerX - 5, minYPosition + extra / 2);
        ctx.lineTo(centerX + 5, minYPosition + extra / 2);
        ctx.strokeStyle = '#ccc';
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(centerX - 5, maxYPosition + extra / 2);
        ctx.lineTo(centerX + 5, maxYPosition + extra / 2);
        ctx.strokeStyle = '#ccc';
        ctx.stroke();

        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);

        // Draw point moving along the ciarcrcle
        //   ctx.beginPath();
        //   ctx.arc(x, y, 10, 0, 2 * Math.PI);
        //   ctx.fill();
        //   let control_point = new Image()
        //   control_point.src = '/resources/RotatableSliderIcons/control_point.svg'

        //   control_point.onload = () => {
        //       ctx.drawImage(control_point, x - control_point.width/2, y - control_point.height/2);
        //     };
        if(control_point_image){
            ctx.drawImage(control_point_image, x - control_point_image.width / 2, y - control_point_image.height / 2);
        }


        // Draw draggable point for the center
        //   ctx.beginPath();
        //   ctx.arc(centerX, centerY, 5, 0, 2 * Math.PI);
        //   ctx.fillStyle = '#0078ff';
        //   ctx.fill();
        //   let camera = new Image()
        //   camera.src = '/resources/RotatableSliderIcons/camera.svg'

        //   camera.onload = () => {
        //       ctx.drawImage(camera, centerX - camera.width/2, centerY - camera.height/2);
        //     };
        const x_opp = centerX - radius * Math.cos(angle);
        const y_opp = centerY - radius * Math.sin(angle);
        
        //camera ro control point
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x_opp, y_opp);
        ctx.strokeStyle = '#ccc';
        ctx.stroke();
        
        
        if(camera_image){
            ctx.save()
            ctx.translate(x_opp, y_opp)
            ctx.rotate(angle + Math.PI)
            ctx.drawImage(camera_image, -camera_image.width / 2, -camera_image.height / 2);
            ctx.restore()
        }

        ctx.beginPath();
          ctx.arc(centerX, centerY, 5, 0, 2 * Math.PI);
          ctx.fillStyle = '#0078ff';
          ctx.fill();

        

    };

    useEffect(() => {
        let control_point = new Image()
        control_point.src = '/resources/RotatableSliderIcons/control_point.svg'
        control_point.onload = () => {
            set_control_point_image(control_point)
        }

        let camera = new Image()
        camera.src = '/resources/RotatableSliderIcons/camera.svg'
        camera.onload = () => {
            set_camera_image(camera)
        }

        initiate_redraw_listener();

        return () => {
            destroy_redraw_listener();
        }

        // let redraw = setInterval(drawCircle, 10)
    }, []);

    const redraw_listener = () => {
        if(rotatable_slider_killswitch){
            return;
        }

        if (rotatable_slider_needs_redraw) {
	        rotatable_slider_needs_redraw = false
            var t = performance.now();
			update_function();
	        requestAnimationFrame(redraw_listener)
	    }
	    else {
	        setTimeout(redraw_listener, 100);
	    }
    }

    const initiate_redraw_listener = () => {
        rotatable_slider_killswitch = false;
        setTimeout(() => {
            redraw_listener();
        },20)
    }

    const destroy_redraw_listener = () => {
        rotatable_slider_killswitch = true;
    }

    useEffect(() => {
        setCenterY(InitialValue + extra/2)
    }, [InitialValue, extra]);

    useEffect(() => {
        setAngle(InitialAngle)
        const x = canvasWidth / 2 + radius * Math.cos(InitialAngle);
        const y = InitialValue + radius * Math.sin(InitialAngle);
        setPointX(x);
        setPointY(y);
    }, [InitialAngle]);

    useEffect(() => {
        let extra_height = canvasHeight - (maxYPosition - minYPosition)
        setExtra(extra_height)
    }, [canvasHeight, maxYPosition, minYPosition]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const centerX = canvasWidth / 2;
        const centerYOffset = centerY - minYPosition; // Initialize centerYOffset here

        drawCircle();
    }, [angle, radius, minYPosition, maxYPosition, centerY, camera_image, control_point_image]); // Removed centerYOffset from the dependency array

    // useEffect(() => {
    //     update_function()
    // }, [angle]);

    // Rest of the code remains the same...
    const handleCenterMouseDown = (e) => {
        setIsCenterDragging(true);
    };

    const handlePointMouseDown = (e) => {
        setIsPointDragging(true);
    };

    const handleMouseMove = (e) => {
        if (isCenterDragging) {
            const canvas = canvasRef.current;
            const rect = canvas.getBoundingClientRect();
            const canvasY = e.clientY - rect.top;

            const newCenterY = Math.max(minYPosition + extra / 2, Math.min(maxYPosition + extra / 2, canvasY));
            setCenterY(newCenterY);
        }

        if (isPointDragging) {
            const canvas = canvasRef.current;
            const rect = canvas.getBoundingClientRect();
            const canvasX = e.clientX - rect.left;
            const canvasY = e.clientY - rect.top;
            const centerX = canvas.width / 2;
            const centerY = minYPosition + centerYOffset;

            const deltaX = canvasX - centerX;
            const deltaY = canvasY - centerY;
            let newAngle = Math.atan2(deltaY, deltaX);

            if (newAngle < 0) {
                newAngle += 2 * Math.PI;
            }

            if(newAngle > 1.5 * Math.PI){
                newAngle = 1.5 * Math.PI
            }

            if(newAngle < Math.PI / 2){
                newAngle = Math.PI/2
            }

            setAngle(newAngle);

            // Update the point coordinates
            const x = centerX + radius * Math.cos(newAngle);
            const y = centerY + radius * Math.sin(newAngle);
            setPointX(x);
            setPointY(y);
        }
        if (isCenterDragging || isPointDragging) {
            window.Module.rotatable_slider_change_3d_camera_vertical_angle(angle - Math.PI)
            let value = camera_max - (((centerY - extra / 2) / 100) * (camera_max - camera_min)/*  + camera_min */)
            // console.log(camera_max - (((centerY - extra / 2) / 100) * (camera_max - camera_min) + camera_min), camera_max, camera_min, centerY, extra, 'update parameters')
            window.Module.rotatable_slider_set_camera_height(value)
            rotatable_slider_needs_redraw = true;
        }        // update_function()
    };

    const handleMouseUp = (e) => {
        if (isCenterDragging || isPointDragging) {
            window.Module.rotatable_slider_change_3d_camera_vertical_angle(angle - Math.PI)
            let value = camera_max - (((centerY - extra / 2) / 100) * (camera_max - camera_min)/*  + camera_min */)
            window.Module.rotatable_slider_set_camera_height(value)
            rotatable_slider_needs_redraw = true;
        }
        setIsCenterDragging(false);
        setIsPointDragging(false);
        // update_function()
    };

    const handleMouseDown = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const canvasX = e.clientX - rect.left;
        const canvasY = e.clientY - rect.top;
        const centerX = canvas.width / 2;
        const centerYOffset = centerY - minYPosition;
        const pointCX = centerX + radius * Math.cos(angle);
        const pointCY = centerY + radius * Math.sin(angle);

        // Check if the click is within the center
        if (
            canvasX >= centerX - 5 && canvasX <= centerX + 5 &&
            canvasY >= centerY - minYPosition - 5 && canvasY <= centerY - minYPosition + 5
        ) {
            setIsPointDragging(false)
            setIsCenterDragging(true);
        } else {
            // Check if the click is within the point on the circle 
            setIsCenterDragging(false);
            setIsPointDragging(true);
        }
    };

    const centerYOffset = centerY - minYPosition;

    return (
        <div style={{height:canvasHeight}}>
            <canvas
                ref={canvasRef}
                width={canvasWidth}
                height={canvasHeight}
                style={{borderRadius: '0', cursor: 'pointer' }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            />
            {/* <div>Angle: {angle.toFixed(2)}</div> */}
        </div>
    );

};

export default RotatableSlider;
