export const COLORS = {
    white: '#FFFFFF',
    white_button_1: '#FEFFFF',
    black: 'rgba(50, 51, 56, 1)',
    black_left_menu: 'rgba(103, 104, 120, 1)',
    primary:'#183e62',
    layer_color:'rgba(4, 40, 68, 0.7)',
    button_primary:'#6666ff',
    button_danger:'#ff4d4d',
    main_page_button:'rgba(3,29,66,0.4)',
    carousel_background:'rgb(0,0,0,.6)',
    mainpage_locale:'#1ABB9C',
    grey:'#ced4da',
    light_grey:'#EFEFEF',
    // button_submit:'#6666ff',
    // button_ok:'#6666ff',
    // button_cancel:'#ff4d4d',
    // button_delete:'#ff4d4d',
    // button_inc:'#6666ff',
    // button_dec:'#ff4d4d',
    //background_toolbar:'', //it has background image 
    background_leftmenu:'#314456',
    background_panel:'#49657F',
    //background_Topbar:'#183e62',
    background_bottommenubar:'#333333',
    modal_heading:'#ffffff',
    modal_header_color: '#000',
    // modal_heading:'#314456',
    // your colors
    theme_color: 'rgba(245, 246, 248, 1)',
    toolbar_background_color: '#F5F6F8',
    toolbar_background_color_faded: 'rgb(245, 246, 248, 0.5)',
    // toolbar_background_color: 'rgb(228, 228, 227, 0.5)',
    navbar_selector_background_color: 'rgb(228, 228, 227, 0.5)',
    nav_bar_light: 'rgba(255, 255, 255, 1)',

    gray1: '#FFFFFF',
    gray2: '#F5F6F8',
    gray3: '#E6E9EE',
    gray4: '#C5C7CF',
    gray5: '#A7A8B3',
    gray6: '#676878',
    gray7: '#323338',
    gray8: '#202126',
    gray9: '#A7A8B2',
    gray10: '#EAECF0',

    blue1: '#E2EFFD',
    blue2: '#C2D6F3',
    blue3: '#D3EDFD',
    blue4: '#3686F7',
    blue5: '#3A63D4',
    blue6: '#0078FF',

    primary_button_blue: 'rgba(69, 152, 247, 1)',

    //Edit Design Page

    left_menu: '#FFFFFF',
    active_view_text: '#323338',
    active_view_background_inactive: '#EDEEF2',
    active_view_background_active: 'rgba(255, 255, 255, 1)',


    panel_header_background: '#788394',
    panel_header_text: '#FFFFFF',
    panel_body_background: '#E6E9EE',
    panel_card_dropdown: 'rgba(245, 246, 248, 1)',
    panel_card_item: '',
    panel_card_item_parent: 'rgba(255, 255, 255, 1)',
    panel_item_separation: 'rgba(230, 233, 238, 1)',
    panel_selected_item: 'rgba(230, 233, 238, 1)',
    panel_dropdown_collapse: 'rgba(230, 233, 238, 1)',
    panel_group_heading: '#676878',
    panel_item_borders: '#CED4D9',

  }