import React, { useState, useEffect, isValidElement } from 'react';
import { Modal, Navbar, Button, Input, Card, CardBody, CardHeader, Col, Nav, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ModalHeader, ModalBody, NavItem, ModalFooter} from "reactstrap";
import './style.css'
const Search = ({classNameOuter, classnameInner, list, set_list, placeholder, search_value}) => {
    const [search_string, set_search_string] = useState('');

    useEffect(() => {
        if(list && list.length){
            if(search_value){
                // console.log()
                set_list(list.filter(x => x[search_value].toLowerCase().includes(search_string.toLowerCase())))
            }
            else{
                set_list(list.filter(x => x.toLowerCase().includes(search_string.toLowerCase())))
            }
        }else{
            set_list([])
        }
    }, [search_string, list]);

    // useEffect(() => {
    //     if(list.length){
    //         console.log("list search", list[0][search_value], search_value)
    //     }
    // }, [list]);

    const clear_search = () => {
        set_search_string('')
    }
    return (
        <div className={classNameOuter? classNameOuter : 'search_component_container_ideal'}>
            <span><i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/></span>
            <input className={classnameInner? classnameInner :'search_component_inner_container'} placeholder= {placeholder ? placeholder :'Type to Search'} type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)}></input>
            <span className='hover_blue' style={{paddingRight:'12px', cursor:'pointer', fontSize:'10px', color:'#9aa5b5', visibility:search_string ? 'visible':'hidden', fontStyle:'italic'}} onClick={clear_search}>Clear</span>
            {/* <span><i style={{paddingRight:'12px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-times' onClick={clear_search}/></span> */}
        </div>
    )
}

export default Search