const includes_search_string = (name, search_string) => {
    if(name){
        let processed_name = name.toLowerCase()
        let processed_search_string = search_string.toLowerCase()
        return(processed_name.includes(processed_search_string))
    }

    return false
}

export default includes_search_string;