import React, { useState,useEffect } from 'react';
import { Modal, Button, Form, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import Multiselect from 'multiselect-react-dropdown';
import { COLORS } from '../../utils/color';
import { useAlert } from 'react-alert'
import { IModalBody, IModalFooter, IModalHeader } from '../IModal';
import {useTranslation} from 'react-i18next'

const Filter_Modal = ({ open, handle_close, update_carousel_json }) => {
    const [ selected_brand_ids, set_selected_brand_ids ] = useState([])
    const [ default_pre_selected_brand_ids, set_default_pre_selected_brand_ids ] = useState([])
    const [ selected_tag_ids, set_selected_tag_ids ] = useState([])
    const [ selected_sales_channel_ids, set_selected_sales_channel_ids ] = useState([])
    const [catalogue_type, set_catalogue_type] = useState('user_and_org');
    const [filter_combination_logic, set_filter_combination_logic] = useState({tag: 'OR', sales_channel: 'OR'});
    const refTags = React.createRef();
    const refSC = React.createRef();
    const refBrands = React.createRef();
    const {t} = useTranslation()

    const toggle_filter_combination_logic = (type, value) => {
        let temp_filter_combination_logic = JSON.parse(JSON.stringify(filter_combination_logic))
        // if(filter_combination_logic[type] == 'OR'){
        //     temp_filter_combination_logic[type] = 'AND'
        //     set_filter_combination_logic(temp_filter_combination_logic)
        // }else{
        //     temp_filter_combination_logic[type] = 'OR'
        //     set_filter_combination_logic(temp_filter_combination_logic)
        // }

        temp_filter_combination_logic[type] = value
        set_filter_combination_logic(temp_filter_combination_logic)
    }

    const alert = useAlert();

    const [ tags, set_tags ] = useState([])
    const [ sales_channels, set_sales_channels ] = useState([])
    const [ brands, set_brands ] = useState([])

    useEffect(() => {
        if(open){
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;
            var data = JSON.parse(active_view.get_current_carousel_filter_settings());
            set_tags(data.tags);
            set_sales_channels(data.sales_channels);
            set_brands(data.brands);
            set_selected_brand_ids(data.selected_brand_ids);
            set_default_pre_selected_brand_ids(data.default_pre_selected_brand_ids);
            set_selected_tag_ids(data.selected_tag_ids);
            set_selected_sales_channel_ids(data.selected_sales_channel_ids);
            set_catalogue_type(data.catalogue_type);
            active_design.delete();
            active_view.delete();
        }
    },[open])

    const onclick_reset = () => {
        set_selected_tag_ids([])
        set_selected_brand_ids([])
        set_default_pre_selected_brand_ids([])
        set_selected_sales_channel_ids([])
        set_catalogue_type("user_and_org");
        set_filter_combination_logic({tag: 'OR', sales_channel: 'OR'})
    }

    const onclick_apply = () => {
        var new_settings = {
            tags, sales_channels, brands, selected_brand_ids, selected_tag_ids, selected_sales_channel_ids, filter_combination_logic, catalogue_type
        }

        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.apply_carousel_filter_settings(JSON.stringify(new_settings));
        update_carousel_json();
        handle_close();
        active_design.delete();
        active_view.delete();
    }

    const onchange_brand_selection = (selected) => {
        var new_selected = selected.map(o => o.id);
        default_pre_selected_brand_ids.map(o => {
            if(!new_selected.find(p => p == o)){
                new_selected.push(o);
                alert.error("Cannot remove brand filter. Please change brand preference under the Preferences menu (Additional Data Preferences)");
            }
        });
        set_selected_brand_ids(new_selected);
    }

    const handleBlurTags = () => {
        refTags.current.hideMenu()
    }

    const handleBlurSC = () => {
        refSC.current.hideMenu()
    }

    const handleBlurBrands = () => {
        refBrands.current.hideMenu()
    }

    return(
        <Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={handle_close}>
            <IModalHeader toggle={handle_close}>Catalogue Filters</IModalHeader>
            <IModalBody>
                {/* <div className='mb-2 flex_property justify-content-end'> <div className='m-2'>Filter with</div> <div style={{cursor:'pointer', backgroundColor: 'white', border: '1px solid ' + COLORS.gray4, borderRadius: '4px', minWidth:'60px', justifyContent:'space-between'}} onClick={toggle_filter_combination_logic} className='py-1 px-2 flex_property'><div className='mr-1'>{filter_combination_logic}</div> <i className='fa fa-caret-down'></i> </div> </div> */}
                {/* <div className='mb-2 flex_property justify-content-end'>
                     <div className='m-2'>Filter with</div>
                     <Input type='select' style={{width:'fit-content', border: '1px solid '+ COLORS.gray4, borderRadius: '4px', padding: '4px 8px', height:'32px', fontSize: '14px'}} value={filter_combination_logic} onChange={(e) => set_filter_combination_logic(e.target.value)}>
                        <option value={'OR'}>OR</option>
                        <option value={'AND'}>AND</option>
                    </Input> 
                </div> */}
                <div className='mb-3 flex_property'>
                    <div className='lines1_elipsis' style={{width:'30%'}}>Catalogue</div>
                    <Input
                        style={{ flex: 1, fontSize: '14px' }}
                        onChange={(e) => 
                            set_catalogue_type(e.target.value)
                        }
                        value={catalogue_type}
                        type='select'
                    >
                        <option value={"user_and_org"}>ORG and User Catalogue</option>
                        <option value={"org"}>ORG Catalogue</option>
                        <option value={"user"}>User Catalogue</option>
                    </Input>
                </div>
                <div className='mb-3 flex_property'>
                    <div className='lines1_elipsis' style={{width:'30%'}}>{t(`Tags`)}</div>
                    <Typeahead
                        style={{ flex: 1, fontSize: '14px' }}
                        ref={refTags}
                        multiple
                        id="tags_typeahead"
                        labelKey="tag"
                        onChange={(selected) => {
                            set_selected_tag_ids(selected.map(o => o.id));
                        }}
                        options={tags}
                        selected={selected_tag_ids.map(o => tags.find(p => p.id == o))}
                        placeholder='Select Tags'
                        onBlur={handleBlurTags}
                    />
                    <div className='flex_property justify-content-end'>
                        <div className='m-2'>{t(`Filter with`)}</div>
                        <Input type='select' style={{ width: 'fit-content', border: '1px solid ' + COLORS.gray4, borderRadius: '4px', padding: '4px 8px', height: '32px', fontSize: '14px' }} value={filter_combination_logic.tag} onChange={(e) => toggle_filter_combination_logic('tag', e.target.value)}>
                            <option value={'OR'}>OR</option>
                            <option value={'AND'}>AND</option>
                        </Input>
                    </div>
                </div>
                <div className='mb-3 flex_property'>
                    <div className='lines1_elipsis' style={{width:'30%'}}>{t(`Sales Channels`)}</div>
                    <Typeahead
                        style={{ flex: 1, fontSize: '14px' }}
                        ref={refSC}
                        multiple
                        id="sales_channels_typeahead"
                        labelKey="name"
                        onChange={(selected) => {
                            set_selected_sales_channel_ids(selected.map(o => o.id));
                        }}
                        options={sales_channels}
                        selected={selected_sales_channel_ids.map(o => sales_channels.find(p => p.id == o))}
                        placeholder='Select Sales Channels'
                        onBlur={handleBlurSC}
                    />
                    <div className='flex_property justify-content-end'>
                        <div className='m-2'>{t(`Filter with`)}</div>
                        <Input type='select' style={{ width: 'fit-content', border: '1px solid ' + COLORS.gray4, borderRadius: '4px', padding: '4px 8px', height: '32px', fontSize: '14px' }} value={filter_combination_logic.sales_channel} onChange={(e) => toggle_filter_combination_logic('sales_channel', e.target.value)}>
                            <option value={'OR'}>OR</option>
                            <option value={'AND'}>AND</option>
                        </Input>
                    </div>
                </div>
                <div className='mb-3 flex_property'>
                    <div className='lines1_elipsis' style={{width:'30%'}}>{t(`Brands`)}</div>
                    <Typeahead
                        style={{ flex: 1, fontSize: '14px' }}
                        ref={refBrands}
                        multiple
                        id="brands_typeahead"
                        labelKey="name"
                        onChange={onchange_brand_selection}
                        options={brands}
                        selected={selected_brand_ids.map(o => brands.find(p => p.id == o))}
                        placeholder='Select Brands'
                        onBlur={handleBlurBrands}
                    />
                </div>
            </IModalBody>
            <IModalFooter>
                <Button type="button" className='secondary_button_default' onClick={onclick_reset}>Reset</Button>
                <Button type="button" className='primary_button_default' onClick={onclick_apply}>Apply</Button>
            </IModalFooter>
        </Modal>
    )    
}

export default Filter_Modal;


// depricated old styling code

// <Card className="bg-secondary shadow border-0">
//     <CardHeader style={{ backgroundColor: COLORS.modal_heading, color: COLORS.modal_header_color, height: '63px' }} >
//         <div>
//             <h5 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0px' }}><span>Filters</span><span><span style={{ fontSize: '24px', fontWeight: '700', cursor: 'pointer' }} onClick={handle_close}>×</span></span></h5>
//         </div>
//     </CardHeader>
//     <CardBody style={{ backgroundColor: "#fefefe" }} className="px-lg-5 py-lg-5">
//         <Row>
//             <Col xs={4}>
//                 <div>Tags</div>
//             </Col>
//             <Col xs={8}>
//                 <Typeahead
//                     multiple
//                     id="tags_typeahead"
//                     labelKey="tag"
//                     onChange={(selected) => {
//                         set_selected_tag_ids(selected.map(o => o.id));
//                     }}
//                     options={tags}
//                     selected={selected_tag_ids.map(o => tags.find(p => p.id == o))}
//                 />
//             </Col>
//         </Row>
//         <br />
//         <Row>
//             <Col xs={4}>
//                 <div>Sales Channels</div>
//             </Col>
//             <Col xs={8}>
//                 <Typeahead
//                     multiple
//                     id="sales_channels_typeahead"
//                     labelKey="name"
//                     onChange={(selected) => {
//                         set_selected_sales_channel_ids(selected.map(o => o.id));
//                     }}
//                     options={sales_channels}
//                     selected={selected_sales_channel_ids.map(o => sales_channels.find(p => p.id == o))}
//                 />
//             </Col>
//         </Row>
//         <br />
//         <Row>
//             <Col xs={4}>
//                 <div>Brands</div>
//             </Col>
//             <Col xs={8}>
//                 <Typeahead
//                     multiple
//                     id="brands_typeahead"
//                     labelKey="name"
//                     onChange={onchange_brand_selection}
//                     options={brands}
//                     selected={selected_brand_ids.map(o => brands.find(p => p.id == o))}
//                 />
//             </Col>
//         </Row>
//         <br />

//         <div className="text-center mt-5">
//             <Button type="button" onClick={onclick_apply}>Apply</Button>
//         </div>
//     </CardBody>
// </Card>