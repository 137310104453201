class TrieNode {
    constructor() {
        this.children = new Map();
        this.isEndOfWord = false;
    }
}

class Trie {
    constructor() {
        this.root = new TrieNode();
    }

    insert(word) {
        let node = this.root;
        for (let char of word) {
            if (!node.children.has(char)) {
                node.children.set(char, new TrieNode());
            }
            node = node.children.get(char);
        }
        node.isEndOfWord = true;
    }

    hasPrefix(word) {
        let node = this.root;
        for (let char of word) {
            if (!node.children.has(char)) {
                return false;
            }
            node = node.children.get(char);
            if (node.isEndOfWord) {
                return true; // word is a prefix of another word
            }
        }
        return false;
    }
}

function hasNoPrefixPairs(strings) {
    const trie = new Trie();
    for (let str of strings) {
        if (trie.hasPrefix(str) || !str) {
            return false; // Found a prefix pair
        }
        trie.insert(str);
    }
    return true; // No prefix pairs found
}

export default hasNoPrefixPairs