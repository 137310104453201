import React, { useEffect, useState } from "react";
import "./style.css"; // Assuming the CSS code is in a file named style.css

const Loader = ({text, org_key}) => {
  const [maskWidth, setMaskWidth] = useState("100%");
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  const [shuffledTips, setShuffledTips] = useState([
    <span>
      <b>Toggle location lines</b> while drawing walls using the <b>spacebar</b>.
    </span>,
    <span>
      <b>Rotate selected objects</b> by pressing the <b>spacebar</b> while placing them in the design.
    </span>,
    <span>
      Optimize your design experience by setting the browser <b>zoom level to 90%</b>.
    </span>,
    <span>
      Immerse yourself in the experience by <b>pressing F11 for fullscreen</b> mode.
    </span>,
    <span>
      No need to worry about saving, as every action is <b>automatically saved</b>.
    </span>,
    <span>
      Utilize the <b>isolate mode (HI)</b> to work on objects without distractions.
    </span>,
    <span>
      Use <b>quick hide (HH)</b> to hide obstructive objects in your render view.
    </span>,
    <span>
      If an object goes out of view, <b>use zoom to selected (Shift+X)</b> to focus on it.
    </span>,
    <span>
      Bring all objects into view <b>with zoom to fit all (Shift+Z)</b>.
    </span>,
    <span>
      The <b>yellow selection</b> indicates the <b>properties opened in the panel</b>.
    </span>,
    <span>
      The <b>command line</b> allows you to <b>search for any tool or option</b> in the software.
    </span>,
    <span>
      Access <b>help</b> by pressing <b>F1</b> or talk to us for any queries.
    </span>,
    <span>
      Find global settings and <b>preferences</b> at the <b>bottom left corner</b> of the screen.
    </span>,
    <span>
      Switch between <b>different camera angles</b> quickly using <b>Views</b>.
    </span>,
    <span>
      Access more view options by clicking <b>the settings icon</b> in the view.
    </span>,
    <span>
      Orient your camera view using the <b>navigation globe</b> in the bottom right corner of the screen.
    </span>,
  ]);
  
  const tips = [
    <span>
      <b>Toggle location lines</b> while drawing walls using the <b>spacebar</b>.
    </span>,
    <span>
      <b>Rotate selected objects</b> by pressing the <b>spacebar</b> while placing them in the design.
    </span>,
    <span>
      Optimize your design experience by setting the browser <b>zoom level to 90%</b>.
    </span>,
    <span>
      Immerse yourself in the experience by <b>pressing F11 for fullscreen</b> mode.
    </span>,
    <span>
      No need to worry about saving, as every action is <b>automatically saved</b>.
    </span>,
    <span>
      Utilize the <b>isolate mode (HI)</b> to work on objects without distractions.
    </span>,
    <span>
      Use <b>quick hide (HH)</b> to hide obstructive objects in your render view.
    </span>,
    <span>
      If an object goes out of view, <b>use zoom to selected (Shift+X)</b> to focus on it.
    </span>,
    <span>
      Bring all objects into view <b>with zoom to fit all (Shift+Z)</b>.
    </span>,
    <span>
      The <b>yellow selection</b> indicates the <b>properties opened in the panel</b>.
    </span>,
    <span>
      The <b>command line</b> allows you to <b>search for any tool or option</b> in the software.
    </span>,
    <span>
      Access <b>help</b> by pressing <b>F1</b> or talk to us for any queries.
    </span>,
    <span>
      Find global settings and <b>preferences</b> at the <b>bottom left corner</b> of the screen.
    </span>,
    <span>
      Switch between <b>different camera angles</b> quickly using <b>Views</b>.
    </span>,
    <span>
      Access more view options by clicking <b>the settings icon</b> in the view.
    </span>,
    <span>
      Orient your camera view using the <b>navigation globe</b> in the bottom right corner of the screen.
    </span>,
  ];
  

  useEffect(() => {
    const startTime = Date.now();
    const endTime = startTime + 30 * 1000; // Half minute

    const revealImage = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      // const progress = Math.min(1, elapsedTime / (endTime - startTime));
      // const width = (1 - progress) * 100;
      const progress = Math.pow(2, -7 * (elapsedTime / (endTime - startTime)));
      const width = progress * 100;

      setMaskWidth(`${width}%`);

      if (currentTime < endTime) {
        requestAnimationFrame(revealImage);
      }

    };

    // Shuffle the tips
    const shuffled = shuffleArray(tips);
    setShuffledTips(shuffled);

    const tipInterval = setInterval(() => {
      setCurrentTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
    }, 5000); // Change tip every 5 seconds


    revealImage();


    return () => {
      clearInterval(tipInterval);
    };

  }, []);


  // Fisher-Yates Shuffle Algorithm
  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  return (
    <div className="loader-container DMSans">
      {/* <div className="loader"></div> */}
      {(!org_key)?(<img className="mb-5" src="/resources/images/infurnia_light_logo.svg" alt="Loading Image" />):''}

      <div className="image-container mb-4">
        <img width={'100%'} src="/resources/images/loading_image.svg" alt="Loading Image" />
        <div className="mask" style={{ width: maskWidth }}></div>
      </div>

      <div className="loading_text">
        {text}
      </div>

      <div className="tips-container flex_center">
        {/* <span>Tips:</span> */}
        <div>
          <span>{"Tips: "}</span>
          {shuffledTips[currentTipIndex]}</div>
      </div>

    </div>
  );
};

export default Loader;
