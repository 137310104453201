import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import general_fetch from "./utils/fetch";

const apiKey = "rwW1RvV9CeenaPLS3CxI7w";
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;
if(!window.navigator.language.toLowerCase().includes("en")){
i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Currently overriding lng with browser chosen. this ignores other ways of setting the lng like queryParams
    lng: window.navigator.language,
    fallbackLng: "en",

    ns: ["default"],
    defaultNS: "default",

    defaultLocale: 'en',

    supportedLngs: ["pt", "en"],

    backend: {
      loadPath: loadPath,
      addPath: null,
      // crossDomain: true,
      // withCredentials: true,
      withCredentials: true,
    },

    saveMissing: true,
  })

i18next
  .on('missingKey', function (lngs, namespace, key, res) {
    // Call a function to create the missing string in i18nexus
    if(key){
      createMissingString(namespace, key);
    }
  });

  const createMissingString = async(namespace, key) => {
    try{
      let body = {};
      body[key] = key;
      let resp = await general_fetch({url: `/i18n/add?&ns=${namespace}`, body});
    }catch(err){
      console.log("Error while adding key to translation - ", key);
    }
  }
}