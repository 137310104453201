import get_org_key from "./get_org_key";

const logout = async (extra_append) => {
    try{
            await window.Promisify(window.Module.logout())
            var org_key = get_org_key();
            if (window.socket) {
                window.socket.disconnect();
            }

            var url = window.Module.API.login_path;
            if (org_key && window.location.origin.includes("infurnia.com")) {
                url = url + '/' + org_key
            }
            let processed_extra_append = ''
            if (org_key && extra_append && extra_append.constructor.name == "String") {
                let p = extra_append.split('/')
                for (let i in p) {
                    let item = p[i]
                    if (item && item !== org_key) {
                        processed_extra_append = processed_extra_append + '/' + item
                    }
                }

            }
            if (processed_extra_append && processed_extra_append.constructor.name == "String") {
                url = url + processed_extra_append
            }
            window.location.href = url;
    }catch(err){
        if(window['sentry_capture_exception']){
            window['sentry_capture_exception']("",err);
        }
    }
}

export default logout