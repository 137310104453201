var config_settings = {
	"production":{
		server_path: 'https://stagingbackend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://stagingadmin.infurnia.com/',
		auth_redirect_path:'https://localhost:3000/login/',
		application_env: 'prod',
		affilate_path: 'https://stagingaffiliate.infurnia.com',
	},
	"staging":{
		server_path: 'https://stagingbackend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://stagingadmin.infurnia.com/',
		auth_redirect_path:'https://localhost:3000/login/',
		application_env: 'prod',
		affilate_path: 'https://stagingaffiliate.infurnia.com',
		
	}
}

var config = config_settings['staging'/*process.env.REACT_APP_ENV*/]
global.config = config
//https://stagingbackend.infurnia.com
// server_path: 'https://newdevbackend.infurnia.com/',
// 		render_server: 'http://localhost:6165/',
// 		admin_path: 'https://stagingadmin.infurnia.com/',
// 		auth_redirect_path:'http://localhost:3000',
// 		application_env: 'dev',
// 		affilate_path: 'https://stagingaffiliate.infurnia.com',