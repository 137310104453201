function kelvinToHex(kelvin) {
    let temperature = kelvin / 100;
    let red, green, blue;

    if (temperature <= 66) {
        red = 255;
        green = Math.min(99.4708025861 * Math.log(temperature) - 161.1195681661, 255);
        blue = temperature <= 19 ? 0 : (138.5177312231 * Math.log(temperature - 10) - 305.0447927307);
    } else {
        red = Math.min(255, 329.698727446 * Math.pow(temperature - 60, -0.1332047592));
        green = Math.min(255, 288.1221695283 * Math.pow(temperature - 60, -0.0755148492));
        blue = 255;
    }

    // Convert the RGB values to hexadecimal format
    const hexRed = Math.round(red).toString(16).padStart(2, '0');
    const hexGreen = Math.round(green).toString(16).padStart(2, '0');
    const hexBlue = Math.round(blue).toString(16).padStart(2, '0');

    // Return the hexadecimal color code
    return `#${hexRed}${hexGreen}${hexBlue}`;
}

export default kelvinToHex