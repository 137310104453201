import * as Sentry from "@sentry/react";

export const handleError = (err) => {
    console.error("Error log - ", err)
    window.sentry_capture_exception("",err);
}

export const logToSentry = (action, fingerprint) => {
    if(typeof(action) === "string"){
        Sentry.captureMessage(action, {fingerprint: [fingerprint ? fingerprint : action]})
    }else{
        Sentry.captureEvent(action)
    }
}