
// const dummy_tick = (t) => (new Promise((res,rej) => {
//     var timer = setTimeout(() => {
//         res();
//         clearTimeout(timer);
//     },t)
// })); 

const dummy_tick = (t) => {}; 

export default dummy_tick ;
