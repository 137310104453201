/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {FormGroup, CardHeader, Input, Button, Row, Col, Collapse, Card, CardBody, Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, CardFooter} from 'reactstrap';
import MaterialDropdown from '../../components/MaterialDropdown/';
import CompositeDropdown from '../../components/CompositeDropdown/';
import repopulate_panel_pop_up from '../../utils/panel_pop_up_repopulation';
import { useAlert } from 'react-alert'
import './style.css';
import { COLORS } from '../../utils/color';
import { Image_Icon, Fa_Icon, Text_Icon } from '../Unit/'
import '../Navbar_Dropdown_BF/style.css';
import { Switch, Tooltip } from 'antd';
import FocusWithin from '../FocusWithin';
import Create_card from '../Create_card';
import set_panel_pop_up_search_show from '../../utils/set_panel_pop_up_search_show';
import {useTranslation} from 'react-i18next'

const dummy_tick = (t) => (new Promise((res,rej) => {
    var timer = setTimeout(() => {
        res();
        clearTimeout(timer);
    },t)
})); 

const styles_panel = {
    text_elipsis : {
        whiteSpace: "nowrap", 
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingTop: 5
	},
	input_styles:{
		padding: 0,
		height: '28px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%',
		paddingLeft:'6px',
		borderRadius:'2px'
	},
	collapse:{
    	position:'absolute',
    	left:'71px',
	    top: '100px',
    	width: '270px',
        height: 'calc(100% - 100px)',
    },
};

// const ReferenceLevellDropdown = ({ json_item, on_click }) => {
//     const [dropdownOpen, setDropdownOpen] = useState(false);
//     // const [rename_enabled, set_rename_enabled] = useState(false);

//     const toggle = () => setDropdownOpen(prevState => !prevState);

//     return (
//         // <Row className='class_position_unset' style={{margin:0}}>
//         //     <Col className='class_position_unset' style={{padding:0, display:'flex', alignItems:'center'}}>
//                 <Dropdown direction="up" className='class_position_unset' nav isOpen={dropdownOpen} style={{listStyle: 'none'}} toggle={json_item && json_item.disabled?null:toggle}>
//                     <DropdownToggle  style={{display:'flex',flexFlow:'row', backgroundColor:'white',cursor:'pointer', color:'black', fontSize:'13px', padding:'0px', border:0}}>
// 						{/* <NavItem style={{listStyleType:'none'}}> */}
// 							<img style={{color:COLORS.white, marginLeft:'5px'}} width='10px' src="/resources/toolbar_icons_new/dropdown.svg" />
// 							{/* <div style={{width:0, borderRight:'2px solid ' + COLORS.gray3, height: 24}}></div> */}
// 						{/* </NavItem> */}
//                     </DropdownToggle>
//                     <DropdownMenu className='ref_level_dropdown_menu'>
//                         {json_item&&json_item.ref_level_options?([{name:"Value" + (json_item.relative_ref_level?" (Fixed)":(json_item.global_ref_level?" (From Global Origin)":" (From Floor Base Top)")),value:"no_ref_level"}]).concat(json_item.ref_level_options).map((o,idx) => (
//                             <DropdownItem style={{listStyle: 'none'}} onClick={() => {on_click?on_click(o.value):''}} key={idx}>
//                                 <div style={{display:'flex',flexFlow:'row',fontSize:'13px'}}>
//                                     <div style={{paddingTop:'5px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{json_item.relative_ref_level&&o.value!="no_ref_level"?'Up to ' + o.name:o.name}</div>
//                                 </div>
//                             </DropdownItem>
//                         )):''}
//                     </DropdownMenu>
//                 </Dropdown>
                
//         //     </Col>
//         // </Row>
//     );
// }

// const RotationInput = ({json_item, on_click, common_onchange_handler, icon_on_click}) => {
// 	const [rotation_input, set_rotation_input] = useState('');
// 	const [rotation_input_final, set_rotation_input_final] = useState('');

// 	useEffect(() => {
// 		if(json_item.default_value){
// 			set_rotation_input(json_item.default_value)
// 		}
// 	}, []);

// 	useEffect(() => {
// 		if(json_item.default_value){
// 			set_rotation_input(json_item.default_value)
// 		}else{
// 			set_rotation_input(0)
// 		}
// 	}, [json_item.default_value]);

// 	// useEffect(() => {
// 	// 	set_rotation_input_final(rotation_input)
// 	// }, [rotation_input]);

// 	useEffect(() => {
// 		if(rotation_input_final !== '' &&  rotation_input_final != json_item.default_value){
// 			on_click()
// 		}
// 	}, [rotation_input_final]);

// 	return (
// 		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }}  className={json_item.disabled?'panel_disabled':'RI----------RI'}>
// 			<CardBody style={{ padding:'15px',paddingBottom:0,/* paddingTop:index ? 0 : 15,*/ backgroundColor:COLORS.panel_card_item}} >
// 			<Row>
// 				<Col style={{fontSize:14,alignItems:"center", cursor:"default", paddingLeft:'8px', marginBottom:'8px', fontWeight:700, color:COLORS.panel_group_heading, display: json_item.onchange_route ? 'flex' : 'none'}}><span title={json_item.name} className='lines2_elipsis' >{"Rotation(deg)"}</span></Col>
// 			</Row>
// 			<input key={"key_"+json_item.id} value={rotation_input_final} type="number"  id={json_item.id} style={{display:'none'}} />
// 			<Row style={{/*marginBottom:'15px'*/}}>
// 				<Col xs={4} style={{fontSize:14,display:'flex',alignItems:"center"}}>
// 					<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}>
// 						<div style={{display:'flex', width:'100%', border:json_item.onchange_route ? '1px solid ' + COLORS.panel_item_borders : 'none', padding:'0px 4px'}}>
// 							<div style={{flex:1, color:json_item.onchange_route ? '#FF5247' : COLORS.panel_group_heading, fontWeight: json_item.onchange_route ? 400 : 700, fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name}</div>
// 							<input key={"key_"+json_item.id + "1"} value={rotation_input} onChange={(e) => {common_onchange_handler(); set_rotation_input(e.target.value)}} onBlur={(e) => {set_rotation_input_final(e.target.value)}} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=json_item.default_value || 0 ; e.target.blur()}}} type="number"  id={json_item.id + "1"} style={{...styles_panel.input_styles, border:'none', flex:5, height:'22px', outline:'none', display:json_item.onchange_route ? 'block' : 'none'}} />
// 						</div>
// 					</div>
// 				</Col>
// 				<Col xs={8} style={{fontSize:14,display:'flex',alignItems:"center", justifyContent:'flex-end', padding:'0px 8px'}}>
// 					{/* {
// 						json_item.buttons && json_item.buttons.length ? json_item.buttons.map(item => (
// 							<img src={item.icon} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); window.Module[item.onclick](JSON.stringify({id: item.name}))}}/>
// 						))
// 						:''
// 					} */}
// 					<span style={{ marginRight:'8px', fontSize:'10px', maxWidth:'110px', textOverflow:'ellipsis', color:COLORS.gray5}} title={json_item.text_content ? json_item.text_content : ''}>{json_item.onchange_route ? "Rotate by " + (json_item.text_content ? json_item.text_content : '90') + ' deg' : json_item.text_content}</span>
// 					<img src={"/resources/panel_icons_new/rotate left.svg"} style={{ marginRight:'8px', cursor:'pointer', height:'20px'}} onClick={json_item.onchange_route ? () => {common_onchange_handler(); set_rotation_input_final(rotation_input - (json_item.text_content ? json_item.text_content : 90)); /*icon_on_click({route: json_item.left_rotation_route, form:{id: json_item.left_id}}) on_click()*/  } : () => {icon_on_click({route: json_item.left_rotation_route, form:{id: json_item.left_id}})} }/>
// 					<img src={"/resources/panel_icons_new/rotate right.svg"} style={{ marginRight:'8px', cursor:'pointer', height:'20px'}} onClick={json_item.onchange_route ? () => {common_onchange_handler(); set_rotation_input_final(rotation_input - ('-' + (json_item.text_content ? json_item.text_content : 90))); /*icon_on_click({route: json_item.right_rotation_route, form:{id: json_item.right_id}}) on_click()*/ } : () => {icon_on_click({route: json_item.right_rotation_route, form:{id: json_item.right_id}})}}/>

// 				</Col>
// 			</Row>
// 			</CardBody>
// 		</Card>
// 	)
// }

// const TabDropdown = ({json_item, icon_on_click, panel_key, set_page_loader, handle_ui_response, metric_system, approximation_mm, approximation_ft, update_view, set_panel_has_unapplied_edits, approxMM, mmToFeetParts, panel_explode, tab_on_click}) => {
// 	const [active_tab, set_active_tab] = useState('');

// 	const reset_dropdown_status = (id) =>{
// 		if(json_item.tabs && json_item.tabs.length){
// 			json_item.tabs.map((item, idx) => {
// 				if(item.id == id){
// 					if(!check_dropdown_state(item.id)){
// 						tab_on_click(item.id)
// 					}
// 				}
// 			})
// 			json_item.tabs.map((item, idx) => {
// 				if(item.id != id){
// 					if(check_dropdown_state(item.id)){
// 						tab_on_click(item.id)
// 					}
// 				}
// 			})
// 		}
// 	}

// 	useEffect(() => {
// 		// if(json_item && json_item.tabs && json_item.tabs.length){
// 		// 	set_active_tab(json_item.tabs[0].id);
// 		// 	// tab_on_click(json_item.tabs[0].id);
// 		// }

// 		if(json_item && json_item.tabs && json_item.tabs.length){
// 			let open_dropdowns =  json_item.tabs.filter(x => check_dropdown_state(x.id))

// 			if(open_dropdowns.length == 1){
// 				set_active_tab(open_dropdowns[0].id)
// 			}
// 			else{
// 				set_active_tab(json_item.tabs[0].id);
// 				reset_dropdown_status(json_item.tabs[0].id)
// 			}
// 		}

// 	}, []);

// 	return(
// 		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CT----------CT':'CT----------CT'}>
// 			<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 			<Row>
// 				<Col xs={12} className='zero_padding' style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
// 					<div style={{padding:'0px 8px', display:'flex', flexFlow: 'row', flexWrap:'wrap', width:'100%', paddingBottom:'6px'}}>
// 						{
// 							json_item.tabs.map((child_tab, idx) => (
// 								<div key={idx} style={active_tab == child_tab.id ? {padding:'4px 0px', margin:'4px 8px', fontSize:'12px', fontWeight:700, borderBottom:'1px solid ' + COLORS.blue6, color:COLORS.blue6, display:'flex'} : {padding:'4px 0px', margin:'4px 8px', fontSize:'12px', borderBottom:'1px solid #E6E9EE', color: COLORS.panel_group_heading, display:'flex'}} onClick={active_tab == child_tab.id ? null : () => {set_active_tab(child_tab.id); reset_dropdown_status(child_tab.id)}} >
// 									<div style={{marginRight: '8px'}}>{child_tab.name}</div>
// 									<div style={{display:active_tab == child_tab.id ? 'flex' : 'none', alignItems: 'center'}}>
// 									{
// 										child_tab.buttons && child_tab.buttons.length ? child_tab.buttons.map(item => (
// 											item.type == "switch" ? 
// 												<Switch style={{marginRight:'8px', color:'green'}} size='small' checked = {item.value == "Off" ? false : true} onClick={(checked, e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
// 											: item.type == 'label' ? 
// 												<span style={{cursor:'pointer',marginRight:'4px', fontSize:'12px', color:COLORS.black, textTransform:'capitalize', fontWeight:400}}>{item.name}</span>
// 											: item.icon == "+" ?
// 												<span style={{cursor:'pointer',marginRight:'8px', fontSize:'12px', color:COLORS.blue6, textTransform:'capitalize'}} onClick={(e) => {icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}>+ Add </span>
// 											:
// 												<img src={item.icon} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
// 										))
// 										: ''
// 									}
// 									</div>
// 								</div>
// 								))
// 						}
// 					</div>
// 				</Col>

// 				{
// 					json_item.tabs.map((child_tab, idx) => (
// 						<div key={idx} className='d--------------------d' isOpen={active_tab == child_tab.id} navbar={true} style={{ width:'100%', overflowX: 'hidden', display:active_tab == child_tab.id ? 'block' : 'none'}}>
// 							<Nav navbar style={{width:'100%', overflowX: 'hidden' }}>
								
// 								{
// 									child_tab.submenu && child_tab.submenu.length ? child_tab.submenu.map((sub_item,idx) =>(
// 										<NavItem key={child_tab.id+idx} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
// 											<Create_card 
// 												panel_key={panel_key}
// 												set_page_loader={set_page_loader}
// 												handle_ui_response={handle_ui_response}
// 												key={child_tab.id+idx}
// 												metric_system={metric_system}
// 												approximation_mm={approximation_mm}
// 												approximation_ft={approximation_ft}
// 												parent_id={child_tab.id}
// 												parent_menu={child_tab.submenu}
// 												update_view={update_view}
// 												json_item={sub_item}
// 												set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 												approxMM={approxMM}
// 												mmToFeetParts={mmToFeetParts}
// 												panel_explode={panel_explode}
// 												is_grouped={child_tab.group_child_inputs}
// 												index={idx}
// 												configuration_type={child_tab.configuration_type}
// 												inside_tab={true}/>
// 										</NavItem>
// 									)):''
									
// 								}
// 								{/* {
// 									json_item.submenu && json_item.submenu.length && !(json_item.submenu[json_item.submenu.length-1].type == 'list-item' || json_item.submenu[json_item.submenu.length-1].type == 'dropdown' || json_item.submenu[json_item.submenu.length-1].type == 'button') ?
// 										<Create_card 
// 											panel_key={panel_key}
// 											set_page_loader={set_page_loader}
// 											handle_ui_response={handle_ui_response}
// 											metric_system={metric_system}
// 											approximation_mm={approximation_mm}
// 											approximation_ft={approximation_ft}
// 											// parent_menu={panel_json}
// 											update_view={update_view}
// 											json_item={{type:'margin_bottom'}}
// 											set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 											approxMM={approxMM}
// 											mmToFeetParts={mmToFeetParts}
// 											panel_explode={false}
// 										/>
// 									:''
// 								}	 */}
// 							</Nav>
// 						</div>
// 					))

// 				}
// 			</Row>
// 			</CardBody>
// 		</Card>
// 	)
// }

// const SelectInput = ({json_item, on_click, on_select_input_change, configuration_type, common_onchange_handler}) => {
// 	const [select_input_value, set_select_input_value] = useState('');
	
// 	useEffect(() => {
// 		if(select_input_value && select_input_value != json_item.default_value)
// 		{
// 			on_click()
// 		}
// 	}, [select_input_value]);

// 	useEffect(() => {
// 		set_select_input_value(json_item.default_value)
// 	}, [json_item.default_value]);
// 	return(
// 		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled SI----------SI':'SI----------SI'}>
// 			<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 			<Row>
// 				{
// 					json_item.options.length == 2 && json_item.onchange_route && !(configuration_type == 'group_by_2') && !(json_item.type == 'layer_input')?
// 						configuration_type == 'names_above' ? 
// 						<Col xs={12} style={{paddingLeft:'8px', paddingRight:'8px'}}>
// 							<div style={{maxWidth:'100%'}}>
// 								<div style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", color: json_item.color ? json_item.color : COLORS.gray6, marginBottom:'4px'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
// 								<div style={{display:"flex", alignItems:"center"}}>
// 									<div style={{width:'100%', display:'flex', fontSize:'12px'}}>
// 										<Input key={"key_"+json_item.id} id={json_item.id} style={{display:'none'}} type="text" value={select_input_value}/>
// 										<div className='lines1_elipsis' key={1} onClick={() => {set_select_input_value(json_item.options[0].value); common_onchange_handler()/*; on_click(); on_select_input_change(json_item.options[0].value)*/}} style={json_item.options[0].value == json_item.default_value ? {width:'50%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor: COLORS.blue6, borderRightColor: COLORS.blue6, padding:'4px', cursor:'pointer', fontWeight:700, color:COLORS.blue6 } : {width:'50%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor:'#C5C7CF', borderRightColor:'transparent', padding:'4px', cursor:'pointer', backgroundColor:COLORS.gray2 }} title={json_item.options[0].name}>{json_item.options[0].name}</div>
// 										<div className='lines1_elipsis' key={2} onClick={() => {set_select_input_value(json_item.options[1].value); common_onchange_handler();/*; on_click(); on_select_input_change(json_item.options[1].value)*/}} style={json_item.options[1].value == json_item.default_value ? {width:'50%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor: COLORS.blue6, borderLeftColor:COLORS.blue6, padding:'4px', cursor:'pointer', fontWeight:700, color:COLORS.blue6 } : {width:'50%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor:'#C5C7CF', borderLeftColor:'transparent', padding:'4px', cursor:'pointer', backgroundColor:COLORS.gray2 }} title={json_item.options[1].name}>{json_item.options[1].name}</div>
// 									</div>
// 								</div>
// 							</div>
// 						</Col>
// 							:
// 							<React.Fragment>
// 							<Col xs={3} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", paddingLeft:'8px', paddingRight:'8px'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></Col>
// 							<Col xs={9} style={{display:"flex", alignItems:"center", justifyContent:'center', paddingLeft:'8px', paddingRight:'8px'}}>
// 								{/* <FormGroup style={{marginBottom:0, width:'100%'}}>
// 									<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
// 										{json_item.options.map((o,idx) => (
// 											<option key={idx} value={o.value}>{o.name}</option>
// 										))}
// 									</Input>
// 								</FormGroup> */}
// 								<div style={{width:'100%', display:'flex', fontSize:'12px'}}>
// 									<Input key={"key_"+json_item.id} id={json_item.id} style={{display:'none'}} type="text" value={select_input_value}/>
// 									<div key={1} onClick={() => {set_select_input_value(json_item.options[0].value); common_onchange_handler();/*; on_click(); on_select_input_change(json_item.options[0].value)*/}} style={json_item.options[0].value == json_item.default_value ? {width:'50%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor: COLORS.blue6, borderRightColor: COLORS.blue6, padding:'4px', cursor:'pointer', fontWeight:700, color:COLORS.blue6 } : {width:'50%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor:'#C5C7CF', borderRightColor:'transparent', padding:'4px', cursor:'pointer', backgroundColor:COLORS.gray2 }} title={json_item.options[0].name}>{json_item.options[0].name}</div>
// 									<div key={2} onClick={() => {set_select_input_value(json_item.options[1].value); common_onchange_handler();/*; on_click(); on_select_input_change(json_item.options[1].value)*/}} style={json_item.options[1].value == json_item.default_value ? {width:'50%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor: COLORS.blue6, borderLeftColor:COLORS.blue6, padding:'4px', cursor:'pointer', fontWeight:700, color:COLORS.blue6 } : {width:'50%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor:'#C5C7CF', borderLeftColor:'transparent', padding:'4px', cursor:'pointer', backgroundColor:COLORS.gray2 }} title={json_item.options[1].name}>{json_item.options[1].name}</div>
// 								</div>
// 							</Col>
// 						</React.Fragment>
					
// 					:
// 					!(configuration_type == 'group_by_2' || configuration_type == 'names_above') || json_item.type == 'layer_input' ? 
// 					<React.Fragment>
// 						<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", paddingLeft:'8px', paddingRight:'8px'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></Col>
// 						<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center', paddingLeft:'8px', paddingRight:'8px'}}>
// 							<FormGroup style={{marginBottom:0, width:'100%', border:'1px solid ' + COLORS.panel_item_borders}}>
// 								<Input onChange={(e) => {set_select_input_value(e.target.value); common_onchange_handler();} } value={select_input_value} key={"key_"+json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none'}} type="select" defaultValue={json_item.default_value} >
// 									{json_item.options.map((o,idx) => (
// 										<option key={idx} value={o.value}>{o.name}</option>
// 									))}
// 								</Input>
// 							</FormGroup>
// 						</Col>
// 					</React.Fragment>
// 					:
// 					<Col xs={12} style={{paddingLeft:'8px', paddingRight:'8px'}}>
// 						<div style={{maxWidth:'100%'}}>
// 							<div style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", color: json_item.color ? json_item.color : COLORS.gray6}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
// 							<div style={{display:"flex", alignItems:"center"}}>
// 								<FormGroup style={{marginBottom:0, width:'100%', border:'1px solid ' + COLORS.panel_item_borders}}>
// 									<Input onChange={(e) => {set_select_input_value(e.target.value); common_onchange_handler();} } value={select_input_value} key={"key_"+json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', height:'24px'}} type="select" defaultValue={json_item.default_value} >
// 										{json_item.options.map((o,idx) => (
// 											<option key={idx} value={o.value}>{o.name}</option>
// 										))}
// 									</Input>
// 								</FormGroup>
// 							</div>
// 						</div>
// 					</Col>
// 				}
// 				{/* <Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 				<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
// 					<FormGroup style={{marginBottom:0, width:'100%'}}>
// 						<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
// 							{json_item.options.map((o,idx) => (
// 								<option key={idx} value={o.value}>{o.name}</option>
// 							))}
// 						</Input>
// 					</FormGroup>
// 				</Col> */}
// 			</Row>
// 			</CardBody>
// 		</Card>
// 	)
// }

// const RefLevelDimensionInput = ({json_item, common_onchange_handler, metric_system, approximation_mm, approximation_ft, mmToFeetParts, approxMM, index, on_click, is_grouped}) => {
// 	const [ ref_level_value, set_ref_level_value ] = useState("");


// 	const get_active_ref_level_name = () => {
// 		if((!ref_level_value) || ref_level_value=="no_ref_level"){
// 			return ((json_item.relative_ref_level?["Value", "Amount"]:(json_item.global_ref_level?["From", "Global Origin"]:["From", "Floor Base Top"])));
// 			// return ("Value" + (json_item.relative_ref_level?" (Amount)":(json_item.global_ref_level?" (From Global Origin)":" (From Floor Base Top)")));
// 		}else if(json_item.ref_level_options&&json_item.ref_level_options.find(o=>o.value == ref_level_value)){
// 			var name_find = json_item.ref_level_options.find(o=>o.value == ref_level_value).name;
// 			// var name_find = json_item.ref_level_options.find(o=>o.value == ref_level_value).name + " with offset ";
// 			return json_item.relative_ref_level?["Up to ",name_find]:["From ", name_find];
// 		}else{
// 			return ['', 'Reference Level Deleted'];
// 		}
// 	}

// 	const dropdown_on_click = (val) => {
// 		set_ref_level_value(val);
// 	}

// 	useEffect(() => {
// 		set_ref_level_value(json_item.default_ref_level);
// 	},[]);

// 	return (
// 		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled class_position_unset':'dI----------dI class_position_unset'}>
// 			<CardBody className='class_position_unset' style={{ padding:'15px',paddingBottom:0, /*paddingTop:index ? 0 : 15,*/ backgroundColor: COLORS.panel_card_item}} >
// 			{/* <Row> */}
// 				{/* <Col style={{display:'flex',justifyContent:'center', alignItems:"center"}}> */}
// 				<Input type="text" value={ref_level_value} style={{display:"none"}} key={"key_"+json_item.id + "_ref_level_select"} id={json_item.id + "_ref_level_select"}/>
// 				<Row className='class_position_unset' style={{/*marginBottom:'15px'*/}}>
// 					<Col className='class_position_unset' sm={12} style={{fontSize:14, display:"flex", alignItems:"left",justifyContent:'left', cursor:"default", fontWeight:700, color:COLORS.panel_group_heading, marginBottom:'12px'}}>
// 						<div style={{marginLeft:'-15px', marginRight:'-15px', paddingLeft:'8px', paddingRight:'8px'}} title={json_item.name} className='lines2_elipsis' >{json_item.name}</div>
// 					</Col>
// 					<Col className='class_position_unset' sm={7} style={{fontSize:13, display:"flex", alignItems:"center", cursor:"default", paddingRight:'8px', paddingLeft:'8px'}}>
// 						{/* <div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}> */}
// 							<div style={{display:'flex', border:'1px solid ' + COLORS.panel_item_borders, paddingLeft:'8px', paddingRight:'8px', alignItems:'center', height:'24px', width:'100%'}}>
// 								<div style={{fontSize:'12px', marginRight:2}}>{get_active_ref_level_name()[0]}</div>
// 								<div style={{flex:1, fontSize:'12px', textAlign:'end'}} className='lines1_elipsis' title={get_active_ref_level_name()[1]}>{get_active_ref_level_name()[1]}</div>
// 								<ReferenceLevellDropdown json_item={json_item} on_click={dropdown_on_click} />
// 							</div>
// 						{/* </div> */}
// 					</Col>
// 					{metric_system=='mm'?(
// 						<React.Fragment>
// 							{((!ref_level_value) || ref_level_value=="no_ref_level")?(
// 								<Col className='class_position_unset' sm={5} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:8, paddingLeft:'8px'}}>
// 									<div style={{border:'1px solid ' + COLORS.panel_item_borders}}>
// 										<Input type="text" id={json_item.id} key={"key_"+json_item.id} onChange={common_onchange_handler} onBlur={json_item.onchange_route ? on_click : null} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
// 									</div>
// 								</Col>
// 							):(
// 								<Col className='class_position_unset' sm={5} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:8, paddingLeft:'8px'}}>
// 									<div style={{border:'1px solid ' + COLORS.panel_item_borders}}>
// 										<Input type="text" id={json_item.id + "_offset"} key={"key_"+json_item.id + "_offset"} onChange={common_onchange_handler} onBlur={json_item.onchange_route ? on_click : null} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_ref_level_offset)||0} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
// 									</div>
// 								</Col>
// 							)}
// 						</React.Fragment>
// 					):(
// 						<React.Fragment>
// 							{((!ref_level_value) || ref_level_value=="no_ref_level")?(
// 								<Col xs={5}>
// 									<FocusWithin onBlur={json_item.onchange_route ? on_click : null} style={{marginLeft: '-7px', marginRight: '-7px', display:'flex', width:'100%', border: '1px solid ' + COLORS.panel_item_borders}} className='blue_highlight_focuswithin'>
// 										{/* <Col> */}
// 											<Col className='class_position_unset zero_padding' sm={4} style={{borderRight:'1px solid ' + COLORS.panel_item_borders}}>
// 												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[0] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value)[0]||0} key={"key_" + json_item.id + "_ft"} id={json_item.id + "_ft"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
// 												{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
// 											</Col>
// 											<Col className='class_position_unset zero_padding' sm={4} style={{borderRight:'1px solid ' + COLORS.panel_item_borders}}>
// 												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[1] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value)[1]||0} key={"key_" + json_item.id + "_in"} id={json_item.id + "_in"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
// 												{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
// 											</Col>
// 											<Col className='class_position_unset zero_padding' sm={4}>
// 												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[2] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value)[2]||0} key={"key_" + json_item.id + "_parts"} id={json_item.id + "_parts"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
// 												{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
// 											</Col>
// 											{/* <Col className='class_position_unset' sm={1} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}>

// 											</Col> */}
// 										{/* </Col> */}
// 									</FocusWithin>
// 								</Col>
// 							):(
// 								<Col xs={5}>
// 									<FocusWithin onBlur={json_item.onchange_route ? on_click : null} style={{marginLeft: '-7px', marginRight: '-7px', display:'flex', width:'100%', border: '1px solid ' + COLORS.panel_item_borders}} className='blue_highlight_focuswithin'>
// 										{/* <Col> */}
// 											<Col className='class_position_unset zero_padding' sm={4} style={{borderRight:'1px solid ' + COLORS.panel_item_borders}}>
// 												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[0] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[0]||0} key={"key_" + json_item.id + "_offset" + "_ft"} id={json_item.id + "_offset" + "_ft"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
// 												{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
// 											</Col>
// 											<Col className='class_position_unset zero_padding' sm={4} style={{borderRight:'1px solid ' + COLORS.panel_item_borders}}>
// 												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[1] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[1]||0} key={"key_" + json_item.id + "_offset" + "_in"} id={json_item.id + "_offset" + "_in"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
// 												{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
// 											</Col>
// 											<Col className='class_position_unset zero_padding' sm={4}>
// 												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[2] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[2]||0} key={"key_" + json_item.id + "_offset" + "_parts"} id={json_item.id + "_offset" + "_parts"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
// 												{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
// 											</Col>
// 											{/* <Col className='class_position_unset' sm={1} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}>

// 											</Col> */}
// 										{/* </Col> */}
// 									</FocusWithin>
// 								</Col>
// 							)}
// 						</React.Fragment>
// 					)}
// 					{/* <Col className='class_position_unset' sm={2}>
// 						<ReferenceLevellDropdown json_item={json_item} on_click={dropdown_on_click} />
// 					</Col> */}
// 				</Row>
// 				{/* </Col> */}
// 			{/* </Row> */}
// 			</CardBody>
// 		</Card>
// 	);
// }

// const check_dropdown_state = (id) => {
// 	if(window.Module){
// 		let state = window.Module.get_dropdown_open_close_state(id);
// 		return state
// 	}

// 	return false;
// }


// const Create_card = ({parent_menu,panel_key, json_item, set_panel_has_unapplied_edits,update_view, metric_system, approximation_mm, parent_id, approximation_ft, set_page_loader,handle_ui_response, approxMM, mmToFeetParts, panel_explode, is_grouped, index, configuration_type, set_modal_mode, set_modal_type, set_layerset_mode, set_given_layerset, inside_tab}) => {
// 	const [ dropdownOpen, setDropdownOpen ] = useState(false);

// 	const toggle = () => setDropdownOpen(!dropdownOpen);
// 	const [collapsed, setCollapsed] = useState(true);

// 	const [clone_general_material_allowed, set_clone_general_material_allowed] = useState(true);
// 	const [showing_all_layersets, set_showing_all_layersets] = useState(false);
// 	const [showing_layerset_settings, set_showing_layerset_settings] = useState(false);
// 	useEffect(() => {
// 		if(window['Module'] && window['Module'].is_feature_enabled){
// 			set_clone_general_material_allowed(window.Module.is_feature_enabled("clone_general_material"));
// 		}
// 		// console.log("json item", json_item)
// 	},[json_item])

// 	useEffect(() => {
// 		if(panel_explode && json_item.type=='dropdown' && !is_dropdown_open()){
// 			dropdown_on_click()
// 		}
// 	}, [panel_explode]);
// 	// const [collapsed, setCollapsed] = useState((json_item && json_item.type == "dropdown" && json_item.open)?false:true);

// 	// Added by Akhil
// 	// need to use useEffect or something similar to set the default state of collapsed from json_item.open (this will be used for repopulating the panel in the same state)
// 	// 
// 	// useEffect(() => {
// 	// 	if(json_item && json_item.type == "dropdown" && json_item.open){
// 	// 		console.log(collapsed)
// 	// 		console.log(json_item.open)
// 	// 		setCollapsed(false)
// 	// 	}
// 	// },[json_item])

// 	const alert = useAlert();

//   	const toggleNavbar = () => setCollapsed(!collapsed);

// 	const tab_on_click = async (id) => {
// 		var active_design = window.Module.get_active_design()
// 		var active_view = active_design.active_view;
// 		active_view.toggle_dropdown_as_open_or_close(id)
// 		toggleNavbar();
// 		active_design.delete();
// 		active_view.delete();
// 	}
	
	


//   	const get_dimension = (id) => {
//   		if(metric_system == 'mm'){
// 			if(isNaN(document.getElementById(id).value)){
// 				return "Invalid Inputs";
// 			}
// 			return Number(document.getElementById(id).value);
// 		}else if(metric_system == 'ft'){
// 			if(isNaN(document.getElementById(id + "_ft").value) || isNaN(document.getElementById(id + "_in").value) || isNaN(document.getElementById(id + "_parts").value)){
// 				return "Invalid Inputs";
// 			}

// 			var ft_val = Number(document.getElementById(id + "_ft").value);
// 			var in_val = Number(document.getElementById(id + "_in").value);
// 			var parts_val = Number(document.getElementById(id + "_parts").value);

// 			var ft_inch_only =  Number(ft_val*304.8)+(in_val*(25.4));
// 			if(approximation_ft == '1/8_inch'){
// 				var fraction =  (parts_val*(25.4/8));
// 			}else if(approximation_ft == '1/16_inch'){
// 				var fraction =  (parts_val*(25.4/16));
// 			}

// 			return Number(ft_inch_only) + Number(fraction);
// 		}
//   	}

// 	const fetch_store_materials_onclick = () => {
// 		set_page_loader({
// 			show: true,
// 			text: 'Please wait'
// 		});
// 		setTimeout(async() => { 
// 			var active_design = window.Module.get_active_design();
// 			var active_view = active_design.active_view;
// 			active_view.active_material_select_item_id = json_item.id;
// 			window.Module.fetch_store_materials_onclick(json_item.id, json_item.route, json_item.material_type, json_item.active_category_id, json_item.pass_params_to_carousel_onclick);
// 			update_view();
// 			set_page_loader({
// 				show: false,
// 				text: 'Please wait'
// 			});	
// 			active_design.delete();
// 			active_view.delete();
// 		},0)
// 	};
	
// 	const clone_active_modal_onclick = () => {
// 		if(json_item && json_item.type == "material_select" && json_item.active_material){
// 			var active_design = window.Module.get_active_design();
// 			var active_view = active_design.active_view;
// 			active_view.active_material_select_item_id = json_item.id;
// 			window.Module.clone_active_modal_onclick(json_item.active_material.id, json_item.id, json_item.route);
// 			update_view();
// 			active_design.delete();
// 			active_view.delete();
// 		}
// 	};
	
// 	const get_active_index = (composites, active_id) => {
// 		return window._.findIndex(composites,o => o.id == active_id);
// 	}

// 	const composite_select_onclick = (composite_id) => {
// 		if(json_item.route){
// 			window.Module[json_item.route](composite_id);
// 			repopulate_panel_pop_up();
// 			update_view();
// 		}
// 	}

// 	const common_onchange_handler = (e) => {
// 		set_panel_has_unapplied_edits(true);
// 	}
	

// 	// Can depricate on_select_input_change
// 	const on_select_input_change = async (value) => {
// 		if(json_item.onchange_route && json_item.onchange_route != ""){
// 			var form = {
// 				id: parent_id
// 			}
// 			form[json_item.id] = value
			
// 			var promise_resp;
// 			try{
// 				promise_resp = window.Module[json_item.onchange_route](JSON.stringify(form));
// 			}catch(err){
// 				err.constructor.name.includes("Error") != true?err=new Error(err):null;
// 				err.name = "Captured error in Panel menu onclick error at " + json_item.onchange_route + ", form=" + JSON.stringify(form) + " : " + err.name
// 				console.error(err);
// 				window.sentry_capture_exception("",err);
// 				alert.error("Something went wrong")
// 				return;
				
// 			}
// 			var ui_response = await window.Promisify(promise_resp);
// 			common_onchange_handler();

// 			handle_ui_response(ui_response);
// 			repopulate_panel_pop_up();
			
// 			update_view();
//   		}
// 	}

//   	const on_click = async () => {
//   		if(json_item.route || json_item.onchange_route){
//   			var form = {
//   				id: json_item.type == 'button' || json_item.type == 'list-item' || json_item.type == 'toggle' ? json_item.id : (json_item.type == 'select_input' && json_item.use_this_id) ? json_item.use_this_id :  parent_id
//   			}

// 			// console.log("form ", form, parent_id)

//   			if(parent_menu){
//   				for (var i = 0; i < parent_menu.length; i++) {
//   					if(parent_menu[i].type == "text_input"){
//   						if(document.getElementById(parent_menu[i].id)){
//   							form[parent_menu[i].id] = document.getElementById(parent_menu[i].id).value;
//   						}
//   					}else if(parent_menu[i].type == "dimension_input"){
// 						var dim_parse = get_dimension(parent_menu[i].id);
// 						if(dim_parse.constructor.name == "String"){
// 							alert.error(dim_parse);
// 							return;
// 						}else{
// 							form[parent_menu[i].id] = get_dimension(parent_menu[i].id);
// 						}
//   					}else if(parent_menu[i].type == "ref_level_dimension_input"){
// 						if(document.getElementById(parent_menu[i].id + "_ref_level_select")){
// 							var current_val = document.getElementById(parent_menu[i].id + "_ref_level_select").value;
// 							if((!current_val) || current_val == "no_ref_level"){
// 								var dim_parse = get_dimension(parent_menu[i].id);
// 								if(dim_parse.constructor.name == "String"){
// 									alert.error(dim_parse);
// 									return;
// 								}else{
// 									form[parent_menu[i].id] = get_dimension(parent_menu[i].id);
// 								}
// 							}else{
// 								var dim_parse = get_dimension(parent_menu[i].id + "_offset");
// 								if(dim_parse.constructor.name == "String"){
// 									alert.error(dim_parse);
// 									return;
// 								}else{
// 									form[parent_menu[i].id] = {
// 										ref_level: current_val,
// 										offset: get_dimension(parent_menu[i].id + "_offset")
// 									};
// 								}
// 							}
// 						}
// 					}else if(parent_menu[i].type == "select_input" || parent_menu[i].type == "layer_input"){
//   						if(document.getElementById(parent_menu[i].id)){
//   							form[parent_menu[i].id] = document.getElementById(parent_menu[i].id).value;
//   						}
//   					}else if(parent_menu[i].type == "colorpicker"){
//   						if(document.getElementById(parent_menu[i].id)){
//   							form[parent_menu[i].id] = document.getElementById(parent_menu[i].id).value;
//   						}
//   					}else if(parent_menu[i].type == 'special_dimension_input'){
// 						form[parent_menu[i].id.width] = document.getElementById(parent_menu[i].id + '_x')
// 						form[parent_menu[i].id.height] = document.getElementById(parent_menu[i].id + '_y')
// 						form[parent_menu[i].id.depth] = document.getElementById(parent_menu[i].id + '_z')
// 					}else if(parent_menu[i].type == 'rotation_input'){
// 						form[parent_menu[i].id] = Number(document.getElementById(parent_menu[i].id).value);
// 					}else if(parent_menu[i].type == 'select_and_dimension_input'){
// 						form[parent_menu[i].id_select] = document.getElementById(parent_menu[i].id_select + 'st').value;
// 						var dim_parse = get_dimension(parent_menu[i].id_dim);
// 						if(dim_parse.constructor.name == "String"){
// 							alert.error(dim_parse);
// 							return;
// 						}else{
// 							form[parent_menu[i].id_dim] = get_dimension(parent_menu[i].id_dim);
// 						}
// 					}else if(parent_menu[i].type == 'toggle'){
// 						form[parent_menu[i].id] = !(document.getElementById(parent_menu[i].id).checked);
// 					}else if(parent_menu[i].type == "checkbox"){
// 						if(document.getElementById(parent_menu[i].id)){
// 							form[parent_menu[i].id] = (document.getElementById(parent_menu[i].id).checked);
// 						}
// 					}
//   				}
//   			}

//   			//special cases
//   			if(json_item.id == "blend_medium" || json_item.id == "blend_high" || json_item.id == "blend_presentation" || json_item.id == "3ds" || json_item.id == "glb_export_with_khr" || json_item.id == "glb_export_without_khr"){
// 				var cam_pos = window.Module.gl_get_camera_position();
//         		var cam_target = window.Module.gl_get_camera_target();
//   				form.camera_pos = {
//   					x: cam_pos.x,
//   					y: cam_pos.y,
//   					z: cam_pos.z
//   				}

//   				form.camera_rot = {
//   					x: cam_target.x,
//   					y: cam_target.y,
//   					z: cam_target.z
//   				}

//   				form.aspect = window.Module.gl_get_camera_aspect();
// 				form.fov = window.Module.gl_get_camera_fov();
// 				cam_pos.delete();
// 				cam_target.delete();
// 				set_page_loader({
// 					show: true,
// 					text: 'Please wait'
// 				});

// 				await dummy_tick(0);
// 			}

// 			if(json_item.id == "active_sales_channel_id_apply" || json_item.id == "refresh_admin_cache" || json_item.id == "pricing_quotation" || json_item.id == "cabinet_composite_schedule" || json_item.route == "open_kitchen_preferences_carousel" || parent_id=="livspace_dropdown" || parent_id=="spacewood_dropdown"){
// 				set_page_loader({
// 					show: true,
// 					text: 'Please wait'
// 				});
// 				await dummy_tick(0);
// 			}
			  
// 			if(json_item.id == "design_thumbnail"){
// 				form.img_data = window.get_snapshot_data(450,253)
// 			}
			
// 			var promise_resp;
// 			try{
// 				promise_resp = window.Module[json_item.type == 'button' || json_item.type == 'list-item' ? json_item.route : json_item.onchange_route](JSON.stringify(form));
// 			}catch(err){
// 				err.constructor.name.includes("Error") != true?err=new Error(err):null;
// 				err.name = "Captured error in Panel menu onclick error at " + json_item.route + ", form=" + JSON.stringify(form) + " : " + err.name
// 				console.error(err);
// 				window.sentry_capture_exception("",err);
// 				alert.error("Something went wrong")
// 				set_page_loader({
// 					show: false,
// 					text: 'Please wait'
// 				});
// 				return;
				
// 			}
// 			var ui_response = await window.Promisify(promise_resp);
			
// 			handle_ui_response(ui_response);
// 			repopulate_panel_pop_up();
			
// 			if(json_item.id == "active_sales_channel_id_apply" || json_item.id == "blend_medium" || json_item.id == "blend_high" || json_item.id == "blend_presentation" || json_item.id == "3ds" || json_item.id == "glb_export_with_khr" || json_item.id == "glb_export_without_khr" || json_item.id == "refresh_admin_cache" || json_item.id == "pricing_quotation" || json_item.id == "cabinet_composite_schedule" || json_item.route == "open_kitchen_preferences_carousel" || parent_id=="livspace_dropdown" || parent_id=="spacewood_dropdown"){
// 				set_page_loader({
// 					show: false,
// 					text: 'Please wait'
// 				});
// 				await dummy_tick(0);
// 			}
			
// 			update_view();
//   		}
//   	}

// 	const icon_on_click = async ({route, form}) => {
// 		var promise_resp;
// 		try{
// 			promise_resp = window.Module[route](JSON.stringify(form));
// 		}catch(err){
// 			err.constructor.name.includes("Error") != true?err=new Error(err):null;
// 			err.name = "Captured error in Panel menu onclick error at " + json_item.route + ", form=" + JSON.stringify(form) + " : " + err.name
// 			console.error(err);
// 			window.sentry_capture_exception("",err);
// 			alert.error("Something went wrong")
// 			set_page_loader({
// 				show: false,
// 				text: 'Please wait'
// 			});
// 			return;
			
// 		}
// 		var ui_response = await window.Promisify(promise_resp);
		
// 		handle_ui_response(ui_response);
// 		repopulate_panel_pop_up();
// 		update_view();

// 	}

// 	const dropdown_on_click = async () => {
// 		var active_design = window.Module.get_active_design()
// 		var active_view = active_design.active_view;
//         active_view.toggle_dropdown_as_open_or_close(json_item.id)
// 		toggleNavbar();
// 		active_design.delete();
// 		active_view.delete();
// 	}

// 	const is_dropdown_open = () => {
// 		if(window.Module){
// 			return window.Module.get_dropdown_open_close_state(json_item.id);
// 		}

// 		return false;
// 	}

// 	const get_dropdown_label = () => {
// 		if(json_item.type == 'dropdown' && json_item.submenu && json_item.submenu.length && json_item.submenu.filter(x => x.type == 'dimension_input' || x.type == 'ref_level_dimension_input').length){
// 			if(metric_system == 'mm'){
// 				return "(in mm)"
// 			}else {
// 				if(approximation_ft=='1/8_inch'){
// 					return '(ft,in,¹/₈")'
// 				}else{
// 					'(ft,in,¹/₁₆")'
// 				}
// 			}
// 		}
// 		return ''
// 	}

// 	const type_fn = () => {
// 		// const focusNextRef = useFocusNext();

// 		if(json_item && json_item.type){
// 			// console.log(json_item)
// 			if(json_item.type=='blank'){
// 				return(<div></div>)
// 			}
// 			else if(json_item && json_item.type=='dropdown' && (json_item.group_child_inputs || json_item.configuration_type == 'not_dropdown')){
// 				let json_item_submenu_copy = JSON.parse(JSON.stringify(json_item.submenu));
// 				// json_item_submenu_copy && json_item_submenu_copy.length ? json_item_submenu_copy.map((sub_item) =>(
// 				// 	sub_item.type = 'special_dimension_input'
// 				// )): ''
// 				// json_item_submenu_copy.type = 'special_dimension_input'
// 				// json_item_submenu_copy.route = json_item.onChangeRoute
// 				// json_item_submenu_copy.name = json_item.name
// 				// json_item_submenu_copy.id = json_item.id
// 				let child_tab_json = {}
// 				child_tab_json.tabs = json_item_submenu_copy
// 				child_tab_json.type = 'child_tabs'
// 				return(
// 					<div style={{width: '100%', overflow: 'hidden'}} className={json_item.disabled?'panel_disabled':''}>
// 						<div className={json_item.submenu && (json_item.submenu.length || (json_item.buttons && json_item.buttons.length))?'':'panel_disabled'} style={{cursor:"pointer",height:'40px', width:'100%',padding:'0px 8px', overflow: 'hidden', display:json_item.remove_dropdown || (!json_item.submenu.length && !(json_item.buttons && json_item.buttons.length) ) ? 'none' :'flex', flexDirection:'row', alignItems:json_item.configuration_type == "is_name"?'center':'end', fontWeight:700, borderBottom:json_item.configuration_type == "is_name" ? '2px solid ' + COLORS.panel_item_separation : 'none'}}>
// 							<span style={{flex:8 , textAlign:'left' }} >
// 								<span id={json_item.id} title={json_item.name + get_dropdown_label()} className='lines2_elipsis' style={{ fontSize:inside_tab ? '12px' : '14px', color:COLORS.panel_group_heading, fontStyle: inside_tab ? 'italic' : 'initial'}} >{json_item && json_item.name ? json_item.name + ' ':''} <span style={{ fontSize:'14px', color:'#CED4D9'}}>{get_dropdown_label()}</span></span>
// 								{/* <span id={json_item.id + 'label'} title={get_dropdown_label()} className='lines2_elipsis' style={{ fontSize:'14px', color:COLORS.panel_group_heading}} >{get_dropdown_label()}</span> */}
// 							</span>
// 							<div style={{flex:3, display:json_item.buttons && json_item.buttons.length ? 'flex' : 'none', alignItems:'center', justifyContent:'flex-end'}}>
// 							{
// 									json_item.buttons && json_item.buttons.length ? json_item.buttons.map(item => (
// 										item.type == "switch" ? 
// 											<Switch style={{marginRight:'8px', color:'green'}} size='small' checked = {item.value == "Off" ? false : true} onClick={(checked, e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
// 										: item.type == 'label' ? 
// 											<span style={{cursor:'pointer',marginRight:'4px', fontSize:'12px', color:COLORS.black, textTransform:'capitalize', fontWeight:400}}>{item.name}</span>
// 										: item.icon == "+" ?
// 											<span style={{cursor:'pointer',marginRight:'8px', fontSize:'14px', color:COLORS.blue6, textTransform:'capitalize'}} onClick={(e) => {is_dropdown_open() ? e.stopPropagation() : null; icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}>+ Add </span>
// 										:
// 											<img src={item.icon} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
// 									))
// 									: ''
// 								}
// 							</div>
// 						</div>

// 						<Collapse className='d--------------------d' isOpen={json_item.disabled ? false : true} navbar={true} style={json_item.buttons && json_item.buttons.length && json_item.buttons.filter(x => x.icon == "/resources/panel_icons_new/lock layer.svg").length ? { width:'100%', overflowX: 'hidden', opacity: 0.5, pointerEvents:'none' } : { width:'100%', overflowX: 'hidden', opacity: 1}}>
// 							{
// 								json_item.make_child_tabs ? 
// 									<Create_card 
// 										panel_key={panel_key}
// 										set_page_loader={set_page_loader}
// 										handle_ui_response={handle_ui_response}
// 										metric_system={metric_system}
// 										approximation_mm={approximation_mm}
// 										approximation_ft={approximation_ft}
// 										// parent_menu={panel_json}
// 										update_view={update_view}
// 										json_item={child_tab_json}
// 										set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 										approxMM={approxMM}
// 										mmToFeetParts={mmToFeetParts}
// 										panel_explode={false}
// 									/>
// 									:''
// 							}
// 							<Nav navbar style={{/*borderLeft:'4px solid #A7A8B2',*/ width:'100%', overflowX: 'hidden', flexDirection:'row', flexWrap:'wrap' , display:json_item.make_child_tabs ? 'none' : 'flex'}}>
// 								{
// 									json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item,idx) =>(
// 										<NavItem style={{display:'flex', alignItems:'center', flexDirection:'row', width: json_item.configuration_type == 'group_by_2' && (sub_item.type == 'dimension_input' || sub_item.type == 'text_input' || sub_item.type == 'toggle' || sub_item.type == 'select_input' || sub_item.type == 'colorpicker' ) ? '50%' : json_item.group_child_inputs && sub_item.type == 'list-item' ? 'auto' : json_item.group_child_inputs && ((sub_item.type == 'dimension_input' && metric_system == 'mm') || sub_item.type == 'text_input' || sub_item.type == 'colorpicker' || (sub_item.type == 'select_input'))? '33.3%' :'100%', overflowX: 'hidden'}}>
// 											<Create_card 
// 												panel_key={panel_key}
// 												set_page_loader={set_page_loader}
// 												handle_ui_response={handle_ui_response}
// 												key={json_item.id+idx}
// 												metric_system={metric_system}
// 												approximation_mm={approximation_mm}
// 												approximation_ft={approximation_ft}
// 												parent_id={json_item.id}
// 												parent_menu={json_item.submenu}
// 												update_view={update_view}
// 												json_item={sub_item}
// 												set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 												approxMM={approxMM}
// 												mmToFeetParts={mmToFeetParts}
// 												panel_explode={panel_explode}
// 												is_grouped={json_item.group_child_inputs}
// 												index={idx}
// 												configuration_type={json_item.configuration_type}
// 												inside_tab={inside_tab}/>
// 										</NavItem>
// 									 )):''
// 								}
// 								{
// 									json_item.submenu && json_item.submenu.length && !((json_item.submenu[json_item.submenu.length-1].type == 'list-item' && !json_item.group_child_inputs) || json_item.submenu[json_item.submenu.length-1].type == 'dropdown' || json_item.submenu[json_item.submenu.length-1].type == 'button') ?
// 										<Create_card 
// 											panel_key={panel_key}
// 											set_page_loader={set_page_loader}
// 											handle_ui_response={handle_ui_response}
// 											metric_system={metric_system}
// 											approximation_mm={approximation_mm}
// 											approximation_ft={approximation_ft}
// 											// parent_menu={panel_json}
// 											update_view={update_view}
// 											json_item={{type:'margin_bottom', margin:json_item.submenu[json_item.submenu.length-1].type == 'list-item' ? '0px' : '16px'}}
// 											set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 											approxMM={approxMM}
// 											mmToFeetParts={mmToFeetParts}
// 											panel_explode={false}
// 											inside_tab={inside_tab}
// 										/>
// 									:''
// 								}
// 							</Nav>
// 						</Collapse>
// 					</div>
// 				)
// 			}
// 			// else if(json_item && json_item.type=='dropdown' && json_item.is_layer){
// 			// 	return(
					
// 			// 			<Collapse className='d--------------------d' isOpen={true} navbar={true} style={{ width:'100%', overflowX: 'hidden'}}>
// 			// 				<Nav navbar style={{borderLeft: '3px solid', borderLeft:'4px solid', borderLeftColor:'#A7A8B2', width:'100%', overflowX: 'hidden' }}>
// 			// 					{
// 			// 						json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item,idx) =>(
// 			// 							<NavItem key={json_item.id+idx} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
// 			// 								<Create_card set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} key={json_item.id+idx} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} parent_id={json_item.id} parent_menu={json_item.submenu} update_view={update_view} json_item={sub_item} set_panel_has_unapplied_edits={set_panel_has_unapplied_edits} approxMM={approxMM} mmToFeetParts={mmToFeetParts} panel_explode = {panel_explode}/>
// 			// 							</NavItem>
// 			// 						)):''
									
// 			// 					}		
// 			// 				</Nav>
// 			// 			</Collapse>
// 			// 	)
// 			// }
// 			else if(json_item && json_item.type=='dropdown'){
// 				let json_item_submenu_copy = JSON.parse(JSON.stringify(json_item.submenu));
// 				// json_item_submenu_copy && json_item_submenu_copy.length ? json_item_submenu_copy.map((sub_item) =>(
// 				// 	sub_item.type = 'special_dimension_input'
// 				// )): ''
// 				// json_item_submenu_copy.type = 'special_dimension_input'
// 				// json_item_submenu_copy.route = json_item.onChangeRoute
// 				// json_item_submenu_copy.name = json_item.name
// 				// json_item_submenu_copy.id = json_item.id
// 				let child_tab_json = {}
// 				child_tab_json.tabs = json_item_submenu_copy
// 				child_tab_json.type = 'child_tabs'
// 				return(
// 					<div style={{width: '100%', overflow: 'hidden'}} className={json_item.disabled?'panel_disabled':''}>
						
// 						<div className={json_item.submenu && (json_item.submenu.length || (json_item.buttons && json_item.buttons.length)) ?'':'panel_disabled'} onClick={json_item.disabled || !(json_item.submenu && (json_item.submenu.length || (json_item.buttons && json_item.buttons.length))) ?null:dropdown_on_click} style={{cursor:"pointer",height:'42px', width:'100%',padding:'0px 15px', overflow: 'hidden', display:json_item.remove_dropdown || (!json_item.submenu.length && !(json_item.buttons && json_item.buttons.length) ) ? 'none' :'flex', flexDirection:'row', alignItems:'center', borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation, borderRightColor:is_dropdown_open() ? COLORS.blue6 : COLORS.toolbar_background_color, borderRightWidth: '5px', borderRightStyle:'solid', backgroundColor:COLORS.toolbar_background_color}}>
// 							{	
// 								json_item.icon?
// 								<span style={{paddingRight:'10px', flex:1}}>
// 									<img height='24px' width='24px' style={{maxHeight: '100%', overflow: 'hidden'}} title={json_item.name} src={json_item.icon} />
// 								</span>	
// 								:''
// 							}
							
// 							<span style={{flex:8 , textAlign:'left' }} >
// 								<span id={json_item.id} title={json_item.name} className='lines2_elipsis' style={{ fontSize:'14px', color:COLORS.black_left_menu}} >{json_item && json_item.name ? json_item.name:''}</span>
// 							</span>
// 							<div style={{flex:3, display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
// 								{/* <FormGroup style={{marginBottom:0, width:'100%', display:json_item.switch_show?'block':'none'}}> */}
// 								{/* <div style={{display:json_item.switch_show?'block':'none'}} className='toggle_switch'>
// 									<Input style={{display:'none', margin:'-6px 0px 0px 20px'}} onChange={common_onchange_handler} type="checkbox"></Input>
// 									<span className='switch'></span>
// 								</div> */}
// 								{/* </FormGroup> */}
// 								{/* <div style={{display:json_item.switch_show?'block':'none', fontSize:'8px'}}>
// 								<label class="toggleSwitch nolabel" onclick="">
// 									<input type="checkbox" checked />
// 									<span>
// 										<span>OFF</span>
// 										<span>ON</span>
// 									</span>
// 									<a></a>
// 								</label>
// 								</div> */}
// 								{/* <input style={{display:json_item.switch_show?'block':'none', marginRight:'8px'}} onChange={common_onchange_handler} type="checkbox"></input> */}
// 								{
// 									json_item.buttons && json_item.buttons.length ? json_item.buttons.map(item => (
// 										item.type == "switch" ? 
// 											<Switch style={{marginRight:'8px', color:'green'}} size='small' checked = {item.value == "Off" ? false : true} onClick={(checked, e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
// 										: item.type == 'label' ? 
// 											<span style={{cursor:'pointer',marginRight:'8px', fontSize:'12px', color:COLORS.black, textTransform:'capitalize'}}>{item.name}</span>
// 										: item.icon == "+" ? 
// 											<span style={{cursor:'pointer',marginRight:'8px', fontSize:'14px', color:COLORS.blue6, textTransform:'capitalize'}} onClick={(e) => {is_dropdown_open() ? e.stopPropagation() : null; icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}>+ Add </span>
// 										:
// 											<img src={item.icon} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
// 									))
// 									: ''
// 								}
// 								<span style={{ fontSize:'14px', color:COLORS.black_left_menu }} ><b>{!is_dropdown_open()?<i style={{fontSize:'12px', color: COLORS.black_left_menu}} className="fa fa-chevron-down"/>:<i style={{fontSize:'12px', color: COLORS.black_left_menu}} className="fa fa-chevron-up"/>}</b></span>
// 							</div>
// 						</div>

// 						{
// 							json_item.id == 'snap_settings' ? 
// 								<Create_card 
// 									panel_key={panel_key}
// 									set_page_loader={set_page_loader}
// 									handle_ui_response={handle_ui_response}
// 									metric_system={metric_system}
// 									approximation_mm={approximation_mm}
// 									approximation_ft={approximation_ft}
// 									// parent_menu={panel_json}
// 									update_view={update_view}
// 									json_item={{type:'snap_text'}}
// 									set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 									approxMM={approxMM}
// 									mmToFeetParts={mmToFeetParts}
// 									panel_explode={false}
// 								/>
// 								:''
// 						}
						
// 						<Collapse className='d--------------------d' isOpen={json_item.remove_dropdown ? true : is_dropdown_open()} navbar={true} style={json_item.buttons && json_item.buttons.length && json_item.buttons.filter(x => x.icon == "/resources/panel_icons_new/lock layer.svg").length ? { width:'100%', overflowX: 'hidden', opacity: 0.5, pointerEvents:'none' } : { width:'100%', overflowX: 'hidden', opacity: 1}}>
// 							{
// 								json_item.make_child_tabs ? 
// 									<Create_card 
// 										panel_key={panel_key}
// 										set_page_loader={set_page_loader}
// 										handle_ui_response={handle_ui_response}
// 										metric_system={metric_system}
// 										approximation_mm={approximation_mm}
// 										approximation_ft={approximation_ft}
// 										// parent_menu={panel_json}
// 										update_view={update_view}
// 										json_item={child_tab_json}
// 										set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 										approxMM={approxMM}
// 										mmToFeetParts={mmToFeetParts}
// 										panel_explode={false}
// 									/>
// 									:''
// 							}
// 							<Nav navbar style={{borderLeft: json_item.remove_dropdown? 'none' : '4px solid', borderLeftColor:'#A7A8B2', width:'100%', overflowX: 'hidden', display:json_item.make_child_tabs ? 'none' : 'flex' }}>
								
// 								{
// 									json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item,idx) =>(
// 										<NavItem key={json_item.id+idx} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
// 											<Create_card 
// 												panel_key={panel_key}
// 												set_page_loader={set_page_loader}
// 												handle_ui_response={handle_ui_response}
// 												key={json_item.id+idx}
// 												metric_system={metric_system}
// 												approximation_mm={approximation_mm}
// 												approximation_ft={approximation_ft}
// 												parent_id={json_item.id}
// 												parent_menu={json_item.submenu}
// 												update_view={update_view}
// 												json_item={sub_item}
// 												set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 												approxMM={approxMM}
// 												mmToFeetParts={mmToFeetParts}
// 												panel_explode={panel_explode}
// 												is_grouped={json_item.group_child_inputs}
// 												index={idx}
// 												configuration_type={json_item.configuration_type}
// 												inside_tab={inside_tab}/>
// 										</NavItem>
// 									)):''
									
// 								}
// 								{
// 									json_item.submenu && json_item.submenu.length && !(json_item.submenu[json_item.submenu.length-1].type == 'list-item' || json_item.submenu[json_item.submenu.length-1].type == 'dropdown' || json_item.submenu[json_item.submenu.length-1].type == 'button') ?
// 										<Create_card 
// 											panel_key={panel_key}
// 											set_page_loader={set_page_loader}
// 											handle_ui_response={handle_ui_response}
// 											metric_system={metric_system}
// 											approximation_mm={approximation_mm}
// 											approximation_ft={approximation_ft}
// 											// parent_menu={panel_json}
// 											update_view={update_view}
// 											json_item={{type:'margin_bottom'}}
// 											set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 											approxMM={approxMM}
// 											mmToFeetParts={mmToFeetParts}
// 											panel_explode={false}
// 											inside_tab={inside_tab}
// 										/>
// 									:''
// 								}	
// 							</Nav>
// 						</Collapse>
// 					</div>		
// 				)
// 			}
// 			else if(json_item.type=='list-item'){
// 				return(
					
// 					<div key={"key_"+json_item.id} id={json_item.id + "_button"} className={json_item.disabled?'panel_disabled': is_grouped ? 'underline_onhover LI----------LI' : json_item.route ? 'hover_enabled_green_border LI----------LI' : 'LI----------LI'} onClick={json_item.disabled?null:on_click} style={{cursor:'pointer', height:42, width:is_grouped ? 'auto' : '100%', display:'flex', flexDirection:'row', alignItems:'center', borderBottom: !is_grouped ? json_item.route ? '2px solid' : '3px solid' : 'none', borderBottomColor:COLORS.panel_item_separation, padding:'15px 8px', backgroundColor:COLORS.panel_card_item, textDecorationColor:json_item.color ? json_item.color : COLORS.black_left_menu}}>
// 						{	
// 							json_item.icon?(
// 								<div style={{paddingRight:'10px', flex:1}}>
// 									<img height='24px' width='24px' title={json_item.name} src={json_item.icon} />
// 								</div>
// 							):''
// 						}	
// 						<div style={{flex:5}}>
// 							<div title={json_item.name} className='lines1_elipsis' id={json_item.id} style={{ fontSize:'12px', color: json_item.color ? json_item.color : json_item.route ? COLORS.black_left_menu : 'A7A8B2', fontWeight: json_item.route ? 400 : 700, textAlign:json_item.route?'left':'center', width:'100%' }} >{json_item.name}</div>
// 						</div>
// 					</div>
// 				)
// 			}
// 			else if(json_item.type=='button'){
// 				return(
// 					<Card /*className='B----------B'*/ style={{ border:'0px solid', borderBottom:'1px solid', borderBottomColor:COLORS.panel_item_separation }} className={json_item.disabled?'panel_disabled':''} >
// 						<CardBody style={{ padding:'15px', backgroundColor:COLORS.panel_card_item}} >
// 							<Button className='panel_green_btn lines1_elipsis' style={{display:'inline-flex',alignItems:'center',height:'32px'}} key={"key_"+json_item.id} id={json_item.id} onClick={json_item.disabled?null:on_click} >{json_item ? json_item.name:''}</Button>
// 						</CardBody>
// 					</Card>
// 				)
// 			}

// 			else if(json_item.type=='text_input'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }}  className={json_item.disabled?'panel_disabled':'TI----------TI'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 							{
// 								is_grouped ? 
// 									<div style={{maxWidth:'100%'}}>
// 										<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15, display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'flex' : 'none'}}>
// 											<div style={{display:'flex', width:'100%', padding:'0px 0px'}}>
// 												<div className='lines1_elipsis' style={{color: json_item.color ? json_item.color : COLORS.gray6 , fontSize:'12px', fontWeight:500}} title={json_item.name ? json_item.name : ''}>{json_item.name ? json_item.name : ''}</div>
// 												{/* <Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/> */}
// 											</div>
// 										</div>
// 										<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}>
// 											<div style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}>
// 												<div style={{flex:1, color: json_item.color ? json_item.color : COLORS.gray6 , fontSize:'12px', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'none' : 'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name ? json_item.name[0] : ''}</div>
// 												<Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=(json_item.default_value) || '' ; e.target.blur()}}} defaultValue={(json_item.default_value)||''} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/>
// 											</div>
// 										</div>
// 									</div>
// 								:
// 									<Row>
// 										<Col xs={6} style={{fontSize:14,display:'flex',alignItems:"center", cursor:"default", paddingLeft:'8px', paddingRight:'8px'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></Col>
// 										<Col xs={6} style={{fontSize:14,display:'flex',alignItems:"center", paddingLeft:'8px', paddingRight:'8px'}}>
// 											<div style={{border: '1px solid ' + COLORS.panel_item_borders}}>
// 												<Input key={"key_"+json_item.id} onChange={common_onchange_handler} onBlur={json_item.onchange_route ? on_click : null} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=(json_item.default_value) || '' ; e.target.blur()}}} defaultValue={json_item.default_value||""} type="text"  id={json_item.id} style={{...styles_panel.input_styles, border:'none'}} placeholder="0"/>
// 											</div>
// 										</Col>
// 									</Row>
// 							}
// 						</CardBody>
// 					</Card>
// 				)
// 			}

// 			// dummy value in image height, can be adjusted
// 			// else if(json_item.type=='card'){
// 			// 	return(
// 			// 		<Card style={{padding:'15px', margin:'10px'}} className={json_item.disabled?'panel_disabled':''}>  
// 			// 			<CardBody style={{padding:'0px'}}>
// 			// 				<Row style={{height:'152px', margin:0, marginBottom:'2px'}}> 
// 			// 					<Col style={{display:'flex', alignItems:"center", justifyContent:'center', backgroundColor:'white',padding:0}}>	
// 			// 						<img style={{cursor:'pointer'}} src= {json_item && json_item.name ?json_item.name:''} height="100%" width="auto" />
// 			// 					</Col>
// 			// 				</Row>
// 			// 				<Row style={{margin:0}}>
// 			// 					<Col style={{display:'flex', alignItems:"center", justifyContent:'center',padding:0, fontSize:'12px'}}>	
// 			// 						{json_item && json_item.name ?json_item.name:''}
// 			// 					</Col>
// 			// 				</Row>
// 			// 			</CardBody>
// 			// 		</Card>	
// 			// 	)
// 			// }
// 			else if(json_item.type=='dimension_input'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled':'dI----------dI'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, /*paddingTop:(index < 3 && is_grouped && metric_system=='mm' || !index) ? 15 : 0,*/ backgroundColor:COLORS.panel_card_item}} >
// 							<Row style={{/*marginBottom:'15px'*/}}>
// 								<Col style={{display:'flex',alignItems:'center', paddingLeft: 15, paddingRight: 15, flexDirection:'row'}}>
// 								{metric_system=='mm'?(
// 									is_grouped ? 
// 										<div style={{maxWidth:'100%'}}>
// 										<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15, display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'flex' : 'none'}}>
// 											<div style={{display:'flex', width:'100%', padding:'0px 0px'}}>
// 												<div className='lines1_elipsis' style={{color: json_item.color && !(configuration_type == 'group_by_2') ? json_item.color : COLORS.gray5 , fontSize:'12px', fontWeight:500}} title={json_item.name ? json_item.name : ''}>{json_item.name ? json_item.name : ''}</div>
// 												{/* <Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/> */}
// 											</div>
// 										</div>
// 										<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}>
// 											<div style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}>
// 												<div style={{flex:1, color: json_item.color ? json_item.color : COLORS.gray5 , fontSize:'12px', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'none' : 'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name ? json_item.name[0] : ''}</div>
// 												<Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/>
// 											</div>
// 										</div>
// 										</div>
// 										:
// 										<div style={{width:'100%'}}>
// 											<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}>
// 												<div style={{display:'flex', width:'100%'}}>
// 													<div style={{fontSize:13, display:"flex", alignItems:"center", cursor:"default", flex:1, paddingRight:'8px', maxWidth:'50%'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
// 													<div style={{fontSize:13, display:"flex", alignItems:"center", flex:1, paddingLeft:'8px', maxWidth:'50%'}}>
// 														<div style={{border:'1px solid ' + COLORS.panel_item_borders}}>
// 															<Input type="text" onChange={common_onchange_handler} id={json_item.id} key={"key_"+json_item.id}  onBlur={json_item.onchange_route ? on_click : null} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} style={{...styles_panel.input_styles, border:'none'}} placeholder="0"/>
// 														</div>
// 													</div>
// 												</div>
// 											</div>
// 										</div>
// 								):(
// 									is_grouped ? 
// 									<Row style={{paddingLeft:'8px', paddingRight:'8px'}}>
// 										{/* <Col sm={5} className='zero_padding' style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col> */}
// 										<Col sm={configuration_type == 'group_by_2' ? 12 : 8} style={{border:'1px solid ' + COLORS.panel_item_borders}} className='blue_highlight_focuswithin'>
// 											{/* <Row style={{marginLeft:0, marginRight:0}}>
// 												<Col sm={12} className='zero_padding' style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 											</Row> */}
// 											<Row style={{padding:'0px 4px'}}>
												
// 												<Col sm={1} className='zero_padding' style={{fontSize:13, textAlign:'left', cursor:'default', display:'flex', alignItems:'center', textAlign:'center', justifyContent:'center', color:json_item.color ? json_item.color: COLORS.gray3}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name && json_item.name.length ? json_item.name[0] : ''}</span></Col>

// 												<Col xs={11} className='zero_padding'>
// 													<FocusWithin onBlur={on_click} style={{display:'flex', width:'100%'}}>
// 														<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.panel_item_borders}}>
// 															<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[0] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value)[0]||0} key={"key_" + json_item.id + "_ft"} id={json_item.id + "_ft"} style={{...styles_panel.input_styles, border: 'none', height: '24px'}} placeholder="0"/>
// 															{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
// 														</Col>
// 														<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.panel_item_borders}}>
// 															<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[1] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value)[1]||0} key={"key_" + json_item.id + "_in"} id={json_item.id + "_in"} style={{...styles_panel.input_styles, border: 'none', height: '24px'}} placeholder="0"/>
// 															{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
// 														</Col>
// 														<Col sm={4} className='zero_padding'>
// 															<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[2] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value)[2]||0} key={"key_" + json_item.id + "_parts"} id={json_item.id + "_parts"} style={{...styles_panel.input_styles, border: 'none', height: '24px'}} placeholder="0"/>
// 															{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
// 														</Col>
// 													</FocusWithin>
// 												</Col>
// 											</Row>
// 										</Col>
// 									</Row>
// 									:
// 									<Row>
// 										<Col sm={5} style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 										<Col sm={2} className='zero_padding'>
// 											<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[0]||0} key={"key_" + json_item.id + "_ft"} id={json_item.id + "_ft"} style={styles_panel.input_styles} placeholder="0"/>
// 											<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span>
// 										</Col>
// 										<Col sm={2} className='zero_padding'>
// 											<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[1]||0} key={"key_" + json_item.id + "_in"} id={json_item.id + "_in"} style={styles_panel.input_styles} placeholder="0"/>
// 											<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span>
// 										</Col>
// 										<Col sm={2} className='zero_padding'>
// 											<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[2]||0} key={"key_" + json_item.id + "_parts"} id={json_item.id + "_parts"} style={styles_panel.input_styles} placeholder="0"/>
// 											<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span>
// 										</Col>
// 									</Row>
// 								)}
// 									<br/>
// 								</Col>
// 							</Row>
// 						</CardBody>
// 					</Card>
					
// 				)
// 			}
// 			else if(json_item.type=='ref_level_dimension_input'){
// 				return(
// 					<RefLevelDimensionInput json_item={json_item} common_onchange_handler={common_onchange_handler} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} mmToFeetParts={mmToFeetParts} approxMM={approxMM} index={index} on_click={on_click} is_grouped={is_grouped}></RefLevelDimensionInput>
// 				)
// 			}
// 			else if(json_item.type=='heading'){
// 				return(<div></div>)
// 			}
			
// 			else if(json_item.type=='multi-buttons'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled':'MB----------MB'}>
// 						<CardBody style={{ padding:'10px', backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col sm={6} style={{fontSize:14, display:'flex', alignItems:'center',justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 							<Col sm={4}>
// 								<Row><Col>
// 									<Row><Col><i title="increase" style={{padding:'5px', fontSize:14,cursor:'pointer' }} className="fa fa-chevron-up panel_color_green_arrow"/></Col></Row>
// 									<Row><Col><input style={styles_panel.input_styles} onChange={common_onchange_handler} placeholder="0"/></Col></Row>
// 									<Row><Col><i title="decrease" style={{padding:'5px', fontSize:14, cursor:'pointer' }} className="fa fa-chevron-down panel_color_green_arrow"/></Col></Row>
// 								</Col></Row>
// 							</Col>
// 							<Col sm={2} style={{fontSize:'13px', display:"flex", alignItems:"center", justifyContent:'center',paddingLeft:0, cursor:"default"}}>
// 								mm
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type=='colorpicker'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CP----------CP':'CP----------CP'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 							{
// 								is_grouped ? 
// 									<div style={{maxWidth: '100%'}}>
// 										<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15, display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'flex' : 'none'}}>
// 											<div style={{display:'flex', width:'100%', padding:'0px 0px'}}>
// 												<div className='lines1_elipsis' style={{color: json_item.color ? json_item.color : COLORS.gray6 , fontSize:'12px', fontWeight:500}} title={json_item.name ? json_item.name : ''}>{json_item.name ? json_item.name : ''}</div>
// 												{/* <Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/> */}
// 											</div>
// 										</div>
// 										<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}>
// 											<div style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}>
// 												{/* <div style={{flex:1, color: json_item.color ? json_item.color : COLORS.gray5 , fontSize:'12px', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'none' : 'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name ? json_item.name[0] : ''}</div> */}
// 												<Input type="color" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=(json_item.default_value) ; e.target.blur()}}} defaultValue={(json_item.default_value)} key={"key_color" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px', padding:0}}/>
// 											</div>
// 										</div>
// 									</div>
// 								:
// 									<Row>
// 										<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", paddingLeft:'8px', paddingRight:'8px'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></Col>
// 										<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'flex-start', paddingLeft:'8px', paddingRight:'8px'}}>
// 											<FormGroup style={{margin:0, width:'100%', border: '1px solid ' + COLORS.panel_item_borders}}>
// 												<Input id={json_item.id} key={"key_color_"+json_item.id} onChange={common_onchange_handler} onBlur={json_item.onchange_route ? on_click : null} defaultValue={json_item.default_value} style={{ padding: '0px', height: '30px', width: '100%', border:'none'}} type="color" />
// 											</FormGroup>
// 										</Col>
// 									</Row>
// 							}
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type=='select_input' || json_item.type == 'layer_input'){
// 				// console.log("json_item", json_item);
// 				// const [select_input_value, set_select_input_value] = useState(json_item.default_value);
// 				// useEffect(() => {
// 				// 	if(select_input_value && select_input_value != json_item.default_value)
// 				// 	on_click()
// 				// }, [select_input_value]);
// 				// return(
// 				// 	<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled SI----------SI':'SI----------SI'}>
// 				// 		<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 				// 		<Row>
// 				// 			{
// 				// 				json_item.options.length == 2 && json_item.onchange_route ?
// 				// 				<React.Fragment>
// 				// 					<Col xs={3} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 				// 					<Col xs={9} style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
// 				// 						{/* <FormGroup style={{marginBottom:0, width:'100%'}}>
// 				// 							<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
// 				// 								{json_item.options.map((o,idx) => (
// 				// 									<option key={idx} value={o.value}>{o.name}</option>
// 				// 								))}
// 				// 							</Input>
// 				// 						</FormGroup> */}
// 				// 						<div style={{width:'100%', display:'flex', fontSize:'12px'}}>
// 				// 							<Input key={"key_"+json_item.id} id={json_item.id} style={{display:'none'}} type="text" value={select_input_value} />
// 				// 							<div key={1} onClick={() => {set_select_input_value(json_item.options[0].value)/*; on_click(); on_select_input_change(json_item.options[0].value)*/}} style={{width:'50%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor: json_item.options[0].value == json_item.default_value ? COLORS.blue6 : '#C5C7CF', borderRightColor: json_item.options[0].value == json_item.default_value ? COLORS.blue6 : 'transparent', padding:'4px' }}>{json_item.options[0].name}</div>
// 				// 							<div key={2} onClick={() => {set_select_input_value(json_item.options[1].value)/*; on_click(); on_select_input_change(json_item.options[1].value)*/}} style={{width:'50%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor: json_item.options[1].value == json_item.default_value ? COLORS.blue6 : '#C5C7CF', borderLeftColor: json_item.options[1].value == json_item.default_value ? COLORS.blue6 : 'transparent', padding:'4px' }}>{json_item.options[1].name}</div>
// 				// 						</div>
// 				// 					</Col>
// 				// 				</React.Fragment>
// 				// 				:
// 				// 				<React.Fragment>
// 				// 					<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 				// 					<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
// 				// 						<FormGroup style={{marginBottom:0, width:'100%'}}>
// 				// 							<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
// 				// 								{json_item.options.map((o,idx) => (
// 				// 									<option key={idx} value={o.value}>{o.name}</option>
// 				// 								))}
// 				// 							</Input>
// 				// 						</FormGroup>
// 				// 					</Col>
// 				// 				</React.Fragment>
// 				// 			}
// 				// 			{/* <Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 				// 			<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
// 				// 				<FormGroup style={{marginBottom:0, width:'100%'}}>
// 				// 					<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
// 				// 						{json_item.options.map((o,idx) => (
// 				// 							<option key={idx} value={o.value}>{o.name}</option>
// 				// 						))}
// 				// 					</Input>
// 				// 				</FormGroup>
// 				// 			</Col> */}
// 				// 		</Row>
// 				// 		</CardBody>
// 				// 	</Card>
// 				// )
// 				return(
// 					<SelectInput json_item={json_item} on_click={on_click} on_select_input_change={on_select_input_change} configuration_type={configuration_type} common_onchange_handler={common_onchange_handler}/>
// 				)
// 			}
// 			else if(json_item.type=='material_select'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled class_position_unset':'class_position_unset'}>
// 						<CardBody className='class_position_unset' style={{padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row  className='class_position_unset' style={{margin:0, width:'100%'}}>
// 							<Col className='class_position_unset' xs={9} style={{display:'flex', alignItems:"center", justifyContent:'center',paddingLeft:'8px', paddingRight:'8px'}}>
// 								<MaterialDropdown material_json={json_item}  popup_panel={true} update_view={update_view} handle_ui_response={handle_ui_response} json_item={json_item}/>
// 							</Col>
// 							<Col xs={3} style={{display:'flex', alignItems:"center", justifyContent:'center',paddingLeft:'8px', paddingRight:'8px'}}>	
// 								{clone_general_material_allowed?(<img onClick={json_item.disabled?null:clone_active_modal_onclick} title='Clone Selected item' style={{marginRight:'10px', cursor:'pointer'}} src="/resources/panel_icons_new/duplicate 16px.svg" height="16px" />):''}
// 								<img onClick={json_item.disabled?null:fetch_store_materials_onclick} title='Fetch from Store' style={{cursor:'pointer'}} src="/resources/icons/fetch_store.svg" height="20px" width="20px" />
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type=='composite_select'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled class_position_unset':'class_position_unset'}>
// 						<CardBody className='class_position_unset' style={{ padding:'0px', backgroundColor:COLORS.panel_card_item}} >
// 						<Row className='class_position_unset' style={{height:'50px',overflowY:'', margin:0}}>
// 							<Col className='class_position_unset' xs={9} style={{display:'flex',position:'unset', alignItems:"center", padding:'0px 5px 0px 10px', height:'100%'}}>
// 								{/* <Dropdown className='class_position_unset' isOpen={dropdownOpen} toggle={toggle}>
// 									<DropdownToggle caret>
// 										Dropdown
// 									</DropdownToggle>
// 									<DropdownMenu className='class_position_unset'>
// 										<DropdownItem header>Header</DropdownItem>
// 										<DropdownItem>Some Action</DropdownItem>
// 										<DropdownItem text>Dropdown Item Text</DropdownItem>
// 										<DropdownItem disabled>Action (disabled)</DropdownItem>
// 										<DropdownItem divider />
// 										<DropdownItem>Foo Action</DropdownItem>
// 										<DropdownItem>Bar Action</DropdownItem>
// 										<DropdownItem>Quo Action</DropdownItem>
// 									</DropdownMenu>
// 								</Dropdown> */}
// 								<CompositeDropdown composites={json_item.composites} active_index={get_active_index(json_item.composites,json_item.active_composite_id)} on_click={composite_select_onclick} json_item={json_item} />
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type == 'checkbox'){
//                 return(
//                     <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CB----------CB':'CB----------CB'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row style={{padding:'2px 0px'}} className='hover_to_gray2' onClick={() => {document.getElementById(json_item.id).click()}}>
// 							<Col xs={10} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px', paddingLeft: '8px'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 							{/* <Col xs={1} style={{paddingRight:'0px'}}>
// 								<FormGroup style={{marginBottom:0, width:'100%'}}>
// 									<Input onChange={common_onchange_handler} key={"key_"+json_item.id} id={json_item.id} type="checkbox" defaultChecked = {json_item.selected}></Input>
// 								</FormGroup>
// 							</Col> */}
// 							<Col xs={2} style={{paddingRight:'8px', paddingLeft:'0px'}}>
// 								<FormGroup style={{marginBottom:0, width:'100%', textAlign:'right'}}>
// 									<input onClick={(e) => {e.stopPropagation(); common_onchange_handler(); on_click()}} key={"key_"+json_item.id} id={json_item.id} type="checkbox" checked = {json_item.selected}></input>
// 								</FormGroup>
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				// 	<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CB----------CB':'CB----------CB'}>
// 				// 		<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 				// 		<Row>
// 				// 			<Col xs={12} className='zero_padding' style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
// 				// 				<div style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default",paddingLeft:'8px', paddingRight:'8px', flex:1}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
// 				// 				<div style={{paddingRight:'8px'}}>
// 				// 					{/* <FormGroup style={{marginBottom:0, width:'100%'}}>
// 				// 						<Input onChange={common_onchange_handler} key={"key_"+json_item.id} id={json_item.id} type="checkbox" defaultChecked = {json_item.selected}></Input>
// 				// 					</FormGroup> */}
// 				// 					<Switch style={{}} size='small' defaultChecked = {json_item.selected} onClick={on_click}/>

// 				// 				</div>
// 				// 			</Col>
// 				// 		</Row>
// 				// 		</CardBody>
// 				// </Card>
//                 )
//             }
// 			else if(json_item.type == 'text_info'){
//                 return(
//                     <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled TI----------TI':'TI----------TI'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col xs={3} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px', flexWrap:'wrap'}}><span title={json_item.key} className='lines2_elipsis' style={{textAlign:'left',fontWeight:500}}>{json_item.key}</span></Col>
// 							<Col xs={1} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px', flexWrap:'wrap'}}><span title={json_item.key} className='lines2_elipsis' style={{textAlign:'left'}}>{"-"}</span></Col>
// 							<Col xs={8} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px', flexWrap:'wrap'}}><span title={json_item.name} className='lines2_elipsis' style={{textAlign:'left'}}>{json_item.name}</span></Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
//                 )
//             }
// 			// else if(json_item.type == 'layer_select_layerset'){

// 			// }else if(json_item.type == 'layer_list_item'){
// 			// 	<div key={"key_"+json_item.id} id={json_item.id + "_button"} className={json_item.disabled?'panel_disabled':'hover_enabled_green_border LSL----------LSL'} onClick={json_item.disabled?null:on_click} style={{cursor:'pointer', height:42, width:'100%', display:'flex', flexDirection:'row', alignItems:'center', borderBottom: '2px solid', borderBottomColor:COLORS.panel_item_separation, padding:'15px', backgroundColor:COLORS.panel_card_item}}>
// 			// 			{	
// 			// 				json_item.icon?(
// 			// 					<span sm={2} style={{paddingRight:'10px'}}>
// 			// 						<img height='24px' width='24px' title={json_item.name} src={json_item.icon} />
// 			// 					</span>
// 			// 				):''
// 			// 			}	
// 			// 			<span sm={10}>
// 			// 				<span title={json_item.name} className='lines2_elipsis' id={json_item.id} style={{ fontSize:'12px', color:COLORS.black_left_menu }} >{json_item.name}</span>
// 			// 			</span>
// 			// 		</div>

// 			// }else if(json_item.type == 'add_layer'){

// 			// }
// 			else if(json_item.type == 'layerset_pop_up'){
// 				return(
// 					<Card style={{ border:'0px solid', backgroundColor:COLORS.gray3, padding:'0px 10px', height:'100%'}}>
// 						<div className='flex_property' style={{justifyContent:'space-between', fontSize:'14px', padding:'15px 5px 4px 5px'}}>
// 							<span style={{}}>Active layer set</span>
// 							<span style={{color:COLORS.gray9, fontSize:'12px'}}>View specific</span>
// 						</div>
// 						<div className='flex_property' style={{padding:'5px 10px', backgroundColor:COLORS.panel_card_item, justifyContent:'space-between', fontSize:'14px', height:'30px'}}>
// 							<span title={json_item && json_item.active_layerset ? json_item.active_layerset.name : ''} style={{}}>{json_item && json_item.active_layerset ? (json_item.active_layerset.name.length <= 25 ? json_item.active_layerset.name : json_item.active_layerset.name.substring(0, 25)+'...') : ''}</span>
// 							<div className='flex_property' >
// 								<i className={showing_all_layersets ? 'fa fa-angle-up' : 'fa fa-angle-down'} style={{cursor:'pointer', fontWeight:'bold', color:'black'}} onClick={() => {set_showing_layerset_settings(false);set_showing_all_layersets(!showing_all_layersets)}}/>
// 								<div id="tooltip1" class="bottom" style={{left:'10px', top:'46px', backgroundColor:'white', display:showing_all_layersets?'block':'none', boxShadow:'#efeaea 0px 4px 4px 0px', padding:'8px'}}>
// 									<div class="tooltip-arrow" style={{left:'84%', borderBottomColor:'white'}}></div>
// 									<div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.blue6, cursor:'pointer', textAlign:'left', paddingLeft:'8px'}} onClick={() => {set_modal_type('create_layerset_modal');set_modal_mode('panel');set_layerset_mode('create')}}>+ Create new layer set</div>
// 									{
// 										json_item && json_item.all_layersets && json_item.all_layersets.length ? json_item.all_layersets.map((single_layerset, idx) => (
// 											<div class="tooltip-label dropdown_visibility_on_hover flex_property display_onhover" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, justifyContent:'space-between', cursor:'pointer'}}>
// 												<div title={single_layerset.name} style={{flex:'6', textAlign:'left', overflowX:'auto', whiteSpace: 'nowrap'}} onClick={() => {window.Module.switch_layerset_onclick(single_layerset.id, 'panel'); update_view()}}>{single_layerset.name.length <= 25 ? single_layerset.name : single_layerset.name.substring(0,25)+'...'}</div>
// 												<div style={{flex:'2'}} className='flex_property dropdown_visibility_on_hover'>
// 													<img style={{cursor:'pointer', marginLeft:'4px', marginRight:'10px'}} width='16px' onClick={(e) => {e.stopPropagation();set_modal_type('create_layerset_modal');set_modal_mode('panel');set_layerset_mode('copy');set_given_layerset(single_layerset)}} className='dropdown_item_hide' src={single_layerset.copy_content}/>
// 													<img style={{cursor:'pointer', display:!single_layerset.delete_lock ? 'block' : 'none'}} width='16px' onClick={(e) => {e.stopPropagation(); window.Module.delete_layerset_onclick(single_layerset.id, 'panel');}} className='dropdown_item_hide' src={single_layerset.delete_content}/>
// 												</div>
// 											</div>
// 										)):''
// 									}
// 								</div>
								
// 								<img src={"/resources/nav_icons_new/settings 16px.svg"} style={{paddingLeft:'4px', marginLeft:'4px', cursor:'pointer', height:'17px', borderLeft:'1px solid #E6E9EE'}} onClick={() => {set_showing_all_layersets(false);set_showing_layerset_settings(!showing_layerset_settings)}}/>
// 								<div id="tooltip1" class="bottom" style={{left:'10px', top:'46px', backgroundColor:'white', display:showing_layerset_settings?'block':'none', boxShadow:'#efeaea 0px 4px 4px 0px', padding:'8px'}}>
// 									<div class="tooltip-arrow" style={{left:'92.5%', borderBottomColor:'white'}}></div>
// 									<div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, textAlign:'left', cursor:'pointer'}} onClick={() => {window.Module.toolbar_router("define_layerset"); update_view(); }} >Define</div>
// 									<div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, textAlign:'left', cursor:'pointer'}} onClick={(e) => {e.stopPropagation();set_modal_type('create_layerset_modal');set_modal_mode('panel');set_layerset_mode('rename');set_given_layerset(json_item.active_layerset)}}>Rename</div>
// 									<div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, textAlign:'left', cursor:'pointer'}} onClick={() => window.Module.toolbar_router("unhide_all_layers_layerset")}>UnHide all layers in this set</div>
// 									{/* <div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, textAlign:'left', cursor:'pointer'}}>UnLock all layers in this set</div> */}
// 									<div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:'#FF5247', textAlign:'left', cursor:'pointer'}} onClick={(e) => {e.stopPropagation(); window.Module.delete_layerset_onclick(json_item.active_layerset.id, 'panel');}}>Delete this layer set</div>
// 								</div>
// 							</div>
// 						</div>
// 						<div className='flex_property' style={{padding:'5px 10px', backgroundColor:COLORS.panel_card_item, justifyContent:'space-between', fontSize:'12px', marginTop:'8px', marginBottom:'4px', height:'30px'}}>
// 							<span style={{color:COLORS.blue6, cursor:'pointer'}} onClick={() => {set_modal_type('create_layer_modal'); set_modal_mode('panel')}}>+ Add Layer</span>
// 							<span style={{color:COLORS.gray9}}>{json_item && json_item.layers_in_design ? json_item.layers_in_design.length : 0} Layers</span>
// 						</div>
// 						<div style={{backgroundColor:COLORS.panel_card_item, fontSize:'12px', padding:'5px 10px'}}>
// 						{
// 							json_item && json_item.layers_in_design && json_item.layers_in_design.length ? json_item.layers_in_design.map((single_layer, idx) => (
// 								<div className='flex_property' style={{height:'30px', width:'100%'}}>
// 									<img src={json_item.active_layerset && json_item.active_layerset.layers && json_item.active_layerset.layers[single_layer.id]['visible'] ? json_item.show_content : json_item.hide_content} style={{height:'16px', width:'16px',marginRight:'8px', cursor:'pointer'}} onClick={() => {window.Module.toggle_layer_visibility_onclick(single_layer.id, 'panel')}}/>
// 									{single_layer.name}
// 								</div>
// 							)):''
// 						}
// 						</div>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type == 'special_dimension_input'){
// 				// console.log(json_item, "panel json")
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled':'dI----------dI'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col style={{display:'flex',justifyContent:'center', alignItems:"center", paddingLeft:'8px', paddingRight:'8px'}}>
// 							{metric_system=='mm'?(
// 								<div style={{width:'100%'}}>
// 									<Row style={{width:'100%', marginLeft:'0px', marginBottom:'8px'}}>
// 										<div style={{width:'100%', display:'flex', alignItems:'center'}}>
// 											{json_item.name}
// 										</div>
// 									</Row>
// 									<Row style={{width:'100%', marginLeft:'0px', marginBottom:'15px'}}>
// 										{/* <Col sm={6} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 										<Col sm={6} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}><Input type="text" onChange={common_onchange_handler} id={json_item.id} key={"key_"+json_item.id} defaultValue={approxMM(json_item.default_value)||0} style={styles_panel.input_styles} placeholder="0"/></Col> */}
// 										<Col xs={4} style={{paddingRight:'8px', paddingLeft:'8px'}}>
// 											<div style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.black, padding:'0px 4px'}}>
// 												<div style={{flex:1, color:'#FF5247', fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>X</div>
// 												<Input type="text" onChange={common_onchange_handler} onBlur={on_click} defaultValue={approxMM(json_item[0].default_value)||0} key={"key_" + json_item[0].id} id={json_item[0].id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'22px'}} placeholder="0"/>
// 											</div>
// 										</Col>
// 										<Col xs={4} style={{paddingRight:'8px', paddingLeft:'8px'}}>
// 											<div style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.black, padding:'0px 4px'}}>
// 												<div style={{flex:1, color:'#23C16B', fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>Y</div>
// 												<Input type="text" onChange={common_onchange_handler} onBlur={on_click} defaultValue={approxMM(json_item[1].default_value)||0} key={"key_" + json_item[1].id} id={json_item[1].id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'22px'}} placeholder="0"/>
// 											</div>
// 										</Col>
// 										<Col xs={4} style={{paddingRight:'8px', paddingLeft:'8px'}}>
// 											<div style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.black, padding:'0px 4px'}}>
// 												<div style={{flex:1, color:'#4597F7', fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>Z</div>
// 												<Input type="text" onChange={common_onchange_handler} onBlur={on_click} defaultValue={approxMM(json_item[2].default_value)||0} key={"key_" + json_item[2].id} id={json_item[2].id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'22px'}} placeholder="0"/>
// 											</div>
// 										</Col>
// 									</Row>
// 								</div>
// 							):(
// 								<div>
// 									<Row style={{width:'100%', marginLeft:'0px', marginBottom:'8px'}}>
// 										<div style={{width:'100%', display:'flex', alignItems:'center'}}>
// 											{json_item.name}
// 										</div>
// 									</Row>

// 									<Row>
// 										<Col sm={5} style={{fontSize:13, textAlign:'left', cursor:'default'}}></Col>
// 										<Col sm={2} className='zero_padding'>
// 											<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span>
// 										</Col>
// 										<Col sm={2} className='zero_padding'>
// 											<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span>
// 										</Col>
// 										<Col sm={2} className='zero_padding'>
// 											<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span>
// 										</Col>
// 									</Row>


// 									<Row style={{marginBottom:'8px'}}>
// 										<Col sm={5} style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item[0].name} className='lines2_elipsis' >{json_item[0].name}</span></Col>
// 										<Col sm={6} className='zero_padding' style={{border:'1px solid ' + COLORS.black}}>
// 											<Row style={{marginLeft:0, marginRight:0}}>
// 												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
// 													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[0].default_value)[0]||0} key={"key_" + json_item[0].id + "_ft"} id={json_item[0].id + "_ft"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
// 													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
// 												</Col>
// 												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
// 													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[0].default_value)[1]||0} key={"key_" + json_item[0].id + "_in"} id={json_item[0].id + "_in"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
// 													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
// 												</Col>
// 												<Col sm={4} className='zero_padding'>
// 													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[0].default_value)[2]||0} key={"key_" + json_item[0].id + "_parts"} id={json_item[0].id + "_parts"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
// 													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
// 												</Col>
// 											</Row>
// 										</Col>
										
// 									</Row>

// 									<Row style={{marginBottom:'8px'}}>
// 										<Col sm={5} style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item[1].name} className='lines2_elipsis' >{json_item[1].name}</span></Col>
// 										<Col sm={6} className='zero_padding' style={{border:'1px solid ' + COLORS.black}}>
// 											<Row style={{marginLeft:0, marginRight:0}}>
// 												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
// 													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[1].default_value)[0]||0} key={"key_" + json_item[1].id + "_ft"} id={json_item[1].id + "_ft"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
// 													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
// 												</Col>
// 												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
// 													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[1].default_value)[1]||0} key={"key_" + json_item[1].id + "_in"} id={json_item[1].id + "_in"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
// 													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
// 												</Col>
// 												<Col sm={4} className='zero_padding'>
// 													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[1].default_value)[2]||0} key={"key_" + json_item[1].id + "_parts"} id={json_item[1].id + "_parts"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
// 													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
// 												</Col>
// 											</Row>
// 										</Col>
// 									</Row>

// 									<Row style={{marginBottom:'8px'}}>
// 										<Col sm={5} style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item[2].name} className='lines2_elipsis' >{json_item[2].name}</span></Col>
// 										<Col sm={6} className='zero_padding' style={{border:'1px solid ' + COLORS.black}}>
// 											<Row style={{marginLeft:0, marginRight:0}}>
// 												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
// 													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[2].default_value)[0]||0} key={"key_" + json_item[2].id + "_ft"} id={json_item[2].id + "_ft"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
// 													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
// 												</Col>
// 												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
// 													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[2].default_value)[1]||0} key={"key_" + json_item[2].id + "_in"} id={json_item[2].id + "_in"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
// 													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
// 												</Col>
// 												<Col sm={4} className='zero_padding'>
// 													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[2].default_value)[2]||0} key={"key_" + json_item[2].id + "_parts"} id={json_item[2].id + "_parts"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
// 													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
// 												</Col>
// 											</Row>
// 										</Col>
// 									</Row>
// 								</div>
								
// 							)}
// 						<br/>
// 					</Col></Row>
// 						</CardBody>
// 					</Card>
					
// 				)
// 			}
// 			else if(json_item.type=='layer_input'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled SI----------SI':'SI----------SI'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row style={{/*marginBottom:'15px'*/}}>
// 							<Col xs={4} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", paddingLeft: '8px', paddingRight: '8px'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 							<Col xs={8} style={{display:"flex", alignItems:"center", justifyContent:'center', paddingLeft: '8px', paddingRight: '8px'}}>
// 								<FormGroup style={{marginBottom:0, width:'100%', border:'1px solid ' + COLORS.panel_item_borders}}>
// 									<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} type="select" defaultValue={json_item.default_value} style={{...styles_panel.input_styles , border:'none'}}>
// 										{json_item.options.map((o,idx) => (
// 											<option key={idx} value={o.value}>{o.name}</option>
// 										))}
// 									</Input>
// 								</FormGroup>
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			// else if(json_item && json_item.type=='dropdown_light'){
// 			// 	return(
// 			// 		<div style={{width: '100%', overflow: 'hidden'}} className={json_item.disabled?'panel_disabled':''}>
						
// 			// 			<div className={json_item.submenu && json_item.submenu.length?'':'panel_disabled'} onClick={json_item.disabled || !(json_item.submenu && json_item.submenu.length) ?null:dropdown_on_click} style={{cursor:"pointer",height:'42px', width:'100%',padding:'0px 15px', overflow: 'hidden', display:'flex', flexDirection:'row', alignItems:'center', borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation, borderRightColor:COLORS.blue6, borderRightWidth: is_dropdown_open()?'5px':'0px', borderRightStyle:'solid', backgroundColor:COLORS.toolbar_background_color}}>
// 			// 				{	
// 			// 					json_item.icon?
// 			// 					<span style={{paddingRight:'10px', flex:1}}>
// 			// 						<img height='24px' width='24px' style={{maxHeight: '100%', overflow: 'hidden'}} title={json_item.name} src={json_item.icon} />
// 			// 					</span>	
// 			// 					:''
// 			// 				}
							
// 			// 				<span style={{flex:8 , textAlign:'left' }} >
// 			// 					<span id={json_item.id} title={json_item.name} className='lines2_elipsis' style={{ fontSize:'14px', color:COLORS.black_left_menu}} >{json_item && json_item.name ? json_item.name:''}</span>
// 			// 				</span>
// 			// 				<span style={{flex:3}}>
// 			// 					<FormGroup style={{marginBottom:0, width:'100%'}}>
// 			// 						<Input onChange={common_onchange_handler} type="checkbox"></Input>
// 			// 					</FormGroup>
// 			// 					<img src={"/resources/nav_icons_new/delete 16px.svg"} style={{paddingLeft:'4px', marginLeft:'4px', cursor:'pointer', height:'17px', borderLeft:'1px solid #E6E9EE'}}/>
// 			// 					<span style={{ fontSize:'14px', color:COLORS.black_left_menu }} ><b>{!is_dropdown_open()?<i style={{fontSize:'12px', color: COLORS.black_left_menu}} className="fa fa-chevron-down"/>:<i className="fa fa-chevron-up"/>}</b></span>
// 			// 				</span>
// 			// 			</div>
// 			// 			<Collapse className='d--------------------d' isOpen={is_dropdown_open()} navbar={true} style={{ width:'100%', overflowX: 'hidden'}}>
// 			// 				<Nav navbar style={{borderLeft: '3px solid', borderLeft:'4px solid', borderLeftColor:'#A7A8B2', width:'100%', overflowX: 'hidden' }}>
								
// 			// 					{
// 			// 						json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item,idx) =>(
// 			// 							<NavItem key={json_item.id+idx} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
// 			// 								<Create_card set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} key={json_item.id+idx} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} parent_id={json_item.id} parent_menu={json_item.submenu} update_view={update_view} json_item={sub_item} set_panel_has_unapplied_edits={set_panel_has_unapplied_edits} approxMM={approxMM} mmToFeetParts={mmToFeetParts} panel_explode = {panel_explode}/>
// 			// 							</NavItem>
// 			// 						)):''
									
// 			// 					}	
								
// 			// 				</Nav>
// 			// 			</Collapse>
// 			// 		</div>		
// 			// 	)
// 			// }
// 			else if(json_item.type == 'rotation_input'){
// 				// const [rotation_input, set_rotation_input] = useState(0);
// 				// return (
// 				// 	<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }}  className={json_item.disabled?'panel_disabled':'RI----------RI'}>
// 				// 		<CardBody style={{ padding:'15px',paddingBottom:0,/* paddingTop:index ? 0 : 15,*/ backgroundColor:COLORS.panel_card_item}} >
// 				// 		<Row>
// 				// 			<Col style={{fontSize:14,alignItems:"center", cursor:"default", paddingLeft:'8px', marginBottom:'8px', fontWeight:700, color:COLORS.panel_group_heading, display: json_item.onchange_route ? 'flex' : 'none'}}><span title={json_item.name} className='lines2_elipsis' >{"Rotation(deg)"}</span></Col>
// 				// 		</Row>
// 				// 		<Row style={{/*marginBottom:'15px'*/}}>
// 				// 			<Col xs={4} style={{fontSize:14,display:'flex',alignItems:"center"}}>
// 				// 				<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}>
// 				// 					<div style={{display:'flex', width:'100%', border:json_item.onchange_route ? '1px solid ' + COLORS.panel_item_borders : 'none', padding:'0px 4px'}}>
// 				// 						<div style={{flex:1, color:json_item.onchange_route ? '#FF5247' : COLORS.panel_group_heading, fontWeight: json_item.onchange_route ? 400 : 700, fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name}</div>
// 				// 						<input key={"key_"+json_item.id} value={rotation_input} onChange={(e) => {common_onchange_handler(); set_rotation_input(e.target.value)}} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=json_item.default_value || 0 ; e.target.blur()}}} defaultValue={json_item.default_value||0} type="number"  id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'22px', outline:'none', display:json_item.onchange_route ? 'block' : 'none'}} />
// 				// 					</div>
// 				// 				</div>
// 				// 			</Col>
// 				// 			<Col xs={8} style={{fontSize:14,display:'flex',alignItems:"center", justifyContent:'flex-end', padding:'0px 8px'}}>
// 				// 				{/* {
// 				// 					json_item.buttons && json_item.buttons.length ? json_item.buttons.map(item => (
// 				// 						<img src={item.icon} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); window.Module[item.onclick](JSON.stringify({id: item.name}))}}/>
// 				// 					))
// 				// 					:''
// 				// 				} */}
// 				// 				<span style={{ marginRight:'8px', fontSize:'10px', maxWidth:'110px', textOverflow:'ellipsis', color:COLORS.gray5}} title={json_item.text_content ? json_item.text_content : ''}>{json_item.onchange_route ? "Rotate by " + (json_item.text_content ? json_item.text_content : '90') + ' deg' : json_item.text_content}</span>
// 				// 				<img src={"/resources/panel_icons_new/rotate left.svg"} style={{ marginRight:'8px', cursor:'pointer', height:'20px'}} onClick={json_item.onchange_route ? () => {common_onchange_handler(); set_rotation_input(rotation_input - (json_item.text_content)); /*icon_on_click({route: json_item.left_rotation_route, form:{id: json_item.left_id}})*/ on_click() } : () => {icon_on_click({route: json_item.left_rotation_route, form:{id: json_item.left_id}})} }/>
// 				// 				<img src={"/resources/panel_icons_new/rotate right.svg"} style={{ marginRight:'8px', cursor:'pointer', height:'20px'}} onClick={json_item.onchange_route ? () => {common_onchange_handler(); set_rotation_input(rotation_input - ('-' + json_item.text_content)); /*icon_on_click({route: json_item.right_rotation_route, form:{id: json_item.right_id}})*/ on_click()} : () => {icon_on_click({route: json_item.right_rotation_route, form:{id: json_item.right_id}})}}/>

// 				// 			</Col>
// 				// 		</Row>
// 				// 		</CardBody>
// 				// 	</Card>
// 				// )

// 				return(
// 					<RotationInput json_item={json_item} on_click={on_click} common_onchange_handler={common_onchange_handler} icon_on_click={icon_on_click}/>
// 				)
// 			}
// 			else if(json_item.type=='select_and_dimension_input'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled SDI----------SDI':'SDI----------SDI'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 							<Row>
// 								<Col xs={json_item.full_select ? 6 : 0} style={{fontSize:'11px', display:json_item.full_select ? 'flex' : "none", alignItems:"center", cursor:"default", padding:'8px'}}><span title={json_item.name_select} className='lines1_elipsis' >{json_item.name_select}</span></Col>
// 								<Col xs={json_item.full_select ? 6 : 0} style={{fontSize:'11px', display:json_item.full_select ? 'flex' : "none", alignItems:"center", cursor:"default", padding:'8px'}}><span title={json_item.name_dim} className='lines1_elipsis' >{json_item.name_dim}</span></Col>
// 							</Row>

// 						<Row style={{/*marginBottom:'15px'*/}}>
// 							<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center', paddingLeft:'8px', paddingRight:'0px'}}>
// 								<Row style={{/*marginBottom:'15px'*/border:'1px solid ' + COLORS.panel_item_borders, margin:0, padding:'0px 4px', width:'100%'}}>
// 									<Col xs={json_item.full_select ? 0 : 6} style={{fontSize:'11px', display:json_item.full_select ? 'none' : "flex", alignItems:"center", cursor:"default", padding:'0px'}}><span title={json_item.name_select} className='lines1_elipsis' >{json_item.name_select}</span></Col>
// 									<Col xs={json_item.full_select ? 12 : 6} style={{display:"flex", alignItems:"center", justifyContent:'center', padding:0}}>
// 										<FormGroup style={{marginBottom:0, width:'100%'}}>
// 											<Input onChange={json_item.apply_on_select ? () => {common_onchange_handler(); on_click()} : () => {common_onchange_handler()}} key={"key_"+json_item.id_select + 'st'} id={json_item.id_select + 'st'} type="select" defaultValue={json_item.default_value_select} style={{...styles_panel.input_styles , border:'none', height:'24px'}}>
// 												{json_item.options_select.map((o,idx) => (
// 													<option key={idx} value={o.value}>{o.name}</option>
// 												))}
// 											</Input>
// 										</FormGroup>
// 									</Col>
// 								</Row>
// 							</Col>
// 							<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default"}}>
// 							{metric_system=='mm'?(
// 										<div style={{paddingRight:'8px', paddingLeft:'0px', marginLeft: -15, marginRight: -15}}>
// 											<div style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}>
// 												<div className='lines1_elipsis' style={{flex:1, color: json_item.color ? json_item.color : COLORS.gray3 , fontSize:'11px', display:json_item.full_select ? 'none' :'flex', alignItems:'center', justifyContent:'center'}} title={json_item.name_dim}>{json_item.name_dim && json_item.name_dim.length ? json_item.name_dim[0] : ''}</div>
// 												<Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value_dim) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value_dim)||0} key={"key_" + json_item.id_dim} id={json_item.id_dim} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/>
// 											</div>
// 										</div>
										
// 								):(
// 									<Row style={{paddingLeft:'8px', paddingRight:'8px'}}>
// 										{/* <Col sm={5} className='zero_padding' style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col> */}
// 										<Col sm={12} style={{border:'1px solid ' + COLORS.panel_item_borders}}>
// 											{/* <Row style={{marginLeft:0, marginRight:0}}>
// 												<Col sm={12} className='zero_padding' style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 											</Row> */}
// 											<Row style={{padding:'0px 4px'}}>
												
// 												<Col sm={json_item.full_select ? 0 : 1} className='zero_padding' style={{fontSize:13, textAlign:'left', cursor:'default', display:json_item.full_select ? 'none' :'flex', alignItems:'center', textAlign:'center', justifyContent:'center', color:json_item.color ? json_item.color: COLORS.gray3}}><span title={json_item.name_dim} className='lines2_elipsis' >{json_item.name_dim && json_item.name_dim.length ? json_item.name_dim[0] : ''}</span></Col>

// 												<Col xs={json_item.full_select ? 12 : 11} className='zero_padding'>
// 													<FocusWithin onBlur={on_click} style={{display:'flex', width:'100%'}}>
// 														<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.panel_item_borders}}>
// 															<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value_dim)[0] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value_dim)[0]||0} key={"key_" + json_item.id_dim + "_ft"} id={json_item.id_dim + "_ft"} style={{...styles_panel.input_styles, border: 'none', height: '24px'}} placeholder="0"/>
// 															{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
// 														</Col>
// 														<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.panel_item_borders}}>
// 															<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value_dim)[1] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value_dim)[1]||0} key={"key_" + json_item.id_dim + "_in"} id={json_item.id_dim + "_in"} style={{...styles_panel.input_styles, border: 'none', height: '24px'}} placeholder="0"/>
// 															{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
// 														</Col>
// 														<Col sm={4} className='zero_padding'>
// 															<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value_dim)[2] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value_dim)[2]||0} key={"key_" + json_item.id_dim + "_parts"} id={json_item.id_dim + "_parts"} style={{...styles_panel.input_styles, border: 'none', height: '24px'}} placeholder="0"/>
// 															{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
// 														</Col>
// 													</FocusWithin>
// 												</Col>
// 											</Row>
// 										</Col>
// 									</Row>
// 								)}
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type == 'toggle'){
//                 return(
//                     <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled TG----------TG':'TG----------TG'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col xs={12} className='zero_padding' style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
// 								<div style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default",paddingLeft:'8px', paddingRight:'8px', flex:1}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
// 								<div style={{paddingRight:'8px'}}>
// 									{/* <FormGroup style={{marginBottom:0, width:'100%'}}>
// 										<Input onChange={common_onchange_handler} key={"key_"+json_item.id} id={json_item.id} type="checkbox" defaultChecked = {json_item.selected}></Input>
// 									</FormGroup> */}
// 									<Switch style={{}} size='small' key={"key_"+json_item.id} id={json_item.id} checked = {json_item.value} onClick={() => {on_click(); common_onchange_handler()}}/>

// 								</div>
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
//                 )
//             }
// 			else if(json_item.type == 'child_tabs'){
// 				// console.log("childtabs", json_item)
// 				// const [active_tab, set_active_tab] = useState('');
//                 // return(
//                 //     <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CT----------CT':'CT----------CT'}>
// 				// 		<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 				// 		<Row>
// 				// 			<Col xs={12} className='zero_padding' style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
// 				// 				<div style={{padding:'0px 8px', display:'flex', flexFlow: 'row', flexWrap:'wrap', width:'100%'}}>
// 				// 					{
// 				// 						json_item.tabs.map((child_tab, idx) => (
// 				// 							<div key={idx} style={check_dropdown_state(child_tab.id) ? {padding:'4px 0px', margin:'0px 8px', fontSize:'12px', fontWeight:700, borderBottom:'1px solid ' + COLORS.blue6, color:COLORS.blue6, display:'flex'} : {padding:'4px 0px', margin:'0px 8px', fontSize:'12px', borderBottom:'1px solid #E6E9EE', color: COLORS.panel_group_heading, display:'flex'}} onClick={check_dropdown_state(child_tab.id) ? null : () => {tab_on_click(active_tab); set_active_tab(child_tab.id); tab_on_click(child_tab.id)}} >
// 				// 								<div style={{marginRight: '8px'}}>{child_tab.name}</div>
// 				// 								{
// 				// 									child_tab.buttons && child_tab.buttons.length ? child_tab.buttons.map(item => (
// 				// 										item.type == "switch" ? 
// 				// 											<Switch style={{marginRight:'8px', color:'green'}} size='small' checked = {item.value == "Off" ? false : true} onClick={(checked, e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
// 				// 										: item.type == 'label' ? 
// 				// 											<span style={{cursor:'pointer',marginRight:'4px', fontSize:'12px', color:COLORS.black, textTransform:'capitalize', fontWeight:400}}>{item.name}</span>
// 				// 										: item.icon == "+" ?
// 				// 											<span style={{cursor:'pointer',marginRight:'8px', fontSize:'12px', color:COLORS.blue6, textTransform:'capitalize'}} onClick={(e) => {is_dropdown_open() ? e.stopPropagation() : null; icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}>+ Add </span>
// 				// 										:
// 				// 											<img src={item.icon} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
// 				// 									))
// 				// 									: ''
// 				// 								}
// 				// 							</div>
// 				// 							))
// 				// 					}
// 				// 				</div>
// 				// 			</Col>

// 				// 			{
// 				// 				json_item.tabs.map((child_tab, idx) => (
// 				// 					<div key={idx} className='d--------------------d' isOpen={check_dropdown_state(child_tab.id)} navbar={true} style={{ width:'100%', overflowX: 'hidden', display:check_dropdown_state(child_tab.id) ? 'block' : 'none'}}>
// 				// 						<Nav navbar style={{width:'100%', overflowX: 'hidden' }}>
											
// 				// 							{
// 				// 								child_tab.submenu && child_tab.submenu.length ? child_tab.submenu.map((sub_item,idx) =>(
// 				// 									<NavItem key={child_tab.id+idx} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
// 				// 										<Create_card 
// 				// 											panel_key={panel_key}
// 				// 											set_page_loader={set_page_loader}
// 				// 											handle_ui_response={handle_ui_response}
// 				// 											key={child_tab.id+idx}
// 				// 											metric_system={metric_system}
// 				// 											approximation_mm={approximation_mm}
// 				// 											approximation_ft={approximation_ft}
// 				// 											parent_id={child_tab.id}
// 				// 											parent_menu={child_tab.submenu}
// 				// 											update_view={update_view}
// 				// 											json_item={sub_item}
// 				// 											set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 				// 											approxMM={approxMM}
// 				// 											mmToFeetParts={mmToFeetParts}
// 				// 											panel_explode={panel_explode}
// 				// 											is_grouped={child_tab.group_child_inputs}
// 				// 											index={idx}
// 				// 											configuration_type={child_tab.configuration_type}/>
// 				// 									</NavItem>
// 				// 								)):''
												
// 				// 							}
// 				// 							{/* {
// 				// 								json_item.submenu && json_item.submenu.length && !(json_item.submenu[json_item.submenu.length-1].type == 'list-item' || json_item.submenu[json_item.submenu.length-1].type == 'dropdown' || json_item.submenu[json_item.submenu.length-1].type == 'button') ?
// 				// 									<Create_card 
// 				// 										panel_key={panel_key}
// 				// 										set_page_loader={set_page_loader}
// 				// 										handle_ui_response={handle_ui_response}
// 				// 										metric_system={metric_system}
// 				// 										approximation_mm={approximation_mm}
// 				// 										approximation_ft={approximation_ft}
// 				// 										// parent_menu={panel_json}
// 				// 										update_view={update_view}
// 				// 										json_item={{type:'margin_bottom'}}
// 				// 										set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 				// 										approxMM={approxMM}
// 				// 										mmToFeetParts={mmToFeetParts}
// 				// 										panel_explode={false}
// 				// 									/>
// 				// 								:''
// 				// 							}	 */}
// 				// 						</Nav>
// 				// 					</div>
// 				// 				))

// 				// 			}
// 				// 		</Row>
// 				// 		</CardBody>
// 				// 	</Card>
//                 // )
// 				return(
// 					<TabDropdown 
// 						json_item={json_item}
// 						icon_on_click={icon_on_click}
// 						panel_key={panel_key}
// 						set_page_loader={set_page_loader}
// 						handle_ui_response={handle_ui_response}
// 						metric_system={metric_system}
// 						approximation_mm={approximation_mm}
// 						approximation_ft={approximation_ft}
// 						update_view={update_view}
// 						set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
// 						approxMM={approxMM}
// 						mmToFeetParts={mmToFeetParts}
// 						panel_explode={panel_explode}
// 						tab_on_click={tab_on_click}
// 					/>
// 				)

//             }
// 			else if(json_item.type == 'button_array'){
//                 return(
//                     <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled BA----------BA':'BA----------BA'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col xs={12} className='zero_padding' style={{display: 'flex', alignItems:'center', flexWrap:'wrap'}}>
// 								{
// 									json_item.buttons && json_item.buttons.length ? json_item.buttons.map((item, idx) =>(
// 										<img src={item.icon} key={idx} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.route, form:{id: item.id}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
// 									))
// 									:''
// 								}
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
//                 )
//             }
// 			else if(json_item.type == 'margin_bottom'){
// 				return(<div style={{height: json_item.margin ? json_item.margin : '16px', borderBottom: json_item.border ? json_item.border : '1px solid #CED4D9', margin: inside_tab ? '0px 8px' : '0px'}}></div>)
// 			}
// 			else if(json_item.type == 'demarcator'){
// 				return(<div style={{height: '16px', backgroundColor:COLORS.gray3}}></div>)
// 			}
// 			else if(json_item.type == 'snap_text'){
// 				return(<div style={{textAlign:'center', padding:'12px 8px', fontSize:'12px', color:COLORS.gray5}}>Press <span style={{fontWeight: 500, fontStyle:'italic'}}>shift</span> to temporarily disable snaps </div>)
// 			}
// 			else{
// 				return(<div></div>)
// 			}
// 		}	
// 	}

// 	return( 
// 		<div style={{width: json_item.type == 'list-item' && is_grouped ? 'auto' : '100%', overflowX: 'hidden'}}>
// 			{type_fn()}
// 		</div>
// 	)
// }


// const ReferenceLevellDropdown = ({ json_item, on_click }) => {
//     const [dropdownOpen, setDropdownOpen] = useState(false);
//     // const [rename_enabled, set_rename_enabled] = useState(false);

//     const toggle = () => setDropdownOpen(prevState => !prevState);

//     return (
//         <Row className='class_position_unset' style={{margin:0}}>
//             <Col className='class_position_unset' style={{padding:0, display:'flex', alignItems:'center'}}>
//                 <Dropdown direction="up" className='class_position_unset' nav isOpen={dropdownOpen} style={{listStyle: 'none'}} toggle={json_item && json_item.disabled?null:toggle}>
//                     <DropdownToggle  style={{display:'flex',flexFlow:'row', backgroundColor:'white',cursor:'pointer', color:'black', fontSize:'13px', padding:'4px 10px', border:0}}>
//                         <span><i className='fa fa-caret-down' /></span>
//                     </DropdownToggle>
//                     <DropdownMenu className='ref_level_dropdown_menu'>
//                         {json_item&&json_item.ref_level_options?([{name:"Value" + (json_item.relative_ref_level?" (Fixed)":(json_item.global_ref_level?" (From Global Origin)":" (From Floor Base Top)")),value:"no_ref_level"}]).concat(json_item.ref_level_options).map((o,idx) => (
//                             <DropdownItem style={{listStyle: 'none'}} onClick={() => {on_click?on_click(o.value):''}} key={idx}>
//                                 <div style={{display:'flex',flexFlow:'row',fontSize:'13px'}}>
//                                     <div style={{paddingTop:'5px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{json_item.relative_ref_level&&o.value!="no_ref_level"?'Up to ' + o.name:o.name}</div>
//                                 </div>
//                             </DropdownItem>
//                         )):''}
//                     </DropdownMenu>
//                 </Dropdown>
                
//             </Col>
//         </Row>
//     );
// }

// const RefLevelDimensionInput = ({json_item, common_onchange_handler, metric_system, approximation_mm, approximation_ft, mmToFeetParts, approxMM}) => {
// 	const [ ref_level_value, set_ref_level_value ] = useState("");


// 	const get_active_ref_level_name = () => {
// 		if((!ref_level_value) || ref_level_value=="no_ref_level"){
// 			return ("Value" + (json_item.relative_ref_level?" (Amount)":(json_item.global_ref_level?" (From Global Origin)":" (From Floor Base Top)")));
// 		}else if(json_item.ref_level_options&&json_item.ref_level_options.find(o=>o.value == ref_level_value)){
// 			var name_find = json_item.ref_level_options.find(o=>o.value == ref_level_value).name + " with offset ";
// 			return json_item.relative_ref_level?"Up to "+name_find:name_find;
// 		}else{
// 			return 'Reference Level Deleted';
// 		}
// 	}

// 	const dropdown_on_click = (val) => {
// 		set_ref_level_value(val);
// 	}

// 	useEffect(() => {
// 		set_ref_level_value(json_item.default_ref_level);
// 	},[]);

// 	return (
// 		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled class_position_unset':'dI----------dI class_position_unset'}>
// 			<CardBody className='class_position_unset' style={{ padding:'11px',paddingBottom:0, backgroundColor: COLORS.panel_card_item}} >
// 			{/* <Row> */}
// 				{/* <Col style={{display:'flex',justifyContent:'center', alignItems:"center"}}> */}
// 				<Input type="text" value={ref_level_value} style={{display:"none"}} key={"key_"+json_item.id + "_ref_level_select"} id={json_item.id + "_ref_level_select"}/>
// 				<Row className='class_position_unset' style={{width:'100%'}}>
// 					<Col className='class_position_unset' sm={12} style={{fontSize:13, display:"flex", alignItems:"left",justifyContent:'left', width:'100%', cursor:"default"}}>
// 						<span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span>
// 					</Col>
// 					<Col className='class_position_unset' sm={12} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', cursor:"default"}}>
// 						<div style={styles_panel.input_styles}>{get_active_ref_level_name()}</div>
// 					</Col>
// 					{metric_system=='mm'?(
// 						<React.Fragment>
// 							{((!ref_level_value) || ref_level_value=="no_ref_level")?(
// 								<Col className='class_position_unset' sm={10} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}>
// 									<Input type="text" id={json_item.id} key={"key_"+json_item.id} onChange={common_onchange_handler} defaultValue={approxMM(json_item.default_value)||0} style={styles_panel.input_styles} placeholder="0"/>
// 								</Col>
// 							):(
// 								<Col className='class_position_unset' sm={10} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}>
// 									<Input type="text" id={json_item.id + "_offset"} key={"key_"+json_item.id + "_offset"} onChange={common_onchange_handler} defaultValue={approxMM(json_item.default_ref_level_offset)||0} style={styles_panel.input_styles} placeholder="0"/>
// 								</Col>
// 							)}
// 						</React.Fragment>
// 					):(
// 						<React.Fragment>
// 							{((!ref_level_value) || ref_level_value=="no_ref_level")?(
// 								<React.Fragment>
// 									<Col className='class_position_unset' sm={3}>
// 										<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[0]||0} key={"key_" + json_item.id + "_ft"} id={json_item.id + "_ft"} style={styles_panel.input_styles} placeholder="0"/>
// 										<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span>
// 									</Col>
// 									<Col className='class_position_unset' sm={3}>
// 										<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[1]||0} key={"key_" + json_item.id + "_in"} id={json_item.id + "_in"} style={styles_panel.input_styles} placeholder="0"/>
// 										<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span>
// 									</Col>
// 									<Col className='class_position_unset' sm={3}>
// 										<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[2]||0} key={"key_" + json_item.id + "_parts"} id={json_item.id + "_parts"} style={styles_panel.input_styles} placeholder="0"/>
// 										<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span>
// 									</Col>
// 									<Col className='class_position_unset' sm={1} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}>

// 									</Col>
// 								</React.Fragment>
// 							):(
// 								<React.Fragment>
// 									<Col className='class_position_unset' sm={3}>
// 										<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[0]||0} key={"key_" + json_item.id + "_offset" + "_ft"} id={json_item.id + "_offset" + "_ft"} style={styles_panel.input_styles} placeholder="0"/>
// 										<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span>
// 									</Col>
// 									<Col className='class_position_unset' sm={3}>
// 										<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[1]||0} key={"key_" + json_item.id + "_offset" + "_in"} id={json_item.id + "_offset" + "_in"} style={styles_panel.input_styles} placeholder="0"/>
// 										<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span>
// 									</Col>
// 									<Col className='class_position_unset' sm={3}>
// 										<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[2]||0} key={"key_" + json_item.id + "_offset" + "_parts"} id={json_item.id + "_offset" + "_parts"} style={styles_panel.input_styles} placeholder="0"/>
// 										<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span>
// 									</Col>
// 									<Col className='class_position_unset' sm={1} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}>

// 									</Col>
// 								</React.Fragment>
// 							)}
// 						</React.Fragment>
// 					)}
// 					<Col className='class_position_unset' sm={2}>
// 						<ReferenceLevellDropdown json_item={json_item} on_click={dropdown_on_click} />
// 					</Col>
// 				</Row>
// 				{/* </Col> */}
// 			{/* </Row> */}
// 			</CardBody>
// 		</Card>
// 	);
// }

// const Create_card = ({parent_menu,panel_key, json_item, set_panel_has_unapplied_edits,update_view, metric_system, approximation_mm, parent_id, approximation_ft, set_page_loader,handle_ui_response, approxMM, mmToFeetParts, set_modal_mode, set_modal_type, set_layerset_mode, set_given_layerset}) => {
// 	const [ dropdownOpen, setDropdownOpen ] = useState(false);

// 	const toggle = () => setDropdownOpen(!dropdownOpen);
// 	const [collapsed, setCollapsed] = useState(true);
// 	const [showing_all_layersets, set_showing_all_layersets] = useState(false);
// 	const [showing_layerset_settings, set_showing_layerset_settings] = useState(false);

// 	const [clone_general_material_allowed, set_clone_general_material_allowed] = useState(true);

// 	useEffect(() => {
// 		if(window['Module'] && window['Module'].is_feature_enabled){
// 			set_clone_general_material_allowed(window.Module.is_feature_enabled("clone_general_material"));
// 		}
// 		// console.log("json item", json_item)
// 	},[json_item])

// 	// useEffect(() => {
// 	// 	if(panel_explode && !is_dropdown_open()){
// 	// 		dropdown_on_click()
// 	// 	}
// 	// }, [panel_explode]);
// 	// const [collapsed, setCollapsed] = useState((json_item && json_item.type == "dropdown" && json_item.open)?false:true);

// 	// Added by Akhil
// 	// need to use useEffect or something similar to set the default state of collapsed from json_item.open (this will be used for repopulating the panel in the same state)
// 	// 
// 	// useEffect(() => {
// 	// 	if(json_item && json_item.type == "dropdown" && json_item.open){
// 	// 		console.log(collapsed)
// 	// 		console.log(json_item.open)
// 	// 		setCollapsed(false)
// 	// 	}
// 	// },[json_item])

// 	const alert = useAlert();

//   	const toggleNavbar = () => setCollapsed(!collapsed);


//   	const get_dimension = (id) => {
//   		if(metric_system == 'mm'){
// 			if(isNaN(document.getElementById(id).value)){
// 				return "Invalid Inputs";
// 			}
// 			return Number(document.getElementById(id).value);
// 		}else if(metric_system == 'ft'){
// 			if(isNaN(document.getElementById(id + "_ft").value) || isNaN(document.getElementById(id + "_in").value) || isNaN(document.getElementById(id + "_parts").value)){
// 				return "Invalid Inputs";
// 			}

// 			var ft_val = Number(document.getElementById(id + "_ft").value);
// 			var in_val = Number(document.getElementById(id + "_in").value);
// 			var parts_val = Number(document.getElementById(id + "_parts").value);

// 			var ft_inch_only =  Number(ft_val*304.8)+(in_val*(25.4));
// 			if(approximation_ft == '1/8_inch'){
// 				var fraction =  (parts_val*(25.4/8));
// 			}else if(approximation_ft == '1/16_inch'){
// 				var fraction =  (parts_val*(25.4/16));
// 			}

// 			return Number(ft_inch_only) + Number(fraction);
// 		}
//   	}

// 	const fetch_store_materials_onclick = () => {
// 		set_page_loader({
// 			show: true,
// 			text: 'Please wait'
// 		});
// 		setTimeout(async() => { 
// 			var active_design = window.Module.get_active_design();
// 			var active_view = active_design.active_view;
// 			active_view.active_material_select_item_id = json_item.id;
// 			window.Module.fetch_store_materials_onclick(json_item.id, json_item.route, json_item.material_type, json_item.active_category_id, json_item.pass_params_to_carousel_onclick);
// 			update_view();
// 			set_page_loader({
// 				show: false,
// 				text: 'Please wait'
// 			});	
// 			active_design.delete();
// 			active_view.delete();
// 		},0)
// 	};
	
// 	const clone_active_modal_onclick = () => {
// 		if(json_item && json_item.type == "material_select" && json_item.active_material){
// 			var active_design = window.Module.get_active_design();
// 			var active_view = active_design.active_view;
// 			active_view.active_material_select_item_id = json_item.id;
// 			window.Module.clone_active_modal_onclick(json_item.active_material.id, json_item.id, json_item.route);
// 			update_view();
// 			active_design.delete();
// 			active_view.delete();
// 		}
// 	};
	
// 	const get_active_index = (composites, active_id) => {
// 		return window._.findIndex(composites,o => o.id == active_id);
// 	}

// 	const composite_select_onclick = (composite_id) => {
// 		if(json_item.route){
// 			window.Module[json_item.route](composite_id);
// 			repopulate_panel_pop_up();
// 			update_view();
// 		}
// 	}

// 	const common_onchange_handler = (e) => {
// 		set_panel_has_unapplied_edits(true);
// 	}
	
// 	const on_select_input_change = async (value) => {
// 		if(json_item.onchange_route && json_item.onchange_route != ""){
// 			var form = {
// 			}
// 			form[json_item.id] = value
			
// 			var promise_resp;
// 			try{
// 				promise_resp = window.Module[json_item.onchange_route](JSON.stringify(form));
// 			}catch(err){
// 				err.constructor.name.includes("Error") != true?err=new Error(err):null;
// 				err.name = "Captured error in Panel menu onclick error at " + json_item.onchange_route + ", form=" + JSON.stringify(form) + " : " + err.name
// 				console.error(err);
// 				window.sentry_capture_exception("",err);
// 				alert.error("Something went wrong")
// 				return;
				
// 			}
// 			var ui_response = await window.Promisify(promise_resp);
// 			common_onchange_handler();

// 			handle_ui_response(ui_response);
// 			repopulate_panel_pop_up();
			
// 			update_view();
//   		}
// 	}


//   	const on_click = async () => {
//   		if(json_item.route){
//   			var form = {
//   				id: json_item.id
//   			}

//   			if(parent_menu){
//   				for (var i = 0; i < parent_menu.length; i++) {
//   					if(parent_menu[i].type == "text_input"){
//   						if(document.getElementById(parent_menu[i].id)){
//   							form[parent_menu[i].id] = document.getElementById(parent_menu[i].id).value;
//   						}
//   					}else if(parent_menu[i].type == "dimension_input"){
// 						var dim_parse = get_dimension(parent_menu[i].id);
// 						if(dim_parse.constructor.name == "String"){
// 							alert.error(dim_parse);
// 							return;
// 						}else{
// 							form[parent_menu[i].id] = get_dimension(parent_menu[i].id);
// 						}
//   					}else if(parent_menu[i].type == "ref_level_dimension_input"){
// 						if(document.getElementById(parent_menu[i].id + "_ref_level_select")){
// 							var current_val = document.getElementById(parent_menu[i].id + "_ref_level_select").value;
// 							if((!current_val) || current_val == "no_ref_level"){
// 								var dim_parse = get_dimension(parent_menu[i].id);
// 								if(dim_parse.constructor.name == "String"){
// 									alert.error(dim_parse);
// 									return;
// 								}else{
// 									form[parent_menu[i].id] = get_dimension(parent_menu[i].id);
// 								}
// 							}else{
// 								var dim_parse = get_dimension(parent_menu[i].id + "_offset");
// 								if(dim_parse.constructor.name == "String"){
// 									alert.error(dim_parse);
// 									return;
// 								}else{
// 									form[parent_menu[i].id] = {
// 										ref_level: current_val,
// 										offset: get_dimension(parent_menu[i].id + "_offset")
// 									};
// 								}
// 							}
// 						}
// 					}else if(parent_menu[i].type == "select_input"){
//   						if(document.getElementById(parent_menu[i].id)){
//   							form[parent_menu[i].id] = document.getElementById(parent_menu[i].id).value;
//   						}
//   					}else if(parent_menu[i].type == "colorpicker"){
//   						if(document.getElementById(parent_menu[i].id)){
//   							form[parent_menu[i].id] = document.getElementById(parent_menu[i].id).value;
//   						}
//   					}else if(parent_menu[i].type == "checkbox"){
//                         if(document.getElementById(parent_menu[i].id)){
//                             form[parent_menu[i].id] = document.getElementById(parent_menu[i].id).checked;
//                         }
//                     }
//   				}
//   			}

//   			//special cases
//   			if(json_item.id == "blend_medium" || json_item.id == "blend_high" || json_item.id == "blend_presentation" || json_item.id == "3ds" || json_item.id == "glb_export_with_khr" || json_item.id == "glb_export_without_khr"){
// 				var cam_pos = window.Module.gl_get_camera_position();
//         		var cam_target = window.Module.gl_get_camera_target();
//   				form.camera_pos = {
//   					x: cam_pos.x,
//   					y: cam_pos.y,
//   					z: cam_pos.z
//   				}

//   				form.camera_rot = {
//   					x: cam_target.x,
//   					y: cam_target.y,
//   					z: cam_target.z
//   				}

//   				form.aspect = window.Module.gl_get_camera_aspect();
// 				form.fov = window.Module.gl_get_camera_fov();
// 				cam_pos.delete();
// 				cam_target.delete();
// 				set_page_loader({
// 					show: true,
// 					text: 'Please wait'
// 				});

// 				await dummy_tick(0);
// 			}

// 			if(json_item.id == "active_sales_channel_id_apply" || json_item.id == "refresh_admin_cache" || json_item.id == "pricing_quotation" || json_item.id == "cabinet_composite_schedule" || json_item.route == "open_kitchen_preferences_carousel" || parent_id=="livspace_dropdown" || parent_id=="spacewood_dropdown"){
// 				set_page_loader({
// 					show: true,
// 					text: 'Please wait'
// 				});
// 				await dummy_tick(0);
// 			}
			  
// 			if(json_item.id == "design_thumbnail"){
// 				form.img_data = window.get_snapshot_data(450,253)
// 			}
			
// 			var promise_resp;
// 			try{
// 				promise_resp = window.Module[json_item.route](JSON.stringify(form));
// 			}catch(err){
// 				err.constructor.name.includes("Error") != true?err=new Error(err):null;
// 				err.name = "Captured error in Panel menu onclick error at " + json_item.route + ", form=" + JSON.stringify(form) + " : " + err.name
// 				console.error(err);
// 				window.sentry_capture_exception("",err);
// 				alert.error("Something went wrong")
// 				set_page_loader({
// 					show: false,
// 					text: 'Please wait'
// 				});
// 				return;
				
// 			}
// 			var ui_response = await window.Promisify(promise_resp);
			
// 			handle_ui_response(ui_response);
// 			repopulate_panel_pop_up();
			
// 			if(json_item.id == "active_sales_channel_id_apply" || json_item.id == "blend_medium" || json_item.id == "blend_high" || json_item.id == "blend_presentation" || json_item.id == "3ds" || json_item.id == "glb_export_with_khr" || json_item.id == "glb_export_without_khr" || json_item.id == "refresh_admin_cache" || json_item.id == "pricing_quotation" || json_item.id == "cabinet_composite_schedule" || json_item.route == "open_kitchen_preferences_carousel" || parent_id=="livspace_dropdown" || parent_id=="spacewood_dropdown"){
// 				set_page_loader({
// 					show: false,
// 					text: 'Please wait'
// 				});
// 				await dummy_tick(0);
// 			}
			
// 			update_view();
//   		}
//   	}

// 	const dropdown_on_click = async () => {
// 		var active_design = window.Module.get_active_design()
// 		var active_view = active_design.active_view;
//         active_view.toggle_dropdown_as_open_or_close(json_item.id)
// 		toggleNavbar();
// 		active_design.delete();
// 		active_view.delete();
// 	}

// 	const is_dropdown_open = () => {
// 		if(window.Module){
// 			return window.Module.get_dropdown_open_close_state(json_item.id);
// 		}

// 		return false;
// 	}

// 	const type_fn = () => {
// 		if(json_item && json_item.type){
// 			// console.log(json_item)
// 			if(json_item.type=='blank'){
// 				return(<div></div>)
// 			}
// 			else if(json_item && json_item.type=='dropdown'){
// 				return(
// 					<div style={{width: '100%', overflow: 'hidden'}} className={json_item.disabled?'panel_disabled':''}>
						
// 						<div className={json_item.submenu && json_item.submenu.length?'':'panel_disabled'} onClick={json_item.disabled || !(json_item.submenu && json_item.submenu.length) ?null:dropdown_on_click} style={{cursor:"pointer",height:'42px', width:'100%',padding:'0px 15px', overflow: 'hidden', display:'flex', flexDirection:'row', alignItems:'center', borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation, borderRightColor:COLORS.blue6, borderRightWidth: is_dropdown_open()?'5px':'0px', borderRightStyle:'solid', backgroundColor:COLORS.toolbar_background_color}}>
// 							{	
// 								json_item.icon?
// 								<span style={{paddingRight:'10px', flex:1}}>
// 									<img height='24px' width='24px' style={{maxHeight: '100%', overflow: 'hidden'}} title={json_item.name} src={json_item.icon} />
// 								</span>	
// 								:''
// 							}
							
// 							<span style={{flex:10 , textAlign:'left' }} >
// 								<span id={json_item.id} title={json_item.name} className='lines2_elipsis' style={{ fontSize:'14px', color:COLORS.black_left_menu}} >{json_item && json_item.name ? json_item.name:''}</span>
// 							</span>
// 							<span style={{flex:1}}>
// 								<span style={{ fontSize:'14px', color:COLORS.black_left_menu }} ><b>{!is_dropdown_open()?<i style={{fontSize:'12px', color: COLORS.black_left_menu}} className="fa fa-chevron-down"/>:<i className="fa fa-chevron-up"/>}</b></span>
// 							</span>
// 						</div>
// 						<Collapse className='d--------------------d' isOpen={is_dropdown_open()} navbar={true} style={{ width:'100%', overflowX: 'hidden'}}>
// 							<Nav navbar style={{width:'100%', overflowX: 'hidden', borderLeft:'4px solid', borderLeftColor:'#A7A8B2'}}>
								
// 								{
// 									json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item,idx) =>(
// 										<NavItem key={json_item.id+idx} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden', height:'100%'}}>
// 											<Create_card set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} key={json_item.id+idx} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} parent_id={json_item.id} parent_menu={json_item.submenu} update_view={update_view} json_item={sub_item} set_panel_has_unapplied_edits={set_panel_has_unapplied_edits} approxMM={approxMM} mmToFeetParts={mmToFeetParts}/>
// 										</NavItem>
// 									)):''
									
// 								}	
								
// 							</Nav>
// 						</Collapse>
// 					</div>		
// 				)
// 			}
// 			else if(json_item.type=='list-item'){
// 				return(
					
// 					<div key={"key_"+json_item.id} id={json_item.id + "_button"} className={json_item.disabled?'panel_disabled':'hover_enabled_green_border LI----------LI'} onClick={json_item.disabled?null:on_click} style={{cursor:'pointer', height:42, width:'100%', display:'flex', flexDirection:'row', alignItems:'center', borderBottom: '2px solid', borderBottomColor:COLORS.panel_item_separation, padding:'15px', backgroundColor:COLORS.panel_card_item}}>
// 						{	
// 							json_item.icon?(
// 								<span sm={2} style={{paddingRight:'10px'}}>
// 									<img height='24px' width='24px' title={json_item.name} src={json_item.icon} />
// 								</span>
// 							):''
// 						}	
// 						<span sm={10}>
// 							<span title={json_item.name} className='lines2_elipsis' id={json_item.id} style={{ fontSize:'12px', color:COLORS.black_left_menu }} >{json_item.name}</span>
// 						</span>
// 					</div>
// 				)
// 			}
// 			else if(json_item.type=='button'){
// 				return(
// 					<Card /*className='B----------B'*/ style={{ border:'0px solid', borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation }} className={json_item.disabled?'panel_disabled':''} >
// 						<CardBody style={{ padding:'15px', backgroundColor:COLORS.panel_card_item}} >
// 							<Button className='panel_green_btn lines1_elipsis' style={{display:'inline-flex',alignItems:'center',height:'32px'}} key={"key_"+json_item.id} id={json_item.id} onClick={json_item.disabled?null:on_click} >{json_item ? json_item.name:''}</Button>
// 						</CardBody>
// 					</Card>
// 				)
// 			}

// 			else if(json_item.type=='text_input'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }}  className={json_item.disabled?'panel_disabled':'TIF----------TIF'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col style={{fontSize:14,display:'flex',alignItems:"center", justifyContent:"flex-start", cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 							<Col style={{fontSize:14,display:'flex',alignItems:"center", justifyContent:"center"}}><Input key={"key_"+json_item.id} onChange={common_onchange_handler} defaultValue={json_item.default_value||""} type="text"  id={json_item.id} style={styles_panel.input_styles} placeholder="0"/></Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}

// 			// dummy value in image height, can be adjusted
// 			// else if(json_item.type=='card'){
// 			// 	return(
// 			// 		<Card style={{padding:'15px', margin:'10px'}} className={json_item.disabled?'panel_disabled':''}>  
// 			// 			<CardBody style={{padding:'0px'}}>
// 			// 				<Row style={{height:'152px', margin:0, marginBottom:'2px'}}> 
// 			// 					<Col style={{display:'flex', alignItems:"center", justifyContent:'center', backgroundColor:'white',padding:0}}>	
// 			// 						<img style={{cursor:'pointer'}} src= {json_item && json_item.name ?json_item.name:''} height="100%" width="auto" />
// 			// 					</Col>
// 			// 				</Row>
// 			// 				<Row style={{margin:0}}>
// 			// 					<Col style={{display:'flex', alignItems:"center", justifyContent:'center',padding:0, fontSize:'12px'}}>	
// 			// 						{json_item && json_item.name ?json_item.name:''}
// 			// 					</Col>
// 			// 				</Row>
// 			// 			</CardBody>
// 			// 		</Card>	
// 			// 	)
// 			// }
// 			else if(json_item.type=='dimension_input'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled':'dI----------dI'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col style={{display:'flex',justifyContent:'center', alignItems:"center"}}>
// 							{metric_system=='mm'?(
// 								<Row style={{width:'100%'}}>
// 									<Col sm={6} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'flex-start', width:'100%', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 									<Col sm={6} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}><Input type="text" onChange={common_onchange_handler} id={json_item.id} key={"key_"+json_item.id} defaultValue={approxMM(json_item.default_value)||0} style={styles_panel.input_styles} placeholder="0"/></Col>
// 								</Row>
// 							):(
// 								<Row>
// 									<Col sm={5} style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 									<Col sm={2} className='zero_padding'>
// 										<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[0]||0} key={"key_" + json_item.id + "_ft"} id={json_item.id + "_ft"} style={styles_panel.input_styles} placeholder="0"/>
// 										<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span>
// 									</Col>
// 									<Col sm={2} className='zero_padding'>
// 										<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[1]||0} key={"key_" + json_item.id + "_in"} id={json_item.id + "_in"} style={styles_panel.input_styles} placeholder="0"/>
// 										<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span>
// 									</Col>
// 									<Col sm={2} className='zero_padding'>
// 										<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[2]||0} key={"key_" + json_item.id + "_parts"} id={json_item.id + "_parts"} style={styles_panel.input_styles} placeholder="0"/>
// 										<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span>
// 									</Col>
// 								</Row>
// 							)}
// 						<br/>
// 					</Col></Row>
// 						</CardBody>
// 					</Card>
					
// 				)
// 			}
// 			else if(json_item.type=='ref_level_dimension_input'){
// 				return(
// 					<RefLevelDimensionInput json_item={json_item} common_onchange_handler={common_onchange_handler} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} mmToFeetParts={mmToFeetParts} approxMM={approxMM}></RefLevelDimensionInput>
// 				)
// 			}
// 			else if(json_item.type=='heading'){
// 				return(<div></div>)
// 			}
			
// 			else if(json_item.type=='multi-buttons'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled':'MB----------MB'}>
// 						<CardBody style={{ padding:'10px', backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col sm={6} style={{fontSize:14, display:'flex', alignItems:'center',justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 							<Col sm={4}>
// 								<Row><Col>
// 									<Row><Col><i title="increase" style={{padding:'5px', fontSize:14,cursor:'pointer' }} className="fa fa-chevron-up panel_color_green_arrow"/></Col></Row>
// 									<Row><Col><input style={styles_panel.input_styles} onChange={common_onchange_handler} placeholder="0"/></Col></Row>
// 									<Row><Col><i title="decrease" style={{padding:'5px', fontSize:14, cursor:'pointer' }} className="fa fa-chevron-down panel_color_green_arrow"/></Col></Row>
// 								</Col></Row>
// 							</Col>
// 							<Col sm={2} style={{fontSize:'13px', display:"flex", alignItems:"center", justifyContent:'center',paddingLeft:0, cursor:"default"}}>
// 								mm
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type=='colorpicker'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CP----------CP':'CP----------CP'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 							<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'flex-start'}}>
// 								<FormGroup style={{margin:0, width:'100%'}}>
// 									<Input id={json_item.id} key={"key_color_"+json_item.id} onChange={common_onchange_handler} defaultValue={json_item.default_value} style={{ padding: '0px', height: '30px', width: '100%'}} type="color" />
// 								</FormGroup>
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type=='select_input'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled SI----------SI':'SI----------SI'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 							<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
// 								<FormGroup style={{marginBottom:0, width:'100%'}}>
// 									<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
// 										{json_item.options.map((o,idx) => (
// 											<option key={idx} value={o.value}>{o.name}</option>
// 										))}
// 									</Input>
// 								</FormGroup>
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type=='material_select'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled class_position_unset':'class_position_unset'}>
// 						<CardBody className='class_position_unset' style={{ padding:'0px', backgroundColor:COLORS.panel_card_item}} >
// 						<Row  className='class_position_unset' style={{height:'50px', margin:0, width:'100%'}}>
// 							<Col className='class_position_unset' xs={9} style={{display:'flex', alignItems:"center", justifyContent:'center',paddingRight:'10px'}}>
// 								<MaterialDropdown material_json={json_item} update_view={update_view} handle_ui_response={handle_ui_response} json_item={json_item}/>
// 							</Col>
// 							<Col xs={3} style={{display:'flex', alignItems:"center", justifyContent:'center'}}>	
// 								{clone_general_material_allowed?(<img onClick={json_item.disabled?null:clone_active_modal_onclick} title='Clone Selected item' style={{marginRight:'10px', cursor:'pointer'}} src="/resources/icons/clone_material.svg" height="20px" width="20px" />):''}
// 								<img onClick={json_item.disabled?null:fetch_store_materials_onclick} title='Fetch from Store' style={{cursor:'pointer'}} src="/resources/icons/fetch_store.svg" height="20px" width="20px" />
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type=='composite_select'){
// 				return(
// 					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled class_position_unset':'class_position_unset'}>
// 						<CardBody className='class_position_unset' style={{ padding:'0px', backgroundColor:COLORS.panel_card_item}} >
// 						<Row className='class_position_unset' style={{height:'50px',overflowY:'', margin:0}}>
// 							<Col className='class_position_unset' xs={9} style={{display:'flex',position:'unset', alignItems:"center", padding:'0px 5px 0px 10px', height:'100%'}}>
// 								{/* <Dropdown className='class_position_unset' isOpen={dropdownOpen} toggle={toggle}>
// 									<DropdownToggle caret>
// 										Dropdown
// 									</DropdownToggle>
// 									<DropdownMenu className='class_position_unset'>
// 										<DropdownItem header>Header</DropdownItem>
// 										<DropdownItem>Some Action</DropdownItem>
// 										<DropdownItem text>Dropdown Item Text</DropdownItem>
// 										<DropdownItem disabled>Action (disabled)</DropdownItem>
// 										<DropdownItem divider />
// 										<DropdownItem>Foo Action</DropdownItem>
// 										<DropdownItem>Bar Action</DropdownItem>
// 										<DropdownItem>Quo Action</DropdownItem>
// 									</DropdownMenu>
// 								</Dropdown> */}
// 								<CompositeDropdown composites={json_item.composites} active_index={get_active_index(json_item.composites,json_item.active_composite_id)} on_click={composite_select_onclick} json_item={json_item} />
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				)
// 			}
// 			else if(json_item.type == 'checkbox'){
//                 return(
//                     <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CB----------CB':'CB----------CB'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col xs={11} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
// 							<Col xs={1} style={{paddingRight:'0px'}}>
// 								<FormGroup style={{marginBottom:0, width:'100%'}}>
// 									<Input onChange={common_onchange_handler} key={"key_"+json_item.id} id={json_item.id} type="checkbox" defaultChecked = {json_item.selected}></Input>
// 								</FormGroup>
// 							</Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
// 				// 	<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CB----------CB':'CB----------CB'}>
// 				// 		<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 				// 		<Row>
// 				// 			<Col xs={12} className='zero_padding' style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
// 				// 				<div style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default",paddingLeft:'8px', paddingRight:'8px', flex:1}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
// 				// 				<div style={{paddingRight:'8px'}}>
// 				// 					{/* <FormGroup style={{marginBottom:0, width:'100%'}}>
// 				// 						<Input onChange={common_onchange_handler} key={"key_"+json_item.id} id={json_item.id} type="checkbox" defaultChecked = {json_item.selected}></Input>
// 				// 					</FormGroup> */}
// 				// 					<Switch style={{}} size='small' defaultChecked = {json_item.selected} onClick={on_click}/>

// 				// 				</div>
// 				// 			</Col>
// 				// 		</Row>
// 				// 		</CardBody>
// 				// </Card>
//                 )
//             }
// 			else if(json_item.type == 'text_info'){
//                 return(
//                     <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled TI----------TI':'TI----------TI'}>
// 						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
// 						<Row>
// 							<Col xs={3} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px', flexWrap:'wrap'}}><span title={json_item.key} className='lines2_elipsis' style={{textAlign:'left',fontWeight:500}}>{json_item.key}</span></Col>
// 							<Col xs={1} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px', flexWrap:'wrap'}}><span title={json_item.key} className='lines2_elipsis' style={{textAlign:'left'}}>{"-"}</span></Col>
// 							<Col xs={8} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px', flexWrap:'wrap'}}><span title={json_item.name} className='lines2_elipsis' style={{textAlign:'left'}}>{json_item.name}</span></Col>
// 						</Row>
// 						</CardBody>
// 					</Card>
//                 )
//             }
// 			// else if(json_item.type == 'layer_select_layerset'){

// 			// }else if(json_item.type == 'layer_list_item'){
// 			// 	<div key={"key_"+json_item.id} id={json_item.id + "_button"} className={json_item.disabled?'panel_disabled':'hover_enabled_green_border LSL----------LSL'} onClick={json_item.disabled?null:on_click} style={{cursor:'pointer', height:42, width:'100%', display:'flex', flexDirection:'row', alignItems:'center', borderBottom: '2px solid', borderBottomColor:COLORS.panel_item_separation, padding:'15px', backgroundColor:COLORS.panel_card_item}}>
// 			// 			{	
// 			// 				json_item.icon?(
// 			// 					<span sm={2} style={{paddingRight:'10px'}}>
// 			// 						<img height='24px' width='24px' title={json_item.name} src={json_item.icon} />
// 			// 					</span>
// 			// 				):''
// 			// 			}	
// 			// 			<span sm={10}>
// 			// 				<span title={json_item.name} className='lines2_elipsis' id={json_item.id} style={{ fontSize:'12px', color:COLORS.black_left_menu }} >{json_item.name}</span>
// 			// 			</span>
// 			// 		</div>

// 			// }else if(json_item.type == 'add_layer'){

// 			// }
// 			else if(json_item.type == 'layerset_pop_up'){
// 				return(
// 					<Card style={{ border:'0px solid', backgroundColor:COLORS.gray3, padding:'0px 10px', height:'100%'}}>
// 						<div className='flex_property' style={{justifyContent:'space-between', fontSize:'14px', padding:'15px 5px 4px 5px'}}>
// 							<span style={{}}>Active layer set</span>
// 							<span style={{color:COLORS.gray9, fontSize:'12px'}}>View specific</span>
// 						</div>
// 						<div className='flex_property' style={{padding:'5px 10px', backgroundColor:COLORS.panel_card_item, justifyContent:'space-between', fontSize:'14px', height:'30px'}}>
// 							<span title={json_item && json_item.active_layerset ? json_item.active_layerset.name : ''} style={{}}>{json_item && json_item.active_layerset ? (json_item.active_layerset.name.length <= 25 ? json_item.active_layerset.name : json_item.active_layerset.name.substring(0, 25)+'...') : ''}</span>
// 							<div className='flex_property' >
// 								<i className={showing_all_layersets ? 'fa fa-angle-up' : 'fa fa-angle-down'} style={{cursor:'pointer', fontWeight:'bold', color:'black'}} onClick={() => {set_showing_layerset_settings(false);set_showing_all_layersets(!showing_all_layersets)}}/>
// 								<div id="tooltip1" class="bottom" style={{left:'10px', top:'46px', backgroundColor:'white', display:showing_all_layersets?'block':'none', boxShadow:'#efeaea 0px 4px 4px 0px', padding:'8px'}}>
// 									<div class="tooltip-arrow" style={{left:'84%', borderBottomColor:'white'}}></div>
// 									<div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.blue6, cursor:'pointer', textAlign:'left', paddingLeft:'8px'}} onClick={() => {set_modal_type('create_layerset_modal');set_modal_mode('panel');set_layerset_mode('create')}}>+ Create new layer set</div>
// 									{
// 										json_item && json_item.all_layersets && json_item.all_layersets.length ? json_item.all_layersets.map((single_layerset, idx) => (
// 											<div class="tooltip-label dropdown_visibility_on_hover flex_property display_onhover" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, justifyContent:'space-between', cursor:'pointer'}}>
// 												<div title={single_layerset.name} style={{flex:'6', textAlign:'left', overflowX:'auto', whiteSpace: 'nowrap'}} onClick={() => {window.Module.switch_layerset_onclick(single_layerset.id, 'panel'); update_view()}}>{single_layerset.name.length <= 25 ? single_layerset.name : single_layerset.name.substring(0,25)+'...'}</div>
// 												<div style={{flex:'2'}} className='flex_property dropdown_visibility_on_hover'>
// 													<img style={{cursor:'pointer', marginLeft:'4px', marginRight:'10px'}} width='16px' onClick={(e) => {e.stopPropagation();set_modal_type('create_layerset_modal');set_modal_mode('panel');set_layerset_mode('copy');set_given_layerset(single_layerset)}} className='dropdown_item_hide' src={single_layerset.copy_content}/>
// 													<img style={{cursor:'pointer', display:!single_layerset.delete_lock ? 'block' : 'none'}} width='16px' onClick={(e) => {e.stopPropagation(); window.Module.delete_layerset_onclick(single_layerset.id, 'panel');}} className='dropdown_item_hide' src={single_layerset.delete_content}/>
// 												</div>
// 											</div>
// 										)):''
// 									}
// 								</div>
								
// 								<img src={"/resources/nav_icons_new/settings 16px.svg"} style={{paddingLeft:'4px', marginLeft:'4px', cursor:'pointer', height:'17px', borderLeft:'1px solid #E6E9EE'}} onClick={() => {set_showing_all_layersets(false);set_showing_layerset_settings(!showing_layerset_settings)}}/>
// 								<div id="tooltip1" class="bottom" style={{left:'10px', top:'46px', backgroundColor:'white', display:showing_layerset_settings?'block':'none', boxShadow:'#efeaea 0px 4px 4px 0px', padding:'8px'}}>
// 									<div class="tooltip-arrow" style={{left:'92.5%', borderBottomColor:'white'}}></div>
// 									<div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, textAlign:'left', cursor:'pointer'}} onClick={() => {window.Module.toolbar_router("define_layerset"); update_view(); }} >Define</div>
// 									<div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, textAlign:'left', cursor:'pointer'}} onClick={(e) => {e.stopPropagation();set_modal_type('create_layerset_modal');set_modal_mode('panel');set_layerset_mode('rename');set_given_layerset(json_item.active_layerset)}}>Rename</div>
// 									<div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, textAlign:'left', cursor:'pointer'}} onClick={() => window.Module.toolbar_router("unhide_all_layers_layerset")}>UnHide all layers in this set</div>
// 									{/* <div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, textAlign:'left', cursor:'pointer'}}>UnLock all layers in this set</div> */}
// 									<div class="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:'#FF5247', textAlign:'left', cursor:'pointer'}} onClick={(e) => {e.stopPropagation(); window.Module.delete_layerset_onclick(json_item.active_layerset.id, 'panel');}}>Delete this layer set</div>
// 								</div>
// 							</div>
// 						</div>
// 						<div className='flex_property' style={{padding:'5px 10px', backgroundColor:COLORS.panel_card_item, justifyContent:'space-between', fontSize:'12px', marginTop:'8px', marginBottom:'4px', height:'30px'}}>
// 							<span style={{color:COLORS.blue6, cursor:'pointer'}} onClick={() => {set_modal_type('create_layer_modal'); set_modal_mode('panel')}}>+ Add Layer</span>
// 							<span style={{color:COLORS.gray9}}>{json_item && json_item.layers_in_design ? json_item.layers_in_design.length : 0} Layers</span>
// 						</div>
// 						<div style={{backgroundColor:COLORS.panel_card_item, fontSize:'12px', padding:'5px 10px'}}>
// 						{
// 							json_item && json_item.layers_in_design && json_item.layers_in_design.length ? json_item.layers_in_design.map((single_layer, idx) => (
// 								<div className='flex_property' style={{height:'30px', width:'100%'}}>
// 									<img src={json_item.active_layerset && json_item.active_layerset.layers && json_item.active_layerset.layers[single_layer.id]['visible'] ? json_item.show_content : json_item.hide_content} style={{height:'16px', width:'16px',marginRight:'8px', cursor:'pointer'}} onClick={() => {window.Module.toggle_layer_visibility_onclick(single_layer.id, 'panel')}}/>
// 									{single_layer.name}
// 								</div>
// 							)):''
// 						}
// 						</div>
// 					</Card>
// 				)
// 			}
// 			else{
// 				return(<div></div>)
// 			}
// 		}	
// 	}

// 	return( 
// 		<div style={{width: '100%', overflowX: 'hidden', height:'100%'}}>
// 			{type_fn()}
// 		</div>
// 	)
// }

const PanelPopup = ({ panel_key, seller_specific, panel_show, panel_heading, panel_json, perspective, set_panel_has_unapplied_edits, metric_system, approximation_mm, approximation_ft, set_page_loader, update_view,handle_ui_response, active_left_menu_item={active_left_menu_item}, set_active_left_menu_item, scroll_pos, set_scroll_pos, approxMM, mmToFeetParts, project_name, project_id, project_client_name, design_name, set_modal_mode, set_layerset_mode, set_given_layerset, panel_has_unapplied_edits, panel_search_json, is_search_mode, set_is_search_mode, search_string_panel, set_search_string_panel, panel_search_string}) => {
	const alert = useAlert()
    const {t} = useTranslation()
	// set_modal_mode
	// const toolbar_on_click = async id => {
	// 	if(id == "print_quotation_toolbar"){
	// 		set_page_loader({
	// 			show: true,
	// 			text: 'Please wait'
	// 		});
	// 		await dummy_tick(0);
	// 	}


	// 	var promise_resp;
	// 	try{
    //         if(id.split("panel_pop_").length>1){
    //             promise_resp = window.Module[id.split("panel_pop_")[1]]("");
    //         }else if(id.split("left_menu_").length>1){
    //             promise_resp = window.Module.left_menu_router(id.split("left_menu_")[1]);
    //         }else{
    //             promise_resp = window.Module.toolbar_router(id);
    //         }
	// 	}catch(err){
	// 		err.constructor.name.includes("Error") != true?err=new Error(err):null;
	// 		err.name = "Captured error in Toolbar onclick error at " + id + " : " + err.name;
	// 		console.error(err);
	// 		window.sentry_capture_exception("",err);
	// 		alert.error("Something went wrong")
	// 		set_page_loader({
	// 			show: false,
	// 			text: 'Please wait'
	// 		});
	// 		return;
	// 	}

	// 	var ui_response = await window.Promisify(promise_resp);

	// 	handle_ui_response(ui_response);
		
	// 	update_view();

	// 	if(id == "print_quotation_toolbar"){
	// 		set_page_loader({
	// 			show: false,
	// 			text: 'Please wait'
	// 		});
	// 		await dummy_tick(0);
	// 	}
	// }
	// useEffect(() => {
	// 	// console.log('layer popup', panel_json)
	// }, [panel_json]);
	
	const handle_close = () => {
		var active_design = window.Module.get_active_design();
		var active_view = active_design.active_view;
		active_view.hide_panel_pop_up();
		update_view();
		// set_active_left_menu_item('')
		active_design.delete();
		active_view.delete();
	}

	const icon_on_click = async ({route, form}) => {
		let promise_resp;
		try{
			promise_resp = window.Module[route](JSON.stringify(form));
		}catch(err){
			err.name = "Captured error in Panel popup menu header onclick error at " + route + ", form=" + JSON.stringify(form) + " : " + err.name
			console.error(err);
			window.sentry_capture_exception("",err);
			alert.error("Something went wrong")
			return;
			
		}
		var ui_response = await window.Promisify(promise_resp);
		handle_ui_response(ui_response);
		repopulate_panel_pop_up()
		update_view()
	}


	const handle_scroll = (e) => {
		set_scroll_pos(e.target.scrollTop)
	}

	useEffect(() => {
		// setTimeout(() => {
			if(document.getElementById('panel_scroll_container_popup')){
				document.getElementById('panel_scroll_container_popup').scroll(0,scroll_pos);
			}
			// console.log("panel json", panel_json, panel_heading)
		// },0)
	},[panel_json])

	useEffect(() => {
		if(!panel_has_unapplied_edits && document.getElementById('panel_scroll_container_popup') && document.getElementById('panel_scroll_container_popup').scrollTop != scroll_pos){
			document.getElementById('panel_scroll_container_popup').scroll(0,scroll_pos);
		}
	}, [panel_has_unapplied_edits]);

	const populate_panel_pop_up_search = async(search_string) => {
		// var active_design = window.Module.get_active_design();
        // var active_view = active_design.active_view;
		var promise_resp;
		if(window.Module){
			try{
				promise_resp = window.Module.populate_panel_pop_up_search(JSON.stringify({"search": search_string}));
			}
			catch(err){
				// alert.error("Something went wrong with search")
				return
			}
		}

		var ui_response = await window.Promisify(promise_resp);
		handle_ui_response(ui_response);

		update_view()

	}

	// useEffect(()=>{
	// 	console.log(panel_show + " panel show");
	// },[panel_show]);

	return (
		
	      	<div navbar="true" style={{...styles_panel.collapse , display:panel_show ? 'block' : 'none' , height: 'calc(100vh - 292px)', top:'250px', left:seller_specific?'0px':'0px', borderTopRightRadius:'10px', zIndex:7}}>
	      		<Card style={{border:'none', height:'100%', borderTopRightRadius: '10px', borderTopLeftRadius:'10px'}}>
						<CardHeader style={{backgroundColor:COLORS.panel_header_background,height:"40px", color:COLORS.panel_header_text, /*borderTopRightRadius: '10px',*/ display:'flex', alignItems:"center", borderTopRightRadius:'6px', borderTopLeftRadius:'6px', justifyContent:'space-between', padding:'0px 8px'}}>
							{/* {panel_show?( */}
							<span className='flex_property' style={{flexShrink: 1, minWidth: 0}}>
								{
									is_search_mode ?
									// <div className='header_icon_container_panel_popup'>	
									<Tooltip title={"Click to go back to panel"} placement='bottomLeft' arraow={{pointAtCenter: true}}>
										<img className='mx-2' style={{cursor:'pointer'}} src='/resources/panel_icons_new/back.svg' onClick={() => {set_is_search_mode(false); set_panel_pop_up_search_show(false); set_search_string_panel(''); populate_panel_pop_up_search('')}}></img> 
									</Tooltip>
									// </div>
									: ''
								}
								<span key={"abc1" + panel_show} style={{ fontSize:'16px', fontWeight:'600', color:COLORS.panel_header_text, flex: 1, minWidth: 0 }} className='lines1_elipsis mx-2' title={((is_search_mode) ? 'Search ' : '') + panel_heading}>{((is_search_mode) ? 'Search ' : '') + panel_heading}</span>
							</span>
							{/* ):(
								<span key={"def1" + panel_show}style={{ fontSize:'16px', fontWeight:'600', color:COLORS.panel_header_text }}>Panel Popup</span>
							)} */}
							<span style={{color:COLORS.panel_header_text, opacity:'unset', fontWeight:'unset', display:"flex", alignItems:'center'}} aria-label="Close" className="close" data-dismiss="modal" type="button">
								{
									panel_json && panel_json.length && panel_json[0].type == "layerset_pop_up" ?
										<div className='header_icon_container_panel_popup'>
											<img src={panel_json ? panel_json[0].open_modal_content : ''} style={{ cursor: 'pointer' }} onClick={() => window.Module.toolbar_router(panel_json[0].open_modal_route)} />
										</div>
										: ''
								}
								{
									panel_heading == 'Preferences' ?
										<div title='Search' className={is_search_mode ? "active_panel_pop_up_search" : "header_icon_container_panel_popup"} onClick={is_search_mode ? null : () => { set_is_search_mode(true); set_panel_pop_up_search_show(true) }}>
											<img src={is_search_mode ? '/resources/panel_icons_new/search_selected.svg' : '/resources/panel_icons_new/search.svg'}></img>
										</div>
										: ''
								}
								{
									panel_heading == 'Materials & Composites' ?
									<Tooltip title={perspective != '3d' ? <div style={{padding: '5px 6px 5px 6px'}}><div style={{paddingBottom: '4px', fontSize: '12px', fontWeight: '500', lineHeight: '22px'}}>Material property picker</div> <div style={{fontSize: '11px', color: '#A7A8B2', fontWeight: '400', lineHeight: '14px'}}>Available only in 3D view</div></div> : <div style={{fontSize: '12px', fontWeight: '500', lineHeight: '22px'}}>Material property picker</div>} placement='top'>
									<div style={{display: 'flex', alignItems: 'center'}} onClick={perspective=='3d'? (e) => {e.stopPropagation(); icon_on_click({route:"trigger_material_picker", form:{id: "trigger_material_picker"}});} : null}>
										<img style={{opacity: (perspective == '3d') ? '' : 0.5}} src={'/resources/toolbar_icons_new/material_property_picker_icon_new.svg'}></img>
									</div> </Tooltip> : ''
									
								}
								<div className='header_icon_container_panel_popup'>
									<img onClick={handle_close} data-toggle="tooltip" data-placement="bottom" title="Close Panel Popup" src={'/resources/nav_icons_new/bottom panel close.svg'}></img>
								</div>
							</span>
						</CardHeader>
						<CardBody id="panel_scroll_container_popup" onScroll={handle_scroll} style={{ padding:'0px', backgroundColor:panel_json && panel_json.length && panel_json[0].type == "layerset_pop_up" ? COLORS.gray3 : COLORS.panel_body_background, height:'558px', overflowY:'auto'}}>
							<div style={{height:'100%', Width: '270px', height:'100%'}}>
								{/* {panel_show?( */}
									<Nav key={"qedqdq1" + panel_show} vertical style={{ Width: '100%', backgroundColor: COLORS.panel_card_item_parent}}>
										<div className='PS----------PS' style={{ display: is_search_mode ? 'flex' : 'none', position: 'sticky', top: '0px', zIndex: 100 }}>
											<div className={'search_component_container_ideal'} style={{ width: '100%', margin: 0, display: is_search_mode ? 'flex' : 'none', height: '32px' }}>
												<div><i style={{ paddingLeft: '12px', paddingBottom: '3px', fontSize: '12px', color: '#9aa5b5' }} className='fa fa-search' /></div>
												<input id='panel_search_input' className={'search_component_inner_container'} placeholder={'Type to Search'} type='text' value={search_string_panel} onChange={(e) => set_search_string_panel(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') { set_search_string_panel(e.target.value.trim()); populate_panel_pop_up_search(e.target.value.trim()) } }}></input>
												<div className='hover_blue' style={{ paddingRight: '12px', cursor: 'pointer', fontSize: '10px', color: '#9aa5b5', visibility: search_string_panel ? 'visible' : 'hidden', fontStyle: 'italic' }} onClick={() => { set_search_string_panel(''); populate_panel_pop_up_search('') }}>{t(`Clear`)}</div>
												{/* <span><i style={{paddingRight:'12px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-times' onClick={clear_search}/></span> */}
												<div className='flex_center' style={{ width: '30px', cursor: 'pointer', height: '100%', borderLeft: '1px solid #C5C7CF' }} onClick={() => { set_search_string_panel(search_string_panel.trim()); populate_panel_pop_up_search(search_string_panel.trim()) }}>
													<img src='/resources/panel_icons_new/arrow_right.svg' />
												</div>
											</div>
										</div>
										{
											is_search_mode && panel_search_json && panel_search_json.length ? <div style={{ height: '16px', backgroundColor: COLORS.panel_body_background, borderBottom: '1px solid #C5C7CF', position: 'sticky', top: '50px', zIndex: 100}}/> : ''
										}
										{
											!(is_search_mode) && panel_json && panel_json.length ? panel_json.map((json_item,idx) =>
											( 
												<Create_card panel_key={panel_key} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} key={idx} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} parent_menu={panel_json} update_view={update_view} json_item={json_item} set_panel_has_unapplied_edits={set_panel_has_unapplied_edits} approxMM={approxMM} mmToFeetParts={mmToFeetParts} set_modal_mode={set_modal_mode} set_layerset_mode={set_layerset_mode} set_given_layerset={set_given_layerset} is_panel_pop_up={true}
													panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string}
												/>
											))
											:
											(is_search_mode) && panel_search_json && panel_search_json.length ? panel_search_json.map((json_item,idx) =>
											(
												<Create_card panel_key={panel_key} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} key={idx} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} parent_menu={panel_search_json} update_view={update_view} json_item={json_item} set_panel_has_unapplied_edits={set_panel_has_unapplied_edits} approxMM={approxMM} mmToFeetParts={mmToFeetParts} set_modal_mode={set_modal_mode} set_layerset_mode={set_layerset_mode} set_given_layerset={set_given_layerset} is_panel_pop_up={true}
													panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} mode={'search'} panel_explode={true}
												/>
											))
											: ''
}
									</Nav>
									{
										!(is_search_mode) && panel_json && panel_json.length ? '' :
											(is_search_mode) && panel_search_json && panel_search_json.length ? ''
												: (is_search_mode) && panel_search_string ?
													<div style={{ height: 'calc( 100% - 50px )', display: 'flex', alignItems: 'center', justifyContent: 'center', color: COLORS.gray5 }}>{t(`No Results Found`)}</div>
													:
													(is_search_mode) && !panel_search_string ?
														<div style={{ height: 'calc( 100% - 50px )', display: 'flex', alignItems: 'center', justifyContent: 'center', color: COLORS.gray5, padding: '16px 32px' }}>{t(`Search any Property and press enter to show results`)}</div>
														:
														<div style={{ padding: '15px' }}>{t(`No Items`)}</div>
									}
								{/* ):(
									<div key={"dedede1" + panel_show} style={{height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around', color:COLORS.gray5, backgroundColor:COLORS.gray3, padding:'12px'}}>
										<div style={{padding:'12px', background:'white', width:'100%', flex:1, display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
											<div style={{marginBottom:'8px',fontSize:'14px', width:'100%', flex:1, textAlign:'left', color:COLORS.black}}>
												<div>Project Name:</div>
												<div style={{fontWeight:'bold'}}>{project_name}</div>
											</div>
											<div style={{marginBottom:'8px',fontSize:'14px', width:'100%', flex:1, textAlign:'left', color:COLORS.black}}>
												<div>Design Name:</div>
												<div style={{fontWeight:'bold'}}>{design_name}</div>
											</div>
											<div style={{marginBottom:'8px',fontSize:'14px', width:'100%', flex:1, textAlign:'left', color:COLORS.black}}>
												<div>Client Name:</div>
												<div style={{fontWeight:'bold'}}>{project_client_name ? project_client_name : '-'}</div>
											</div>
											<div style={{marginBottom:'8px',fontSize:'14px', width:'100%', flex:1, textAlign:'left', color:COLORS.black}}>
												<div>Project ID:</div>
												<div style={{fontWeight:'bold'}}>{project_id}</div>
											</div>
										</div>
										<div style={{backgroundColor:'transparent', padding:'12px', width:'100%', flex:1, display:'flex', alignItems:'center'}}>
											Select a tool or any elements to view the Properties / Preferences
										</div>
									</div>
								)} */}
							</div>
						</CardBody>
						{/* <CardFooter style={{height:'36px', padding:'0px'}}>
							<div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', height:'100%', transform:'scale(0.9)'}}>
								{
									json_file && json_file.length ? json_file.map((json_item,idx) => (
										// <div style={{boxShadow:json_item.active?'0px 0px 7px #314456 inset':'none',cursor:json_item.disabled?'not-allowed':'pointer',opacity:json_item.disabled?'0.3':'1'}} key={json_item.id + String(idx)}>
										// background-color: #F5F6F8;
										// border-radius: 10px;
										<div className={json_item.active?'':''} style={{cursor:json_item.disabled?'not-allowed':'pointer',opacity:json_item.disabled?'0.3':'1'}} key={json_item.id + String(idx)}>
											{	
												json_item.type==="image" ?
												<Image_Icon
													onClick={() => {!json_item.disabled?toolbar_on_click(json_item.id):''}}
													unit_type={json_item.type?json_item.type:''}
													unit_id={json_item.id?json_item.id:''}
													unit_name={json_item.name?json_item.name:''}
													unit_content={json_item.content?json_item.content:''}
													unit_root={json_item.route?json_item.route:''}
													unit_show={json_item.show?json_item.show:'true'}
													unit_disabled={json_item.disabled?json_item.disabled:''}
													unit_active={""}
												/>:""
											}
											{	
												json_item.type==="text" ?	
												<Text_Icon
													onClick={() => {!json_item.disabled?toolbar_on_click(json_item.id):""}}
													unit_type={json_item.type?json_item.type:''}
													unit_id={json_item.id?json_item.id:''}
													unit_name={json_item.name?json_item.name:''}
													unit_content={json_item.content?json_item.content:''}
													unit_root={json_item.route?json_item.route:''}
													unit_show={json_item.show?json_item.show:'true'}
													unit_disabled={json_item.disabled?json_item.disabled:''}
													unit_active={""}
												/>:""
											}
											{	
												json_item.type==="icon"	?
												<Fa_Icon
													onClick={() => {!json_item.disabled?toolbar_on_click(json_item.id):''}}
													unit_type={json_item.type?json_item.type:''}
													unit_id={json_item.id?json_item.id:''}
													unit_name={json_item.name?json_item.name:''}
													unit_content={json_item.content?json_item.content:''}
													unit_root={json_item.route?json_item.route:''}
													unit_show={json_item.show?json_item.show:'true'}
													unit_disabled={json_item.disabled?json_item.disabled:''}
													unit_active={""}
												/>:""
											}
											{	
												json_item.type==="separator"? " " : " "
												
											}
										</div>
									))
									:''
								}
							</div>
						</CardFooter> */}
					
	      		</Card>
	      	</div>
	   
	);
}

export default PanelPopup;

//style={{ background-image: linear-gradient(to right, COLORS.mainpage_locale 1%, green 1%)}}