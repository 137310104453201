import React, { useState, useEffect } from 'react';
import { Button } from "reactstrap";

const DeleteNotification = ({ onSubmit, onCancel, message, onClose, customButtonJson }) => {

	const onClickCancel = () => {
		onCancel()
		onClose()
	}

	const onClickSubmit = () => {
		onSubmit()
		onClose()
	}

	return (
		<div className='DMSans' style={{ width: '480px', zIndex: 10000 }}>
			<div style={{ backgroundColor: '#FFFFFF', borderRadius: '8px', padding: '36px' }}>
				<div style={{ textAlign: 'center' }}>{message ? message : 'Press Delete to Confirm'}</div>
				<div className='flex_center' style={{ paddingTop: "24px", textAlign: 'center', gap: '16px' }}>
					<Button onClick={onClickCancel} className={customButtonJson && customButtonJson.cancel_type ? customButtonJson.cancel_type : 'secondary_button_default'} >Cancel</Button>
					<Button onClick={onClickSubmit} className={customButtonJson && customButtonJson.type ? customButtonJson.type : 'red_button_default'} >{customButtonJson && customButtonJson.name ? customButtonJson.name : 'Delete' }</Button>
				</div>
			</div>
		</div>

	);
}

export default DeleteNotification;