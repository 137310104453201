const filter_operators_json = [
    {
        id:'none',
        text:"None",
        type:'all'
    },
    {
        id:'equal_to',
        text:"Equal to",
        type:'all'
    },
    {
        id:'not_equal_to',
        text:"Not Equal to",
        type:'all'
    },
    {
        id:'greater_than',
        text:"Greater than",
        type:'number'
    },
    {
        id:'less_than',
        text:"Less than",
        type:'number'
    },
    {
        id:'greater_than_equal_to',
        text:"Greater than Equal to",
        type:'number'
    },
    {
        id:'less_than_equal_to',
        text:"Less than Equal to",
        type:'number'
    },
]
export default filter_operators_json;