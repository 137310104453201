const timeSince = function(date_string, suffixMap) {
    var seconds = Math.floor((new Date() - new Date(date_string)) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " " + (suffixMap && suffixMap["years"] ? suffixMap["years"] : "years ago");
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " " + (suffixMap && suffixMap["months"] ? suffixMap["months"] : "months ago");
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " " + (suffixMap && suffixMap["days"] ? suffixMap["days"] : "days ago");
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " " + (suffixMap && suffixMap["hours"] ? suffixMap["hours"] : "hours ago");
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " " + (suffixMap && suffixMap["minutes"] ? suffixMap["minutes"] : "minutes ago");
    }
    return Math.floor(seconds) + " " + (suffixMap && suffixMap["seconds"] ? suffixMap["seconds"] : "seconds ago");
}

export const toReadableDate = (date) => {
  return new Date(date).toString().substring(4,15)
}


export default timeSince