import debug_log from "./debug_log";
import general_fetch from "./fetch";

async function upload_image({ type, sku_id, upl }) {
    try{
        var fd = new FormData();
        fd.append('upl', upl)
        sku_id && fd.append('sku_id', sku_id);
        
        var resp = await general_fetch({ url: `/file_transfer/upload_top_view_${type}`, body:fd, is_form_data: true });
        debug_log('upload complete')
        return resp;
    }catch(err){
        console.error("Captured error while trying to upload image for sku - " + sku_id, err)
        throw err
    }
}

export default upload_image