/* eslint-disable no-unused-expressions */
import React, {useEffect} from 'react';
import { Navbar, Nav, NavItem } from "reactstrap";
import { COLORS } from '../../utils/color';
import { Image_Icon, Fa_Icon, Text_Icon } from '../Unit/'
import { useAlert } from 'react-alert'
import ToolBarButtonDropdown from '../ToolbarButtonDropdown';
import ToolBarSectionDropdown from '../ToolBarSectionDropdown';
import { send_analytics } from '../../utils/send_analytics';
import './style.css'

const dummy_tick = (t) => (new Promise((res,rej) => {
    var timer = setTimeout(() => {
        res();
        clearTimeout(timer);
    },t)
})); 


const styles = {
	// owned_style: {
    //     display: "unset",
    //     position: "absolute", 
    //     backgroundColor:"grey", 
    //     color:COLORS.white,
    //     borderRadius:"3px",
    //     zIndex:250,
    //     top:10, 
    //     right:'90px',
    //     fontSize:'12px'
    // },
	text_elipsis : {
		whiteSpace: "nowrap", 
		overflow: "hidden",
		textOverflow: "ellipsis",
		paddingTop: 5
	},
	nav_bar2: {
        // backgroundImage: 'linear-gradient(to bottom, #89b1d3,rgb(187, 204, 220))',
        width:'100%',
        // color: COLORS.white,
        backgroundColor: COLORS.toolbar_background_color,
        height:'60px',
        // padding:'10px 0px 6px 4px'
		// display:'inline-table',
    },
};

const DynamicToolbar = ({ update_view, json_file, handle_ui_response, set_page_loader }) => {

	const alert = useAlert()

	const toolbar_on_click = async (id, item, tab_id) => {
		if(id == "production_details_$cam_outputs_of_all_cabinets" || id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner" || id == "production_details_$fulfillment_tags"){
			set_page_loader({
				show: true,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}


		var promise_resp;
		try{
            if(item){
                var analytics_data = item
                analytics_data.tab_id = tab_id
                analytics_data.category = "toolbar button clicked";
                send_analytics(analytics_data,true)
            };
            if(window.debug_mode){
                window.add_debug_log("toolbar_router(\"" + id.replace(/"/g, '\\"') + "\");");
            }
            promise_resp = window.Module.toolbar_router(id);
		}catch(err){
			err.constructor.name.includes("Error") != true?err=new Error(err):null;
			err.name = "Captured error in Toolbar onclick error at " + id + " : " + err.name;
			console.error(err);
			window.sentry_capture_exception("",err);
			alert.error("Something went wrong")
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			return;
		}

		var ui_response = await window.Promisify(promise_resp);

		handle_ui_response(ui_response);
		
		update_view();

		if(id=="production_details_$cam_outputs_of_all_cabinets" || id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner" || id == "production_details_$fulfillment_tags"){
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}
	}

    // useEffect(() => {
    //     console.log("toolbar_json", json_file)
    // }, [json_file]);

	return (
		<div>
			<Navbar style={{...styles.nav_bar2, padding:0}}>
                <Nav style={{height:'100%', marginLeft:'4px'}}>
                    {
                    	json_file && json_file.sections && json_file.sections.length ? json_file.sections.map((section, section_idx) => (
                            // let icons = section.buttons;
							// <div style={{boxShadow:json_item.active?'0px 0px 7px #314456 inset':'none',cursor:json_item.disabled?'not-allowed':'pointer',opacity:json_item.disabled?'0.3':'1'}} key={json_item.id + String(idx)}>
							// background-color: #F5F6F8;
  							// border-radius: 10px;
                            !section ? '' :
                            <React.Fragment key={section_idx}>
                                <div className={`infurnia_toolbar_section ${section.sub_menu && section.sub_menu.length ? 'show_infurnia_section_highlight' : ''}`}>
                                {
                                    section.buttons && section.buttons.length ? section.buttons.map((json_item, idx) => (
                                        <React.Fragment key={json_item.id + String(idx)}>
                                            <div className={json_item.active == 'true' ?'toolbar_active show_on_hover':'show_on_hover hover_toolbar'} style={{cursor:json_item.disabled == 'true'?'not-allowed':'pointer',opacity:json_item.disabled == 'true'?'0.3':'1', position:'relative', height:'100%', marginRight:'2px', paddingTop:'10px'}} key={json_item.id + String(idx)}>
                                                {	
                                                    json_item.type==="image" ?
                                                    <Image_Icon
                                                        onClick={() => {!(json_item.disabled == 'true')?toolbar_on_click(json_item.id,json_item,json_file.id):''}}
                                                        unit_type={json_item.type?json_item.type:''}
                                                        unit_id={json_item.id?json_item.id:''}
                                                        unit_name={json_item.name?json_item.name:''}
                                                        unit_content={json_item.content?json_item.content:''}
                                                        unit_root={json_item.route?json_item.route:''}
                                                        unit_show={json_item.show?json_item.show:'true'}
                                                        unit_show_name={json_item.show_name?json_item.show_name:''}
                                                        unit_disabled={json_item.disabled == 'true'?json_item.disabled:''}
                                                        unit_active={json_item.active == 'true'?json_item.active:''}
                                                        unit_disabled_message={json_item.disabled_message ? json_item.disabled_message : ''}

                                                    />:""
                                                }
                                                {	
                                                    json_item.type==="text" ?	
                                                    <Text_Icon
                                                        onClick={() => {!(json_item.disabled == 'true')?toolbar_on_click(json_item.id,json_item,json_file.id):""}}
                                                        unit_type={json_item.type?json_item.type:''}
                                                        unit_id={json_item.id?json_item.id:''}
                                                        unit_name={json_item.name?json_item.name:''}
                                                        unit_content={json_item.content?json_item.content:''}
                                                        unit_root={json_item.route?json_item.route:''}
                                                        unit_show={json_item.show?json_item.show:'true'}
                                                        unit_disabled={json_item.disabled == 'true'?json_item.disabled:''}
                                                        unit_active={json_item.active == 'true'?json_item.active:''}
                                                        unit_disabled_message={json_item.disabled_message ? json_item.disabled_message : ''}
                                                    />:""
                                                }
                                                {	
                                                    json_item.type==="icon"	?
                                                    <Fa_Icon
                                                        onClick={() => {!(json_item.disabled == 'true')?toolbar_on_click(json_item.id,json_item,json_file.id):''}}
                                                        unit_type={json_item.type?json_item.type:''}
                                                        unit_id={json_item.id?json_item.id:''}
                                                        unit_name={json_item.name?json_item.name:''}
                                                        unit_content={json_item.content?json_item.content:''}
                                                        unit_root={json_item.route?json_item.route:''}
                                                        unit_show={json_item.show?json_item.show:'true'}
                                                        unit_disabled={json_item.disabled == 'true'?json_item.disabled:''}
                                                        unit_active={json_item.active == 'true'?json_item.active:''}
                                                        unit_disabled_message={json_item.disabled_message ? json_item.disabled_message : ''}
                                                    />:""
                                                }
                                                {	
                                                    json_item.type==="separator"? " " : " "
                                                    
                                                }
                                                {/* {
                                                    json_item.sub_buttons && json_item.sub_buttons.length ?
                                                    <div className='hidden_dropdown' style={{position: 'absolute', top:'40px', zIndex:10000, backgroundColor:COLORS.gray2}}>
                                                        {
                                                            json_item.sub_buttons.map((json_sub_item, idx) => (
                                                                <div className={json_sub_item.active == 'true' ?'toolbar_active':''} style={{cursor:json_sub_item.disabled?'not-allowed':'pointer',opacity:json_sub_item.disabled?'0.3':'1'}} key={json_sub_item.id + String(idx)}>
                                                                    {	
                                                                        json_sub_item.type==="image" ?
                                                                        <Image_Icon
                                                                            onClick={() => {!json_sub_item.disabled?toolbar_on_click(json_sub_item.id):''}}
                                                                            unit_type={json_sub_item.type?json_sub_item.type:''}
                                                                            unit_id={json_sub_item.id?json_sub_item.id:''}
                                                                            unit_name={json_sub_item.name?json_sub_item.name:''}
                                                                            unit_content={json_sub_item.content?json_sub_item.content:''}
                                                                            unit_root={json_sub_item.route?json_sub_item.route:''}
                                                                            unit_show={json_sub_item.show?json_sub_item.show:'true'}
                                                                            unit_disabled={json_sub_item.disabled?json_sub_item.disabled:''}
                                                                            unit_active={json_sub_item.active == 'true'?json_sub_item.active:''}
                                                                        />:""
                                                                    }
                                                                    {	
                                                                        json_sub_item.type==="text" ?	
                                                                        <Text_Icon
                                                                            onClick={() => {!json_sub_item.disabled?toolbar_on_click(json_sub_item.id):""}}
                                                                            unit_type={json_sub_item.type?json_sub_item.type:''}
                                                                            unit_id={json_sub_item.id?json_sub_item.id:''}
                                                                            unit_name={json_sub_item.name?json_sub_item.name:''}
                                                                            unit_content={json_sub_item.content?json_sub_item.content:''}
                                                                            unit_root={json_sub_item.route?json_sub_item.route:''}
                                                                            unit_show={json_sub_item.show?json_sub_item.show:''}
                                                                            unit_disabled={json_sub_item.disabled?json_sub_item.disabled:''}
                                                                            unit_active={json_sub_item.active?json_sub_item.active:''}
                                                                        />:""
                                                                    }
                                                                    {	
                                                                        json_sub_item.type==="icon"	?
                                                                        <Fa_Icon
                                                                            onClick={() => {!json_sub_item.disabled?toolbar_on_click(json_sub_item.id):''}}
                                                                            unit_type={json_sub_item.type?json_sub_item.type:''}
                                                                            unit_id={json_sub_item.id?json_sub_item.id:''}
                                                                            unit_name={json_sub_item.name?json_sub_item.name:''}
                                                                            unit_content={json_sub_item.content?json_sub_item.content:''}
                                                                            unit_root={json_sub_item.route?json_sub_item.route:''}
                                                                            unit_show={json_sub_item.show?json_sub_item.show:''}
                                                                            unit_disabled={json_sub_item.disabled?json_sub_item.disabled:''}
                                                                            unit_active={json_sub_item.active?json_sub_item.active:''}
                                                                        />:""
                                                                    }
                                                                    {	
                                                                        json_sub_item.type==="separator"? " " : " "
                                                                        
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    : ''
                                                } */}
                                            </div>
                                            {/* {
                                                json_item.sub_buttons && json_item.sub_buttons.length ?
                                                <div className= {json_item.disabled == 'true' ? '' : 'show_on_hover'} title={!json_item.disabled == 'true' ? '' : 'Item dropdown disabled'} style={{height:'40px', width:'10px', display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer', zIndex:2, marginRight:'4px', opacity: json_item.disabled == 'true' ? 0.3 : 1}}>
                                                {
                                                    <NavItem style={{listStyleType:'none'}}>
                                                        <img style={{color:COLORS.white}} width='8px' src="/resources/toolbar_icons_new/dropdown.svg" />
                                                        <div style={{width:0, borderRight:'2px solid ' + COLORS.gray3, height: 24}}></div>
                                                    </NavItem>
                                                }
                                                <div className='hidden_dropdown' style={{position: 'absolute', top:'40px', zIndex:10, backgroundColor:COLORS.gray2}}>
                                                    {
                                                        json_item.sub_buttons.map((json_sub_item, idx) => (
                                                            <div className={json_sub_item.active == 'true' ?'toolbar_active':''} style={{cursor:json_sub_item.disabled == 'true'?'not-allowed':'pointer',opacity:json_sub_item.disabled == 'true'?'0.3':'1'}} key={json_sub_item.id + String(idx)}>
                                                                {	
                                                                    json_sub_item.type==="image" ?
                                                                    <Image_Icon
                                                                        onClick={() => {!(json_sub_item.disabled == 'true')?toolbar_on_click(json_sub_item.id):''}}
                                                                        unit_type={json_sub_item.type?json_sub_item.type:''}
                                                                        unit_id={json_sub_item.id?json_sub_item.id:''}
                                                                        unit_name={json_sub_item.name?json_sub_item.name:''}
                                                                        unit_content={json_sub_item.content?json_sub_item.content:''}
                                                                        unit_root={json_sub_item.route?json_sub_item.route:''}
                                                                        unit_show={json_sub_item.show?json_sub_item.show:'true'}
                                                                        unit_disabled={(json_sub_item.disabled == 'true')?json_sub_item.disabled:''}
                                                                        unit_active={json_sub_item.active == 'true'?json_sub_item.active:''}
                                                                        unit_disabled_message={json_sub_item.disabled_message ? json_sub_item.disabled_message : ''}
                                                                    />:""
                                                                }
                                                                {	
                                                                    json_sub_item.type==="text" ?	
                                                                    <Text_Icon
                                                                        onClick={() => {!(json_sub_item.disabled == 'true')?toolbar_on_click(json_sub_item.id):""}}
                                                                        unit_type={json_sub_item.type?json_sub_item.type:''}
                                                                        unit_id={json_sub_item.id?json_sub_item.id:''}
                                                                        unit_name={json_sub_item.name?json_sub_item.name:''}
                                                                        unit_content={json_sub_item.content?json_sub_item.content:''}
                                                                        unit_root={json_sub_item.route?json_sub_item.route:''}
                                                                        unit_show={json_sub_item.show?json_sub_item.show:'true'}
                                                                        unit_disabled={(json_sub_item.disabled == 'true')?json_sub_item.disabled:''}
                                                                        unit_active={json_sub_item.active == 'true'?json_sub_item.active:''}
                                                                        unit_disabled_message={json_item.disabled_message ? json_item.disabled_message : ''}
                                                                    />:""
                                                                }
                                                                {	
                                                                    json_sub_item.type==="icon"	?
                                                                    <Fa_Icon
                                                                        onClick={() => {!(json_sub_item.disabled == 'true')?toolbar_on_click(json_sub_item.id):''}}
                                                                        unit_type={json_sub_item.type?json_sub_item.type:''}
                                                                        unit_id={json_sub_item.id?json_sub_item.id:''}
                                                                        unit_name={json_sub_item.name?json_sub_item.name:''}
                                                                        unit_content={json_sub_item.content?json_sub_item.content:''}
                                                                        unit_root={json_sub_item.route?json_sub_item.route:''}
                                                                        unit_show={json_sub_item.show?json_sub_item.show:'true'}
                                                                        unit_disabled={(json_sub_item.disabled == 'true')?json_sub_item.disabled:''}
                                                                        unit_active={json_sub_item.active == 'true'?json_sub_item.active:''}
                                                                        unit_disabled_message={json_item.disabled_message ? json_item.disabled_message : ''}
                                                                    />:""
                                                                }
                                                                {	
                                                                    json_sub_item.type==="separator"? " " : " "
                                                                    
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                </div>
                                                : ''
                                            } */}
                                            {
                                                json_item.sub_buttons && json_item.sub_buttons.length ? 
                                                    <ToolBarButtonDropdown json_item = {json_item} set_page_loader={set_page_loader} update_view={update_view} handle_ui_response={handle_ui_response} tab_id = {json_file.id}></ToolBarButtonDropdown>
                                                : ''
                                            }
                                        </React.Fragment>
                                    )) 
                                    : ''
                                }

                                {/* {
                                    section.sub_menu && section.sub_menu.length ? 
                                        <div className='show_on_hover' style={{height:'40px', width:'32px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                            {
                                                <NavItem style={{listStyleType:'none'}}>
                                                    <img style={{color:COLORS.white}} width='12px' src="/resources/toolbar_icons_new/dropdown.svg" />
                                                    <div style={{width:0, borderRight:'2px solid ' + COLORS.gray3, height: 24}}></div>
                                                </NavItem>
                                            }
                                            <div className='hidden_dropdown' style={{position: 'absolute', top:'40px', zIndex:10, backgroundColor:COLORS.white, padding: '8px'}}>
                                                <div style={{backgroundColor:COLORS.gray2, display:'flex'}}>
                                                    {
                                                        section.sub_menu.map((json_item, idx) => (
                                                            <div className={json_item.active == 'true' ?'toolbar_active':''} style={{cursor:json_item.disabled == 'true' ?'not-allowed':'pointer',opacity:json_item.disabled == 'true'?'0.3':'1'}} key={json_item.id + String(idx)}>
                                                                {	
                                                                    json_item.type==="image" ?
                                                                    <Image_Icon
                                                                        onClick={() => {!(json_item.disabled == 'true')?toolbar_on_click(json_item.id):''}}
                                                                        unit_type={json_item.type?json_item.type:''}
                                                                        unit_id={json_item.id?json_item.id:''}
                                                                        unit_name={json_item.name?json_item.name:''}
                                                                        unit_content={json_item.content?json_item.content:''}
                                                                        unit_root={json_item.route?json_item.route:''}
                                                                        unit_show={json_item.show?json_item.show:'true'}
                                                                        unit_disabled={(json_item.disabled == 'true')?json_item.disabled:''}
                                                                        unit_active={json_item.active == 'true'?json_item.active:''}
                                                                        unit_disabled_message={json_item.disabled_message ? json_item.disabled_message : ''}
                                                                    />:""
                                                                }
                                                                {	
                                                                    json_item.type==="text" ?	
                                                                    <Text_Icon
                                                                        onClick={() => {!(json_item.disabled == 'true')?toolbar_on_click(json_item.id):""}}
                                                                        unit_type={json_item.type?json_item.type:''}
                                                                        unit_id={json_item.id?json_item.id:''}
                                                                        unit_name={json_item.name?json_item.name:''}
                                                                        unit_content={json_item.content?json_item.content:''}
                                                                        unit_root={json_item.route?json_item.route:''}
                                                                        unit_show={json_item.show?json_item.show:'true'}
                                                                        unit_disabled={(json_item.disabled == 'true')?json_item.disabled:''}
                                                                        unit_active={json_item.active == 'true'?json_item.active:''}
                                                                        unit_disabled_message={json_item.disabled_message ? json_item.disabled_message : ''}
                                                                    />:""
                                                                }
                                                                {	
                                                                    json_item.type==="icon"	?
                                                                    <Fa_Icon
                                                                        onClick={() => {!(json_item.disabled == 'true')?toolbar_on_click(json_item.id):''}}
                                                                        unit_type={json_item.type?json_item.type:''}
                                                                        unit_id={json_item.id?json_item.id:''}
                                                                        unit_name={json_item.name?json_item.name:''}
                                                                        unit_content={json_item.content?json_item.content:''}
                                                                        unit_root={json_item.route?json_item.route:''}
                                                                        unit_show={json_item.show?json_item.show:'true'}
                                                                        unit_disabled={(json_item.disabled == 'true')?json_item.disabled:''}
                                                                        unit_active={json_item.active == 'true'?json_item.active:''}
                                                                        unit_disabled_message={json_item.disabled_message ? json_item.disabled_message : ''}
                                                                    />:""
                                                                }
                                                                {	
                                                                    json_item.type==="separator"? " " : " "
                                                                    
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    : ''
                                } */}
                                {
                                    section.sub_menu && section.sub_menu.length ? 
                                        <ToolBarSectionDropdown json_item = {section.sub_menu} set_page_loader={set_page_loader} update_view={update_view} handle_ui_response={handle_ui_response} tab_id = {json_file.id}></ToolBarSectionDropdown>
                                    : ''
                                }
                                </div>

                                <div className='' style={{height:'60px', width:'16px', display: json_file.sections.length - 1 != section_idx ? 'flex' : 'none', alignItems:'center', justifyContent:'center'}}>
                                    {
                                        <NavItem key={"spearator" + section_idx} style={{listStyleType:'none'}}>
                                            {/* <img style={{color:COLORS.white}} height='24px' width='24px' src={new_src?new_src:''} /> */}
                                            <div style={{width:'2px', /*borderRight:'2px solid ' +*/ backgroundColor: COLORS.gray3, height: 40, borderRadius: '2px'}}></div>
                                        </NavItem>
                                    }
                                </div>
                            </React.Fragment>
                        ))
						:''
                    }
                </Nav>
            </Navbar>
		</div>
	)
}


export default DynamicToolbar;

