/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Modal, Navbar, Button, Input, Card, Col, Nav, Row, tbody, thead, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";

import { useAlert } from 'react-alert'
import { IModalBody, IModalFooter, IModalHeader } from '../../components/IModal';
import ISearch from '../../components/ISearch';
import MainPageLayout from '../../components/MainPageLayout/index.js';
import ImageCarousel from '../../components/ImageCarousel/index.js';
import timeSince, { toReadableDate } from '../../utils/time_utils.js';
import DesignsLayout from '../../components/DesignsLayout/index.js';
import './style.css'
import { Addn_prop_modal, Copy_Design_Modal, Edit_Design_Modal, Lock_Warning_Modal, New_Project_Modal, Share_Branch_Modal_New } from '../../components/CommonModals/index.js';
import changeProject from '../../utils/changeProject.js';
import { ITD, ITH, ITable, ITableBody, ITableHeader, ITableRow } from '../../components/ITable/index.js';
import {useTranslation} from 'react-i18next'
import { COLORS } from '../../utils/color.js';
import Design_Dropdown from '../../components/Design_Dropdown/index.js';
import IImg from '../../components/IImg/index.js';
import { send_analytics } from '../../utils/send_analytics.js';


const get_image_src = (design_item) => {
    return (design_item && design_item.display_pic && design_item.display_pic.image) ? (design_item.display_pic.image.includes('resources/') ? (design_item.display_pic.image) : (window.Module.API.server_path + design_item.display_pic.image)) : '/resources/images/site_image.jpeg';
}


const Edit_Project_Modal = ({ open, project_name, client_name, client_details, update_project, project_status_id, handle_close, active_project, set_page_loader, order_id }) => {

    const [status_selected, set_status_selected] = useState('null')
    const [updated_project_name, set_updated_project_name] = useState('')
    const [updated_order_id, set_updated_order_id] = useState('')
    const [updated_client_name, set_updated_client_name] = useState('')
    const [updated_client_details, set_updated_client_details] = useState('');
    const [status_options, set_status_options] = useState([])
    const alert = useAlert()

    const on_click_close = () => {
        set_updated_project_name('')
        set_updated_client_name('')
        set_updated_order_id('')
        set_status_selected('null')
        handle_close()
    }
    const validate_project_name = (project_name) => {
        var re = /^[a-zA-Z0-9 ]+$/
        return project_name.trim() && re.test(project_name)
    }

    const submit_values = () => {
        if (validate_project_name(updated_project_name)) {
            update_project(active_project, updated_project_name, updated_client_name, updated_client_details, status_selected, updated_order_id)
            // handle_close()
            on_click_close()
        } else {
            alert.error('Special Characters Not permitted. Please modify the Project Name')
        }
    }

    useEffect(() => {
        if (open) {
            if (project_name) {
                set_updated_project_name(project_name);
            }
            if (client_name) {
                set_updated_client_name(client_name);
            }
            if(client_details){
                set_updated_client_details(client_details);
            }
            if (order_id) {
                set_updated_order_id(order_id);
            }
            if (project_status_id) {
                set_status_selected(project_status_id)
            }

            window.Promisify(window.Module.get_store_status())
                .then(resp => {
                    // console.log("resp proj d", resp )
                    set_status_options(JSON.parse(resp));
                    // if(!project_status_id){
                    //     set_status_selected(JSON.parse(resp)[0].id);
                    // }
                })
        }
    }, [open, project_name, client_name, client_details, project_status_id, order_id])

    return (
        <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={on_click_close}>
            <IModalHeader toggle={on_click_close}>
                Edit Project
            </IModalHeader>
            <IModalBody>
                <div className='edit_project_modal_div'>
                    <span style={{ width: '120px', fontSize: '14px', display: 'flex', alignItems: 'center' }}>Project Name*</span>
                    <Input style={{ width: '280px' }} type="text" value={updated_project_name} onChange={(e) => set_updated_project_name(e.target.value)} />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{ width: '120px', fontSize: '14px', display: 'flex', alignItems: 'center' }}>Client Name</span>
                    <Input style={{ width: '280px' }} type="text" value={updated_client_name} onChange={(e) => set_updated_client_name(e.target.value)} />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{width:'120px', fontSize:'14px', display:'flex', alignItems:'center'}}>Client Details</span>
                    <Input style={{width:'280px'}} type="text" value={updated_client_details} onChange={(e) => set_updated_client_details(e.target.value) } />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{ width: '120px', fontSize: '14px', display: 'flex', alignItems: 'center' }}>Project ID</span>
                    <Input style={{ width: '280px' }} type="text" value={updated_order_id} onChange={(e) => set_updated_order_id(e.target.value)} />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{ width: '120px', fontSize: '14px', display: 'flex', alignItems: 'center' }}>Project Status</span>
                    <Input style={{ width: '280px' }} type="select" value={status_selected} onChange={(e) => set_status_selected(e.target.value)}>
                        <option value='null'>None</option>
                        {status_options.map((itm, idx) => (
                            <option key={idx} value={itm.id}>{itm.status}</option>
                        ))}
                    </Input>
                </div>
            </IModalBody>

            <IModalFooter style={{}}>
                <Button style={{ marginRight: '32px' }} className='primary_button_default' onClick={submit_values}>Update</Button>
            </IModalFooter>
        </Modal>

    )
}

const Project_Table = ({ org_key, show_alert_box, all_project_list_display, user_id, project_status, update_project, Filler }) => {
    const history = useHistory();
    const [is_project_settings_open, set_is_project_settings_open] = useState(false);
    const [is_edit_modal_open, set_is_edit_modal_open] = useState(false);
    const [active_list, set_active_list] = useState({});
    const {t} = useTranslation()

    const handle_close = () => {
        set_is_edit_modal_open(false)
    }

    useEffect(() => {
        console.log('edit', is_edit_modal_open)
    }, [is_edit_modal_open]);

    return (
        <div className='inf-p-2' style={{height: '100%'}}>
            <Edit_Project_Modal open={is_edit_modal_open} project_name={active_list.name} client_name={active_list.client_name} client_details={active_list.client_details} update_project={update_project} project_status_id={active_list.project_status_id} handle_close={handle_close} active_project={active_list.id} order_id={active_list.order_id}></Edit_Project_Modal>
            <ITable rowSeparated={true} hover={true} style_container={{ maxHeight: '100%', overflow: 'auto', whiteSpace: 'nowrap', background: 'white'}}>
                <colgroup>
                    <col style={{width: '30%'}}></col>
                    <col style={{width: '14%'}}></col>
                    <col style={{width: '14%'}}></col>
                    <col style={{width: '14%'}}></col>
                    <col style={{width: '14%'}}></col>
                    <col style={{width: '7%'}}></col>
                    <col style={{width: '7%'}}></col>
                </colgroup>
                <ITableHeader style={{ position: 'sticky', top: '0px', backgroundColor: 'white', height: '54px' }}>
                    <ITH style={{paddingLeft: '32px'}}>Project Name</ITH>
                    <ITH>Client Name </ITH>
                    <ITH>Project Id </ITH>
                    <ITH>Status </ITH>
                    <ITH>Last Update </ITH>
                    <ITH></ITH>
                    <ITH></ITH>
                </ITableHeader>
                <ITableBody>
                    {
                        all_project_list_display && all_project_list_display.length ? all_project_list_display.map((list_item, index) => (
                            <ITableRow onClick={() => { changeProject(list_item.id, history) }} key={index} className='visible_on_hover' style={{height: '48px', fontWeight: 300, fontSize: '12px', cursor: 'pointer'}}>
                                <ITD title={list_item.name ? list_item.name : "-"} className='lines1_elipsis' style={{paddingLeft: '32px', fontSize: '14px', maxWidth: '300px'}}>{list_item.name ? list_item.name : "-"}&nbsp;&nbsp;<span style={{color: 'var(--inf-theme-gray-500)'}}>{user_id !== list_item.user_id ? "(Non Owned)" : ""}</span></ITD>
                                <ITD title={list_item.client_name ? list_item.client_name : "-"} className='lines1_elipsis'>{list_item.client_name ? list_item.client_name : "-"}</ITD>
                                <ITD title={list_item.order_id ? list_item.order_id : "-"} className='lines1_elipsis'>{list_item.order_id ? list_item.order_id : "-"}</ITD>
                                <ITD>{list_item.project_status_id && list_item.project_status_id != 'null' ? project_status ? project_status.find(x => x.id == list_item.project_status_id) ? project_status.find(x => x.id == list_item.project_status_id).status : list_item.project_status_id : list_item.project_status_id : "-"}</ITD>
                                <ITD>{list_item.last_activity_at ? toReadableDate(list_item.last_activity_at) : "-"}</ITD>
                                <ITD><div className="invisible" style={{ color: 'var(--inf-theme-gray-500)', fontStyle: 'italic' }}>{t(`Click to open`)}</div></ITD>
                                {
                                    user_id == list_item.user_id ?
                                        (
                                            <ITD>
                                                <Dropdown onClick={(e) => {e.stopPropagation()}} isOpen={is_project_settings_open === list_item.id} toggle={() => { if (is_project_settings_open === list_item.id) { set_is_project_settings_open(false) } else { set_is_project_settings_open(list_item.id) } }} style={{display: 'flex', justifyContent: 'center'}}>
                                                    <DropdownToggle className="invisible" style={{ borderRadius: '4px', backgroundColor: 'white', color: '#22272E', alignItems: 'center', width: '32px', height: '32px', textAlign: 'center', padding: '0px', border:'none', boxShadow: 'none' }}>
                                                        {/* <i style={{ fontSize: '18px', padding: '2px' }} className='fa fa-cog'></i> */}
                                                        <div className={`perspective_item ${is_project_settings_open === list_item.id ? 'perspective_item_semi_active' : ''}`} style={{}}>
                                                            <img width={'16px'} src='/resources/icons/options.svg'></img>
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu right container="body" className='DMSans' style={{minWidth: '120px'}}>
                                                        <DropdownItem style={{padding: '6px 12px'}} onClick={() => {
                                                            set_is_edit_modal_open(!is_edit_modal_open)
                                                            set_active_list(list_item)
                                                        }}>Edit Project</DropdownItem>
                                                        <DropdownItem style={{padding: '6px 12px'}} onClick={() => show_alert_box(list_item.id)}>Delete</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                                {/* <i style={{fontSize:'18px', color:'red', cursor:'pointer'}} onClick={() => show_alert_box(list_item.id)} className='fa fa-trash hide'/> */}
                                            </ITD>
                                        )
                                        : (<ITD></ITD>)}
                            </ITableRow>
                        )) :
                            <div style={{ padding: '6px 12px', height: '40px', color: 'black', backgroundColor: 'white' }}>{t(`No Projects to list. Try changing Search parameter or create New Project.`)}</div>
                    }
                </ITableBody>
            </ITable>
        </div>
    )
}

const RecentProjectCard = ({ project, project_to_design_map = {} }) => {

    const history = useHistory()

    return (
        // <div /*className='inf-flex-grid-item inf-px-5 inf-mb-9'*/>
        <div style={{ cursor: 'pointer', boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.06)', borderRadius: '4px', overflow: 'hidden' }} /*onClick={pre_image_onclick_fn}*/ onClick={() => { changeProject(project.id, history) }} /*className='scale_105'*/>
            <div style={{ position: 'relative' }} className='show_on_hover'>
                {/* <img style={{ width: '100%', objectFit: 'cover' }} src={get_image_src()} /> */}
                <ImageCarousel images={project_to_design_map[project.id] ? project_to_design_map[project.id].map(x => get_image_src(x)) : []} />
                {/* <div className='hide' style={{...styles.text_elipsis, color:COLORS.black, fontWeight:500, padding:'6px 12px 0px 12px', backgroundColor:'transparent', fontSize:'12px', position:'absolute', bottom:'6px', fontStyle:'italic', textAlign:'center', width: '100%' }} title={''}>{'needs work'}</div> */}
            </div>
            <div className='inf-px-3 inf-py-2 recent-project-card-footer'>
                <div className='inf-flex inf-justify-between inf-mb-1'>
                    <div className='inf-font-medium lines1_elipsis inf-mr-3 recent-project-card-footer-project-name' title={project && project.name ? project.name : ''}>{project && project.name ? project.name : ''}</div>
                    <div className='flex_property' style={{ color: 'var(--inf-theme-gray-400)' }}>{project_to_design_map[project.id] ? project_to_design_map[project.id].length : 0}&nbsp;<img src='/resources/MainPageIcons/file-outline-light.svg' height={'12px'}></img></div>
                </div>
                <div className='inf-flex inf-justify-between inf-text-xs inf-font-light'>
                    <div className='lines1_elipsis inf-mr-3 recent-project-card-footer-secondary-info' style={{ color: 'var(--inf-theme-gray-400)' }} title={project && project.client_name ? project.client_name : '-'}>Client: {project && project.client_name ? project.client_name : '-'}</div>
                    <div style={{ color: 'var(--inf-theme-gray-400)', whiteSpace: 'nowrap' }}>{timeSince(project.last_activity_at, {hours: 'hr ago', minutes: 'min ago', seconds: 'sec ago'})}</div>
                </div>
            </div>
        </div>
        // </div>
    )
}

const ProjectsGrid = ({ list_display, project_to_design_map }) => {
    return (
        <div className='inf-p-2' /*className='inf-py-3 inf-flex-grid inf-wrap-5-lg inf-wrap-2-md inf-gap-0'*/ style={{ display: 'grid', gap: '36px', gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`, }}>
            {
                list_display && list_display.length ?
                    list_display.map((list_item, idx) => (
                        <RecentProjectCard project={list_item} project_to_design_map={project_to_design_map}/>
                    ))
                    :
                    // <Filler initialized={initialized} message='Please create new project and create design.' />
                    ''
            }
        </div>
    )
}

const RecentProjects = ({ list_display, project_to_design_map }) => {
    const {t} = useTranslation()
    return (
        <div className='inf-p-2'>
            {/* // <div className='inf-flex inf-flex-col inf-px-5 inf-text-left' style={{ width: '100%' }}> */}
            <div className='inf-mb-3 inf-px-2 inf-justify-between inf-flex'>
                <div className='inf-text-base inf-font-semibold'><div className='inf-flex inf-items-center'> <img className='inf-mr-2' src='/resources/MainPageIcons/folder-outline-dark.svg'></img>{t(`Recent Projects`)}</div></div>
                { window.Module.is_feature_enabled('project_creation') ? <TriggerModal modalToggle={<div className='primary_button_ghost' style={{fontWeight: 600}}> + Create new project</div>} ModalComponent={New_Project_Modal} /> : '' }
            </div>
            <div>
                {
                    list_display && list_display.length ?
                    <ProjectsGrid list_display={list_display} project_to_design_map={project_to_design_map}/>
                    :
                    <FillerEmptyState message={"No Projects to display"} />
                }
            </div>
            {/* // </div> */}
        </div>
    )
}


const RecentDesigns = ({initialized, recent_designs, fetch_recent_designs, submit_for_delete_confirm, set_page_loader, org_key, store_details}) => (
    <div className='inf-p-2'>
        {/* <div className='inf-flex inf-flex-col inf-px-5 inf-text-left' style={{ width: '100%' }}> */}
        <div className='inf-py-3 inf-px-2'>
            <div className='inf-text-base inf-font-semibold'><div className='inf-flex inf-items-center'> <img className='inf-mr-2' src='/resources/MainPageIcons/file-outline.svg'></img>Recent Designs</div></div>
        </div>
        <div style={{ flex: 1 }}>
            {
                initialized && recent_designs && recent_designs.length ?

                    <div /*className='inf-py-3 inf-flex-grid inf-wrap-5-lg inf-wrap-2-md inf-gap-0'*/ className='inf-p-2' style={{ display: 'grid', gap: '36px', gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))` }}>
                        {
                            // <Card_Layout recent_card_div_height={recent_card_div_height} list={recent_designs} image_onclick_fn={image_onclick_fn} no_of_cards={3} page_name='main' set_page_loader={set_page_loader} />
                            recent_designs.map((list_item, idx) => (
                                <RecentDesignCard key={list_item.current_design_branch_id} design={list_item} fetch_recent_designs={fetch_recent_designs} submit_for_delete_confirm={submit_for_delete_confirm} set_page_loader={set_page_loader} org_key={org_key} store_details={store_details}/>
                            ))
                            // <GridLayout items={recent_designs} gap={16} />
                            // :
                            // <Filler initialized={initialized} message='Please create new project and create design.' />
                            // ''
                        }
                    </div>
                    :
                    <FillerEmptyState message={"No Recent Designs available"} />
            }
        </div>
        {/* </div> */}
    </div>
)

const RecentDesignCard = ({ design, fetch_recent_designs, submit_for_delete_confirm, set_page_loader, org_key, store_details }) => {
     // ----------------------------------

     const [show_edit_design_modal, set_show_edit_design_modal] = useState(false);
     const [show_addn_prop_modal, set_show_addn_prop_modal] = useState(false);
     const [show_share_design, set_show_share_design] = useState(false);
     const [active_item, set_active_item] = useState('');
     const [is_lock_warning_open, set_is_lock_warning_open] = useState(false);
     const [show_copy_design_modal, set_show_copy_design_modal] = useState(false);
     const [user_id, set_user_id] = useState('');

     const alert = useAlert()
 
     const onclick_open_modal = (active_item, modal_type) => {
         set_active_item(active_item ? active_item : '')
         if (modal_type === 'edit_design') {
             set_show_edit_design_modal(true)
         }
         if (modal_type === 'copy_design') {
             set_show_copy_design_modal(true)
         }
         if (modal_type === 'addn_prop') {
             set_show_addn_prop_modal(true)
         }
         if (modal_type === 'lock_design') {
             set_is_lock_warning_open(true)
         }
         if (modal_type === 'share_design') {
             set_show_share_design(true)
         }
     }
 
     const onclick_close_modal = (modal_type) => {
         set_active_item('')
         if (modal_type === 'edit_design') {
             set_show_edit_design_modal(false)
         }
         if (modal_type === 'copy_design') {
             set_show_copy_design_modal(false)
         }
         if (modal_type === 'addn_prop') {
             set_show_addn_prop_modal(false)
         }
         if (modal_type === 'share_design') {
             set_show_share_design(false)
         }
         if (modal_type === 'lock_design') {
             set_is_lock_warning_open(false)
         }
     }

    //  ------------------------------------------------------
    const history = useHistory()

    const image_onclick_fn = (current_design_branch_id) => {
        window.trigger_page_loader(true, 'Loading your design')
        setTimeout(async () => {
            //await dummy_tick(0);
            if (current_design_branch_id) {
                var url = '/design/' + current_design_branch_id;
                (org_key && window.location.origin.includes("infurnia.com") ? (url = "/" + org_key + url) : null);
                history.push(url)
            }
        }, 0)
    }

    const rename_design = (design_id, name) => {
        set_page_loader({
            show: true,
            text: 'Renaming design...'
        });

        setTimeout(async () => {
            var response = await window.Promisify(window.Module.rename_design(design_id, name))
            fetch_recent_designs();

            alert.success("Design renamed successfully");

            set_page_loader({
                show: false,
                text: 'Please wait'
            });
        }, 0)
    }

    const copy_design = (branch_id, project_id, name, advanced_paste_options) => {
        set_page_loader({
            show: true,
            text: 'Copying your design...'
        });

        setTimeout(async () => {
            var response;
            // console.log("kpp resp advanced paste options", advanced_paste_options)
            if(advanced_paste_options)
                response = await window.Promisify(window.Module.clone_design(branch_id, project_id, name, JSON.stringify(advanced_paste_options)))
            else
                response = await window.Promisify(window.Module.clone_design(branch_id, project_id, name, JSON.stringify({})))
            var clone_design_interval = setInterval(async () => {
                // var resp = await window.Promisify(window.Module.get_clone_design_status());
                var resp = await window.Promisify(window.Module.get_await_core_status("clone_design"));
                var resp_json = JSON.parse(resp);
                console.log(resp_json["status"] + " status")
                if (resp_json["status"] == "execution_done") {
                    clearInterval(clone_design_interval);
                    if (resp_json["response_code"] == "out_of_license") {
                        alert.error("Out of license to create new design")
                    } else if (resp_json["response_code"] == "couldn't_create_design") {
                        alert.error("Couldn't copy design");
                    } else {
                        fetch_recent_designs();
                        alert.success("Design copied successfully");
                    }
                    set_page_loader({
                        show: false,
                        text: 'Please wait'
                    });
                }
            }, 100)

        }, 0)
    }

    const update_design_additional_properties = (design_id, additional_properties) => {
        set_page_loader({
            show: true,
            text: 'Updating design additional properties...'
        });

        setTimeout(async () => {
            var response = await window.Promisify(window.Module.update_design_additional_properties(design_id, additional_properties))
            fetch_recent_designs();
            alert.success('Design additional properties updated')

            set_page_loader({
                show: false,
                text: 'Please wait'
            });
        }, 0)
    }

    useEffect(() => {
        set_user_id(window.Module.get_active_user().designer_id)
    }, []);


    return (
        // <div /*className='inf-flex-grid-item inf-px-5 inf-mb-9'*/>
        <div style={{position: 'relative'}} className='visible_on_hover'>
            <Lock_Warning_Modal open={is_lock_warning_open} handle_close={() => {onclick_close_modal("lock_design")}} active_item={active_item} onSubmit={fetch_recent_designs}></Lock_Warning_Modal>
            <Edit_Design_Modal open={show_edit_design_modal} rename_design={rename_design} handle_close={onclick_close_modal} active_design={active_item} />
            <Addn_prop_modal open={show_addn_prop_modal} update_design_additional_properties={update_design_additional_properties} handle_close={onclick_close_modal} active_design={active_item} set_page_loader={set_page_loader} />
            <Share_Branch_Modal_New org_key={org_key} open={show_share_design} handle_close={() => onclick_close_modal('share_design')} active_design={active_item} set_page_loader={set_page_loader} />
            <Copy_Design_Modal open={show_copy_design_modal} copy_design={copy_design} handle_close={onclick_close_modal} active_design={active_item} set_page_loader={set_page_loader} user_id={user_id} />
            <Design_Dropdown onclick_open_modal={onclick_open_modal} active_item={design} submit_for_delete_confirm={submit_for_delete_confirm} set_page_loader={set_page_loader} fetchUpdatedData={fetch_recent_designs} style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 2}} className='invisible design_dropdown' store_details={store_details} project_details={{client_name: design && design.Project && design.Project.client_name, project_name: design && design.Project && design.Project.name}}/>
            <div style={{ cursor: 'pointer', boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.06)', borderRadius: '4px', overflow: 'hidden', border: '1px solid var(--light-border-color)' }} onClick={() => { image_onclick_fn(design.current_design_branch_id) }} className='scale_105'>
                <div style={{ position: 'relative' }} className='show_on_hover'>
                    <IImg style={{ width: '100%', objectFit: 'cover', borderBottom: '1px solid var(--light-border-color)', aspectRatio: '16/9' }} src={get_image_src(design)} />
                    {
                        // <div className='hide' style={{ ...styles.text_elipsis, color: COLORS.black, fontWeight: 500, padding: '6px 12px 0px 12px', backgroundColor: 'transparent', fontSize: '12px', position: 'absolute', bottom: '6px', fontStyle: 'italic', textAlign: 'center', width: '100%' }} title={''}>{'needs work'}</div>
                    }
                </div>
                <div className='inf-px-3 inf-py-2' style={{ background: 'white' }}>
                    <div className='inf-font-medium lines1_elipsis inf-mb-1' style={{ color: 'var(--inf-theme-gray-700)' }} title={design && design.name ? design.name : ''}>{design && design.name ? design.name : ''}</div>
                    <div className='inf-flex inf-justify-between inf-text-xs inf-font-light'>
                        <div className='lines1_elipsis inf-mr-3' style={{ color: 'var(--inf-theme-gray-500)' }} title={design.Project && design.Project.name ? design.Project.name : ''}>In: {design.Project && design.Project.name ? design.Project.name : ''}</div>
                        <div style={{ color: 'var(--inf-theme-gray-500)', whiteSpace: 'nowrap' }}>{timeSince(design.last_activity_at, {hours: 'hr ago', minutes: 'min ago', seconds: 'sec ago'})}</div>
                    </div>
                </div>
            </div>
        </div>
        // </div>
    )
}


const TriggerModal = ({modalToggle, ModalComponent}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => setIsOpen(!isOpen)

    return(
        <React.Fragment>
            <ModalComponent open={isOpen} handle_close={toggleModal}/>
            <div className='inf-flex' onClick={toggleModal}>
                {modalToggle}
            </div>
        </React.Fragment>
    )

}

const FillerEmptyState = ({ message }) => {
    return (
        <div className='filler_empty_state'>
            {message}
        </div>
    )
}

const Home = ({ org_key, set_page_loader, store_details, submit_for_delete_confirm, init, init_needed, set_init_needed, logout }) => {


    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const [viewParam, setViewParam] = useState(searchParams.get('view') || 'recent');
    const [projectParam, setProjectParam] = useState('');

    const [recent_designs, set_recent_designs] = useState([])
    const [all_designs, set_all_designs] = useState([]);
    const [recent_card_div_height, set_recent_card_div_height] = useState('')
    const [projects_view, set_projects_view] = useState(false);
    const [list_display, set_list_display] = useState([]);
    const [user_id, set_user_id] = useState('')
    const [project_status, set_project_status] = useState([]);
    const [project_to_design_map, set_project_to_design_map] = useState({});
    const [is_grid_view, set_is_grid_view] = useState(false);
    const [is_create_project_enabled, set_is_create_project_enabled] = useState(false);
   
    const [list, set_list] = useState([])
    const history = useHistory();
    const alert = useAlert()
    const {t} = useTranslation()

    const [initialized, set_initialized] = useState(false);

    const fetch_projectDetails_core = async () => {
        try{
        var response = await window.Promisify(window.Module.getProjects())
        var response_obj = response && JSON.parse(response)
        if (response_obj) {
            set_list(response_obj)
        }
        set_initialized(true)
        }catch(err){
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }
    }

    const fetchProjectAndDesignDetailsCore = async () => {
        try{
        var response = await window.Promisify(window.Module.getProjectsAndDesigns())
        set_initialized(true)
        var response_obj = response && JSON.parse(response)
        console.log('response projects and designs', response_obj)
        if (response_obj && response_obj.projects) {
            let project_ids = Object.keys(response_obj.projects)
            set_list(project_ids.map(id => response_obj["projects"][id]))
        }
        if (response_obj && response_obj.designs) {
            let temp_map = {}
            let design_ids = Object.keys(response_obj.designs)
            design_ids.map(id => {
                if (!temp_map[response_obj["designs"][id].project_id]) {
                    temp_map[response_obj["designs"][id].project_id] = []
                }
                temp_map[response_obj["designs"][id].project_id].push(response_obj["designs"][id])
            })
            set_project_to_design_map(temp_map)
        }
        }catch(err){
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }

    }

    const fetch_projectDetails = async () => {
        return new Promise((resolve, reject) => {
            set_page_loader({
                show: true,
                text: 'Fetching projects...'
            });

            setTimeout(async () => {
                await fetch_projectDetails_core();

                set_page_loader({
                    show: false,
                    text: 'Please wait'
                });
                resolve();
            }, 0)
        }).catch(err => {
            //console.error(err);
            return Promise.reject(err);
        })
    }

    const fetchProjectAndDesignDetails = async () => {
        return new Promise((resolve, reject) => {
            set_page_loader({
                show: true,
                text: 'Fetching projects...'
            });

            setTimeout(async () => {
                await fetchProjectAndDesignDetailsCore();

                set_page_loader({
                    show: false,
                    text: 'Please wait'
                });
                resolve();
            }, 0)
        }).catch(err => {
            //console.error(err);
            return Promise.reject(err);
        })
    }

    const update_project = (active_project_id, project_name, client_name, client_details, project_status_id, order_id) => {
        set_page_loader({
            show: true,
            text: 'Updating project...'
        });

        setTimeout(async () => {
            console.log("proj id", active_project_id)
            var response = await window.Promisify(window.Module.update_project(active_project_id, project_name, client_name, project_status_id, order_id, client_details))
            fetch_projectDetails();
            fetch_recent_designs_core()

            set_page_loader({
                show: false,
                text: 'Please wait'
            });
        }, 0)
    }

    const onclick_delete = (id) => {
        try {
            if (id) {
                set_page_loader({
                    show: true,
                    text: 'Deleting project...'
                }, async () => {
                    //await dummy_tick(0);
                    try {
                        var response = await window.Promisify(window.Module.deleteProject(id));
                        var obj_response = response && JSON.parse(response)

                        if (obj_response && obj_response.response_code == -1) {
                            if (obj_response.action == 'TOAST_ERROR') {
                                alert.error(obj_response.message)
                            } else {
                                alert.error("Could not delete the project. Something went wrong.")
                            }
                        } else {
                            set_list(obj_response)
                            alert.success('Project deleted successfully')
                        }
                    }
                    catch (ee) {
                        alert.error('Project not found')
                    }

                    // await fetch_projectDetails_core();
                    await fetch_recent_designs_core();

                    set_page_loader({
                        show: false,
                        text: 'Deleting project...'
                    })
                })
            }
        }
        catch (ee) {
            //console.log(ee);
            alert.error('Request failed')
        }
    }

    // window.onload = maxWindow;
    // const maxWindow = () => {
    //     window.moveTo(0, 0);

    //     if (document.all) {
    //         top.window.resizeTo(screen.availWidth, screen.availHeight);
    //     }

    //     else if (document.layers || document.getElementById) {
    //         if (top.window.outerHeight < screen.availHeight || top.window.outerWidth < screen.availWidth) {
    //             top.window.outerHeight = screen.availHeight;
    //             top.window.outerWidth = screen.availWidth;
    //         }
    //     }
    // }


    const show_alert_box = (id) => {
        submit_for_delete_confirm(
            () => onclick_delete(id),
            () => { },
            'This will delete the project and all its designs permanently. Are you sure you want to proceed?'
        )
    }

    const fetch_recent_designs_core = async () => {
        try{
            set_initialized(false);
            var response = await window.Promisify(window.Module.get_recent_designs());
            set_initialized(true);
            var rd = JSON.parse(response);
            if (rd && rd.length) {
                let temp_map = {}
                rd.map(x => {
                    if (!temp_map[x.project_id]) {
                        temp_map[x.project_id] = []
                    }
                    temp_map[x.project_id].push(x)
                })
                set_project_to_design_map(temp_map)
            }
            set_all_designs(rd)
            var rd1 = rd && rd.slice(0, 8);
            console.log("recent_designs: ",rd1)
            set_recent_designs(rd1)
        }catch(err){
            console.log(err);
            window.sentry_capture_exception("",err);
        }
    }

    const fetch_recent_designs = async () => {
        set_page_loader({
            show: true,
            text: 'Fetching recent designs...'
        })

        setTimeout(async () => {
            await fetch_recent_designs_core();
            set_page_loader({
                show: false,
                text: 'Fetching recent designs...'
            })
        }, 0)
    }


    


    // const designs_in_project = async (id) => {
    //     let resp
    //     console.log("designs_in_project", id)


    //         console.log("designs_in_project json out")
    //         resp = await window.Promisify(window.Module.load_designs("733959ccb75945ad"))
    //         console.log("designs_in_project json", JSON.parse(resp))
    //         // set_recent_project_designs(JSON.parse(resp))
    //     return JSON.parse(resp)
    // } 


    useEffect(async () => {
        if (!projectParam) {
            if (init_needed) {
                set_init_needed(false);
                var init_resp = await init()
                if (init_resp) {
                    return;
                }
            }

            if (!window.location.origin.includes("infurnia.com")) {
                document.title = window.location.origin.split('.')[1];
            } else {
                document.title = "Infurnia"
            }
            fetch_recent_designs();
            fetch_projectDetails();
            // fetchProjectAndDesignDetails();
            var element = document.getElementById('recent_card_div');
            var height = element && element.offsetHeight;
            height && set_recent_card_div_height(Math.floor(height / 2));
            // var resp_store_details = await general_fetch({url:'/user/get_attached_stores'})
            // if(resp_store_details && resp_store_details.length>0){
            //     set_all_store_options(resp_store_details)

            // window.redirect_target = 'home'
            // }
            set_is_create_project_enabled(window.Module.is_feature_enabled('project_creation'))
        }
    }, [projectParam])

    useEffect(() => {
        if(initialized && !searchParams.get('project')){
            if(viewParam === 'recent'){
                send_analytics({category: "Recently Accessed Page Opened"})
            }else if(viewParam === 'projects'){
                send_analytics({category: "Project List Opened", project_count: ((list && list.length) || 0)})
            }
        }
    }, [viewParam, initialized]);

    useEffect(() => {
        window.redirect_status = 'infurnia';
    }, []);

   
    useEffect(() => {
        set_user_id(window.Module.get_active_user().designer_id);
        window.Promisify(window.Module.get_store_status())
            .then(resp => {
                set_project_status(JSON.parse(resp));
            })
        // console.log("designs_in_project outside if", list)

        // if(list.length){
        // console.log("designs_in_project inside if")
        //     designs_in_project(list[0].id)
        // }
    }, [list]);



    useEffect(() => {
        // Update the state when the query parameter changes
        setViewParam(searchParams.get('view') || 'recent');
        setProjectParam(searchParams.get('project') || '');
    }, [location.search]);

    useEffect(() => {
        console.log('project_to_design_map', project_to_design_map)
    }, [project_to_design_map]);

    


    const Projects = (
        <div className='inf-p-2 inf-flex inf-flex-col' style={{flex: 1, minHeight: '300px'}}>
            {/* <div className='inf-text-left inf-px-5 inf-flex inf-flex-col' style={{ height: '100%', width: '100%' }}> */}
            <div className='inf-mb-4'>
                <div className='inf-px-2 inf-flex inf-items-center inf-justify-between'>
                    <div className='inf-text-base inf-flex inf-items-center inf-font-semibold'>
                        <img className='inf-mr-2' src='/resources/MainPageIcons/folder-outline-dark.svg'></img>
                        Viewing All Projects
                    </div>
                    <ISearch style_outer={{width: '300px'}} list={list} set_list={set_list_display} placeholder={"Search Project / Client Name"} search_parameters={["name", "client_name"]} />
                    <div className='inf-flex inf-items-center'>
                        <div className='inf-mr-4' style={{color: 'var(--inf-theme-gray-400)'}}>{`Displaying ${list_display && list_display.length} projects`}</div>
                        <div className='inf-mr-4' onClick={() => set_is_grid_view(false)} style={{cursor: 'pointer', opacity: is_grid_view ? 0.5 : 1}}>
                            <img src='/resources/icons/list.svg' height={'18px'}/>
                        </div>
                        <div onClick={() => set_is_grid_view(true)} style={{cursor: 'pointer', opacity: is_grid_view ? 1 : 0.5}}>
                          <img src='/resources/icons/grid.svg' height={'18px'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ flex: 1, overflow: 'auto' }}>
                {
                    list_display && list_display.length ?
                        is_grid_view ?
                            <ProjectsGrid list_display={list_display} project_to_design_map={project_to_design_map} />
                            :
                            <Project_Table org_key={org_key} show_alert_box={show_alert_box} all_project_list_display={list_display} user_id={user_id} project_status={project_status} update_project={update_project} />
                        :
                        <FillerEmptyState message={"No Projects to list"} />
                }
            </div>
            {/* </div> */}
        </div>
    )

    return (
        
        <React.Fragment>
            <MainPageLayout store_details={store_details} org_key={org_key} logout={logout} set_page_loader={set_page_loader} viewParam={viewParam}
                // SideBarSearch={<ISearch list={list} set_list={set_list_display} search_parameters={['name', 'client_name', 'order_id']} placeholder={"Seach"} style_inner={{ width: '100%' }} style_outer={{ margin: '0px 0px var(--spacing-3) 0px' }}></ISearch>}
            >
                <div className='inf-flex inf-flex-col inf-text-left' style={{ width: '100%', height: '100%', padding: '16px 48px 48px 48px' }}>
                    {
                        projectParam ?
                            // <Designs designs={all_designs.filter(x => x.project_id === projectParam)}/>
                            <DesignsLayout key={projectParam} org_key={org_key} active_project_id={projectParam} set_page_loader={set_page_loader} submit_for_delete_confirm={submit_for_delete_confirm} store_details={store_details} init={init} logout={logout} />
                            :
                            viewParam == 'recent' ?
                                <React.Fragment>
                                    <RecentProjects list_display={list.slice(0, 4)} project_to_design_map={project_to_design_map}/>
                                    <hr style={{marginLeft: '-48px', marginRight: '-48px', borderColor: COLORS.gray3}}/>
                                    <RecentDesigns initialized={initialized} recent_designs={recent_designs} fetch_recent_designs={fetch_recent_designs} submit_for_delete_confirm={submit_for_delete_confirm} set_page_loader={set_page_loader} org_key={org_key} store_details={store_details}/>
                                </React.Fragment>
                                :
                                Projects
                    }
                </div>
            </MainPageLayout>
        </React.Fragment>
    );
}

export default Home;