const sort_by_text = ( a,b ) => {
	if (!a.text) {
		return -1;
	}
	if (!b.text) {
		return 1;
	}
    if(a.text.toLowerCase() < b.text.toLowerCase()) { 
    	return -1;
    }
    if(a.text.toLowerCase() > b.text.toLowerCase()) { 
    	return 1;
    }
    return 0;
}

export default sort_by_text;