import React, { useCallback } from "react";

const FocusWithin = ({ children, onBlur, ...props }) => {
    const handleBlur = useCallback(
      (e) => {
        const currentTarget = e.currentTarget;
  
        // Give browser time to focus the next element
        requestAnimationFrame(() => {
          // Check if the new focused element is a child of the original container
          if (!currentTarget.contains(document.activeElement)) {
            onBlur();
          }
        });
      },
      [onBlur]
    );
  
    return (
      <div {...props} onBlur={handleBlur}>
        {children}
      </div>
    );
  }

  export default FocusWithin;