import { useState, useEffect, useRef} from 'react';

import dummy_tick from './dummy_tick';


function useStateCallback(initialState) {
	const [state, setState] = useState(initialState);
	const cbRef = useRef(null); // mutable ref to store current callback

	const setStateCallback = (state, cb) => {
		cbRef.current = cb; // store passed callback to ref
		setState(state);
	};

	// const exec_callback = async () => {
	// 	// //await dummy_tick(0);
	// 	if (cbRef.current) {
	// 		cbRef.current(state);
	// 		cbRef.current = null; // reset callback after execution
	// 	}
	// }


	useEffect(() => {
		// requestAnimationFrame(() => {
			if (cbRef.current) {
				var temp = cbRef.current;
				cbRef.current = null; // reset callback after execution
				temp(state);
			}
		// });
		// if (cbRef.current) {
		// 	var temp = cbRef.current;
		// 	cbRef.current = null; // reset callback after execution
		// 	temp(state);
		// }
	}, [state]);

	return [state, setStateCallback];
}

export default useStateCallback;