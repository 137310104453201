/* eslint-disable no-unused-expressions */
import React, { useState , useEffect } from 'react';
import {Dropdown, Label, Modal, FormGroup, Button, Input, Col, DropdownMenu, DropdownItem, DropdownToggle, Table, Row } from "reactstrap";
// import general_fetch from '../../utils/fetch';
import './AFW_Structure.css';
import { useAlert } from 'react-alert'
import {useTranslation} from 'react-i18next'

const styles = {
	header_row: {
		marginLeft: 0, overflowX: "auto",
	},
	categories_div: {
		height:'40px', paddingLeft:'20px', display:'flex', alignItems:'center', color:'#435A6F', cursor: 'pointer'
	},
	text_elipsis: {
		 whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
	},
	category_dropdown_style: {
        height: '18px', fontSize: '14px', backgroundColor: 'unset', border: 'none', padding: 0, display: 'flex', alignItems: 'center', cursor:'pointer',
	}
};

const Filler = ({ initialized }) => (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Inventory to list  </b></span><br/> Try adjusting filters or consider adding from warehouse<br /> &nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching your inventory  </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
					</th>
				)}
			</tr>
		</thead>
	</Table>	
);

const modal_json = [{
	name: 'Furniture',
	id:'furniture',
	checked:true,
	categories:[
        {
            id:'cat1_fur1',
            name:'cat1_fur1',
			checked:true,
        },
        {
            id:'cat2_fur2',
            name:'cat2_fur2',
			checked:true,
        },
    ]
}, {
	name: 'Accessories',
	id:'accessory', 
	checked:true,
    categories:[
        {
            id:'cat1_accer1',
            name:'cat1_accer1',
			checked:true,
        },
        {
            id:'cat2_accer2',
            name:'cat2_accer2',
			checked:true,
        }
    ]
}, {
	name: 'Hardware', 
	id:'hardware',
	checked:true,
    categories:[
        {
            id:'cat1_hard1',
            name:'cat1_hard1',
			checked:true,
        },
        {
            id:'cat2_hard2',
            name:'cat2_hard2',
			checked:true,
        }
    ]
}, {
	name: 'Building', 
	id:'building',
	checked:true,
    categories:[
        {
            id:'cat1_build1',
            name:'cat1_build1',
			checked:true,
        },
        {
            id:'cat2_build2',
            name:'cat2_build2',
			checked:true,
        }
    ]
}, {
	name: 'Finishes', 
	id:'finish',
	checked:true,
    categories:[
        {
            id:'cat1_finish1',
            name:'cat1_finish1',
			checked:true,
        },
        {
            id:'cat2_finish2',
            name:'cat2_finish2',
			checked:true,
        }
    ]
},
]

const Category_collapse  = ({ selected_cats, selected_cats_map, set_selected_cats, sku_category, user}) => {	

	const [ is_category_selected, set_is_category_selected ] = useState(false);

		const toggle_category_selection = () => {
			set_selected_cats(selected_cats_map[sku_category.id] ? selected_cats.filter(x => x != sku_category.id) : [ ...selected_cats, sku_category.id ]);
		}

		useEffect(() => {
			if (sku_category && selected_cats_map[sku_category.id]) {
				set_is_category_selected(true);
			} else {
				set_is_category_selected(false);
			}
		}, [ selected_cats_map, sku_category ])

	return(
		<div style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center', backgroundColor: '#FAFAFA', boxShadow: 'inset 0 -1px 0 0 #E4E7EB', cursor: 'pointer'}}>
			<FormGroup style={{padding:0}} check >
				<Label className='checkbox_style' check style={{display:'flex', alignItems:'center'}}>
					{
						is_category_selected?
						<i onClick={e => toggle_category_selection()} style={{fontSize:'12px'}} className='fa fa-check-square-o' />
						:
						<i onClick={e => toggle_category_selection()} style={{fontSize:'12px'}} className='fa fa-square-o' />
					}
				</Label> 
			</FormGroup>
			<span style={{textTransform:'capitalize',paddingLeft:'24px'}}>{sku_category.name}</span>
		</div>
	)
}

const Single_division = ({selected_cats, selected_cats_map, set_selected_cats, onclick_division, division, active_division}) => {
    
    const [ is_division_selected, set_is_division_selected ] = useState(true);
	const [ is_division_partially_selected, set_is_division_partially_selected ] = useState(false);

	const toggle_division_selection = () => {
		let division_map = division.categories.reduce((final_cat, cat_elem) => ({ ...final_cat, [cat_elem.id]: true }), {});
        set_selected_cats(is_division_selected ? selected_cats.filter(x => !division_map[x]) : [ ...new Set([ ...selected_cats, ...Object.keys(division_map) ]) ]);
	}

	useEffect(() => {
		if (Object.keys(selected_cats_map).length) {
		    let division_map = division.categories.reduce((final_cat, cat_elem) => ({ ...final_cat, [cat_elem.id]: true }), {});
            set_is_division_selected(Object.keys(division_map).filter(x => !selected_cats_map[x]).length ? false : true);
			set_is_division_partially_selected(Object.keys(division_map).filter(x => selected_cats_map[x]).length ? true : false);
		} else {
			set_is_division_selected(false);
			set_is_division_partially_selected(false);
		}
	}, [ selected_cats_map, division ])

    return(
        <div onClick={() => onclick_division(division)} className={division && division.id==active_division.id ? 'division_active' : 'division_hover'} style={styles.categories_div}>
            <FormGroup style={{padding:0}} check >
                <Label className='checkbox_style' check style={{display:'flex', alignItems:'center', cursor: 'pointer'}}>
					{
						is_division_selected?
						<i onClick={e => {toggle_division_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-check-square-o' />
						:
						is_division_partially_selected?
							<i onClick={e => {toggle_division_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-minus-square-o' />
							:
							<i onClick={e => {toggle_division_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-square-o' />
					}
                </Label>
            </FormGroup>
            <div style={{marginLeft:"20px"}} >{division?division.name:''}</div>
        </div>
    )
}

const AWF_Structure = ({  modal_json,modal_type ,user, set_page_loader, handle_close, is_filter_modal, update_modal_json, handle_ui_response}) => {
	
	//State variables
	const [ selected_cats, set_selected_cats ] = useState([]);
	const [ selected_cats_map, set_selected_cats_map ] = useState({});
    const [ active_division, set_active_division ] = useState('');
	const [ categories, set_categories ] = useState([]);
	const alert = useAlert();
    const {t} = useTranslation();

	

	//Member functions 

	//Effects
    useEffect(()=>{
        if(modal_json && modal_json.length){
            set_active_division(modal_json[0])
			set_selected_cats(modal_json.reduce((final,elem) => [...final, ...elem.categories.filter(y=>y.checked).map(y=>y.id)],[]));
        }
    },[modal_json])
    
    useEffect(()=>{
        if(active_division){
            set_categories(active_division.categories && active_division.categories.length ? active_division.categories : [])
        }
    },[active_division])
    
    // useEffect(() => {
    //     console.log('categories======>>>', categories)
	// },[categories])
    
    const onclick_division = (division) => {
        set_active_division(division);
    }

    useEffect(() => {
		// console.log('selected_cats', selected_cats)
		set_selected_cats_map(selected_cats.reduce((final, elem) => ({ ...final, [elem]: true }), {}));
	}, [ selected_cats ])

	const onclick_apply_changes = () =>{
		if(selected_cats && modal_json){
			console.log('RESULT=========>',selected_cats)
            var temp_div_tree = [...modal_json]
			var result=[];
            var temp_div_tree = temp_div_tree.length && temp_div_tree.map((single_division) =>{
				var cat_sel_count=0;

				single_division.categories = single_division.categories && single_division.categories.length && single_division.categories.map((single_category) => {

					selected_cats.includes(single_category.id)?cat_sel_count++:null;
					result.push({type:'category_type', id: single_category.id, 
						checked: selected_cats.includes(single_category.id)?true:false
					})
				})
				
				result.push({type: 'division', id: single_division.id, 
					checked: single_division.categories.length===cat_sel_count?true:false
				})
			})
			console.log('RESULT=========>',result)
			// window.Module.quotation_filters_apply(JSON.stringify(result), modal_type);
		} else {
			console.log('selected_cats is not a array.');
		}
		handle_ui_response(JSON.stringify({update_view:true}));
		// console.log("onclick apply changes")
	}

	const on_click_handleclose = () => {
		handle_close()
		set_categories('')		
		set_active_division('')
		set_selected_cats([]);
		set_selected_cats_map({});
	}

	return(
		<div>
			<div style={{width:'100%', height:'700px'}}>	
				<div className="modal-header" style={{paddingLeft:'20px', paddingRight:'20px', height:'56px', width:'100%', boxShadow: 'inset 0 -1px 0 0 #E4E7EB'}}>
					<div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
						<span style={{fontSize:'18px', color:'#234361', fontFamily: 'Source_Sans_Pro-SemiBold'}}>{modal_type=="include_in_quotation_modal"?'Include in Quotation':''}{modal_type=="show_at_top_level_quotation_modal"?'Top Level':''}{modal_type=="boq_schedule"?'Select Schedule':''}</span>
						<i onClick={on_click_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
					</div>		
				</div>

				<div className="modal-body" style={{height:'calc(100% - 56px)', width:'100%', padding:0}}>
					
                <div style={{paddingTop:'10px', width:'240px', height:'calc(100% - 74px)', backgroundColor: 'rgba(255,255,255,0.00)', boxShadow: 'inset -1px 0 0 0 #F2F4F6'}}>
                    {
                        modal_json && modal_json.length ? modal_json.map((division, idx) => (
                            <Single_division key={idx} selected_cats={selected_cats} selected_cats_map={selected_cats_map} set_selected_cats={set_selected_cats} onclick_division={onclick_division} division={division} active_division={active_division}/>
                        )):<div style={{paddingLeft:'20px'}}>{t(`No Inventory`)}</div>
                    }
                </div>
					<div style={{position:'absolute', bottom:'0px', left:'0px' ,width:'240px', height:'74px'}} />		
					<div style={{position:'absolute', top:'0px', left:'240px' ,width:'calc(100% - 240px)', height:'100%'}}>		
						{
							modal_json && modal_json.length?
								<div style={{height:'40px', padding:'0px 20px', display:'flex', alignItems:'center'}}>
									<span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', color:'#234361'}}>Category for <span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', textTransform: 'capitalize', color:'#234361'}}>{active_division && active_division.id?active_division.id:'No Name'}</span></span>
								</div>
								:''
						}
						<div style={{height:'calc(100% - 90px)', overflow:'auto', width:'100%'}}>
							{
								(categories && categories.length) ? categories.map((sku_category, idx) => (
									
                                    <Category_collapse key={idx} selected_cats_map={selected_cats_map} selected_cats={selected_cats} set_selected_cats={set_selected_cats} sku_category={sku_category} user={user} />
								)) : (
									<div style={{paddingTop:'10px',paddingLeft:'20px'}}>{t(`No Category`)}</div>
									// <Filler initialized={categories && categories.length ? true : false} />
								) 
							}
						</div>
						<div style={{height:'40px', padding:'0px 20px', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
							<Button onClick={selected_cats.length||modal_type==='show_at_top_level_quotation_modal'?onclick_apply_changes:null} disabled={selected_cats.length||modal_type==='show_at_top_level_quotation_modal'?false:true}  style={{height:'30px'}} className='blue_button' >Apply</Button>
						</div>
					</div>	
				</div>
			</div>
		</div>					
	)
}

export default AWF_Structure;


// import React, { useState , useEffect } from 'react';
// import {Dropdown, Label, Modal, FormGroup, Button, Input, Col, DropdownMenu, DropdownItem, DropdownToggle, Table, Row } from "reactstrap";
// // import general_fetch from '../../utils/fetch';
// import './sku_groups.css';
// import { useAlert } from 'react-alert'

// const styles = {
// 	header_row: {
// 		marginLeft: 0, overflowX: "auto",
// 	},
// 	categories_div: {
// 		height:'40px', paddingLeft:'20px', display:'flex', alignItems:'center', color:'#435A6F', cursor: 'pointer'
// 	},
// 	text_elipsis: {
// 		 whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
// 	},
// 	category_dropdown_style: {
//         height: '18px', fontSize: '14px', backgroundColor: 'unset', border: 'none', padding: 0, display: 'flex', alignItems: 'center', cursor:'pointer',
// 	}
// };

// const Filler = ({ initialized }) => (
// 	<Table className="align-items-center table-flush text-center" responsive>
// 		<thead className="thead-light text-center">
// 			<tr>
// 				{ initialized ? (
// 					<th scope="col">
// 						<span style={styles.table_header}><b>No Inventory to list  </b></span><br/> Try adjusting filters or consider adding from warehouse<br /> &nbsp;
// 					</th>
// 				):(
// 					<th scope="col">
// 						<Row>
// 							<Col xs={12} className="text-center">
// 								<img src="/load2.gif" />
// 							</Col>
// 							<Col xs={12} className="text-center">
// 								<span style={styles.table_header}><b>Fetching your inventory  </b></span><br/> <br /> Please wait...<br /> <br />
// 							</Col>
// 						</Row>
// 					</th>
// 				)}
// 			</tr>
// 		</thead>
// 	</Table>	
// );

// const modal_json = [{
// 	name: 'Furniture',
// 	id:'furniture',
// 	checked:true,
// 	categories:[
//         {
//             id:'cat1_fur1',
//             name:'cat1_fur1',
// 			checked:true,
//             sub_categories:[
//                 {
//                     id:'sub1_cat1_fur1',
//                     name:'sub1_cat1_fur1',
// 					checked:true,
//                 },
//                 {
//                     id:'sub2_cat1_fur1',
//                     name:'sub2_cat1_fur1',
// 					checked:true,
//                 },
//             ]
//         },
//         {
//             id:'cat2_fur2',
//             name:'cat2_fur2',
// 			checked:true,
//             sub_categories:[
//                 {
//                     id:'sub1_cat2_fur2',
//                     name:'sub1_cat2_fur2',
// 					checked:true,
//                 },
//                 {
//                     id:'sub2_cat2_fur2',
//                     name:'sub2_cat2_fur2',
// 					checked:true,
//                 }
//             ]
//         },
//     ]
// }, {
// 	name: 'Accessories',
// 	id:'accessory', 
// 	checked:true,
//     categories:[
//         {
//             id:'cat1_accer1',
//             name:'cat1_accer1',
// 			checked:true,
//             sub_categories:[
//                 {
//                     id:'sub1_cat1_accer1',
//                     name:'sub1_cat1_accer1',
// 					checked:true,
//                 },
//                 {
//                     id:'sub2_cat1_accer1',
//                     name:'sub2_cat1_accer1',
// 					checked:true,
//                 }
//             ]
//         },
//         {
//             id:'cat2_accer2',
//             name:'cat2_accer2',
// 			checked:true,
//             sub_categories:[
//                 {
//                     id:'sub1_cat2_accer2',
//                     name:'sub1_cat2_accer2',
// 					checked:true,
//                 },
//                 {
//                     id:'sub2_cat2_accer2',
//                     name:'sub2_cat2_accer2',
// 					checked:true,
//                 }
//             ]
//         }
//     ]
// }, {
// 	name: 'Hardware', 
// 	id:'hardware',
// 	checked:true,
//     categories:[
//         {
//             id:'cat1_hard1',
//             name:'cat1_hard1',
// 			checked:true,
//             sub_categories:[
//                 {
//                     id:'sub1_cat1_hard1',
//                     name:'sub1_cat1_hard1',
// 					checked:true,
//                 },
//                 {
//                     id:'sub2_cat1_hard1',
//                     name:'sub2_cat1_hard1',
// 					checked:true,
//                 }
//             ]
//         },
//         {
//             id:'cat2_hard2',
//             name:'cat2_hard2',
// 			checked:true,
//             sub_categories:[
//                 {
//                     id:'sub1_cat2_hard2',
//                     name:'sub1_cat2_hard2',
// 					checked:true,
//                 },
//                 {
//                     id:'sub2_cat2_hard2',
//                     name:'sub2_cat2_hard2',
// 					checked:true,
//                 }
//             ]
//         }
//     ]
// }, {
// 	name: 'Building', 
// 	id:'building',
// 	checked:true,
//     categories:[
//         {
//             id:'cat1_build1',
//             name:'cat1_build1',
// 			checked:true,
//             sub_categories:[
//                 {
//                     id:'sub1_cat1_build1',
//                     name:'sub1_cat1_build1',
// 					checked:true,
//                 },
//                 {
//                     id:'sub2_cat1_build1',
//                     name:'sub2_cat1_build1',
// 					checked:true,
//                 }
//             ]
//         },
//         {
//             id:'cat2_build2',
//             name:'cat2_build2',
// 			checked:true,
//             sub_categories:[
//                 {
//                     id:'sub1_cat2_build2',
//                     name:'sub1_cat2_build2',
// 					checked:true,
//                 },
//                 {
//                     id:'sub2_cat2_build2',
//                     name:'sub2_cat2_build2',
// 					checked:true,
//                 }
//             ]
//         }
//     ]
// }, {
// 	name: 'Finishes', 
// 	id:'finish',
// 	checked:true,
//     categories:[
//         {
//             id:'cat1_finish1',
//             name:'cat1_finish1',
// 			checked:true,
//             sub_categories:[
//                 {
//                     id:'sub1_cat1_finish1',
//                     name:'sub1_cat1_finish1',
// 					checked:true,
//                 },
//                 {
//                     id:'sub2_cat1_finish1',
//                     name:'sub2_cat1_finish1',
// 					checked:true,
//                 }
//             ]
//         },
//         {
//             id:'cat2_finish2',
//             name:'cat2_finish2',
// 			checked:true,
//             sub_categories:[
//                 {
//                     id:'sub1_cat2_finish2',
//                     name:'sub1_cat2_finish2',
// 					checked:true,
//                 },
//                 {
//                     id:'sub2_cat2_finish2',
//                     name:'sub2_cat2_finish2',
// 					checked:true,
//                 }
//             ]
//         }
//     ]
// },
// ]

// const Category_collapse  = ({ selected_sub_cats, selected_sub_cats_map, set_selected_sub_cats, sku_category, user}) => {	

// 	const [show_collapse, set_show_collapse] = useState(false)
// 	const [ is_category_selected, set_is_category_selected ] = useState(false);
// 	const [ is_category_partialy_selected, set_is_category_partialy_selected ] = useState(false);

// 	const open_close_collapse = () => {
// 		if(sku_category){
// 			set_show_collapse(!show_collapse)
// 		}
// 	}

// 	const toggle_category_selection = () => {
// 		let category_map = sku_category.sub_categories.reduce((final, elem) => ({ ...final, [elem.id]: true }), {});
// 		set_selected_sub_cats(is_category_selected ? selected_sub_cats.filter(x => !category_map[x]) : [ ...new Set([ ...selected_sub_cats, ...Object.keys(category_map) ]) ]);
// 	}

// 	useEffect(() => {
// 		if (Object.keys(selected_sub_cats_map).length) {
// 			// console.log('caf selection ops ---<>>>> ');
// 			set_is_category_selected(sku_category.sub_categories.filter(x => !selected_sub_cats_map[x.id]).length ? false : true);
// 			set_is_category_partialy_selected(sku_category.sub_categories.filter(x => selected_sub_cats_map[x.id]).length ? true : false);
// 		} else {
// 			set_is_category_selected(false);
// 			set_is_category_partialy_selected(false)
// 		}
// 	}, [ selected_sub_cats_map, sku_category ])

// 	useEffect(() => {
// 		set_show_collapse(false);
// 		console.log("YOOOOO", sku_category)
// 	}, [ sku_category ])

// 	return(
// 		<div>
// 			<div onClick={open_close_collapse} style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center', backgroundColor: '#FAFAFA', boxShadow: 'inset 0 -1px 0 0 #E4E7EB', cursor: 'pointer'}}>
// 				<FormGroup style={{padding:0}} check >
// 					<Label className='checkbox_style' check style={{display:'flex', alignItems:'center'}}>
// 						{
// 							is_category_selected?
// 							<i onClick={e => {toggle_category_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-check-square-o' />
// 							:
// 							is_category_partialy_selected?
// 								<i onClick={e => {toggle_category_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-minus-square-o' />
// 								:
// 								<i onClick={e => {toggle_category_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-square-o' />
// 						}
// 						{/* <Input style={{margin:0}} type="checkbox" checked={is_category_selected}  onClick={e => {toggle_category_selection(); e.stopPropagation()}} /> */}
// 					</Label> 
// 				</FormGroup>
// 				<span style={{textTransform:'capitalize',paddingLeft:'24px'}}>{sku_category.name}</span>
// 			</div>
// 			<div >
// 				<Row style={{display:show_collapse?'flex':'none', alignItems:'center',margin:0, width:'100%'}}>
// 				{
// 					sku_category && sku_category.sub_categories && sku_category.sub_categories.length ? sku_category.sub_categories.map((sku_sub_category, idx) => (
// 						<Single_sub_category key={idx} selected_sub_cats={selected_sub_cats} selected_sub_cats_map={selected_sub_cats_map} set_selected_sub_cats={set_selected_sub_cats} user={user} sku_sub_category={sku_sub_category} />
// 					)):
// 					<div style={{padding:'20px'}}>No Sub-Category</div>
// 				}
// 				</Row>
// 			</div>
// 		</div>
// 	)
// }

// const Single_sub_category  = ({ selected_sub_cats, selected_sub_cats_map, set_selected_sub_cats, sku_sub_category}) => {	

// 	const [ is_sub_category_selected, set_is_sub_category_selected ] = useState(true);

// 	const toggle_sub_cats_selection = () => {
// 		// console.log('In --->>>>> ', selected_sub_cats_map, sku_sub_category, selected_sub_cats.filter(x => x != sku_sub_category.id));
// 		set_selected_sub_cats(selected_sub_cats_map[sku_sub_category.id] ? selected_sub_cats.filter(x => x != sku_sub_category.id) : [ ...selected_sub_cats, sku_sub_category.id ]);
// 	}

// 	useEffect(() => {
// 		if (sku_sub_category && selected_sub_cats_map[sku_sub_category.id]) {
// 			set_is_sub_category_selected(true);
// 		} else {
// 			set_is_sub_category_selected(false);
// 		}
// 	}, [ selected_sub_cats_map, sku_sub_category ])


// 	return(
// 		<Col xs={12} sm={6} md={4} lg={3} xl={3} style={{padding:'10px', height:'100%'}}>
//             <div style={{padding:'10px', height:'auto', border: '1px solid #D5DAE0', fontSize:'12px', backgroundColor: '#F8F9FA', borderRadius: '4px 4px 0 0', display:'flex', alignItems:'center' }}>
//                 <FormGroup style={{padding:0}} check >
//                     <Label className='checkbox_style' check style={{display:'flex', alignItems:'center', cursor: 'pointer'}}>
// 						{
// 							is_sub_category_selected?
// 							<i onClick={e => {toggle_sub_cats_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-check-square-o' />
// 							:
// 							<i onClick={e => {toggle_sub_cats_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-square-o' />
// 						}
//                         {/* <Input checked={is_sub_category_selected} onClick={e => {toggle_sub_cats_selection(); e.stopPropagation()}} style={{margin:0}} type="checkbox" /> */}
//                     </Label>
//                 </FormGroup>
//                 <span style={{textTransform:'capitalize',paddingLeft:'24px', ...styles.text_elipsis}}>{sku_sub_category&&sku_sub_category.name ? sku_sub_category.name : ''}</span>
//             </div>
// 		</Col>
// 	)
// }

// const Single_division = ({selected_sub_cats, selected_sub_cats_map, set_selected_sub_cats, onclick_division, division, active_division}) => {
    
//     const [ is_division_selected, set_is_division_selected ] = useState(true);
// 	const [ is_division_partially_selected, set_is_division_partially_selected ] = useState(false);

// 	const toggle_division_selection = () => {
//         // console.log('TOGGLE DIVISIONS');
// 		let division_map = division.categories.reduce((final_cat, cat_elem) => ({ ...final_cat, ...(cat_elem.sub_categories.reduce((final, elem) => ({ ...final, [elem.id]: true }), {})) }), {});
// 		// console.log("DIV MAP --->>> ", division_map)
//         set_selected_sub_cats(is_division_selected ? selected_sub_cats.filter(x => !division_map[x]) : [ ...new Set([ ...selected_sub_cats, ...Object.keys(division_map) ]) ]);
// 	}

// 	useEffect(() => {
//        // console.log('selected_sub_cats_map ______ ', selected_sub_cats_map);
// 		if (Object.keys(selected_sub_cats_map).length) {
// 			// console.log('group selection ops ---<>>>> ', selected_sub_cats_map, sku_category.sku, (sku_category.sku.filter(x => !selected_sub_cats_map[x])).length);
// 		    let division_map = division.categories.reduce((final_cat, cat_elem) => ({ ...final_cat, ...cat_elem.sub_categories.reduce((final, elem) => ({ ...final, [elem.id]: true }), {}) }), {});
//             set_is_division_selected(Object.keys(division_map).filter(x => !selected_sub_cats_map[x]).length ? false : true);
// 			set_is_division_partially_selected(Object.keys(division_map).filter(x => selected_sub_cats_map[x]).length ? true : false);
// 		} else {
// 			set_is_division_selected(false);
// 			set_is_division_partially_selected(false);
// 		}
// 	}, [ selected_sub_cats_map, division ])

//     return(
//         <div onClick={() => onclick_division(division)} className={division && division.id==active_division.id ? 'division_active' : 'division_hover'} style={styles.categories_div}>
//             <FormGroup style={{padding:0}} check >
//                 <Label className='checkbox_style' check style={{display:'flex', alignItems:'center', cursor: 'pointer'}}>
// 					{
// 						is_division_selected?
// 						<i onClick={e => {toggle_division_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-check-square-o' />
// 						:
// 						is_division_partially_selected?
// 							<i onClick={e => {toggle_division_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-minus-square-o' />
// 							:
// 							<i onClick={e => {toggle_division_selection(); e.stopPropagation()}} style={{fontSize:'12px'}} className='fa fa-square-o' />
// 					}
//                     {/* <Input checked={is_division_selected}  onClick={e => {toggle_division_selection(); e.stopPropagation()}} style={{margin:0}} type="checkbox" />     */}
//                 </Label>
//             </FormGroup>
//             <div style={{marginLeft:"20px"}} >{division?division.name:''}</div>
//         </div>
//     )
// }

// const AWF_Structure = ({  modal_type ,user, set_page_loader, handle_close, is_filter_modal, update_modal_json, handle_ui_response}) => {
	
// 	//State variables
// 	const [ selected_sub_cats, set_selected_sub_cats ] = useState([]);
// 	const [ selected_sub_cats_map, set_selected_sub_cats_map ] = useState({});
//     const [ active_division, set_active_division ] = useState('');
// 	const [ categories, set_categories ] = useState([]);
// 	const [ all_categories, set_all_categories ] = useState([]);
// 	const alert = useAlert();
	

// 	//Member functions 

// 	//Effects
//     useEffect(()=>{
//         if(modal_json && modal_json.length){
//             set_active_division(modal_json[0])
// 			set_all_categories(modal_json.reduce((final,elem) => [...final, ...elem.categories.map(y=>y)],[]));
//         }
//     },[modal_json])

// 	useEffect(()=>{
// 		set_selected_sub_cats(all_categories.reduce((final,elem) => [...final, ...elem.sub_categories.filter(y=>y.checked).map(y=>y.id)],[]))
//     },[all_categories])
    
//     useEffect(()=>{
//         if(active_division){
//             set_categories(active_division.categories && active_division.categories.length ? active_division.categories : [])
//         }
//     },[active_division])
    
//     useEffect(() => {
//         console.log('categories======>>>', categories)
// 	},[categories])
    
//     const onclick_division = (division) => {
//         set_active_division(division);
//     }

//     useEffect(() => {
// 		console.log('selected_sub_cats', selected_sub_cats)
// 		set_selected_sub_cats_map(selected_sub_cats.reduce((final, elem) => ({ ...final, [elem]: true }), {}));
// 	}, [ selected_sub_cats ])

// 	// const onclick_apply_changes = () =>{
// 	// 	if(selected_sub_cats && selected_sub_cats.length && modal_json){
//     //         var temp_div_tree = [...modal_json]
//     //         var temp_div_tree = temp_div_tree.length && temp_div_tree.filter((single_division) =>{

// 	// 			single_division.categories = single_division.categories && single_division.categories.length && single_division.categories.filter((single_category) => {
					
// 	// 				single_category.sub_categories = single_category.sub_categories && single_category.sub_categories.length && single_category.sub_categories.filter((single_sub_category) => 
// 	// 					selected_sub_cats.includes(single_sub_category.id)
// 	// 				)

// 	// 				return single_category.sub_categories && single_category.sub_categories.length
// 	// 			})
				
// 	// 			return single_division.categories && single_division.categories.length
// 	// 		})
// 	// 		console.log('temp_div_tree=========>',temp_div_tree)
// 	// 		on_click_handleclose()
// 	// 	} else {
// 	// 		console.log('No inventory added');
// 	// 	}
// 	// }

// 	const onclick_apply_changes = () =>{
// 		if(selected_sub_cats && selected_sub_cats.length && modal_json){
//             var temp_div_tree = [...modal_json]
// 			var result=[];
//             var temp_div_tree = temp_div_tree.length && temp_div_tree.map((single_division) =>{
// 				var cat_sel_count=0;

// 				single_division.categories = single_division.categories && single_division.categories.length && single_division.categories.map((single_category) => {
// 					var sub_sel_count=0;

// 					single_category.sub_categories = single_category.sub_categories && single_category.sub_categories.length && single_category.sub_categories.map((single_sub_category) => {
// 						selected_sub_cats.includes(single_sub_category.id)?sub_sel_count++:null;
// 						result.push({type:'sub_category_type', id: single_sub_category.id, 
// 							checked: selected_sub_cats.includes(single_sub_category.id)?true:false
// 						})
// 					})

// 					single_category.sub_categories.length===sub_sel_count?cat_sel_count++:null;
// 					result.push({type:'category_type', id: single_category.id, 
// 						checked: single_category.sub_categories.length===sub_sel_count?true:false
// 					})
// 				})
				
// 				result.push({type: 'division', id: single_division.id, 
// 					checked: single_division.categories.length===cat_sel_count?true:false
// 				})
// 			})
// 			// console.log('RESULT=========>',result)
// 			if(modal_type=='boq_schedule'){
// 				// window.Module.quotation_filters_apply(JSON.stringify(result), modal_type);	
// 			}else{
// 				window.Module.quotation_filters_apply(JSON.stringify(result), modal_type);	
// 			}
// 		} else {
// 			alert.info('No inventory added');
// 		}
// 		handle_ui_response(JSON.stringify({update_view:true}));
// 		// console.log("onclick apply changes")
// 		on_click_handleclose()
// 	}

// 	const on_click_handleclose = () => {
// 		handle_close()
// 		set_categories('')		
// 		set_active_division('')
// 		set_selected_sub_cats([]);
// 		set_selected_sub_cats_map({});
// 	}

// 	return(
// 		<div>
// 			<div style={{width:'100%', height:'700px'}}>	
// 				<div className="modal-header" style={{paddingLeft:'20px', paddingRight:'20px', height:'56px', width:'100%', boxShadow: 'inset 0 -1px 0 0 #E4E7EB'}}>
// 					<div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
// 						<span style={{fontSize:'18px', color:'#234361', fontFamily: 'Source_Sans_Pro-SemiBold'}}>{modal_type=="include_in_quotation_modal"?'Filter':''}{modal_type=="show_at_top_level_quotation_modal"?'Move to Top':''}{modal_type=="boq_schedule"?'Select Schedule':''}</span>
// 						<i onClick={on_click_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
// 					</div>		
// 				</div>

// 				<div className="modal-body" style={{height:'calc(100% - 56px)', width:'100%', padding:0}}>
					
//                 <div style={{paddingTop:'10px', width:'240px', height:'calc(100% - 74px)', backgroundColor: 'rgba(255,255,255,0.00)', boxShadow: 'inset -1px 0 0 0 #F2F4F6'}}>
//                     {
//                         modal_json && modal_json.length ? modal_json.map((division, idx) => (
//                             <Single_division key={idx} selected_sub_cats={selected_sub_cats} selected_sub_cats_map={selected_sub_cats_map} set_selected_sub_cats={set_selected_sub_cats} onclick_division={onclick_division} division={division} active_division={active_division}/>
//                         )):<div style={{paddingLeft:'20px'}}>No Inventory</div>
//                     }
//                 </div>
// 					<div style={{position:'absolute', bottom:'0px', left:'0px' ,width:'240px', height:'74px'}} />		
// 					<div style={{position:'absolute', top:'0px', left:'240px' ,width:'calc(100% - 240px)', height:'100%'}}>		
// 						{
// 							modal_json && modal_json.length?
// 								<div style={{height:'40px', padding:'0px 20px', display:'flex', alignItems:'center'}}>
// 									<span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', color:'#234361'}}>Category for <span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', textTransform: 'capitalize', color:'#234361'}}>{active_division && active_division.id?active_division.id:'No Name'}</span></span>
// 								</div>
// 								:''
// 						}
// 						<div style={{height:'calc(100% - 90px)', overflow:'auto', width:'100%'}}>
// 							{
// 								(categories && categories.length) ? categories.map((sku_category, idx) => (
									
//                                     <Category_collapse key={idx} selected_sub_cats_map={selected_sub_cats_map} selected_sub_cats={selected_sub_cats} set_selected_sub_cats={set_selected_sub_cats} sku_category={sku_category} user={user} />
// 								)) : (
// 									<div style={{paddingTop:'10px',paddingLeft:'20px'}}>No Category</div>
// 									// <Filler initialized={categories && categories.length ? true : false} />
// 								) 
// 							}
// 						</div>
// 						<div style={{height:'40px', padding:'0px 20px', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
// 							<Button onClick={onclick_apply_changes} disabled={!selected_sub_cats.length}  style={{height:'30px'}} className='blue_button' >Apply</Button>
// 						</div>
// 					</div>	
// 				</div>
// 			</div>
// 		</div>					
// 	)
// }

// export default AWF_Structure;