import React, { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import { useAlert } from 'react-alert';
import debug_log from '../../utils/debug_log';
import { Button, Input } from 'reactstrap';
import './style.css';

export const UploadFile = ({ id, set_file, file_size, styles, button_name, accept, update_additional_file, additional_files_idx, set_text, additional_files, callbackOnClickUpload}) => {

	const onchange_filename = (e) => {
		if(update_additional_file){
			update_additional_file(additional_files_idx, e.target.files[0]) 
		}else{
			var file = e.target.files[0];
			set_file(file)
			var reader = new FileReader();
			reader.onload = function(event) {
				// The file's text will be printed here
				console.log(event.target.result)
				set_text(event.target.result)
			};
			if((accept == '.mtl' || accept == '.json') && file) reader.readAsText(file)
			e.stopPropagation();
		}
	}

	const onclick_upload_button = () => {
		document.getElementById(id).click();
		if(callbackOnClickUpload){
			callbackOnClickUpload()
		}
	}

	useEffect(() => {
		if(additional_files && !additional_files[additional_files_idx]){
			document.getElementById(id).value = null
		}
	},[additional_files])

	return(
		<div>
			<Button className="primary_button_default" style={styles} onClick = {onclick_upload_button}>{button_name?button_name:'Select file'}</Button>
			<Input id={id} type="file" onChange={(e) => onchange_filename(e)} style={{display:"none"}} accept={accept}/>
		</div>	
	)
}

const Upload3dModel = ({primary_file, set_primary_file, mtl_file, set_mtl_file, additional_files, set_additional_files, primary_file_type, set_primary_file_type, mtl_text, set_mtl_text, expected_texture_files, set_expected_texture_files, callbackOnClickUpload3DModel}) => {

	const alert = useAlert();

	const validate_model_filename = (filename) => {
		var re = /^[._a-zA-Z0-9 ]+$/
		return re.test(filename)
	}

	useEffect(() => {
		if (primary_file) {
			var file = primary_file.name;
			var file_array = file.split('.')
			var file_type = file_array[file_array.length - 1]
			if (primary_file) {
				set_primary_file_type(file_type)
			}
		} else {
			set_primary_file_type('')
		}
	}, [primary_file])

	useEffect(() => {
		if (primary_file_type === 'obj' && primary_file && mtl_file) {
			let temp_arr = expected_texture_files && expected_texture_files.length ? expected_texture_files.map(x => null) : ''
			set_additional_files(temp_arr)
		} else if (primary_file && (primary_file_type == 'gltf' || primary_file_type == 'blend')) {
			set_additional_files([])
		}
	}, [primary_file_type, mtl_file, expected_texture_files])

	const add_additional_file = () => {
		let a = [...additional_files]
		a.push(null)
		set_additional_files(a)
	}

	const update_additional_file = (index, value) => {
		let a = [...additional_files]
		a[index] = value
		set_additional_files(a)
	}

	useEffect(() => {
		if ((primary_file_type == 'gltf' || primary_file_type == 'blend') && Object.keys(additional_files) && Object.keys(additional_files).length) {
			var add_entry = true;
			Object.keys(additional_files).map((json_idx) => {
				if (!additional_files[json_idx]) {
					add_entry = false;
				}
			})
			if (add_entry) {
				add_additional_file()
			}
		} else if ((primary_file_type == 'gltf' || primary_file_type == 'blend')) {
			add_additional_file()
		}
	}, [additional_files])

	useEffect(() => {
		if (mtl_text) {
			let names = mtl_text.split(/\s|\/|\\|:/)
			let filtered_names = names && names.length ? names.filter(x => x.includes('.png') || x.includes('.jpg') || x.includes('.jpeg')) : ''
			let unique_names = [...new Set(filtered_names)]
			set_expected_texture_files(unique_names)

			let lines = mtl_text.split(/\r?\n/)

			let new_mtl_text = ""
			let temp = lines && lines.length ? lines.map((x, idx) => {
				if (x.includes('.png') || x.includes('.jpg') || x.includes('.jpeg')) {
					let words = x.split(" ")
					if (words && words.length) {
						let edited_text = words[0] + " "
						let t = words && words.length ? words.map((y, index) => {
							if (y.includes('.png') || y.includes('.jpg') || y.includes('.jpeg')) {
								let smaller_words = y.split(/\/|\\|:/)
								let tempp = smaller_words && smaller_words.length ? smaller_words.map(z => {
									if (z.includes('.png') || z.includes('.jpg') || z.includes('.jpeg'))
										edited_text += z
								}) : ''
							} else {
								// edited_text += y
								// edited_text += " "
							}
						}) : ''
						new_mtl_text += edited_text
					}
				} else {
					new_mtl_text += x
				}
				if (idx != lines.length - 1) new_mtl_text += '\n'
			}) : ''

			let uploadBlob = new Blob([new_mtl_text])
			let new_mtl_file = new File([uploadBlob], mtl_file.name)
			set_mtl_file(new_mtl_file)
		}
	}, [mtl_text])


	return (
        <React.Fragment>
				<div className='inf_3d_model_upload_header'>
					Upload 3D Model file
				</div>
				<div className='inf_3d_model_upload_body'>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ flex: '1', minWidth: '80px' }}>
							<UploadFile accept=".blend,.glb,.obj,.skp" id="id_upload_file_low" button_name='Upload' set_file={set_primary_file} callbackOnClickUpload={callbackOnClickUpload3DModel}/>
							<div className='flex_property'>{primary_file ? <span className='lines1_elipsis' style={{ fontSize: '12px' }} title={primary_file.name}><i class="fa fa-paperclip" aria-hidden="true" style={{ color: 'grey' }} /><span>{' '}</span>{primary_file.name} </span> : <span style={{ opacity: '0.5' }}><i class="fa fa-paperclip" aria-hidden="true" style={{ color: 'grey' }} /><span style={{ fontSize: '12px' }}> {'No file chosen'}</span></span>}</div>
						</div>
						<div className='inf-pl-3' style={{ flex: '3' }}>
							<div>Format: .Obj, .blend, .glb, .skp</div>
							{
								primary_file && primary_file.size > 102400000 ?
									<div style={{ color: 'red' }}>File size exceeded, Please select a file of size less than 100 MB</div>
									:
									<div style={{ color: '#425A70' }}>Please select a file of size less than 100 MB</div>
							}
							{
								primary_file && primary_file.name && !validate_model_filename(primary_file.name) ?
									<div style={{ color: 'red' }}>Invalid Filename, Filename can only have alphanumeric characters</div>
									:
									<div style={{ color: '#425A70' }}></div>
							}
						</div>
					</div>
				</div>

				{
					primary_file && primary_file_type == 'obj' ?
						<>
							<hr className='inf-my-4'/>
							<div className='inf_3d_model_upload_header'>
								Upload Material file
							</div>
							<div className='inf_3d_model_upload_body'>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ flex: '1' }}>
										<UploadFile accept=".mtl" id="id_upload_file_mtl_low" button_name='Upload' set_file={set_mtl_file} set_text={set_mtl_text} style={{ backgroundColor: '#f0ad4e', borderColor: '#f0ad4e', color: 'white', fontSize: '13px' }} />
										<div style={{ marginLeft: '3px' }}>{mtl_file ? <span className='lines1_elipsis' style={{ fontSize: '12px' }}><i class="fa fa-paperclip" aria-hidden="true" style={{ color: 'grey' }} /><span>{' '}</span>{mtl_file.name} </span> : <span style={{ opacity: '0.5' }}><i class="fa fa-paperclip" aria-hidden="true" style={{ color: 'grey' }} /><span style={{ fontSize: '12px' }}> {'No file chosen'}</span></span>}</div>
									</div>
									<div className='inf-pl-3' style={{ flex: '3' }}>
										<div>Format: .mtl</div>
										{
											mtl_file && mtl_file.size > 10000000 ?
												<div style={{ color: 'red' }}>File size exceeded, Please select a file of size less than 10 MB</div>
												:
												<div style={{ color: '#425A70' }}>Please select a file of size less than 10 MB</div>
										}
										{
											mtl_file && mtl_file.name && !validate_model_filename(mtl_file.name) ?
												<div style={{ color: 'red' }}>Invalid Filename, Filename can only have alphanumeric characters</div>
												:
												<div style={{ color: '#425A70' }}></div>
										}
									</div>
								</div>
							</div>
						</>
						: ''
				}

				{
					(mtl_file && primary_file_type == 'obj' && expected_texture_files && expected_texture_files.length) || ((primary_file_type == 'gltf' || primary_file_type == 'blend')) ?
						<>
							<hr className='inf-my-4'/>
							<div className='inf_3d_model_upload_header inf-flex inf-justify-between'>
								Upload Texture file
								<span style={{ color: '#425A70', fontSize: '14px', fontWeight: 400 }}>Please select files of size less than 10Mb</span>
							</div>
							<div className='inf_3d_model_upload_body' style={{ maxHeight: '240px', overflowY: 'scroll' }}>
								<div className='inf-mb-2'>{'Upload required texture files'} </div>
								{
									primary_file_type == 'obj' && expected_texture_files && expected_texture_files.length ? expected_texture_files.map((texture_file_name, idx) => (
										<div className='display_onhover flex_property inf-py-2'>
											<div style={{flex: 2}}>
												<UploadFile accept=".jpg,.jpeg,.png" id={"id_upload_file_high" + idx} button_name='Upload' update_additional_file={update_additional_file} additional_files_idx={idx} expected_name={texture_file_name} additional_files={additional_files} style={{ backgroundColor: '#f0ad4e', borderColor: '#f0ad4e', color: 'white', fontSize: '13px' }} />
											</div>
											<div style={{ flex: 5 }} className='lines1_elipsis' title={additional_files[idx] && additional_files[idx].name ? additional_files[idx].name : texture_file_name}>
												<i class="fa fa-paperclip" aria-hidden="true" style={{ color: 'grey', opacity: additional_files[idx] ? 1 : 0.5 }} />
												<span style={{ opacity: additional_files[idx] ? 1 : 0.5 }}>{additional_files[idx] ? '' : 'Upload'} {additional_files[idx] ? additional_files[idx].name : texture_file_name}</span>
												{
													additional_files[idx] && additional_files[idx].name != texture_file_name ? <span style={{ color: 'red' }}> {'(Expecting "'} {texture_file_name}{'")'} </span> : ''
												}
												{
													additional_files[idx] && additional_files[idx].name == texture_file_name && additional_files[idx].size > 10000000 ? <span style={{ color: 'red' }}>{'(File size exceeded 10MB)'}</span> : ''
												}
											</div>
											<div style={{flex: 1, display: 'flex', justifyContent:'flex-end'}} className='x1 inf-px-2'>
												<span className='invisible1'><i onClick={() => { update_additional_file(idx, null) }} style={{ color: 'red', cursor: 'pointer' }} className='fa fa-trash' /></span>
											</div>
										</div>
									)) : ''
								}
								{
									(primary_file_type == 'gltf' || primary_file_type == 'blend') && additional_files && additional_files.length ? additional_files.map((addl_file, idx) => {
										return (
											<div className='display_onhover flex_property inf-py-2'>
												<div style={{flex: 2}}>
													<UploadFile accept=".jpg,.jpeg,.png" id={"id_upload_file_high" + idx} button_name='Upload' update_additional_file={update_additional_file} additional_files_idx={idx} additional_files={additional_files} style={{ backgroundColor: '#f0ad4e', borderColor: '#f0ad4e', color: 'white', fontSize: '13px' }} />
												</div>
												<div style={{ flex: 5 }} className='lines1_elipsis' title={additional_files[idx] && additional_files[idx].name ? additional_files[idx].name : ''}>
													<i class="fa fa-paperclip" aria-hidden="true" style={{ color: 'grey', opacity: additional_files[idx] ? 1 : 0.5 }} />
													<span style={{ opacity: additional_files[idx] ? 1 : 0.5 }}>{additional_files[idx] ? additional_files[idx].name : 'No file selected'}</span>
													{
														additional_files[idx] && additional_files[idx].size > 10000000 ? <span style={{ color: 'red' }}>{'(File size exceeded 10MB)'}</span> : ''
													}
												</div>
												<div style={{flex: 1, display: 'flex', justifyContent:'flex-end'}} className='x1 inf-px-2'>
													<span className='invisible1'><i onClick={() => { update_additional_file(idx, null) }} style={{ color: 'red', cursor: 'pointer' }} className='fa fa-trash' /></span>
												</div>
											</div>
										)
									}) : ''
								}
							</div>
						</>
						: ''
				}
			{/* <IModalFooter>
				<Button
					disabled={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) ? false : true}
					onClick={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) ? upload_asset : null}
					className='primary_button_default' type="button" >
					Confirm Uploads
				</Button>
			</IModalFooter> */}
            </React.Fragment>
	);
}

export default Upload3dModel;